import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Info extends React.Component {

    render() {
        const { t, Clinic } = this.props;

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="mb-5" title={t("NumberAuto")}>
                    <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Clinic.Id}</span>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-text me-1"></i>
                                {t("Clinic.Forms.Name.Title")}
                            </span>
                            <span className="ms-5">
                                <i title={t(`IsActive.${Clinic.IsActive}`)} className={`fa-solid fa-${Clinic.IsActive === false ? "lock text-danger" : "lock-open text-success"}  me-3`}></i>
                                {Clinic.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-user-doctor me-1"></i>
                                {t("Clinic.Forms.DoctorName.Title")}
                            </span>
                            <span className="ms-10">
                                {Clinic.DoctorName}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-kit-medical me-1"></i>
                                {t("Clinic.Forms.MedicalSpecialist.Title")}
                            </span>
                            <span className="ms-10">
                                {Clinic.MedicalSpecialist?.Name}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">

                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-hospital me-1"></i>
                                {t("HealthCenter.Title")}
                            </span>
                            <span className="ms-10">
                                {Clinic.HealthCenter.Name}
                            </span>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-circle-dollar me-1"></i>
                            {t("Clinic.Services")}
                        </div>
                        <table className="table table-sm table-striped table-row-gray-800 table-rounded table-hover table-fix-head border mb-0" style={{
                            borderCollapse: 'collapse'
                        }} >
                            <tbody className="fs-7 text-gray-800 border border-gray-800" style={{
                                borderCollapse: 'collapse'
                            }}>
                                {
                                    Clinic.Services?.map(service => {
                                        return <tr key={`services-${service.Id}`}>
                                            <td>
                                                <span className={`bullet bullet-lg me-2 bg-primary`}></span>
                                                <span>
                                                    {service.Name}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="ms-1">
                                                    {service.Price}
                                                </span>
                                                <span className="ms-1">
                                                    {service.Currency.Name}
                                                </span>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    Clinic.Users && Clinic.Users.length > 0 ?
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-users me-1"></i>
                                    {t("Clinic.UserClinics.Title")}
                                </div>
                                <table className="table table-sm table-striped table-row-gray-800 table-rounded table-hover table-fix-head border mb-0" style={{
                                    borderCollapse: 'collapse'
                                }} >
                                    <thead className="fs-6 fw-bold align-middle border  border-gray-800" style={{
                                        borderCollapse: 'collapse'
                                    }}>
                                        <tr>
                                            <th title={t("Clinic.UserClinics.User")}  >
                                                <span className="d-flex align-items-center justify-content-center">
                                                    <i className="fa-regular text-dark fa-users me-md-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Clinic.UserClinics.User")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("LastUpdatedUser")}  >
                                                <span className="d-flex align-items-center justify-content-center">
                                                    <i className="fa-regular text-dark fa-user me-md-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("LastUpdatedUser")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("CreationDate")}  >
                                                <span className="d-flex align-items-center justify-content-center">
                                                    <i className="fa-regular text-dark fa-calendar-clock me-md-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("CreationDate")}
                                                    </span>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="fs-7 text-gray-800 border border-gray-800" style={{
                                        borderCollapse: 'collapse'
                                    }}>
                                        {
                                            Clinic.Users.map(user => {
                                                return <tr key={`users-${user.Id}`}>
                                                    <td>
                                                        {user.Doctor.Name}
                                                    </td>
                                                    <td>
                                                        {user.User.Name}
                                                    </td>
                                                    <td>
                                                        {user.CreationDate}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div> : ""
                }
                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-user me-1"></i>
                                {t("LastUpdatedUser")}
                            </span>
                            <span className="ms-10">
                                {Clinic.User.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("CreationDate")}
                            </span>
                            <span className="ms-10">
                                {Clinic.CreationDate}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("LastUpdatedDate")}
                            </span>
                            <span className="ms-10">
                                {Clinic.UpdateDate}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));