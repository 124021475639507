import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";
import Select from 'react-select';

import serverAPI from "../../../api/serverAPI";
import SelectCurrencies from "../../shared/SelectList/SelectCurrencies";
import { fetchClinics } from "../../Manager/Clinics/actions";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, Id: 0, Currency: null, clinics: [], defaultCurrency: null };

    constructor(props) {
        super(props);
        const { clinics } = this.props;
        if (!clinics || !clinics.clinics || clinics.clinics?.length === 0) {
            this.props.fetchClinics();
        }
        this.ClinicId = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.clinics.length === 0 && props.clinics && props.clinics.clinics) {
            state.clinics = [];
            state.clinics = props.clinics.clinics.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }

        if (!state.isInitialize && props.isEdit === true && props.service) {
            state.isInitialize = true;
            props.initialize({
                CurrencyId: props.service.Currency.Id,
                Price: props.service.Price,
                Name: props.service.Name
            });
            state.Id = props.service.Id;
        }
        return state;
    }

    componentDidMount() {
        if (this.props.isEdit && this.props.service) {
            this.setState({
                defaultCurrency: [{ Id: this.props.service.Currency.Id, value: this.props.service.Currency.Id, label: this.props.service.Currency.Name }]
            });
        }
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.formValues.CurrencyId && (this.props.isEdit || this.ClinicId.current?.getValue()[0]?.Id)) {
            this.setState({
                isSubmit: true
            });

            let url = '/ClinicServices/';
            let method = serverAPI.post;
            if (this.props.isEdit) {
                method = serverAPI.put;
                url = '/ClinicServices/' + this.state.Id;
            }

            method(url, {
                ...this.props.formValues,
            }).then(response => {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
                this.props.update();
                this.props.handleClose();
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
            });

        } else {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.AllInformationIsRequired.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.AllInformationIsRequired.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        }
        return false;
    }


    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setCurrency = Currency => {
        this.props.dispatch(change('formClinicService', 'CurrencyId', Currency.Id));
    }

    setClinic = Clinic => {
        this.props.dispatch(change('formClinicService', 'ClinicId', Clinic.Id));
    }

    render() {
        const { t, clinics } = this.props;

        let isLoadedClinics = false;
        if (clinics) {
            isLoadedClinics = clinics.isLoaded;
        }

        return <>

            <form method="POST" name="formClinicService" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <div className={`col-md-${this.props.isEdit ? "4" : "3"}`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Name"><i className="fa-regular text-dark fa-text me-2"></i>{t('Services.Forms.Name.Title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Name"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Services.Forms.Name.Placeholder')}
                            title={t('Services.Forms.Name.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className={`col-md-${this.props.isEdit ? "4" : "3"}`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Price"><i className="fa-regular text-dark fa-coin me-2"></i>{t('Services.Forms.Price.Title')}</label>
                        <Field
                            ref={Price => this.Price = Price}
                            name="Price"
                            id="Price"
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Services.Forms.Price.Placeholder')}
                            title={t('Services.Forms.Price.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className={`col-md-${this.props.isEdit ? "4" : "3"}`}>
                        <SelectCurrencies required onChange={this.setCurrency} defaultValue={this.state.defaultCurrency} />
                    </div>
                    {
                        this.props.isEdit ? "" :
                            <div className={`col-md-3`}>
                                <label className={`col-form-label required fw-bold fs-6`} htmlFor="ClinicId"><i className="fa-regular text-dark fa-house-medical-flag me-2"></i>{t('Clinic.Forms.Name.Title')}</label>
                                <Select
                                    onChange={this.setClinic}
                                    name="ClinicId"
                                    id="ClinicId"
                                    placeholder={t('Clinic.Forms.Name.Select')}
                                    title={t('Clinic.Forms.Name.Select')}
                                    isLoading={!isLoadedClinics}
                                    isSearchable={true}
                                    required
                                    ref={this.ClinicId}
                                    options={this.state.clinics}
                                    filterOption={this.filterOptions}
                                />
                            </div>
                    }
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Name) {
        errors.Name = 'Services.Forms.Name.Error';
    }
    if (!values.Price) {
        errors.Price = 'Services.Forms.Price.Error';
    }
    if (!values.ClinicId) {
        errors.ClinicId = 'Clinic.Forms.Name.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formClinicService')(state),
        validate,
        clinics: state.clinics,
        user: state.user
    };
};

const formClinicService = reduxForm({
    form: 'formClinicService',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, { fetchClinics })(withTranslation('common')(formClinicService(Form)));
