import serverAPI from "../../../../api/serverAPI";

export const fetcGynecologyInfoes = id => async dispatch => {
    dispatch({ type: 'GYNE_INFO_LOADING' });
    const response = await serverAPI.get(`/GynecologyInfoes/${id}`);
    dispatch({ type: 'GYNE_INFO_INITIAL', gynecologyInfo: response.data });
}

export const fetcGynecologyInfoHistories = id => async dispatch => {
    dispatch({ type: 'GYNE_INFO_HISTORY_LOADING' });
    const response = await serverAPI.get(`/GynecologyInfoHistories/${id}`);
    dispatch({ type: 'GYNE_INFO_HISTORY_INITIAL', gynecologyInfoHistories: response.data });
}