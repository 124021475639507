const GynecologyInfoesReducers = (state = [], action) => {
    switch (action.type) {
        case "GYNE_INFO_LOADING":
            return {
                gynecologyInfo: null,
                isLoaded: false
            };
        case "GYNE_INFO_INITIAL":
            return {
                gynecologyInfo: action.gynecologyInfo,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default GynecologyInfoesReducers;