import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import SelectExam from "./SelectExam";
import serverAPI from "../../../../api/serverAPI";
import SelectField from "../../../shared/SelectField";

import { fetchLabCenters } from '../actions';

class Form extends React.Component {

    state = { Id: 0, isSubmit: false, SelectedExam: [], labCenters: [], CurrentLabCenters: "", initialize: false };

    constructor(props) {
        super(props);
        const { labCenters } = this.props;
        if (!labCenters || !labCenters.labCenters || labCenters.labCenters?.length === 0) {
            this.props.fetchLabCenters();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.labCenters && state.labCenters.length === 0 && props.labCenters.labCenters) {
            state.labCenters = [];
            props.labCenters.labCenters.forEach(item => {
                state.labCenters.push({
                    ...item,
                    id: item.Id,
                    label: item.HealthCenter.Name + " - " + item.Name
                });
            });
        }
        if (!state.initialize && props.isEdit && props.Order) {
            state.initialize = true;
            props.initialize({
                OrderNote: props.Order.Note
            });
            state.Id = props.Order.Id;
            state.SelectedExam = props.Order.items.map(p => p.LabExam);
            state.CurrentLabCenters = props.Order.LabCenter;
        }
        return state;
    }

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} readOnly={readOnly} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Select')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderTextArea = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        rows,
        meta: { touched, error }
    }) => (
        <>
            <textarea {...input} id={id} title={title} rows={rows} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    onSubmit = event => {
        const { t, Appointment } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let method = serverAPI.post;
        if (this.props.isEdit === true) {
            method = serverAPI.put;
        }
        method(`/VisitLabOrders/${Appointment.Id}`, {
            ...this.props.formValues,
            LabOrderId: this.state.Id,
            PatientId: Appointment.patient.Id,
            ClinicId: this.props.currentClinic.Id,
            LabCenterId: this.state.CurrentLabCenters ? this.state.CurrentLabCenters.Id : null,
            Exams: this.state.SelectedExam.map(({ Id }) => Id)
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Order.Lab.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Order.Lab.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
            this.props.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return event.preventDefault();
    }

    setSelectedExam = SelectedExam => {
        this.setState({
            SelectedExam
        });
    }

    setCurrentLabCenters = CurrentLabCenters => {
        this.setState({
            CurrentLabCenters
        });
    }

    render() {
        const { t, pristine, submitting } = this.props;

        return (<>
            <form method="POST" name="formNewLabOrder" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={this.onSubmit}>
                <div className="row mb-5" >
                    <div className="col-md-7 ">
                        <SelectExam SelectedExam={this.state.SelectedExam} setSelectedExam={this.setSelectedExam} />
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex justify-content-between flex-column">
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                                <div className="d-flex align-items-center">
                                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                        <i className="fa-regular fa-vial me-2 text-primary h3 mb-0"></i>
                                        {t('ClinicScreen.Orders.Lab.SelectedExam')}
                                        :
                                        ({this.state.SelectedExam.length})
                                    </div>
                                </div>
                            </div>
                            <div className={`scroll h-350px border-1 p-3  mb-2`} >
                                <div className="d-flex flex-column">
                                    {
                                        this.state.SelectedExam.map(item => {
                                            return <li key={`exam-item-${item.Id}`} className="d-flex align-items-center py-2">
                                                <span className="bullet me-5 bg-primary"></span> {item.Name}
                                            </li>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="row w-100 ">
                                    {
                                        this.state.labCenters?.length > 0 ?
                                            <SelectField
                                                Text={this.state.CurrentLabCenters ? this.state.CurrentLabCenters.HealthCenter.Name + " - " + this.state.CurrentLabCenters.Name : ""}
                                                Title={t("ClinicScreen.Orders.Lab.Center.Title")}
                                                Placeholder={t("ClinicScreen.Orders.Lab.Center.Placeholder")}
                                                update={this.setCurrentLabCenters}
                                                data={this.state.labCenters} /> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="OrderNote"><i className="fa-regular text-dark fa-note me-2"></i>{t('ClinicScreen.Orders.Forms.Note.Title')}</label>
                        <Field
                            ref={OrderNote => this.OrderNote = OrderNote}
                            name="OrderNote"
                            id="OrderNote"
                            rows={5}
                            component={this.renderTextArea}
                            placeholder={t('ClinicScreen.Orders.Forms.Note.Placeholder')}
                            title={t('ClinicScreen.Orders.Forms.Note.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('ClinicScreen.Orders.Lab.Send')} disabled={(pristine || submitting || this.state.isSubmit || this.state.SelectedExam.length === 0) && (this.state.SelectedExam.length === 0 || this.state.isSubmit)} data-kt-indicator={this.state.isSubmit ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-microscope"></i>
                            {t('ClinicScreen.Orders.Lab.Send')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose} disabled={submitting}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formNewLabOrder')(state),
        labCenters: state.labCenters,
        user: state.user
    };
};

const formNewLabOrder = reduxForm({
    form: 'formNewLabOrder',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetchLabCenters })(withTranslation('common')(formNewLabOrder(Form)));