import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

class SelectExam extends React.Component {

    state = { SelectedExam: [] };

    setSelectedExam = Exam => {
        const objIndex = this.state.SelectedExam.findIndex(p => p.Id === Exam.Id);
        let SelectedExam = this.state.SelectedExam;
        if (objIndex >= 0) {
            SelectedExam = SelectedExam.filter(p => p.Id !== Exam.Id);
        } else {
            SelectedExam.push(Exam);
        }
        this.setState({
            SelectedExam
        }, () => { this.props.setSelectedExam(SelectedExam) });
    }

    setSelectedAll = isChecked => {
        let SelectedExam = [];
        if (isChecked) {
            this.props.Order.items.filter(p => p.OrderItemStatus?.Id === 5).forEach(Exam => {
                SelectedExam.push(Exam);
            });
        }
        this.setState({
            SelectedExam
        }, () => { this.props.setSelectedExam(SelectedExam) });
    }

    renderTableRow = () => {
        const { t, Order } = this.props;

        const Items = Order.items.filter(p => p.OrderItemStatus?.Id === 5);
        if (Items.length > 0) {
            return Items.map((exam) => {
                const hasExam = this.state.SelectedExam.some(p => p.Id === exam.Id);
                return <tr key={`accepted-lab-exam-${exam.Id}`}>
                    <td>
                        <div className={`form-check form-check-custom form-check-${exam.OrderItemStatus?.Color}`} title={t(`OrdersStatus.${exam.OrderItemStatus?.Name}`)}>
                            <input
                                name="checkbox"
                                type="checkbox"
                                value={exam.Id}
                                onChange={() => this.setSelectedExam(exam)}
                                className="form-check-input"
                                checked={hasExam}
                            />
                        </div>
                    </td>
                    <td>
                        {exam.LabExam?.Name}
                    </td>
                    <td>
                        {exam.LabExam.Type?.Name}
                    </td>
                </tr>
            });
        }
        return <tr><td className="text-center" colSpan={3}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t } = this.props;
        return (<>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                        <i className="fa-regular fa-vial me-2 text-primary h3 mb-0"></i>
                        {t('ClinicScreen.Orders.Lab.SelectedExam')}
                        :
                        ({this.state.SelectedExam.length})
                    </div>
                </div>
                <div>
                    <div className="form-check form-check-custom">
                        <input
                            name="checkbox"
                            type="checkbox"
                            id="SelectAll"
                            onChange={(e) => this.setSelectedAll(e.target.checked)}
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="SelectAll">
                            {t('SelectAll')}
                        </label>
                    </div>
                </div>
            </div>
            <div className={`  border-1 border-dark border-dashed table-responsive`} >
                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                    <thead>
                        <tr>
                            <th title={t("Select")}>
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-square-check"></i>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Lab.Table.Name")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-vial me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Name")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Lab.Table.Type")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-flask-vial me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Type")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRow()}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(SelectExam));