const UserClinicsReducers = (state = [], action) => {
    switch (action.type) {
        case "USER_CLINICS_LOADING":
            return {
                userClinics: [],
                isLoaded: false
            };
        case "USER_CLINICS_INITIAL":
            return {
                userClinics: action.userClinics,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default UserClinicsReducers;