import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { change, getFormValues, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import { ConfigProvider, TimePicker } from "antd";
import { Link } from "react-router-dom";

import serverAPI from "../../../api/serverAPI";
import Search from "../../Patients/Search";
import CreatePatient from "../../Patients/Create/";
import History from "../Modal/History";
import { fetchVisitHistory } from '../actions';
import SearchByDocumentNumber from "../../Patients/Search/ByDocumentNumber/";
import PatientsCard from "../../Patients/PatientsCard";

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Date: `${moment().format('DD/MM/YYYY')}`,
            isInitialize: false,
            showSearchModal: false,
            showHistoryModal: false,
            isSubmit: false,
            Patient: null,
            loading: false,
            StartTime: '',
            EndTime: '',
            showNewPatient: false,
            DocumentNumber: ""
        };
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && !props.isNew) {
            state.isInitialize = true;
            props.initialize({
                Date: props.item.Date
            });
            state.Date = props.item.Date;
        }
        return state;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.props.dispatch(change('formAppoitment', 'Date', picker.startDate.format('DD/MM/YYYY')));
        this.setState({
            Date: picker.startDate.format('DD/MM/YYYY')
        });
    }

    showSearchModal = () => {
        this.setState({
            showSearchModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showSearchModal: false,
            showHistoryModal: false,
            showNewPatient: false
        });
    }

    showHistoryModal = () => {
        if (this.state.Patient &&
            this.props.currentClinic &&
            (!this.props.visitHistory || this.props.visitHistory.PatientId !== this.state.Patient.Id || this.props.visitHistory.ClinicId !== this.props.currentClinic.Id)) {
            this.props.fetchVisitHistory(this.state.Patient.Id, this.props.currentClinic.Id);
        }
        this.setState({
            showHistoryModal: true
        });
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    selectPatient = Patient => {
        if (Patient) {
            this.props.dispatch(change('formAppoitment', 'PatientId', Patient.Id));
        } else {
            this.props.dispatch(change('formAppoitment', 'PatientId', ""));
        }
        this.setState({ Patient, showSearchModal: false });
    }

    changePatient = () => {
        this.props.dispatch(change('formAppoitment', 'PatientId', ""));
        this.setState({ Patient: null });
    }

    onChangeTime = (time, timeString) => {
        this.props.dispatch(change('formAppoitment', 'StartTime', timeString[0]));
        this.props.dispatch(change('formAppoitment', 'EndTime', timeString[1]));
        this.setState({ StartTime: timeString[0], EndTime: timeString[1] });
    }

    onSubmit = () => {
        const { t } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        serverAPI.post(`/ClinicRegistration/`, {
            ...this.props.formValues,
            StartTime: this.state.StartTime,
            EndTime: this.state.EndTime,
            PatientId: this.state.Patient.Id,
            ClinicId: this.props.currentClinic.Id
        }).then(response => {
            if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Save.NewAppoitment.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.NewAppoitment.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.handleClose();
            } else if (response.data.code === "patient_is_exist") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.patient_is_exist.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.patient_is_exist.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "fill_all_inputs") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.fill_all_inputs.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.fill_all_inputs.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            }
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    DocumentIsNotExist = DocumentNumber => {
        this.setState({
            showNewPatient: true,
            DocumentNumber
        });
    }

    showNewPatient = () => {
        this.setState({
            showNewPatient: true
        });
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;
        const { Patient } = this.state;

        return <>
            {
                this.state.showSearchModal ? <Search handleClose={this.handleClose} select={this.selectPatient} /> : ""
            }
            {
                this.state.showHistoryModal && Patient ? <History handleClose={this.handleClose} Patient={Patient} /> : ""
            }
            {
                this.state.showNewPatient ? <CreatePatient handleClose={this.handleClose} DocumentNumber={this.state.DocumentNumber} /> : ""
            }
            <form method="POST" name="formAppoitment" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password"
                onSubmit={
                    handleSubmit(values => {
                        return new Promise(resolve => resolve())
                            .then(() => {
                                const errors = validate(values, this.props);
                                return Object.keys(errors).length !== 0;
                            })
                            .then(errors => {
                                if (errors) {
                                    this.checkSubmit();
                                } else {
                                    this.onSubmit();
                                }
                            })
                    })
                }>

                <div className="row">
                    <div className="col-md-6">
                        <div className="row ms-10">

                            <Link
                                to="#"
                                title={t('Patient.New')}
                                className="btn btn-sm btn-sm-icon btn-light-danger "
                                onClick={() => this.showNewPatient()}>
                                <i className="h3 fa-solid fa-plus me-1"></i>
                                {t('Patient.New')}
                            </Link>

                            <div className="col-md-12 mb-3">
                                <label className="col-form-label required fw-bold fs-6" htmlFor="Appointment_Date">{t('Appointment.Date')}</label>
                                <DateRangePicker
                                    ref={SelectDate => this.SelectDate = SelectDate}
                                    initialSettings={{
                                        singleDatePicker: true,
                                        opens: 'center',
                                        autoUpdateInput: true,
                                        buttonClasses: 'btn',
                                        cancelClass: "btn-danger",
                                        applyButtonClasses: "btn-primary",
                                        showDropdowns: true,
                                        format: 'DD/MM/YYYY',
                                        timePicker: false,
                                        minDate: `${moment().format('DD/MM/YYYY')}`,
                                        locale: {
                                            format: 'DD/MM/YYYY',
                                            cancelLabel: t('Close'),
                                            firstDay: 6,
                                            applyLabel: t('Apply'),
                                            customRangeLabel: t('DateRangePicker.Range.customRange'),
                                            monthNames: [
                                                `${t("DateRangePicker.Months.January")}`,
                                                `${t("DateRangePicker.Months.February")}`,
                                                `${t("DateRangePicker.Months.March")}`,
                                                `${t("DateRangePicker.Months.April")}`,
                                                `${t("DateRangePicker.Months.May")}`,
                                                `${t("DateRangePicker.Months.June")}`,
                                                `${t("DateRangePicker.Months.July")}`,
                                                `${t("DateRangePicker.Months.August")}`,
                                                `${t("DateRangePicker.Months.September")}`,
                                                `${t("DateRangePicker.Months.October")}`,
                                                `${t("DateRangePicker.Months.November")}`,
                                                `${t("DateRangePicker.Months.December")}`
                                            ],
                                            daysOfWeek: [
                                                t("DateRangePicker.DaysOfWeek.Sunday"),
                                                t("DateRangePicker.DaysOfWeek.Monday"),
                                                t("DateRangePicker.DaysOfWeek.Tuesday"),
                                                t("DateRangePicker.DaysOfWeek.Wednesday"),
                                                t("DateRangePicker.DaysOfWeek.Thursday"),
                                                t("DateRangePicker.DaysOfWeek.Friday"),
                                                t("DateRangePicker.DaysOfWeek.Saturday")
                                            ],
                                        },
                                    }}
                                    onApply={this.handleApplyDate} >
                                    <input
                                        defaultValue={this.state.Date}
                                        ref={Date => this.Date = Date}
                                        name="Date"
                                        id="Appointment_Date"
                                        autoComplete="off"
                                        required
                                        className="form-control form-control-solid ps-10"
                                        type="text"
                                        placeholder={t('Appointment.Forms.Date.Placeholder')}
                                        title={t('Appointment.Forms.Date.Title')}
                                    />
                                </DateRangePicker>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="col-form-label fw-bold fs-6" htmlFor="Time">{t('Appointment.Forms.Time.Title')}</label>
                                <ConfigProvider theme={{
                                    token: {
                                        fontFamily: 'Du_Bold',
                                    }
                                }}>
                                    <TimePicker.RangePicker
                                        inputReadOnly
                                        id="Time"
                                        name="Time"
                                        needConfirm={true}
                                        ref={Time => this.Time = Time}
                                        format={'HH:mm a'}
                                        minuteStep={5}
                                        size="large"
                                        onChange={this.onChangeTime}
                                        className="form-control form-control-solid ps-10 d-flex text-center"
                                        placeholder={t('Appointment.Forms.Time.Placeholder')}
                                        title={t('Appointment.Forms.Time.Title')}
                                        activeBg={"#ff0000"}
                                    />
                                </ConfigProvider>
                            </div>
                            <div className="col-md-12 mb-3">
                                <SearchByDocumentNumber showCard={false} update={this.selectPatient} DocumentIsNotExist={this.DocumentIsNotExist} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {
                            Patient ? <div className="ms-10"><PatientsCard patient={Patient} />      </div> : ""
                        }
                    </div>
                </div>
                <div className="modal-footer pb-0 px-0">
                    {
                        Patient ? <>
                            <button type="button" className="btn btn-sm btn-info me-auto" title={t('Appointment.History.Title')} onClick={() => this.showHistoryModal()}>
                                <i className="me-1 fa-solid fa-rectangle-history-circle-user"></i>
                                {t('Appointment.History.Title')}
                            </button>
                        </> : ""
                    }
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Appointment.Forms.Save')} disabled={pristine || submitting || Patient === null || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-calendar-check"></i>
                            {t('Appointment.Forms.Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose} disabled={submitting}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>

            </form >
        </>;
    }
}

const validate = values => {
    const errors = {};

    if (!values.Date) {
        errors.Date = 'Patient.Forms.Date.Error';
    }

    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formAppoitment')(state),
        user: state.user,
        visitHistory: state.visitHistory
    };
};

const formAppoitment = reduxForm({
    form: 'formAppoitment',
    validate,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetchVisitHistory })(withTranslation('common')(formAppoitment(Form)));

