import serverAPI from "../../../../api/serverAPI";

export const fetchRadiologyExams = () => async dispatch => {
    dispatch({ type: 'RADIOLOGY_EXAMS_LOADING' });
    const response = await serverAPI.get(`/RadiologyExams/`);
    dispatch({ type: 'RADIOLOGY_EXAMS_INITIAL', radiologyExams: response.data.data });
}

export const fetchRadiologyExamTypes = () => async dispatch => {
    dispatch({ type: 'RADIOLOGY_EXAM_TYPES_LOADING' });
    const response = await serverAPI.get(`/RadiologyExamTypes/`);
    dispatch({ type: 'RADIOLOGY_EXAM_TYPES_INITIAL', radiologyExamTypes: response.data.data });
}

export const fetchRadiologyCenters = () => async dispatch => {
    dispatch({ type: 'RADIOLOGY_CENTER_LOADING' });
    const response = await serverAPI.get(`/RadiologyCenters/`);
    dispatch({ type: 'RADIOLOGY_CENTER_INITIAL', radiologyCenters: response.data.data });
}

export const fetchRadiologyOrders = (Id, PatientId) => async dispatch => {
    dispatch({ type: 'RADIOLOGY_ORDERS_LOADING' });
    const response = await serverAPI.get(`/VisitRadiologyOrders/${Id}`);
    dispatch({ type: 'RADIOLOGY_ORDERS_INITIAL', radiologyOrders: response.data.data, PatientId });
}