const PatientChronicDiseasesReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_CHRONIC_DISEASE_LOADING":
            return {
                patientChronicDisease: null,
                isLoaded: false
            };
        case "PATIENT_CHRONIC_DISEASE_INITIAL":
            return {
                patientChronicDisease: action.patientChronicDisease,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientChronicDiseasesReducers;