import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class Profile extends React.Component {

    render() {
        const { t, user } = this.props;
        return (<>
            <Helmet>
                <title>{t("Pages.Profile")} | {t('SiteName')}</title>
            </Helmet>
            {
                user?.user ?
                    <div className="d-flex justify-content-center col-12">
                        <div className="card mb-5 mb-xl-10 col-xl-8">
                            <div className="card-header cursor-pointer">
                                <div className="card-title m-0">
                                    <h3 className="fw-bolder m-0">
                                        <i className="fa-solid h2 fa-address-card me-1 text-primary"></i>
                                        {t("Pages.Profile")}
                                    </h3>
                                </div>
                                <div className="align-self-center">
                                    <Link to={"/Profile/Edit"} className="btn btn-sm btn-primary me-2">
                                        <i className="fa-regular fa-edit me-1"></i>
                                        {t("Profile.Edit")}
                                    </Link>
                                    <Link to={"/Profile/ChangePassword"} className="btn btn-sm btn-danger me-2">
                                        <i className="fa-regular fa-key me-1"></i>
                                        {t("Profile.ChangePassword.Title")}
                                    </Link>
                                </div>

                            </div>
                            <div className="card-body p-9">
                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        {t("Profile.Form.Name.Title")}
                                    </label>
                                    <div className="col-lg-8">
                                        {user.user.Name}
                                    </div>
                                </div>

                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-hospital me-1"></i>
                                        {t("HealthCenter.Title")}
                                    </label>
                                    <div className="col-lg-8">
                                        {user.user.HealthCenter.Name}
                                    </div>
                                </div>

                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-at me-1"></i>
                                        {t("Profile.Form.Email.Title")}
                                    </label>
                                    <div className="col-lg-8">
                                        {user.user.Email}
                                    </div>
                                </div>


                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-phone me-1"></i>
                                        {t("Profile.Form.Phone.Title")}
                                    </label>
                                    <div className="col-lg-8">
                                        {user.user.Phone}
                                    </div>
                                </div>

                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        {t("Users.Form.Username.Title")}
                                    </label>
                                    <div className="col-lg-8">
                                        {user.user.Username}
                                    </div>
                                </div>
                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-shield-keyhole me-1"></i>
                                        {t("Users.Form.Permission.Title")}
                                    </label>
                                    <div className="col-lg-8">
                                        <div className="d-flex flex-column">
                                            {
                                                user.user.UserPermission.map(permission => {
                                                    return <li key={`permissions-${permission.Id}`} className="d-flex align-items-center pb-1">
                                                        <span className={`bullet bullet-lg me-2 bg-primary`}></span>
                                                        <span>
                                                            {permission.Name}
                                                        </span>
                                                    </li>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">
                                        <i className="fa-regular text-dark fa-right-to-bracket me-1"></i>
                                        {t("Profile.Form.LastLogin")}
                                    </label>
                                    <div className="col-lg-8">
                                        {user.user.LastLogin}

                                        <Link to={"/Profile/Login/History"} className="btn btn-sm btn-info mx-3">
                                            <i className="fa-regular fa-history"></i>
                                            {t("Profile.LoginHistory")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
            }
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Profile));