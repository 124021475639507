import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PatientName from "../../Patients/PatientName";

import ServiceInfo from "../../ClinicScreen/Services/Info";

class Info extends React.Component {

    state = { showServiceInfo: false, service: null }

    showServiceInfo = (service, type) => {
        this.setState({
            showServiceInfo: true,
            service: { ...service, Type: type.Type }
        });
    }

    handleClose = () => {
        this.setState({
            showServiceInfo: false,
            service: null
        });
    }

    render() {
        const { t, Bill } = this.props;
        return <>
            {
                this.state.showServiceInfo && this.state.service ?
                    <ServiceInfo service={this.state.service} handleClose={this.handleClose} /> :
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.props.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                                {t("Table.Options.Info")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                Bill.Visit.patient ? <div className="row mb-2">
                                    <span className="d-flex align-items-center text-muted">
                                        <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Patient.Name")}
                                        </span>
                                    </span>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <div className="d-flex align-items-center ms-10">
                                            <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                                <PatientName patient={Bill.Visit.patient} />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""
                            }
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar me-2"></i>
                                            {t("Appointment.Forms.Date.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Bill.Visit.Date}
                                        </span>
                                    </div>
                                </div>
                                {
                                    Bill.Visit.Clinic ? <div className="col-md-6">
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-house-medical-flag me-2"></i>
                                                {t("HealthCenter.Clinic")}
                                            </span>
                                            <span className="ms-10">
                                                {Bill.Visit.Clinic?.Name}
                                            </span>
                                        </div>
                                    </div> : ""
                                }

                            </div>

                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                        <i className="fa-regular fa-file-invoice-dollar me-2 text-primary h3 mb-0"></i>
                                        {t('Services.Bill_Title')}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex flex-column ms-10">
                                    {
                                        Bill.Types.map(type => {
                                            return <div key={`service-type-item-${type.Type.Id}`} className="mb-1">
                                                <div className="mb-3">
                                                    {t(`Services.Type.${type.Type.Name}`)} {type.items.length > 1 ? ` : ( ${type.items.length} )` : ""}
                                                </div>
                                                <div className="d-flex flex-column ms-10 mb-3">
                                                    {
                                                        type.items.map(service => {
                                                            return <li key={`service-sub-item-${service.Id}`} className="d-flex align-items-center mb-3">
                                                                <span className={`bullet me-3 bg-${service.IsDeported ? "success" : "dark"} `}></span>
                                                                <div className="row me-5 flex-grow-1">
                                                                    <Link to="#" className="col-md-9" onClick={() => this.showServiceInfo(service, type)} >
                                                                        {service.Details}
                                                                    </Link>
                                                                    <div className="col-md-3">
                                                                        {service.Price + " " + service.Currency.Name}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row mt-5">
                                    {
                                        Bill.Bill.Discount ?
                                            <>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <span className="fw-bold text-muted mb-2">
                                                            <i className="fa-regular text-dark fa-sigma me-2"></i>
                                                            {t("Services.Amount")}
                                                            :
                                                        </span>
                                                        <span className="fs-5 ms-10">
                                                            {Bill.Bill.Amount}
                                                            <span className="mx-1">
                                                                {Bill.Bill.Currency.Name}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <span className="fw-bold text-muted mb-2">
                                                            <i className="fa-solid fa-percent me-2 text-dark "></i>
                                                            {t("Services.Discount.Title")}
                                                            :
                                                        </span>
                                                        <span className="fs-5 ms-10">
                                                            {Bill.Bill.Discount}
                                                            <span className="mx-1">
                                                                {Bill.Bill.Currency.Name}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </> : ""
                                    }

                                    <div className={`${Bill.Bill.Discount ? "col-md-4" : "w-100 d-flex justify-content-evenly pt-3"}`} title={t("Services.Total.Note")}>
                                        <span className="d-md-block d-none text-nowrap">
                                            <i className="fa-regular text-dark fa-calculator me-2"></i>
                                            {t("Services.Total.Title")}
                                            :
                                        </span>
                                        <span className="fs-5 ms-10">
                                            {Bill.Bill.Total}
                                            <span className="mx-1">
                                                {Bill.Bill.Currency.Name}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            }
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));