const PatientsReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_LOADING":
            return {
                patients: [],
                toRemove: [],
                isLoaded: false
            };
        case "PATIENT_INITIAL":
            return {
                patients: action.patients,
                toRemove: [],
                isLoaded: true
            };
        case "PATIEN_UPDATE":
            state.patients = state.patients.filter(v => v.Id !== action.id);
            if (state.patients) {
                state.patients.unshift(action.newNode)
            } else {
                state.patients = [action.newNode];
            }
            return {
                patients: state.patients,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "PATIEN_ADD_NEW":
            if (state.patients) {
                state.patients.unshift(action.newNode)
            } else {
                state.patients = [action.newNode];
            }
            return {
                patients: state.patients,
                toRemove: state.toRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientsReducers;