import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import serverAPI from "../../../api/serverAPI";
// import { changeLockedStatus,  removeClinc } from "./actions";
import Info from "./Info";
import { changeLockedStatus, clearClinics, removeClinics, undoDeleteClinics } from "./actions";
import Edit from "./Edit";
import UserPermission from "./UserPermission";
// import Edit from "./Edit";
// import Permission from "./Permission";

class Table extends React.Component {

    state = {
        clinic: null,
        showInfo: false,
        showEdit: false,
        showEditPermissions: false
    };

    handleClose = () => {
        this.setState({
            clinic: null,
            showInfo: false,
            showEdit: false,
            showEditPermissions: false
        });
    }

    showEditPermissions = clinic => {
        this.setState({
            showEditPermissions: true,
            clinic
        })
    }

    showEdit = clinic => {
        this.setState({
            showEdit: true,
            clinic
        })
    }

    showInfo = clinic => {
        this.setState({
            showInfo: true,
            clinic
        })
    }


    changeLockStatusClinc = clinic => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        let type = "Locked";
        if (clinic.IsActive === false) {
            type = "Unlocked";
        }

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t(`Processing.Clinic.${type}`)}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-dark " onClick={() => { toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-xmark"></i> {t('Undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.confirmLockStatusClinc(clinic.Id, type); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-check"></i> {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.WARNING,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    confirmLockStatusClinc = (id, type) => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        serverAPI.put(`/ChangeLockStatusClinc/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t(`toast.Save.LockedStatus.Title`)}
                    </div>
                    <div className="h6">
                        {t(`toast.Save.LockedStatus.${type}`)}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.changeLockedStatus(id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showDelete = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        this.props.removeClinics(id);

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('Processing.Delete')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-dark " onClick={() => { this.props.undoDeleteClinics(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('Undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.confirmDelete(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-recycle"></i> {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.WARNING,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    confirmDelete = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });

            serverAPI.delete("/Clinics/" + id).then(response => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Delete.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Delete.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.clearClinics(id);
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    renderTableRow = () => {
        const { t, clinics } = this.props;
        if (clinics && clinics.clinics && clinics.clinics.length > 0) {
            return clinics.clinics.map((item, index) => {
                return <tr key={`clinic-${index}-${item.Id}`}>
                    <td>
                        <i title={t(`IsActive.${item.IsActive}`)} className={`fa-solid fa-${item.IsActive === false ? "lock text-danger" : "lock-open text-success"}  me-1`}></i>
                    </td>
                    <td>
                        {item.Name}
                    </td>
                    <td>
                        {item.DoctorName}
                    </td>
                    <td>
                        {item.MedicalSpecialist?.Name}
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.showEdit(item)}>
                                        <i className="fa-regular fa-edit text-dark me-1"></i>
                                        {t("Table.Options.Edit")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.showEditPermissions(item)}>
                                        <i className="fa-regular fa-shield-keyhole text-dark me-1"></i>
                                        {t("Table.Options.Permissions")}
                                    </Link>
                                    <Link to="#"
                                        onClick={() => this.changeLockStatusClinc(item)}
                                        className="dropdown-item"  >
                                        <i className={`fa-regular fa-${item.IsActive === false ? "lock" : "lock-open"} text-dark me-1`}></i>
                                        {t(`Table.Options.${item.IsActive === true ? "Lock" : "Unlock"}`)}
                                    </Link>
                                    <Link to="#"
                                        onClick={() => this.showDelete(item.Id)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-trash text-dark me-1"></i>
                                        {t("Table.Options.Delete")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, clinics } = this.props;

        let isLoaded = false;
        if (clinics) {
            isLoaded = clinics.isLoaded;
        }
        return <>
            {this.state.showInfo && this.state.clinic ? <Info Clinic={this.state.clinic} handleClose={this.handleClose} /> : ""}
            {this.state.showEdit && this.state.clinic ? <Edit {...this.props} Clinic={this.state.clinic} handleClose={this.handleClose} /> : ""}
            {this.state.showEditPermissions && this.state.clinic ? <UserPermission {...this.props} Clinic={this.state.clinic} handleClose={this.handleClose} /> : ""}

            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t(`IsActive.Title`)} >
                                    <i className="fa-regular text-dark fa-lock me-1"></i>
                                </th>
                                <th title={t("Clinic.Forms.Name.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Clinic.Forms.Name.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Clinic.Forms.DoctorName.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user-doctor me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Clinic.Forms.DoctorName.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Clinic.Forms.MedicalSpecialist.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-kit-medical me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Clinic.Forms.MedicalSpecialist.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinics: state.clinics
    };
};

export default connect(mapStateToProps, { changeLockedStatus, removeClinics, undoDeleteClinics, clearClinics })(withTranslation('common')(Table));