import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import AppointmentCard from "../../Appointment/AppointmentCard";
import LabOrders from "../../ClinicScreen/LabOrders";
import RadiologyOrders from "../../ClinicScreen/RadiologyOrders";
import DrugOrders from "../../ClinicScreen/DrugOrders";
import VitalSigns from "../../ClinicScreen/VitalSigns";
import Services from "../../ClinicScreen/Services";
import { fetchPatientVisits as fetch } from "./actions";
import { fetchClinics } from "../../Manager/Clinics/actions";

class Visits extends React.Component {

    state = {
        clinics: [],
        showInfo: false,
        visit: null,
        currentClinic: null,
        showLabOrders: false,
        showRadiologyOrders: false,
        showDrugOrders: false,
        showVitalSigns: false,
        showServices: false
    }

    componentDidMount() {
        const { visits, clinics } = this.props;
        if (!visits || !visits.visits || visits.visits?.length === 0 || visits.PatientId !== this.props.patient.Id) {
            this.update();
        }

        if (!clinics || !clinics.clinics || clinics.clinics?.length === 0) {
            this.props.fetchClinics();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.clinics.length === 0 && props.clinics && props.clinics.clinics) {
            state.clinics = [];
            state.clinics = props.clinics.clinics.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    update = () => {
        this.props.fetch(this.props.patient.Id);
    }

    updateClinic = currentClinic => {
        this.setState({
            currentClinic
        });
    }

    showVitalSigns = visit => {
        this.setState({
            showVitalSigns: true,
            visit
        });
    }

    showLabOrders = visit => {
        this.setState({
            showLabOrders: true,
            visit
        });
    }

    showDrugOrders = visit => {
        this.setState({
            showDrugOrders: true,
            visit
        });
    }

    showRadiologyOrders = visit => {
        this.setState({
            showRadiologyOrders: true,
            visit
        });
    }

    showInfo = visit => {
        this.setState({
            showInfo: true,
            visit
        });
    }

    showServices = visit => {
        this.setState({
            showServices: true,
            visit
        });
    }

    handleClose = () => {
        this.setState({
            showDrugOrders: false,
            showRadiologyOrders: false,
            showLabOrders: false,
            showVitalSigns: false,
            showServices: false,
            showInfo: false,
            visit: null
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    renderTableRow = () => {
        const { t, visits } = this.props;
        if (visits && visits.visits && visits.visits.length > 0) {
            let searchResult = visits.visits;
            if (this.state.currentClinic) {
                searchResult = searchResult.filter(p => this.state.currentClinic.value === p.Clinic?.Id);
            }

            if (searchResult.length > 0) {
                return searchResult.map((visit) => {
                    return <tr key={`patient-visit-${visit.Id}-${visits.PatientId}`}>
                        <td>
                            {visit.Date}
                        </td>
                        <td>
                            {visit.DoctorUser?.Name}
                        </td>
                        <td>
                            {visit.Clinic?.Name}
                        </td>
                        <td>
                            <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        <Link to="#"
                                            onClick={() => this.showInfo(visit)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-info text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                        <Link to="#"
                                            onClick={() => this.showLabOrders(visit)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-microscope text-dark me-1"></i>
                                            {t("MedicalRecords.Nav.Lab")}
                                        </Link>
                                        <Link to="#"
                                            onClick={() => this.showRadiologyOrders(visit)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-circle-radiation text-dark me-1"></i>
                                            {t("MedicalRecords.Nav.Radiology")}
                                        </Link>
                                        <Link to="#"
                                            onClick={() => this.showDrugOrders(visit)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-prescription text-dark me-1"></i>
                                            {t("MedicalRecords.Nav.Drug")}
                                        </Link>
                                        <Link to="#"
                                            onClick={() => this.showVitalSigns(visit)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-monitor-waveform text-dark me-1"></i>
                                            {t("VitalSigns.Title")}
                                        </Link>
                                        <Link to="#"
                                            onClick={() => this.showServices(visit)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-file-invoice-dollar text-dark me-1"></i>
                                            {t("Services.Title")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                });
            }
            return <tr><td className="text-center" colSpan={4}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={4}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, visits, clinics } = this.props;

        let isLoaded = false;
        if (visits) {
            isLoaded = visits.isLoaded;
        }

        let isLoadedClinics = false;
        if (clinics) {
            isLoadedClinics = clinics.isLoaded;
        }

        return <>
            {
                this.state.showInfo ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                                {t("Table.Options.Info")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AppointmentCard Appointment={this.state.visit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showLabOrders ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-microscope me-2 text-primary "></i>
                                {t("MedicalRecords.Nav.Lab")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LabOrders Appointment={this.state.visit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showRadiologyOrders ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-circle-radiation me-2 text-primary "></i>
                                {t("MedicalRecords.Nav.Radiology")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RadiologyOrders Appointment={this.state.visit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showDrugOrders ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-prescription me-2 text-primary "></i>
                                {t("MedicalRecords.Nav.Drug")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DrugOrders Appointment={this.state.visit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showVitalSigns ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-monitor-waveform me-2 text-primary "></i>
                                {t("VitalSigns.Title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <VitalSigns Appointment={this.state.visit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showServices ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-file-invoice-dollar me-2 text-primary "></i>
                                {t("Services.Title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Services Appointment={this.state.visit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-user-doctor-message me-2 text-info mb-0"></i>
                            {t('MedicalRecords.Nav.Visits')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                            <div></div>
                            <div className="d-flex align-items-center">
                                <Select
                                    onChange={this.updateClinic}
                                    isClearable={true}
                                    name="Clinics "
                                    id="Clinics "
                                    placeholder={t('Clinic.Forms.Name.Select')}
                                    title={t('Clinic.Forms.Name.Select')}
                                    isLoading={!isLoadedClinics}
                                    isSearchable={true}
                                    ref={Clinics => this.Clinics = Clinics}
                                    options={this.state.clinics}
                                    filterOption={this.filterOptions}
                                />
                            </div>
                        </div>

                        <div className="position-relative">
                            <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                                minHeight: '100px'
                            }}>
                                <div className="table-loading-message">
                                    {t("Processing.Title")}
                                </div>
                                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                    <thead>
                                        <tr className="fw-bold fs-6 text-gray-800">
                                            <th title={t("Appointment.History.Table.Date")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-calendar me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Appointment.History.Table.Date")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("ClinicScreen.Doctor")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-user-doctor me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("ClinicScreen.Doctor")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("HealthCenter.Clinic")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("HealthCenter.Clinic")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Table.Options.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-gears me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Table.Options.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableRow()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        visits: state.visits
    };
};

export default connect(mapStateToProps, { fetchClinics, fetch })(withTranslation('common')(Visits));