import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../../api/serverAPI";
import history from "../../../history";
import { checkLogin } from '../../../../actions/';

class EditProfile extends React.Component {

    state = { isInitialize: false };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.user && props.user.user) {
            state.isInitialize = true;
            props.initialize({
                Name: props.user.user.Name,
                Phone: props.user.user.Phone
            });
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = event => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        const postData = {
            ...this.props.formValues
        };

        serverAPI.put("/EditProfile/", postData).then(response => {
            if (response.data.code === "fill_all_inputs") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.fill_all_inputs.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.fill_all_inputs.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Save.Edit.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Save.Edit.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.checkLogin();
                history.push("/Profile");
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

        return event.preventDefault();
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    render() {
        const { t, pristine, reset, submitting, handleSubmit } = this.props;
        return (<>
            <Helmet>
                <title>{t("Profile.Title")} - {t("Profile.Edit")} | {t('SiteName')}</title>
            </Helmet>
            <div className="d-flex justify-content-center col-12">
                <div className="card mb-5 mb-xl-10 col-xl-8 ">
                    <div className="card-header cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">
                                <i className="fa-solid h2 fa-edit me-2 text-primary"></i>
                                {t("Profile.Title")} - {t("Profile.Edit")}
                            </h3>
                        </div>
                        <div className="align-self-center">
                            <Link to={"/Profile"} className="btn btn-sm btn-info me-2">
                                <i className="fa-regular fa-address-card me-1"></i>
                                {t("Profile.Title")}
                            </Link>
                            <Link to={"/Profile/ChangePassword"} className="btn btn-sm btn-danger me-2">
                                <i className="fa-regular fa-key me-1"></i>
                                {t("Profile.ChangePassword.Title")}
                            </Link>
                        </div>
                    </div>
                    <form method="POST" id="formEditProfile" name="formEditProfile" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        onSubmit={
                            handleSubmit(values => {
                                return new Promise(resolve => resolve())
                                    .then(() => {
                                        const errors = validate(values, this.props);
                                        return Object.keys(errors).length !== 0;
                                    })
                                    .then(errors => {
                                        if (errors) {
                                            this.checkSubmit();
                                        } else {
                                            this.onSubmit();
                                        }
                                    })
                            })
                        }
                    >
                        <div className="card-body p-9">
                            <div className="row mb-6">
                                <label className="col-lg-4 required col-form-label fs-6 fw-bold">
                                    {t("Profile.Form.Name.Title")}
                                </label>
                                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                    <Field
                                        ref={Name => this.Name = Name}
                                        name="Name"
                                        component={this.renderField}
                                        type={"text"}
                                        placeholder={t('Profile.Form.Name.Placeholder')}
                                        title={t('Profile.Form.Name.Title')}
                                        className="form-control form-control-solid"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-lg-4 required col-form-label fs-6 fw-bold">
                                    {t("Profile.Form.Phone.Title")}
                                </label>
                                <div className="col-lg-8">
                                    <Field
                                        ref={Phone => this.Phone = Phone}
                                        name="Phone"
                                        component={this.renderField}
                                        type={"text"}
                                        placeholder={t('Profile.Form.Phone.Placeholder')}
                                        title={t('Profile.Form.Phone.Title')}
                                        className="form-control form-control-solid"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" disabled={pristine || submitting} className="btn btn-sm btn-success me-2">
                                <i className="fa-regular fa-save me-1"></i>
                                {t("Save")}
                            </button>
                            <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-light btn-sm btn-active-light-primary">
                                <i className="fa-regular fa-xmark me-1"></i>
                                {t("Reset")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formEditProfile')(state),
        user: state.user
    };
};

const validate = values => {
    const errors = {};
    if (!values.Name) {
        errors.Name = 'Profile.Form.Name.Error';
    }
    if (!values.Phone) {
        errors.Phone = 'Profile.Form.Phone.Error';
    }
    return errors;
}

const formEditProfile = reduxForm({
    form: 'formEditProfile',
    validate,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { checkLogin })(withTranslation('common')(formEditProfile(EditProfile)));