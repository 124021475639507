import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, getFormValues, reduxForm, change } from "redux-form";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import serverAPI from "../../../../../api/serverAPI";
import SelectCurrencies from "../../../../shared/SelectList/SelectCurrencies";
import SelectBankBoxes from "../../../../shared/SelectList/SelectBankBoxes";
import CheckGridView from "../../../Checks/GridView";
import AccountField from "../../../Accounts/AccountField";

class Form extends React.Component {

    state = {
        account: null,
        ChecksSum: 0,
        Checks: [],
        BankBox: null,
        Currency: null,
        defaultCurrency: null,
        isSubmit: false,
        hasDuplicateCheck: false,
        Type: "",
        count: 0,
        Date: `${moment().format('DD/MM/YYYY')}`,
        isInitialize: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize) {
            state.isInitialize = true;
            if (props.Account) {
                props.initialize({
                    CashValue: props.CashValue,
                    Date: `${moment().format('DD/MM/YYYY')}`,
                    Rate: props.Currency ? props.Currency.Rate : 1,
                    CardValue: 0,
                    CheckValue: 0,
                    Total: props.CashValue,
                    CreditCardCommissionPecentage: 0,
                    CreditCardCommission: 0,
                    CurrencyId: props.Currency ? props.Currency.Id : 0,
                    BankBoxId: 0
                });
                state.account = props.Account;
                if (props.Currency) {
                    state.Currency = {
                        ...props.Currency,
                        value: props.Currency.Id,
                        name: props.Currency.Name,
                        rate: props.Currency.Rate,
                        label: props.Currency.Name,
                    };
                }
            } else {
                props.initialize({
                    CashValue: 0,
                    Date: `${moment().format('DD/MM/YYYY')}`,
                    Rate: 1,
                    CardValue: 0,
                    CheckValue: 0,
                    Total: 0,
                    CreditCardCommissionPecentage: 0,
                    CreditCardCommission: 0,
                    CurrencyId: 0,
                    BankBoxId: 0
                });
            }
        }
        return state;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            Date: picker.startDate.format('DD/MM/YYYY')
        });
        this.props.dispatch(change('formReceiptVoucher', 'Date', picker.startDate.format('DD/MM/YYYY')));
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} checked={checked} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderTextArea = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        rows,
        meta: { touched, error }
    }) => (
        <>
            <textarea {...input} id={id} title={title} rows={rows} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setBankBoxes = BankBox => {
        this.props.dispatch(change('formReceiptVoucher', 'BankBoxId', BankBox.Id));
        this.props.dispatch(change('formReceiptVoucher', 'CreditCardCommissionPecentage', BankBox.Percentage));
        this.setValue();
        this.setState({
            BankBox
        });
    }

    setRate = Currency => {
        if (Currency?.length === 1) {
            if (Currency[0]) {
                Currency = Currency[0];
            }
        }
        this.props.dispatch(change('formReceiptVoucher', 'Rate', Currency.Rate));
        this.props.dispatch(change('formReceiptVoucher', 'CurrencyId', Currency.Id));
        this.setState({
            Currency
        });
    }

    setValue = () => {
        const { CashValue, CardValue, CheckValue, CreditCardCommissionPecentage } = this.props.formValues;
        this.props.dispatch(change('formReceiptVoucher', 'Total', parseFloat(CashValue) + parseFloat(CardValue) + parseFloat(CheckValue)));
        this.props.dispatch(change('formReceiptVoucher', 'CreditCardCommission', parseFloat(CardValue) * CreditCardCommissionPecentage / 100));
    }

    onSubmit = () => {
        const { t } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/ReceiptVouchers/`, {
            ...this.props.formValues,
            AccountId: this.state.account.Id,
            Checks: this.state.Checks
        }).then(response => {
            if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                if (this.props.update) {
                    this.props.update();
                }
                this.props.handleClose();
            } else if (response.data.code === "check_value_error") {
                toast.update(this._toastId.current, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.check_value_error.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.check_value_error.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    setAccount = account => {
        this.setState({
            account
        });
    }

    saveData = (Checks, hasDuplicateCheck) => {
        let ChecksSum = 0;
        Checks.forEach(item => {
            ChecksSum += parseFloat(item.Value);
        });
        this.setState({
            Checks,
            ChecksSum,
            hasDuplicateCheck
        });
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    render() {
        const { t, submitting, formValues, handleSubmit } = this.props;

        return <>
            <form method="POST" name="formReceiptVoucher" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0;
                        })
                        .then(errors => {
                            if (errors || this.state.account === null) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            }>
                {
                    formValues?.CheckValue && parseFloat(formValues?.CheckValue) !== this.state.ChecksSum ?
                        <div className="alert bg-light-danger border-3 border-danger d-flex flex-column flex-sm-row p-5 mb-5">
                            <i className="fs-2hx text-danger me-2 mb-0 fa-regular fa-money-check-dollar fa-shake"></i>
                            <div className="d-flex flex-column pe-1 w-100">
                                <h5 className="mb-1">{t("Accounting.Check.Missing.Title")}</h5>
                                <span>{t("Accounting.Check.Missing.Body")}.</span>
                            </div>
                        </div> : ""
                }

                <div className="row">
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label required fw-bold fs-6`} ><i className="fa-regular text-dark fa-file-user me-2"></i>{t('Accounting.ReceiptVoucher.Account')}</label>
                        {
                            this.props.Account ? <div>
                                {this.props.Account.Number + " - " + this.props.Account.Name}
                            </div> : <AccountField account={this.state.account} update={this.setAccount} />
                        }

                    </div>
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label required fw-bold fs-6`} ><i className="fa-regular text-dark fa-calendar me-2"></i>{t('Accounting.JournalVoucher.Forms.Date.Title')}</label>
                        <DateRangePicker
                            ref={SelectDate => this.SelectDate = SelectDate}
                            initialSettings={{
                                singleDatePicker: true,
                                opens: 'center',
                                autoUpdateInput: true,
                                buttonClasses: 'btn',
                                cancelClass: "btn-danger",
                                applyButtonClasses: "btn-primary",
                                showDropdowns: true,
                                format: 'DD/MM/YYYY',
                                timePicker: false,
                                maxDate: `${moment().format('DD/MM/YYYY')}`,
                                locale: {
                                    format: 'DD/MM/YYYY',
                                    cancelLabel: t('Close'),
                                    firstDay: 6,
                                    applyLabel: t('Apply'),
                                    customRangeLabel: t('DateRangePicker.Range.customRange'),
                                    monthNames: [
                                        `${t("DateRangePicker.Months.January")}`,
                                        `${t("DateRangePicker.Months.February")}`,
                                        `${t("DateRangePicker.Months.March")}`,
                                        `${t("DateRangePicker.Months.April")}`,
                                        `${t("DateRangePicker.Months.May")}`,
                                        `${t("DateRangePicker.Months.June")}`,
                                        `${t("DateRangePicker.Months.July")}`,
                                        `${t("DateRangePicker.Months.August")}`,
                                        `${t("DateRangePicker.Months.September")}`,
                                        `${t("DateRangePicker.Months.October")}`,
                                        `${t("DateRangePicker.Months.November")}`,
                                        `${t("DateRangePicker.Months.December")}`
                                    ],
                                    daysOfWeek: [
                                        t("DateRangePicker.DaysOfWeek.Sunday"),
                                        t("DateRangePicker.DaysOfWeek.Monday"),
                                        t("DateRangePicker.DaysOfWeek.Tuesday"),
                                        t("DateRangePicker.DaysOfWeek.Wednesday"),
                                        t("DateRangePicker.DaysOfWeek.Thursday"),
                                        t("DateRangePicker.DaysOfWeek.Friday"),
                                        t("DateRangePicker.DaysOfWeek.Saturday")
                                    ],
                                },
                            }}
                            onApply={this.handleApplyDate} >
                            <input
                                defaultValue={this.state.Date}
                                ref={Date => this.Date = Date}
                                name="Date"
                                id="Date"
                                autoComplete="off"
                                required
                                className="form-control ps-10"
                                type="text"
                                placeholder={t('Accounting.JournalVoucher.Forms.Date.Placeholder')}
                                title={t('Accounting.JournalVoucher.Forms.Date.Title')}
                            />
                        </DateRangePicker>

                    </div>
                    <div className="col-lg-3 mb-5">
                        <SelectCurrencies defaultValue={[this.state.Currency]} required onChange={this.setRate} />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Rate"><i className="fa-regular text-dark fa-percent me-2"></i>{t('Currencies.Forms.Rate.Title')}</label>
                        <Field
                            ref={Rate => this.Rate = Rate}
                            name="Rate"
                            id="Rate"
                            component={this.renderField}
                            type={"number"}
                            required
                            disabled={this.state.Currency && this.state.Currency.Id === 1 ? true : false}
                            placeholder={t('Currencies.Forms.Rate.Placeholder')}
                            title={t('Currencies.Forms.Rate.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="CashValue"><i className="fa-regular text-dark fa-money-bills me-2"></i>{t('Accounting.ReceiptVoucher.Forms.CashValue.Title')}</label>
                        <Field
                            ref={CashValue => this.CashValue = CashValue}
                            name="CashValue"
                            id="CashValue"
                            component={this.renderField}
                            type={"number"}
                            required
                            onBlur={this.setValue}
                            placeholder={t('Accounting.ReceiptVoucher.Forms.CashValue.Placeholder')}
                            title={t('Accounting.ReceiptVoucher.Forms.CashValue.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="CardValue"><i className="fa-regular text-dark fa-credit-card me-2"></i>{t('Accounting.ReceiptVoucher.Forms.CardValue.Title')}</label>
                        <Field
                            ref={CardValue => this.CardValue = CardValue}
                            name="CardValue"
                            id="CardValue"
                            onBlur={this.setValue}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Accounting.ReceiptVoucher.Forms.CardValue.Placeholder')}
                            title={t('Accounting.ReceiptVoucher.Forms.CardValue.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="CheckValue"><i className="fa-regular text-dark fa-money-check-dollar me-2"></i>{t('Accounting.ReceiptVoucher.Forms.CheckValue.Title')}</label>
                        <Field
                            ref={CheckValue => this.CheckValue = CheckValue}
                            name="CheckValue"
                            id="CheckValue"
                            onBlur={this.setValue}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Accounting.ReceiptVoucher.Forms.CheckValue.Placeholder')}
                            title={t('Accounting.ReceiptVoucher.Forms.CheckValue.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Total"><i className="fa-regular text-dark fa-sigma me-2"></i>{t('Total')}</label>
                        <Field
                            ref={Total => this.Total = Total}
                            name="Total"
                            id="Total"
                            component={this.renderField}
                            type={"number"}
                            disabled
                            placeholder={t('Total')}
                            title={t('Total')}
                            className="form-control"
                        />
                    </div>
                </div>
                {
                    formValues?.CardValue > 0 ?
                        <div className="row mb-5">
                            <div className="col-md-3">
                                <SelectBankBoxes required onChange={this.setBankBoxes} />
                            </div>
                            <div className="col-md-3">
                                <label className={`col-form-label required fw-bold fs-6`} htmlFor="ReferenceNumber"><i className="fa-regular text-dark fa-hashtag me-2"></i>{t('Accounting.ReceiptVoucher.Forms.ReferenceNumber.Title')}</label>
                                <Field
                                    ref={ReferenceNumber => this.ReferenceNumber = ReferenceNumber}
                                    name="ReferenceNumber"
                                    id="ReferenceNumber"
                                    component={this.renderField}
                                    type={"text"}
                                    required
                                    placeholder={t('Accounting.ReceiptVoucher.Forms.ReferenceNumber.Placeholder')}
                                    title={t('Accounting.ReceiptVoucher.Forms.ReferenceNumber.Title')}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`col-form-label required fw-bold fs-6`} htmlFor="CreditCardCommissionPecentage"><i className="fa-regular text-dark fa-percentage me-2"></i>{t('Accounting.ReceiptVoucher.Forms.CreditCardCommissionPecentage.Title')}</label>
                                <Field
                                    onBlur={this.setValue}
                                    ref={CreditCardCommissionPecentage => this.CreditCardCommissionPecentage = CreditCardCommissionPecentage}
                                    name="CreditCardCommissionPecentage"
                                    id="CreditCardCommissionPecentage"
                                    component={this.renderField}
                                    type={"number"}
                                    required
                                    placeholder={t('Accounting.ReceiptVoucher.Forms.CreditCardCommissionPecentage.Placeholder')}
                                    title={t('Accounting.ReceiptVoucher.Forms.CreditCardCommissionPecentage.Title')}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`col-form-label fw-bold fs-6`} htmlFor="CreditCardCommission"><i className="fa-regular text-dark fa-dollar me-2"></i>{t('Accounting.ReceiptVoucher.Forms.CreditCardCommission.Title')}</label>
                                <Field
                                    ref={CreditCardCommission => this.CreditCardCommission = CreditCardCommission}
                                    name="CreditCardCommission"
                                    id="CreditCardCommission"
                                    component={this.renderField}
                                    type={"number"}
                                    disabled
                                    placeholder={t('Accounting.ReceiptVoucher.Forms.CreditCardCommission.Placeholder')}
                                    title={t('Accounting.ReceiptVoucher.Forms.CreditCardCommission.Title')}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        : ""
                }
                {
                    formValues?.CheckValue > 0 ?
                        <CheckGridView saveData={this.saveData} />
                        : ""
                }
                <div className="row mb-5">
                    <div className="col-md-12 mt-5">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Note"><i className="fa-regular text-dark fa-note me-2"></i>{t('Accounting.ReceiptVoucher.Forms.Note.Title')}</label>
                        <Field
                            ref={Note => this.Note = Note}
                            name="Note"
                            id="Note"
                            rows={5}
                            component={this.renderTextArea}
                            placeholder={t('Accounting.ReceiptVoucher.Forms.Note.Placeholder')}
                            title={t('Accounting.ReceiptVoucher.Forms.Note.Title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={submitting || this.state.isSubmit || this.state.hasDuplicateCheck || (formValues?.CheckValue > 0 && this.state.ChecksSum !== parseFloat(formValues?.CheckValue))} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>


            </form>
        </>;
    }
}

const validate = values => {
    const errors = {};
    if (parseFloat(values.CashValue) < 0) {
        errors.CashValue = 'Accounting.ReceiptVoucher.Forms.CashValue.Error';
    }
    if (parseFloat(values.CardValue) < 0) {
        errors.CardValue = 'Accounting.ReceiptVoucher.Forms.CardValue.Error';
    }
    if (parseFloat(values.CheckValue) < 0) {
        errors.CheckValue = 'Accounting.ReceiptVoucher.Forms.CheckValue.Error';
    }
    if (!values.ReferenceNumber && parseFloat(values.CardValue) > 0) {
        errors.ReferenceNumber = 'Accounting.ReceiptVoucher.Forms.ReferenceNumber.Error';
    }
    if (parseFloat(values.CreditCardCommissionPecentage) < 0 || parseFloat(values.CreditCardCommissionPecentage) > 100) {
        errors.CreditCardCommissionPecentage = 'Accounting.ReceiptVoucher.Forms.CreditCardCommissionPecentage.Error';
    }
    if (parseFloat(values.CreditCardCommission) < 0) {
        errors.CreditCardCommission = 'Accounting.ReceiptVoucher.Forms.CreditCardCommission.Error';
    }

    if ((parseFloat(values.CashValue) + parseFloat(values.CardValue) + parseFloat(values.CheckValue)) <= 0) {
        errors.Total = 'Accounting.ReceiptVoucher.Forms.Total.Error';
    }

    if (!values.CurrencyId) {
        errors.CurrencyId = 'Currencies.Forms.Select.Error';
    }
    if (!values.Rate || values.Rate <= 0) {
        errors.Rate = 'Currencies.Forms.Rate.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formReceiptVoucher')(state),
        validate,
        user: state.user,
        patientBills: state.patientBills
    };
};

const formReceiptVoucher = reduxForm({
    form: 'formReceiptVoucher',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formReceiptVoucher(Form)));