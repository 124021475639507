import React from "react";
import { withTranslation } from 'react-i18next';
import { Field } from "redux-form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import serverAPI from "../../../../api/serverAPI";
import Search from "../index";
import PatientName from "../../PatientName";
import PatientsCard from "../../PatientsCard";
import PatientInfo from "../Info";

class SearchByDocumentNumber extends React.Component {

    state = { Patient: null, showSearchModal: false };

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        value,
        defaultValue,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} defaultValue={defaultValue} value={value} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} placeholder={placeholder} onKeyUp={e => this.search(e)} onKeyDown={e => this.handleKeyPress(e)} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showSearchModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Search')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    CheckDocumentNumber = DocumentNumber => {
        if (DocumentNumber) {
            const { t } = this.props;
            this.setState({ Patient: null });

            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            serverAPI.get(`/PatientRegistration/?DocumentNumber=${DocumentNumber}`).then(response => {
                if (response.data && response.data.data && response.data.data.length > 0 && response.data.data[0]) {
                    this.setState({ Patient: response.data.data[0] });
                    this.props.update(response.data.data[0]);
                    toast.dismiss(_toastId);
                } else {
                    if (this.props.DocumentIsNotExist) {
                        this.props.DocumentIsNotExist(DocumentNumber);
                    }
                    toast.update(_toastId, {
                        render: () => <div
                        ><div className="h5">
                                {t('toast.Error.document_is_not_exist.Title')}
                            </div>
                            <div className="h6">
                                {t('toast.Error.document_is_not_exist.Body')}
                            </div>
                        </div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000
                    });
                }
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR
                });
            });
        }
    }

    changePatient = () => {
        this.setState({ Patient: null });
        this.props.update(null);
    }

    showSearchModal = () => {
        this.setState({
            showSearchModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showSearchModal: false,
            showPatientInfo: false
        });
    }

    selectPatient = Patient => {
        this.setState({ Patient, showSearchModal: false });
        this.props.update(Patient);
    }

    handleKeyPress = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
        return false;
    }

    search = event => {
        if (event.key === 'Enter') {
            this.CheckDocumentNumber(event.target.value);
            event.preventDefault();
        }
        return false;
    }

    showPatientInfo = () => {
        this.setState({
            showPatientInfo: true
        })
    }

    render() {
        const { t } = this.props;
        return <>
            {
                this.state.showSearchModal ? <Search handleClose={this.handleClose} select={this.selectPatient} /> : ""
            }
            {this.state.showPatientInfo && this.state.Patient ? <PatientInfo patient={this.state.Patient} handleClose={this.handleClose} /> : ""}
            <label className="col-form-label fw-bold fs-6" htmlFor="DocumentNumber"><i className="fa-regular text-dark fa-user-injured me-2"></i>{t('Patient.Forms.ID.Title')}</label>
            {
                this.state.Patient ?
                    <>
                        <div className="input-group position-relative">
                            <Field
                                ref={DocumentNumber => this.DocumentNumber = DocumentNumber}
                                name="DocumentNumber"
                                id="DocumentNumber"
                                defaultValue={
                                    this.state.Patient.DocumentNumber ?
                                        this.state.Patient.DocumentNumber :
                                        this.state.Patient.Name.FirstName + " " + this.state.Patient.Name.SecondName + " " + this.state.Patient.Name.ThirdName + " " + this.state.Patient.Name.LastName
                                }
                                autoComplete="off"
                                className="form-control form-control-solid disabled ps-10"
                                type="text"
                                component={this.renderField}
                                disabled={true}
                                placeholder={t('Patient.Forms.ID.Placeholder')}
                                title={this.state.Patient.Name.FirstName + " " + this.state.Patient.Name.SecondName + " " + this.state.Patient.Name.ThirdName + " " + this.state.Patient.Name.LastName}
                            />
                            <button className="btn btn-danger btn-icon" title={t('Clear')} type="button" onClick={() => this.changePatient()}>
                                <i className="fa-regular fa-broom"></i>
                            </button>
                        </div>
                        {
                            this.props.showCard ?
                                <div className="mt-10">
                                    <PatientsCard patient={this.state.Patient} />
                                </div>
                                :
                                <Link className="text-dark" to="#" onClick={() => this.showPatientInfo()}>
                                    <div className="mt-3">
                                        <PatientName patient={this.state.Patient} />
                                    </div>
                                </Link>
                        }
                    </> :
                    <>
                        <div className="input-group position-relative">
                            <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6" style={{
                                zIndex: 9
                            }}>
                                <i className="fa-regular fa-magnifying-glass"></i>
                            </span>
                            <Field
                                ref={DocumentNumber => this.DocumentNumber = DocumentNumber}
                                name="DocumentNumber"
                                id="DocumentNumber"
                                component={this.renderFieldGroup}
                                onBlur={e => this.CheckDocumentNumber(e.target.value)}
                                type={"text"}
                                onChange={this.changePatient}
                                placeholder={t('Patient.Forms.ID.Placeholder')}
                                title={t('Patient.Forms.ID.Title')}
                                className="form-control form-control-solid ps-10"
                            />
                        </div>
                    </>
            }
        </>;
    }
}

export default withTranslation('common')(SearchByDocumentNumber);