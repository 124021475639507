import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Info from "./Info";
import Balances from "./Balances";
import Edit from "./New";

class Table extends React.Component {

    state = { showInfo: false, showBalancesInfo: false, account: null, showEdit: false };

    showInfo = account => {
        this.setState({
            showInfo: true,
            account
        });
    }

    showEdit = account => {
        this.setState({
            showEdit: true,
            account
        });
    }

    showBalancesInfo = account => {
        this.setState({
            showBalancesInfo: true,
            account
        });
    }

    handleClose = () => {
        this.setState({
            showEdit: false,
            showBalancesInfo: false,
            showInfo: false,
            account: null
        });
    }

    render() {
        const { t, bankAccounts, isSelectable } = this.props;

        let isLoaded = false;
        if (bankAccounts && bankAccounts.bankAccounts) {
            isLoaded = bankAccounts.isLoaded;
        }

        return <>
            {this.state.showBalancesInfo && this.state.account ? <Balances Account={this.state.account.MainAccount} handleClose={this.handleClose} /> : ""}
            {this.state.showInfo && this.state.account ? <Info Account={this.state.account} handleClose={this.handleClose} /> : ""}
            {this.state.showEdit && this.state.account ? <Edit Account={this.state.account} handleClose={this.handleClose} update={this.props.update} isEdit={true} /> : ""}
            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr>
                                <th title={t("Accounting.BankAccounts.Forms.Number.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.BankAccounts.Forms.Number.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.BankAccounts.Forms.Name.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.BankAccounts.Forms.Name.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Banks.Forms.Select.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-landmark me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Banks.Forms.Select.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bankAccounts.bankAccounts.length > 0 ?
                                    bankAccounts.bankAccounts.map(item => {
                                        return <tr key={`bankAccounts-${item.Id}`}>
                                            <td>{item.Number}</td>
                                            <td>{item.Name}</td>
                                            <td>
                                                {item.Bank?.Name}
                                                -
                                                <i className="fa-regular fa-code-branch mx-1 text-info"></i>
                                                {item.BankBranch?.Name}
                                            </td>
                                            <td>
                                                {
                                                    isSelectable ?
                                                        <Link
                                                            to="#"
                                                            onClick={() => this.props.select(item)}
                                                            className="btn btn-sm btn-icon btn-light-dark ms-1"  >
                                                            <i className="fa-solid fa-check"></i>
                                                        </Link>
                                                        :
                                                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa-regular fa-gears"></i>
                                                                    <span className="d-md-block d-none text-nowrap ms-1">
                                                                        {t("Table.Options.Title")}
                                                                    </span>
                                                                </button>
                                                                <div className="dropdown-menu ">
                                                                    <Link to="#"
                                                                        onClick={() => this.showInfo(item)}
                                                                        className="dropdown-item"  >
                                                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                                                        {t("Table.Options.Info")}
                                                                    </Link>
                                                                    <Link to="#"
                                                                        onClick={() => this.showEdit(item)}
                                                                        className="dropdown-item"  >
                                                                        <i className="fa-regular fa-edit text-dark me-1"></i>
                                                                        {t("Table.Options.Edit")}
                                                                    </Link>

                                                                    <Link to="#"
                                                                        onClick={() => this.showBalancesInfo(item)}
                                                                        className="dropdown-item"  >
                                                                        <i className="fa-regular fa-scale-balanced text-dark me-1"></i>
                                                                        {t("Accounting.Balances.Title")}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr><td className="text-center" colSpan={4}>{t("NoResult")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        bankAccounts: state.bankAccounts
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));