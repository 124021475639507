import React from "react";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getFormValues, reduxForm } from "redux-form";

import { fetchDrugAllergies } from "../ClinicScreen/DrugAllergy/actions";
import { fetchFamilyHistory } from "../ClinicScreen/FamilyHistory/actions";
import { fetchPastMedicalHistory } from "../ClinicScreen/PastMedicalHistory/actions";
import { fetchPastSurgicalHistory } from "../ClinicScreen/PastSurgicalHistory/actions";
import { fetchObstetricAndGyneHistory } from "../ClinicScreen/ObstetricAndGyneHistory/actions";
import SearchByDocumentNumber from "../Patients/Search/ByDocumentNumber/";
import Info from "./Info";
import { fetchPatientChronicDiseases } from "../ClinicScreen/ChronicDisease/actions";

class MedicalRecords extends React.Component {

    state = { Patient: null, tab: "OverviewTab" };

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_content_inner").classList.add("w-100");
        document.getElementById("kt_content_inner").classList.add("mx-5");
        document.getElementById("kt_content_inner").classList.remove("container-xxl");
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_content_inner").classList.add("container-xxl");
        document.getElementById("kt_content_inner").classList.remove("mx-5");
        document.getElementById("kt_content_inner").classList.remove("w-100");
    }

    selectPatient = Patient => {
        this.setState({ Patient, tab: "OverviewTab" });
        if (Patient) {
            this.props.fetchDrugAllergies(Patient.Id);
            this.props.fetchFamilyHistory(Patient.Id);
            this.props.fetchPastMedicalHistory(Patient.Id);
            this.props.fetchPastSurgicalHistory(Patient.Id);
            this.props.fetchPatientChronicDiseases(Patient.Id);
            if (Patient.Sex === "female") {
                this.props.fetchObstetricAndGyneHistory(Patient.Id);
            }
        }
    }

    render() {
        const { t } = this.props;

        return (<>
            <Helmet>
                <title>{t("MedicalRecords.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className={`row g-6 g-xl-9 mb-6 mb-xl-9 mt-0`}>
                <div className="col-lg-3">
                    <div className="card h-100">
                        <div className="card-header align-items-center ">
                            <h3 className="card-title">
                                <span className="fw-bolder mb-2 text-dark">
                                    <i className="h3 fa-duotone fa-rectangle-history-circle-user me-2 text-info mb-0"></i>
                                    {t('MedicalRecords.Title')}
                                </span>
                            </h3>
                        </div>
                        <div className="card-body ">
                            <form method="POST" name="formMedicalRecordsPatientsSearch" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password"  >
                                <div className="row">
                                    <div className="col-12">
                                        <SearchByDocumentNumber showCard={true} update={this.selectPatient} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.state.Patient ?
                        <div className="col-lg-9">
                            <Info Patient={this.state.Patient} />
                        </div >
                        : ""
                }
            </div>

        </>);
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formMedicalRecordsPatientsSearch')(state),
        user: state.user,
        drugAllergy: state.drugAllergy,
        familyHistory: state.familyHistory,
        pastMedicalHistory: state.pastMedicalHistory,
        pastSurgicalHistory: state.pastSurgicalHistory,
        patientChronicDisease: state.patientChronicDisease,
        obstetricAndGyneHistory: state.obstetricAndGyneHistory
    };
};

const formMedicalRecordsPatientsSearch = reduxForm({
    form: 'formMedicalRecordsPatientsSearch',
    enableReinitialize: true,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetchDrugAllergies, fetchFamilyHistory, fetchPastMedicalHistory, fetchPastSurgicalHistory, fetchObstetricAndGyneHistory, fetchPatientChronicDiseases })(withTranslation('common')(formMedicalRecordsPatientsSearch(MedicalRecords)));
