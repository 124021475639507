import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import serverAPI from "../../../api/serverAPI";
import { changeLockedStatus, fetchUserTypes, removeUser } from "./actions";
import Info from "./Info";
import Edit from "./Edit";
import Permission from "./Permission";
import ClinicsPermission from "./ClinicsPermission";

class Table extends React.Component {

    state = {
        user: null,
        showInfo: false,
        showEdit: false,
        showEditPermissions: false,
        showClinicsPermission: false
    };

    handleClose = () => {
        this.setState({
            user: null,
            showInfo: false,
            showEdit: false,
            showEditPermissions: false,
            showClinicsPermission: false
        });
    }

    showClinicsPermission = user => {
        this.setState({
            showClinicsPermission: true,
            user
        })
    }

    showEditPermissions = user => {
        this.setState({
            showEditPermissions: true,
            user
        })
    }

    showEdit = user => {
        this.setState({
            showEdit: true,
            user
        })
    }

    showInfo = user => {
        this.setState({
            showInfo: true,
            user
        })
    }

    resetPassword = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t(`Processing.ResetPassword`)}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-dark " onClick={() => { toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-xmark"></i> {t('Undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.confirmResetPassword(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-check"></i> {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.WARNING,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    confirmResetPassword = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        serverAPI.put(`/ResetUserPassword/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t(`toast.Save.ResetPassword.Title`)}
                    </div>
                    <div className="h6">
                        {t(`toast.Save.ResetPassword.Body`)}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    changeLockStatusUser = user => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        let type = "Locked";
        if (user.IsActive === false) {
            type = "Unlocked";
        }

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t(`Processing.User.${type}`)}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-dark " onClick={() => { toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-xmark"></i> {t('Undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.confirmLockStatusUser(user.Id, type); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-check"></i> {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.WARNING,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    confirmLockStatusUser = (id, type) => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        serverAPI.put(`/ChangeLockStatusUser/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t(`toast.Save.LockedStatus.Title`)}
                    </div>
                    <div className="h6">
                        {t(`toast.Save.LockedStatus.${type}`)}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.changeLockedStatus(id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showDelete = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('Processing.Delete')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-dark " onClick={() => { toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('Undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.confirmDelete(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-recycle"></i> {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.WARNING,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    confirmDelete = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });

            serverAPI.delete("/Users/" + id).then(response => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Delete.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Delete.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.removeUser(id);
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    renderTableRow = () => {
        const { t, users } = this.props;
        if (users && users.users && users.users.length > 0) {
            return users.users.map((item, index) => {
                return <tr key={`user-${index}-${item.Id}`}>
                    <td>
                        <i title={t(`IsActive.${item.IsActive}`)} className={`fa-solid fa-${item.IsActive === false ? "lock text-danger" : "lock-open text-success"}  me-1`}></i>
                    </td>
                    <td>
                        {item.Name}
                    </td>
                    <td>
                        {item.Email}
                    </td>
                    <td>
                        {item.Username}
                    </td>
                    <td>
                        {item.Phone}
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.showEdit(item)}>
                                        <i className="fa-regular fa-edit text-dark me-1"></i>
                                        {t("Table.Options.Edit")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.showEditPermissions(item)}>
                                        <i className="fa-regular fa-shield-keyhole text-dark me-1"></i>
                                        {t("Table.Options.Permissions")}
                                    </Link>
                                    <Link to="#"
                                        onClick={() => this.resetPassword(item.Id)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-key text-dark me-1"></i>
                                        {t("Table.Options.ResetPassword")}
                                    </Link>
                                    <Link to="#"
                                        onClick={() => this.showClinicsPermission(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-house-medical-flag text-dark me-1"></i>
                                        {t("Table.Options.ClincsPermissions")}
                                    </Link>
                                    <Link to="#"
                                        onClick={() => this.changeLockStatusUser(item)}
                                        className="dropdown-item"  >
                                        <i className={`fa-regular fa-${item.IsActive === false ? "lock" : "lock-open"} text-dark me-1`}></i>
                                        {t(`Table.Options.${item.IsActive === true ? "Lock" : "Unlock"}`)}
                                    </Link>
                                    <Link to="#"
                                        onClick={() => this.showDelete(item.Id)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-trash text-dark me-1"></i>
                                        {t("Table.Options.Delete")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, users } = this.props;

        let isLoaded = false;
        if (users) {
            isLoaded = users.isLoaded;
        }
        return <>
            {this.state.showInfo && this.state.user ? <Info User={this.state.user} handleClose={this.handleClose} /> : ""}
            {this.state.showEdit && this.state.user ? <Edit {...this.props} User={this.state.user} handleClose={this.handleClose} /> : ""}
            {this.state.showEditPermissions && this.state.user ? <Permission {...this.props} User={this.state.user} handleClose={this.handleClose} /> : ""}
            {this.state.showClinicsPermission && this.state.user ? <ClinicsPermission {...this.props} User={this.state.user} handleClose={this.handleClose} /> : ""}

            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t(`IsActive.Title`)} >
                                    <i className="fa-regular text-dark fa-lock me-1"></i>
                                </th>
                                <th title={t("Users.Form.Name.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Users.Form.Name.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Users.Form.Email.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-at me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Users.Form.Email.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Users.Form.Username.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Users.Form.Username.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Users.Form.Phone.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-phone me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Users.Form.Phone.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        users: state.users,
        userTypes: state.userTypes
    };
};

export default connect(mapStateToProps, { changeLockedStatus, removeUser, fetchUserTypes })(withTranslation('common')(Table));