import axios from 'axios';
import { getToken } from "../context/auth";

let serverAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'content-type': 'application/json',
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'TimezoneOffset': - new Date().getTimezoneOffset(),
        Authorization: `Bearer ${getToken()}`
    },
    withCredentials: true,
});

export const serverAPIUpload = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'content-type': 'multipart/form-data/',
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'TimezoneOffset': - new Date().getTimezoneOffset(),
        Authorization: `Bearer ${getToken()}`
    },
    withCredentials: true,
});

export const setToken = token => {
    serverAPI.defaults.headers.Authorization = `Bearer ${token}`;
    serverAPIDownload.defaults.headers.Authorization = `Bearer ${token}`;
    serverAPIUpload.defaults.headers.Authorization = `Bearer ${token}`;
}

export const serverAPIDownload = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'TimezoneOffset': - new Date().getTimezoneOffset(),
        Authorization: `Bearer ${getToken()}`
    },
    responseType: 'blob',
    withCredentials: true,
});

export default serverAPI;

