import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { fetcGynecologyInfoHistories as fetch } from "./actions";
import Info from "./Info";

class Table extends React.Component {

    state = { showInfo: false, gynecologyInfo: null };

    showInfo = gynecologyInfo => {
        this.setState({
            showInfo: true,
            gynecologyInfo
        })
    }

    handleClose = () => {
        this.setState({
            showInfo: false,
            gynecologyInfo: null
        });
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        if (this.props.Appointment) {
            this.props.fetch(this.props.Appointment.patient.Id);
        } else if (this.props.patient) {
            this.props.fetch(this.props.patient.Id);
        }
    }

    renderTableRow = () => {
        const { t, gynecologyInfoHistories } = this.props;
        if (gynecologyInfoHistories && gynecologyInfoHistories.gynecologyInfoHistories && gynecologyInfoHistories.gynecologyInfoHistories.length > 0) {
            return gynecologyInfoHistories.gynecologyInfoHistories.map((item, index) => {
                return <tr key={`patient-gynecologyInfoHistories-${index}-${item.Id}`}>
                    <td>
                        {item.LMP}
                    </td>
                    <td>
                        {item.Gravida}
                    </td>
                    <td>
                        {item.Para}
                    </td>
                    <td>
                        {item.Abortion}
                    </td>
                    <td>
                        {item.Ectopic}
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, gynecologyInfoHistories } = this.props;

        let isLoaded = false;
        if (gynecologyInfoHistories) {
            isLoaded = gynecologyInfoHistories.isLoaded;
        }
        return <>
            {this.state.showInfo && this.state.gynecologyInfo ? <Info {...this.props} moreInfo={true} gynecologyInfo={this.state.gynecologyInfo} handleClose={this.handleClose} /> : ""}

            <div className="position-relative h-100">
                <div className={`table-responsive-md h-100 ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("GynecologyInfoes.Forms.LMP.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("GynecologyInfoes.Forms.LMP.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("GynecologyInfoes.Forms.Gravida.Title")}>
                                    <span className="d-flex align-items-center">
                                        {t("GynecologyInfoes.Forms.Gravida.Title")}
                                    </span>
                                </th>
                                <th title={t("GynecologyInfoes.Forms.Para.Title")}>
                                    <span className="d-flex align-items-center">
                                        {t("GynecologyInfoes.Forms.Para.Title")}
                                    </span>
                                </th>
                                <th title={t("GynecologyInfoes.Forms.Abortion.Title")}>
                                    <span className="d-flex align-items-center">
                                        {t("GynecologyInfoes.Forms.Abortion.Title")}
                                    </span>
                                </th>
                                <th title={t("GynecologyInfoes.Forms.Ectopic.Title")}>
                                    <span className="d-flex align-items-center">
                                        {t("GynecologyInfoes.Forms.Ectopic.Title")}
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        gynecologyInfoHistories: state.gynecologyInfoHistories
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Table));