import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

class ControlPanel extends React.Component {

    state = { showPrices: false };

    showPrices = () => {
        this.setState({
            showPrices: true
        });
    }

    handleClose = () => {
        this.setState({
            showPrices: false
        });
    }

    render() {
        const { t, user } = this.props;

        return (<>
            <Helmet>
                <title>{t("Pages.ControlPanel")} | {t('SiteName')}</title>
            </Helmet>

            <div className="row g-6 g-xl-9 mb-6 mb-xl-9 mt-0">
                <div className="col-lg-6 col-xxl-4">
                    <div className="card h-100">
                        <div className="card-header align-items-center border-0 mt-4 flex-nowrap">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="fw-bolder mb-2 text-dark"> {t('Welcome')}</span>
                                <span className="text-muted fw-bold fs-7" title={t("Profile.Form.Name.Title")}>
                                    <i className="fa-regular text-dark fa-text me-1"></i>
                                    {user.user.Name}
                                </span>
                            </h3>
                            <div className="card-toolbar">
                                <Link to="/Profile" title={t("Pages.Profile")} className="btn btn-sm btn-icon btn-color-info btn-active-light-info">
                                    <i className="h3 fa-duotone fa-address-card"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body pt-5  align-items-center d-flex">
                            <div className="w-100">
                                <div className="row mb-5">
                                    <label className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        {t("Users.Form.Username.Title")}
                                    </label>
                                    <span className="ms-10">
                                        {user.user.Username}
                                    </span>
                                </div>
                                <div className="row mb-5">
                                    <label className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-at me-1"></i>
                                        {t("Profile.Form.Email.Title")}
                                    </label>
                                    <span className="ms-10">
                                        {user.user.Email}
                                    </span>
                                </div>
                                <div className="row mb-5">
                                    <label className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-hospital me-1"></i>
                                        {t("HealthCenter.Title")}
                                    </label>
                                    <span className="ms-10">
                                        {user.user.HealthCenter.Name}
                                    </span>
                                </div>
                                <div className="row mb-5">
                                    <label className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-right-to-bracket me-1"></i>
                                        {t("Profile.Form.LastLogin")}
                                    </label>
                                    <span className="ms-10">
                                        {user.user.LastLogin}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 5) ?
                    <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                        <div className="col-12">
                            <span className="menu-icon">
                                <i className="h3 fa-regular fa-user me-2"></i>
                            </span>
                            <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                {t('Permissions.Registration')}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Appointment.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Appointment"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-calendar display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Appointment.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Patient.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Patients"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-user-injured display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Patient.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 2) ?
                    <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                        <div className="col-12">
                            <span className="menu-icon">
                                <i className="h3 fa-regular fa-user-doctor me-2"></i>
                            </span>
                            <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                {t('Permissions.Doctor')}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('ClinicScreen.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/ClinicScreen"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-calendar display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('ClinicScreen.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('MedicalRecords.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Patients/Medical/Records"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-rectangle-history-circle-user display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('MedicalRecords.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Diagnosis.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Diagnosis"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-book-medical display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Diagnosis.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 4) ?
                    <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                        <div className="col-12">
                            <span className="menu-icon">
                                <i className="h3 fa-regular fa-microscope me-2"></i>
                            </span>
                            <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                {t('Permissions.Lab')}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Lab.SpecimenTaken.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Lab/Specimen/Taken"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-syringe display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Lab.SpecimenTaken.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Lab.Results.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Lab/Exams/Result"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-flask-round-potion display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Lab.Results.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Lab.Exams.Current.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Lab/Exams"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-vial display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Lab.Exams.Current.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 3) ?
                    <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                        <div className="col-12">
                            <span className="menu-icon">
                                <i className="h3 fa-regular fa-circle-radiation me-2"></i>
                            </span>
                            <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                {t('Permissions.Radiologist')}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Radiology.ReceivingPatients.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Radiology/Receiving/Patients"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-x-ray display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Radiology.ReceivingPatients.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Radiology.Exams.Current.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Radiology/Exams"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-biohazard display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Radiology.Exams.Current.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 6) ?
                    <>
                        <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                            <div className="col-12">
                                <span className="menu-icon">
                                    <i className="h3 fa-regular fa-cash-register me-2"></i>
                                </span>
                                <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                    {t('Permissions.Accounting')}
                                </span>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Accounting.ReceiveCash.Menu')}>
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                        <Link to={"/Accounting/Receive/Cash"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                            <div className="symbol symbol-60px mb-5">
                                                <i className="fa-duotone fa-box-dollar display-1"></i>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">
                                                {t('Accounting.ReceiveCash.Menu')}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Accounting.ReceiptVoucher.Menu')}>
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                        <Link to={"/Accounting/Receipt/Voucher"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                            <div className="symbol symbol-60px mb-5">
                                                <i className="fa-duotone fa-receipt display-1"></i>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">
                                                {t('Accounting.ReceiptVoucher.Menu')}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Accounting.JournalVoucher.Menu')}>
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                        <Link to={"/Accounting/Journal/Voucher"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                            <div className="symbol symbol-60px mb-5">
                                                <i className="fa-duotone fa-ticket display-1"></i>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">
                                                {t('Accounting.JournalVoucher.Menu')}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Accounting.BankAccounts.Menu')}>
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                        <Link to={"/Accounting/Bank/Accounts"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                            <div className="symbol symbol-60px mb-5">
                                                <i className="fa-duotone fa-landmark display-1"></i>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">
                                                {t('Accounting.BankAccounts.Menu')}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Accounting.Accounts.Menu')}>
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                        <Link to={"/Accounting/Accounts"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                            <div className="symbol symbol-60px mb-5">
                                                <i className="fa-duotone fa-file-user display-1"></i>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">
                                                {t('Accounting.Accounts.Menu')}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Prices.Menu')}>
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                        <Link to={"#"} className="text-gray-800 text-hover-primary d-flex flex-column" onClick={() => this.showPrices()}>
                                            <div className="symbol symbol-60px mb-5">
                                                <i className="fa-duotone fa-circle-dollar display-1 text-dark"></i>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">
                                                {t('Prices.Menu')}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.showPrices ?
                                <Modal
                                    size="lg"
                                    centered
                                    backdrop="static"
                                    keyboard={false}
                                    enforceFocus={false}
                                    show={true}
                                    onHide={this.handleClose}>
                                    <Modal.Header closeButton >
                                        <Modal.Title>
                                            <i className="h3 fa-duotone fa-circle-dollar me-2 text-primary "></i>
                                            <span className="text-nowrap me-1">
                                                {t('Prices.Menu')}
                                            </span>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 ">
                                            <div className="col-sm-12 col-md-4 mt-3" title={t('Radiology.Exams.Prices.Menu')}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                        <Link to={"/Accounting/Radiology/Exams/Prices"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                                            <div className="symbol symbol-60px mb-5">
                                                                <i className="fa-duotone fa-circle-radiation display-1 text-success"></i>
                                                            </div>
                                                            <div className="fs-5 fw-bolder mb-2">
                                                                {t('Radiology.Exams.Prices.Menu')}
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 mt-3" title={t('Lab.Exams.Prices.Menu')}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                        <Link to={"/Accounting/Lab/Exams/Prices"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                                            <div className="symbol symbol-60px mb-5">
                                                                <i className="fa-duotone fa-microscope display-1 text-danger"></i>
                                                            </div>
                                                            <div className="fs-5 fw-bolder mb-2">
                                                                {t('Lab.Exams.Prices.Menu')}
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 mt-3" title={t('Accounting.ClinicServices.Menu')}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                        <Link to={"/Accounting/Clinic/Services/Prices"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                                            <div className="symbol symbol-60px mb-5">
                                                                <i className="fa-duotone fa-cash-register display-1 text-warning"></i>
                                                            </div>
                                                            <div className="fs-5 fw-bolder mb-2">
                                                                {t('Accounting.ClinicServices.Menu')}
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                : ""
                        }
                    </> : ""
            }
            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 9 || p.Id === 1) ?
                    <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                        <div className="col-12">
                            <span className="menu-icon">
                                <i className="h3 fa-regular fa-user-tie me-2"></i>
                            </span>
                            <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                {t('Permissions.Manager')}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Users.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Manager/Users"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-users display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Users.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('Clinic.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/Manager/Clinics"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-house-medical-flag display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('Clinic.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                user && user.user && user.user.UserPermission.some(p => p.Id === 1) ?
                    <div className="row g-6 g-xl-9 mb-2 mb-xl-3 mt-0 permission-icons">
                        <div className="col-12">
                            <span className="menu-icon">
                                <i className="h3 fa-regular fa-user-crown me-2"></i>
                            </span>
                            <span className="menu-section text-dark text-uppercase fs-4 ls-3">
                                {t('Permissions.Admin')}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3 permission-icon" title={t('HealthCenter.Menu')}>
                            <div className="card h-100">
                                <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                    <Link to={"/HealthCenters/"} className="text-gray-800 text-hover-primary d-flex flex-column">
                                        <div className="symbol symbol-60px mb-5">
                                            <i className="fa-duotone fa-hospital display-1"></i>
                                        </div>
                                        <div className="fs-5 fw-bolder mb-2">
                                            {t('HealthCenter.Menu')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(ControlPanel));