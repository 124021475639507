import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Field, reduxForm } from "redux-form";

import TreeView from "./TreeView";

class SelectField extends React.Component {

    state = { Search: "", currentItem: null, showModal: false, index: 0, initialize: false };

    static getDerivedStateFromProps(props, state) {
        if (state.index !== props.index) {
            state.index = props.index;
            state.currentItem = null;
        }
        if (state.currentItem && state.currentItem.label) {
            props.initialize({
                Select: state.currentItem.label
            });
        }
        if (!state.initialize && props.Text) {
            state.initialize = true;
            props.initialize({
                Select: props.Text
            });
        }
        return state;
    }

    setSearch = Search => {
        this.setState({ Search });
    }

    showModal = () => {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
    }

    select = item => {
        this.setState({
            showModal: false,
            currentItem: item
        });
        this.props.update(item);
    }

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} readOnly={readOnly} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Select')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    render() {
        const { t, data, Title, Placeholder } = this.props;
        return (<>

            {
                this.state.showModal ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="me-1 fa-solid fa-check me-2 text-primary"></i>
                                    {Title}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card">
                                <div className="d-flex mb-3 justify-content-end">
                                    <div className="position-relative w-md-200px me-md-2">
                                        <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                            <i className="fa-regular fa-magnifying-glass"></i>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid ps-10"
                                            id="Search"
                                            name="Search"
                                            autoComplete="off"
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            value={this.state.Search}
                                            placeholder={t("AdvancedSearch.Search.ByName")} />
                                    </div>
                                </div>
                                <TreeView
                                    currentId={this.state.currentItem ? this.state.currentItem.id : 0}
                                    data={data}
                                    isAdmin={false}
                                    Search={this.state.Search}
                                    select={this.select}
                                    setCurrentItem={this.setCurrentItem} />
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }

            <div className="input-group position-relative">
                <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6" style={{
                    zIndex: 9
                }}>
                    <i className="fa-regular fa-magnifying-glass"></i>
                </span>
                <Field
                    ref={Select => this.Select = Select}
                    name="Select"
                    id="Select"
                    component={this.renderFieldGroup}
                    disabled="disabled"
                    readOnly={true}
                    type={"text"}
                    placeholder={Placeholder}
                    title={Title}
                    defaultValue={this.props.Text}
                    value={this.state.currentItem ? this.state.currentItem.label : this.props.Text}
                    className="form-control form-control-solid disabled ps-10"
                />
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const formSelect = reduxForm({
    form: 'formSelect'
});

export default connect(mapStateToProps, {})(withTranslation('common')(formSelect(SelectField)));