import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Select from 'react-select';

import serverAPI from "../../api/serverAPI";
import { fetchVisitStatus } from "../../actions";
import { fetchVisitHistory, fetchAppointments } from '../Appointment/actions/';
import { fetchDrugAllergies } from "./DrugAllergy/actions";
import { fetchFamilyHistory } from "./FamilyHistory/actions";
import { fetchPastMedicalHistory } from "./PastMedicalHistory/actions";
import { fetchPastSurgicalHistory } from "./PastSurgicalHistory/actions";
import { fetchObstetricAndGyneHistory } from "./ObstetricAndGyneHistory/actions";

import AppointmentCard from "../Appointment/AppointmentCard";
import DiagnosisField from "../Diagnosis/DiagnosisField";
import LabOrders from "./LabOrders";
import RadiologyOrders from "./RadiologyOrders";
import DrugOrders from "./DrugOrders";
import DrugAllergy from "./DrugAllergy";
import FamilyHistory from "./FamilyHistory";
import VisitType from "../Appointment/Statistics/VisitType";
import VisitStatus from "../Appointment/Statistics/VisitStatus";
import VitalSigns from "./VitalSigns";
import PatientForms from "./PatientForms";
import Services from "./Services";
import Measurements from "./Measurements";
import PastMedicalHistory from "./PastMedicalHistory";
import PastSurgicalHistory from "./PastSurgicalHistory";
import ObstetricAndGyneHistory from "./ObstetricAndGyneHistory";
import GynecologyInfo from "./GynecologyInfo";
import PatientName from "../Patients/PatientName";
import MedicalRecords from "./MedicalRecords";
import ChronicDisease from "./ChronicDisease";
import { fetchPatientChronicDiseases } from "./ChronicDisease/actions";
import { fetchClinics } from "../Manager/Clinics/actions";

class ClinicScreen extends React.Component {

    state = {
        tab: "LabOrders",
        Note: "",
        clinics: [],
        Diagnos: null,
        Patient: null,
        visitStatus: [],
        Appointment: null,
        isNoteChange: false,
        showDrugAllergy: false,
        showPatientForms: false,
        showFamilyHistory: false,
        showChronicDisease: false,
        showMedicalRecords: false,
        showPastMedicalHistory: false,
        showPastSurgicalHistory: false,
        showObstetricAndGyneHistory: false,
        showVitalSigns: false,
        SearchByName: "",
        currentClinic: null,
        searchVisitStatus: [],
        appointmentVisitStatus: [],
    };

    constructor(props) {
        super(props);
        this.props.fetchVisitStatus();
        this.props.fetchClinics();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.clinics.length === 0 && props.clinics && props.clinics.clinics) {
            state.clinics = [];
            props.clinics.clinics.forEach(item => {
                state.clinics.push({
                    ...item,
                    id: item.Id,
                    label: item.Name
                });
            });
            if (props.clinics.clinics.length === 1) {
                state.currentClinic = props.clinics.clinics[0];
                const Date = `${moment().format('DD/MM/YYYY')}`;
                props.fetchAppointments(state.currentClinic.Id, Date);
            }
        }

        let appointmentVisitStatus = []
        let VisitType = [];
        if (props.appointments && props.appointments.appointments) {
            props.appointments.appointments.forEach(item => {
                appointmentVisitStatus.push(item.VisitStatu);
                if (item.IsNew === true || item.IsNew === false) {
                    VisitType.push(item.IsNew);
                }
            });
        }
        state.VisitType = VisitType;
        state.appointmentVisitStatus = appointmentVisitStatus;

        if (props.visitStatus && props.visitStatus.visitStatus) {
            state.visitStatus = props.visitStatus.visitStatus.filter(p => p.Id <= 3).map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> <span className={`badge badge-square badge-${item.Color} badge-sm me-1 p-2`}>{props.t(`Appointment.VisitStatus.${item.Name}`)}</span></div>,
                };
            });
        }

        return state;
    }

    setTab = tab => {
        this.setState({
            tab
        });
    }

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_content_inner").classList.add("w-100");
        document.getElementById("kt_content_inner").classList.add("mx-5");
        document.getElementById("kt_content_inner").classList.remove("container-xxl");
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_content_inner").classList.add("container-xxl");
        document.getElementById("kt_content_inner").classList.remove("mx-5");
        document.getElementById("kt_content_inner").classList.remove("w-100");
    }

    getAppointment = () => {
        const Date = `${moment().format('DD/MM/YYYY')}`;
        this.props.fetchAppointments(this.state.currentClinic.Id, Date);
    }

    updateClinic = currentClinic => {
        this.setState({
            currentClinic,
            SearchByName: ""
        }, () => { this.getAppointment() });
    }

    changeClinic = () => {
        this.setState({
            Appointment: null,
            currentClinic: null,
            Patient: null
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setVisitStatus = searchVisitStatus => {
        this.setState({
            searchVisitStatus
        });
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    filterAppointmentByName = (arrayToSearch, key) => {
        let searchResult = [];
        const subResult = arrayToSearch.filter(p =>
            p.patient.Name.FirstName.includes(key) ||
            p.patient.Name.SecondName.includes(key) ||
            p.patient.Name.ThirdName.includes(key) ||
            p.patient.Name.LastName.includes(key)
        );
        subResult.forEach(result => {
            if (!searchResult.some(p => p.Id === result.Id)) {
                searchResult.push(result);
            }
        });
        return searchResult;
    }

    selectAppointment = Appointment => {
        this.setState({
            Appointment,
            Note: Appointment.Note,
            Diagnos: Appointment.ICD10
        }, () => {
            this.fetchDrugAllergies();
            this.fetchFamilyHistory();
            this.fetchPastMedicalHistory();
            this.fetchPastSurgicalHistory();
            this.fetchPatientChronicDiseases();
            if (Appointment.patient.Sex === "female") {
                this.fetchObstetricAndGyneHistory();
            }
        });
    }

    showObstetricAndGyneHistory = () => {
        this.setState({
            showObstetricAndGyneHistory: true
        });
    }

    fetchObstetricAndGyneHistory = () => {
        if (this.state.Appointment && this.state.Appointment.patient) {
            this.props.fetchObstetricAndGyneHistory(this.state.Appointment.patient.Id);
        }
    }

    updateObstetricAndGyneHistory = () => {
        this.fetchObstetricAndGyneHistory();
        this.hideObstetricAndGyneHistory();
    }

    hideObstetricAndGyneHistory = () => {
        this.setState({
            showObstetricAndGyneHistory: false
        });
    }

    showPastSurgicalHistory = () => {
        this.setState({
            showPastSurgicalHistory: true
        });
    }

    fetchPastSurgicalHistory = () => {
        if (this.state.Appointment && this.state.Appointment.patient) {
            this.props.fetchPastSurgicalHistory(this.state.Appointment.patient.Id);
        }
    }

    updatePastSurgicalHistory = () => {
        this.fetchPastSurgicalHistory();
        this.hidePastSurgicalHistory();
    }

    hidePastSurgicalHistory = () => {
        this.setState({
            showPastSurgicalHistory: false
        });
    }

    showPastMedicalHistory = () => {
        this.setState({
            showPastMedicalHistory: true
        });
    }

    fetchPastMedicalHistory = () => {
        if (this.state.Appointment && this.state.Appointment.patient) {
            this.props.fetchPastMedicalHistory(this.state.Appointment.patient.Id);
        }
    }

    updatePastMedicalHistory = () => {
        this.fetchPastMedicalHistory();
        this.hidePastMedicalHistory();
    }

    hidePastMedicalHistory = () => {
        this.setState({
            showPastMedicalHistory: false
        });
    }

    fetchFamilyHistory = () => {
        if (this.state.Appointment && this.state.Appointment.patient) {
            this.props.fetchFamilyHistory(this.state.Appointment.patient.Id);
        }
    }

    fetchDrugAllergies = () => {
        if (this.state.Appointment && this.state.Appointment.patient) {
            this.props.fetchDrugAllergies(this.state.Appointment.patient.Id);
        }
    }

    fetchPatientChronicDiseases = () => {
        if (this.state.Appointment && this.state.Appointment.patient) {
            this.props.fetchPatientChronicDiseases(this.state.Appointment.patient.Id);
        }
    }

    setNote = Note => {
        this.setState({
            Note,
            isNoteChange: true
        });
    }

    saveDiagnosis = Diagnos => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.put(`/SavePatientDiagnoses/${this.state.Appointment.Id}`, {
            CodeId: Diagnos.Id,
            PatientId: this.state.Appointment.patient.Id,
            ClinicId: this.state.currentClinic.Id
        }).then(response => {

            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Diagnos.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Diagnos.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                Diagnos
            });
            this.getAppointment();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    showChronicDisease = () => {
        this.setState({
            showChronicDisease: true
        });
    }

    hideChronicDisease = () => {
        this.setState({
            showChronicDisease: false
        });
    }

    updateChronicDisease = () => {
        this.fetchPatientChronicDiseases();
        this.hideChronicDisease();
    }

    updateDrugAllergy = () => {
        this.fetchDrugAllergies();
        this.hideDrugAllergy();
    }

    showDrugAllergy = () => {
        this.setState({
            showDrugAllergy: true
        });
    }

    hideDrugAllergy = () => {
        this.setState({
            showDrugAllergy: false
        });
    }

    showMedicalRecords = () => {
        this.setState({
            showMedicalRecords: true
        });
    }

    hideMedicalRecords = () => {
        this.setState({
            showMedicalRecords: false
        });
    }

    updateFamilyHistory = () => {
        this.fetchFamilyHistory();
        this.hideFamilyHistory();
    }

    showFamilyHistory = () => {
        this.setState({
            showFamilyHistory: true
        });
    }

    hideFamilyHistory = () => {
        this.setState({
            showFamilyHistory: false
        });
    }

    showVitalSigns = () => {
        this.setState({
            showVitalSigns: true
        });
    }

    hideVitalSigns = () => {
        this.setState({
            showVitalSigns: false
        });
    }

    showPatientForms = () => {
        this.setState({
            showPatientForms: true
        });
    }

    hidePatientForms = () => {
        this.setState({
            showPatientForms: false
        });
    }

    saveNote = () => {
        if (this.state.isNoteChange) {
            const { t } = this.props;
            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            serverAPI.put(`/SavePatientVisitNote/${this.state.Appointment.Id}`, {
                Note: this.state.Note,
                PatientId: this.state.Appointment.patient.Id,
                ClinicId: this.state.currentClinic.Id
            }).then(response => {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.VisitNote.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.VisitNote.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.getAppointment();
                this.setState({
                    isNoteChange: false
                });
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            });
        }
    }

    changeAppointment = () => {
        this.setState({
            Appointment: null,
            Note: "",
            Diagnos: null
        });
    }

    renderTableRow = () => {
        const { t, appointments } = this.props;
        if (appointments && appointments.appointments && appointments.appointments.length > 0) {
            const searchArray = this.state.SearchByName.split(' ');

            let searchResult = appointments.appointments.filter(p => p.VisitStatu.Id !== 4);
            searchArray.forEach(item => {
                searchResult = this.filterAppointmentByName(searchResult, item);
            });

            if (this.state.searchVisitStatus?.value) {
                searchResult = searchResult.filter(p => this.state.searchVisitStatus.value === p.VisitStatu.Id);
            }

            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`clinic-screen-${index}-${item.Id}`}>
                        <td title={t(`Appointment.VisitStatus.${item.VisitStatu.Name}`)}>

                            <span className={`badge badge-square badge-${item.VisitStatu.Color} badge-sm me-1 p-2`}> {index + 1}</span>
                        </td>
                        <td>
                            <Link to="#" onClick={() => this.selectAppointment(item)} className="text-nowrap">
                                <PatientName patient={item.patient} />
                            </Link>
                        </td>
                        <td title={t("Patient.ArrivalTime")}>
                            {item.ArrivalTime}
                        </td>
                    </tr >;
                });
            }
            return <tr><td className="text-center" colSpan={3}>{t("NoResult")}</td></tr >;
        }
        return <tr><td className="text-center" colSpan={3}>{t("NoRows")}</td></tr>;
    }

    renderClinic = () => {
        const { t } = this.props;
        const searchResult = this.state.clinics.filter(p =>
            p.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()) ||
            p.DoctorName.toLowerCase().includes(this.state.SearchByName.toLowerCase()));

        return searchResult.map((clinic, key) => {
            return <div key={`clinic_${clinic.Id}_${key}`} className="timeline-item">
                <div className="timeline-line w-40px"></div>
                <div className="timeline-icon symbol symbol-circle symbol-40px me-4" >
                    <div className="symbol-label bg-light ">
                        <i className="fas fa-house-medical-flag text-primary"></i>
                    </div>
                </div>
                <div className="timeline-content mb-0 mt-n1">
                    <div className="pe-3 my-3 d-flex justify-content-between" >
                        <div>
                            <div>
                                {clinic.Name}
                            </div>
                            {
                                clinic.DoctorName ? <div className="ms-10 fs-8 mt-2">
                                    <i className="fas fa-user-doctor text-primary me-2"></i>
                                    {clinic.DoctorName}
                                </div> : ""
                            }
                        </div>
                        <div>
                            <div className="btn btn-icon btn-light btn-active-light-info me-1" title={t('Table.Options.Select')} onClick={() => this.updateClinic(clinic)} >
                                <i className="fa-solid fa-check "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    render() {
        const { t, appointments, visitStatus, drugAllergy, familyHistory, pastMedicalHistory, pastSurgicalHistory, obstetricAndGyneHistory, patientChronicDisease } = this.props;

        let isLoaded = false;
        if (appointments) {
            isLoaded = appointments.isLoaded;
        }

        let isLoadedVisitStatus = false;
        if (visitStatus) {
            isLoadedVisitStatus = visitStatus.isLoaded;
        }

        let hasDrugAllergy = false;
        if (drugAllergy && drugAllergy.drugAllergy && drugAllergy.drugAllergy.Name && drugAllergy.drugAllergy.Name.Drugs?.length > 0) {
            hasDrugAllergy = true;
        }

        let hasFamilyHistory = false;
        if (familyHistory && familyHistory.familyHistory?.Note) {
            hasFamilyHistory = true;
        }

        let hasPastMedicalHistory = false;
        if (pastMedicalHistory && pastMedicalHistory.pastMedicalHistory?.Note) {
            hasPastMedicalHistory = true;
        }

        let hasPastSurgicalHistory = false;
        if (pastSurgicalHistory && pastSurgicalHistory.pastSurgicalHistory?.Note) {
            hasPastSurgicalHistory = true;
        }


        let hasObstetricAndGyneHistory = false;
        if (obstetricAndGyneHistory && obstetricAndGyneHistory.obstetricAndGyneHistory?.Note) {
            hasObstetricAndGyneHistory = true;
        }

        let hasChronicDisease = false;
        if (patientChronicDisease && patientChronicDisease.patientChronicDisease?.length > 0) {
            hasChronicDisease = true;
        }

        return <>
            <Helmet>
                <title>{t("ClinicScreen.Title")} | {t('SiteName')}</title>
            </Helmet>
            {
                this.state.showDrugAllergy && this.state.Appointment && this.state.Appointment.patient ?
                    <DrugAllergy update={this.updateDrugAllergy} patient={this.state.Appointment.patient} handleClose={this.hideDrugAllergy} />
                    : ""
            }
            {
                this.state.showFamilyHistory && this.state.Appointment && this.state.Appointment.patient ?
                    <FamilyHistory update={this.updateFamilyHistory} patient={this.state.Appointment.patient} handleClose={this.hideFamilyHistory} />
                    : ""
            }
            {
                this.state.showPastMedicalHistory && this.state.Appointment && this.state.Appointment.patient ?
                    <PastMedicalHistory update={this.updatePastMedicalHistory} patient={this.state.Appointment.patient} handleClose={this.hidePastMedicalHistory} />
                    : ""
            }
            {
                this.state.showPastSurgicalHistory && this.state.Appointment && this.state.Appointment.patient ?
                    <PastSurgicalHistory update={this.updatePastSurgicalHistory} patient={this.state.Appointment.patient} handleClose={this.hidePastSurgicalHistory} />
                    : ""
            }
            {
                this.state.showObstetricAndGyneHistory && this.state.Appointment && this.state.Appointment.patient ?
                    <ObstetricAndGyneHistory update={this.updateObstetricAndGyneHistory} patient={this.state.Appointment.patient} handleClose={this.hideObstetricAndGyneHistory} />
                    : ""
            }
            {
                this.state.showPatientForms && this.state.Appointment && this.state.Appointment.patient ?
                    <PatientForms showCreate={true} patient={this.state.Appointment.patient} Clinic={this.state.currentClinic} handleClose={this.hidePatientForms} />
                    : ""
            }
            {
                this.state.showMedicalRecords && this.state.Appointment && this.state.Appointment.patient ?
                    <MedicalRecords Patient={this.state.Appointment.patient} handleClose={this.hideMedicalRecords} />
                    : ""
            }
            {
                this.state.showChronicDisease && this.state.Appointment && this.state.Appointment.patient ?
                    <ChronicDisease update={this.updateChronicDisease} patient={this.state.Appointment.patient} handleClose={this.hideChronicDisease} />
                    : ""
            }

            <div className={`row g-6 g-xl-9 mb-6 mb-xl-9 mt-0 ${this.state.currentClinic ? "" : "justify-content-center"}`}>
                <div className={` ${this.state.currentClinic ? "col-lg-4 col-xxl-3" : "col-md-6 col-sm-12"}`}>
                    <div className="card h-100">
                        {
                            this.state.currentClinic ?
                                <>
                                    <div className="card-header align-items-center">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="fw-bolder text-dark">
                                                {this.state.currentClinic.Name}
                                            </span>
                                        </h3>
                                        <div className="card-toolbar">
                                            {
                                                this.state.clinics.length > 1 ?
                                                    <Link
                                                        to="#"
                                                        title={t('ClinicScreen.ChangeClinic')}
                                                        className="btn btn-sm btn-icon btn-light-primary "
                                                        onClick={() => this.changeClinic()}>
                                                        <i className="h3 fa-duotone fa-arrows-retweet"></i>
                                                    </Link> : ""
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.Appointment ?
                                            <div className="card-body pt-5">
                                                <Link
                                                    to="#"
                                                    title={t('ClinicScreen.ChangePatient')}
                                                    className="btn btn-sm btn-block btn-light-danger mb-4 w-100"
                                                    onClick={() => this.changeAppointment()}>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <i className="fa-duotone fa-ban me-2"></i>
                                                        {t('ClinicScreen.ChangePatient')}
                                                    </div>
                                                </Link>
                                                <AppointmentCard Appointment={this.state.Appointment} />
                                            </div>
                                            :
                                            <div className="card-body p-3 pt-5">
                                                <div className={`table-responsive-md w-100 h-100 ${isLoaded ? "" : "table-loading"}`}  >
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center  w-100">
                                                        <Link
                                                            to="#"
                                                            title={t('Refresh')}
                                                            className="btn btn-sm btn-block btn-light-primary mb-4 w-100"
                                                            onClick={() => this.getAppointment()}>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <i className="fa-duotone fa-rotate me-2"></i>
                                                                {t('Refresh')}
                                                            </div>
                                                        </Link>
                                                        <div className="d-flex align-items-center mb-2 w-100">
                                                            <Select
                                                                onChange={this.setVisitStatus}
                                                                isClearable={true}
                                                                name="ByVisitStatus"
                                                                id="ByVisitStatus"
                                                                className="w-100"
                                                                placeholder={t('AdvancedSearch.Search.ByVisitStatus')}
                                                                title={t('AdvancedSearch.Search.ByVisitStatus')}
                                                                isRtl={true}
                                                                isLoading={!isLoadedVisitStatus}
                                                                isSearchable={true}
                                                                ref={ByVisitStatus => this.ByVisitStatus = ByVisitStatus}
                                                                options={this.state.visitStatus}
                                                                filterOption={this.filterOptions}
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2 w-100">
                                                            <input
                                                                value={this.state.SearchByName}
                                                                onChange={e => this.setSearch(e.target.value)}
                                                                ref={SearchByName => this.SearchByName = SearchByName}
                                                                name="SearchByName"
                                                                id="SearchByName"
                                                                autoComplete={"off"}
                                                                type={"text"}
                                                                placeholder={t('AdvancedSearch.Search.ByName')}
                                                                title={t('AdvancedSearch.Search.ByName')}
                                                                className="form-control form-control-sm" />
                                                        </div>
                                                    </div>
                                                    <div className="table-loading-message">
                                                        {t("Processing.Title")}
                                                    </div>
                                                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                                        <thead className="border-bottom border-gray-200 fs-6 fw-bold ">
                                                            <tr>
                                                                <th title={t("Table.Number")}>
                                                                    <i className="fa-regular text-dark fa-hashtag"></i>
                                                                </th>
                                                                <th title={t("Patient.Name")}>
                                                                    <span className="d-flex align-items-center">
                                                                        <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                                                        <span className="d-md-block d-none text-nowrap">
                                                                            {t("Patient.Name")}
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th title={t("Patient.ArrivalTime")}>
                                                                    <span className="d-flex align-items-center">
                                                                        <i className="fa-regular text-dark fa-clock me-1"></i>
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="fs-6 fw-semibold text-gray-600">
                                                            {this.renderTableRow()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <>
                                    <div className="card-header align-items-center">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="fw-bolder text-dark">
                                                <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                                                {t('ClinicScreen.SelectClinic')}
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="card-body pt-5">
                                        <div className="d-flex mb-3 justify-content-end">
                                            <div className="position-relative w-md-200px me-md-2">
                                                <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid ps-10"
                                                    id="Search"
                                                    name="Search"
                                                    autoComplete="off"
                                                    onChange={(e) => this.setSearch(e.target.value)}
                                                    value={this.state.Search}
                                                    placeholder={t("AdvancedSearch.Search.ByName")} />
                                            </div>
                                        </div>
                                        <div className="timeline">
                                            {this.renderClinic()}
                                        </div>
                                    </div>
                                </>
                        }

                    </div>
                </div>
                {
                    this.state.Appointment ? <>
                        <div className="col-lg-8 col-xxl-9">
                            <div className="row">
                                <div className="col-lg-6 col-xxl-5 mb-3">

                                    <div className="card mb-3">
                                        <div className="card-body p-3">
                                            <div className="d-grid w-100 hover-scroll-x">
                                                <div className="flex-nowrap text-nowrap hover-text-wrap w-100">

                                                    <div title={t("MedicalRecords.Title")} className={`btn btn-sm btn-sm-icon m-1 btn-icon-primary btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary`} onClick={() => this.showMedicalRecords()}  >
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-rectangle-history-circle-user `}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("MedicalRecords.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div title={t("PatientForms.Title")} className={`btn btn-sm btn-sm-icon m-1 btn-light-success`} onClick={() => this.showPatientForms()}>
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-file-lines`}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("PatientForms.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div title={t("DrugAllergy.Title")} className={`btn btn-sm btn-sm-icon m-1 ${hasDrugAllergy ? "btn-light-danger" : "bg-secondary"}`} onClick={() => this.showDrugAllergy()}>
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-light-${hasDrugAllergy ? "emergency-on fa-shake" : "emergency"} `}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("DrugAllergy.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div title={t("ChronicDisease.Title")} className={`btn btn-sm btn-sm-icon m-1 btn-outline btn-outline-dashed  ${hasChronicDisease ? "btn-light-danger border-dashed border-danger " : "bg-secondary"}`} onClick={() => this.showChronicDisease()}  >
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-viruses `}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("ChronicDisease.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div title={t("PastMedicalHistory.Title")} className={`btn btn-sm btn-sm-icon m-1 ${hasPastMedicalHistory ? "btn-light-warning" : "bg-secondary"}`} onClick={() => this.showPastMedicalHistory()}>
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-notes-medical`}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("PastMedicalHistory.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div title={t("PastSurgicalHistory.Title")} className={`btn btn-sm btn-sm-icon m-1 ${hasPastSurgicalHistory ? "btn-light-primary" : "bg-secondary"}`} onClick={() => this.showPastSurgicalHistory()}>
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-kit-medical`}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("PastSurgicalHistory.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    {
                                                        this.state.Appointment.patient.Sex === "female" ?
                                                            <div title={t("ObstetricAndGyneHistory.Title")} className={`btn btn-sm btn-sm-icon m-1 ${hasObstetricAndGyneHistory ? "btn-dark" : "bg-secondary"}`} onClick={() => this.showObstetricAndGyneHistory()}>
                                                                <span className="d-flex align-items-center">
                                                                    <i className={`fa-regular fa-person-breastfeeding`}></i>
                                                                    <span className="d-md-block d-none text-nowrap">
                                                                        {t("ObstetricAndGyneHistory.Title")}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            : ""
                                                    }

                                                    <div title={t("FamilyHistory.Title")} className={`btn btn-sm btn-sm-icon m-1 ${hasFamilyHistory ? "btn-light-info" : "bg-secondary"}`} onClick={() => this.showFamilyHistory()}>
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fa-regular fa-family`}></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("FamilyHistory.Title")}
                                                            </span>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-header align-items-center border-0 mt-4">
                                            <label className="card-title h3 d-flex align-items-center" htmlFor="Diagnosis">
                                                <i className="h3 fa-duotone fa-book-medical me-2 text-info"></i>
                                                {t("Diagnosis.Title")}
                                            </label>
                                        </div>
                                        <div className="card-body pt-5">
                                            <div className="w-100">
                                                <DiagnosisField
                                                    Appointment={this.state.Appointment}
                                                    update={this.saveDiagnosis}
                                                    Diagnos={this.state.Diagnos}
                                                />
                                                {
                                                    this.state.Diagnos ?
                                                        <div className="d-flex flex-column align-items-start mt-3">
                                                            <span className="fs-5 fw-bold">
                                                                <i className="h3 fa-duotone fa-book-medical me-2 text-info"></i>
                                                                {this.state.Diagnos.Code}
                                                            </span>
                                                            <span className="fs-6">
                                                                {this.state.Diagnos.Note}
                                                            </span>
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header align-items-center border-0 mt-4">
                                            <label className="card-title h3 d-flex align-items-center" htmlFor="Note">
                                                <i className="h3 fa-duotone fa-note-medical me-2 text-info"></i>
                                                {t("ClinicScreen.Note.Title")}
                                            </label>
                                        </div>
                                        <div className="card-body pt-5">
                                            <div className="w-100">
                                                <textarea
                                                    id="Note"
                                                    name="Note"
                                                    ref={Note => this.Note = Note}
                                                    rows={7}
                                                    onChange={(e) => this.setNote(e.target.value)}
                                                    onBlur={this.saveNote}
                                                    value={this.state.Note ? this.state.Note : ""}
                                                    title={t("ClinicScreen.Note.Title")}
                                                    placeholder={t("ClinicScreen.Note.Placeholder")}
                                                    className="form-control form-control-transparent ps-5"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6 col-xxl-7 mb-3">
                                    <div className="card h-100">
                                        <div className="card-header align-items-center border-0 mt-4">
                                            <div className="d-grid w-100 hover-scroll-x">
                                                <ul className="nav nav-tabs flex-nowrap text-nowrap w-100 mb-2">
                                                    <li className="nav-item w-100 me-1">
                                                        <a className="nav-link justify-content-center w-100 active btn-sm-icon btn btn-flex btn-light-success" title={t("ClinicScreen.Orders.Lab.Title")} data-bs-toggle="tab" href="#LabOrders" onClick={() => this.setTab("LabOrders")}>
                                                            <span className="svg-icon fs-2"> <i className="fa-solid fa-microscope"></i></span>
                                                            <span className="d-flex flex-column align-items-start">
                                                                <span className="fs-4 fw-bold d-md-block d-none text-nowrap">
                                                                    {t("ClinicScreen.Orders.Lab.Title")}
                                                                </span>
                                                                <span className="fs-7 d-md-block d-none">{t("ClinicScreen.Orders.Lab.Description")} </span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item w-100 me-1">
                                                        <a className="nav-link justify-content-center w-100 btn-sm-icon btn btn-flex btn-light-info" title={t("ClinicScreen.Orders.Radiology.Title")} data-bs-toggle="tab" href="#RadiologyOrders" onClick={() => this.setTab("RadiologyOrders")}>
                                                            <span className="svg-icon fs-2">
                                                                <i className="fa-solid fa-circle-radiation"></i>
                                                            </span>
                                                            <span className="d-flex flex-column align-items-start">
                                                                <span className="fs-4 fw-bold d-md-block d-none text-nowrap">
                                                                    {t("ClinicScreen.Orders.Radiology.Title")}
                                                                </span>
                                                                <span className="fs-7 d-md-block d-none">{t("ClinicScreen.Orders.Radiology.Description")} </span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item w-100 me-1">
                                                        <a className="nav-link justify-content-center w-100 btn-sm-icon btn btn-flex btn-light-danger" title={t("ClinicScreen.Orders.Drug.Title")} data-bs-toggle="tab" href="#DrugsOrder" onClick={() => this.setTab("DrugsOrder")}>
                                                            <span className="svg-icon fs-2">
                                                                <i className="fa-solid fa-prescription"></i>
                                                            </span>
                                                            <span className="d-flex flex-column align-items-start">
                                                                <span className="fs-4 fw-bold d-md-block d-none text-nowrap">
                                                                    {t("ClinicScreen.Orders.Drug.Title")}
                                                                </span>
                                                                <span className="fs-7 d-md-block d-none">{t("ClinicScreen.Orders.Drug.Description")} </span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item w-100">
                                                        <a className="nav-link justify-content-center w-100 btn-sm-icon btn btn-flex btn-light-dark" title={t("VitalSigns.Title")} data-bs-toggle="tab" href="#VitalSigns" onClick={() => this.setTab("VitalSigns")}>
                                                            <span className="svg-icon fs-2">
                                                                <i className="fa-solid fa-monitor-waveform"></i>
                                                            </span>
                                                            <span className="d-flex flex-column align-items-start">
                                                                <span className="fs-4 fw-bold d-md-block d-none text-nowrap">
                                                                    {t("VitalSigns.Title")}
                                                                </span>
                                                                <span className="fs-7 d-md-block d-none">{t("VitalSigns.Description")} </span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body pt-5">
                                            <div className="tab-content h-100" >
                                                <div className="tab-pane h-100 fade show active" id="LabOrders" role="tabpanel">
                                                    {
                                                        this.state.tab === "LabOrders" ?
                                                            <LabOrders showCreate={true} Appointment={this.state.Appointment} currentClinic={this.state.currentClinic} />
                                                            : ""
                                                    }
                                                </div>
                                                <div className="tab-pane h-100 fade" id="RadiologyOrders" role="tabpanel">
                                                    {
                                                        this.state.tab === "RadiologyOrders" ?
                                                            <RadiologyOrders showCreate={true} Appointment={this.state.Appointment} currentClinic={this.state.currentClinic} />
                                                            : ""
                                                    }
                                                </div>
                                                <div className="tab-pane h-100 fade" id="DrugsOrder" role="tabpanel">
                                                    {
                                                        this.state.tab === "DrugsOrder" ?
                                                            <DrugOrders showCreate={true} Appointment={this.state.Appointment} currentClinic={this.state.currentClinic} />
                                                            : ""
                                                    }
                                                </div>
                                                <div className="tab-pane h-100 fade" id="VitalSigns" role="tabpanel">
                                                    {
                                                        this.state.tab === "VitalSigns" ?
                                                            <VitalSigns showCreate={true} Appointment={this.state.Appointment} currentClinic={this.state.currentClinic} />
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.Appointment.patient.Sex === "female" ? <>
                                    <div className="row">
                                        <div className="col-lg-6 col-xxl-5 mb-3">
                                            <GynecologyInfo Appointment={this.state.Appointment} />
                                        </div>
                                        <div className="col-lg-6 col-xxl-7 mb-3">
                                            <div className="card h-100">
                                                <div className="card-header align-items-center border-0 mt-4">
                                                    <div className="card-title h3 d-flex align-items-center">
                                                        <i className="h3 fa-duotone fa-person-breastfeeding me-2 text-info"></i>
                                                        {t("ObstetricAndGyneHistory.Title")}
                                                    </div>
                                                    <div className="card-toolbar">
                                                        <Link
                                                            to="#"
                                                            onClick={() => this.showObstetricAndGyneHistory()}
                                                            title={hasObstetricAndGyneHistory ? t("ObstetricAndGyneHistory.Edit") : t("ObstetricAndGyneHistory.New")}
                                                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                                                            <i className="fa-regular fa-plus"></i>
                                                            <span className="d-md-block d-none text-nowrap ms-1">
                                                                {hasObstetricAndGyneHistory ? t("ObstetricAndGyneHistory.Edit") : t("ObstetricAndGyneHistory.New")}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-5">
                                                    <div className="w-100 h-100 d-flex flex-column justify-content-between">
                                                        {
                                                            obstetricAndGyneHistory && obstetricAndGyneHistory.obstetricAndGyneHistory ? <>
                                                                <div style={{
                                                                    whiteSpace: 'pre-wrap'
                                                                }} dangerouslySetInnerHTML={{
                                                                    __html: obstetricAndGyneHistory.obstetricAndGyneHistory.Note
                                                                }}></div>
                                                                <div>
                                                                    <div className="separator separator-dashed border-dark mb-5"></div>
                                                                    <div className="row">
                                                                        <div className="col-xl-3 mb-3 col-md-6">
                                                                            <div className="row">
                                                                                <span className="fw-bold text-muted mb-2">
                                                                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                                                                    {t("LastUpdatedUser")}
                                                                                </span>
                                                                                <span className="ms-10">
                                                                                    {obstetricAndGyneHistory.obstetricAndGyneHistory.User.Name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-3 mb-3 col-md-6">
                                                                            <div className="row">
                                                                                <span className="fw-bold text-muted mb-2">
                                                                                    <i className="fa-regular text-dark fa-hospital me-1"></i>
                                                                                    {t("HealthCenter.Title")}
                                                                                </span>
                                                                                <span className="ms-10">
                                                                                    {obstetricAndGyneHistory.obstetricAndGyneHistory.User.HealthCenter.Name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-3 mb-3 col-md-6">
                                                                            <div className="row">
                                                                                <span className="fw-bold text-muted mb-2">
                                                                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                                                    {t("CreationDate")}
                                                                                </span>
                                                                                <span className="ms-10">
                                                                                    {obstetricAndGyneHistory.obstetricAndGyneHistory.CreationDate}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-3 mb-3 col-md-6">
                                                                            <div className="row">
                                                                                <span className="fw-bold text-muted mb-2">
                                                                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                                                    {t("LastUpdatedDate")}
                                                                                </span>
                                                                                <span className="ms-10">
                                                                                    {obstetricAndGyneHistory.obstetricAndGyneHistory.UpdateDate}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : ""
                            }

                            <div className="row">
                                <div className="col-lg-6 col-xxl-5 mb-3">
                                    <Measurements patient={this.state.Appointment.patient} Appointment={this.state.Appointment} />
                                </div>
                                <div className="col-lg-6 col-xxl-7 mb-3">
                                    <Services showCreate={true} Appointment={this.state.Appointment} currentClinic={this.state.currentClinic} />
                                </div>
                            </div>
                        </div>
                    </> :
                        this.state.currentClinic && this.state.appointmentVisitStatus?.length > 0 ?
                            <div className="col-lg-8 col-xxl-9">
                                <div className="row d-flex justify-content-evenly flex-wrap">
                                    <div className="col-xl-4 col-md-6 col-sm-12">
                                        <div className="card h-100">
                                            <div className="card-body pt-5">
                                                <VisitStatus Data={this.state.appointmentVisitStatus} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12">
                                        <div className="card h-100">
                                            <div className="card-body pt-5">
                                                <VisitType Data={this.state.VisitType} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <></>
                }
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinics: state.clinics,
        drugAllergy: state.drugAllergy,
        visitStatus: state.visitStatus,
        appointments: state.appointments,
        familyHistory: state.familyHistory,
        pastMedicalHistory: state.pastMedicalHistory,
        pastSurgicalHistory: state.pastSurgicalHistory,
        patientChronicDisease: state.patientChronicDisease,
        obstetricAndGyneHistory: state.obstetricAndGyneHistory
    };
};

export default connect(mapStateToProps, { fetchClinics, fetchAppointments, fetchVisitHistory, fetchVisitStatus, fetchDrugAllergies, fetchFamilyHistory, fetchPastMedicalHistory, fetchPastSurgicalHistory, fetchObstetricAndGyneHistory, fetchPatientChronicDiseases })(withTranslation('common')(ClinicScreen));