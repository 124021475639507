import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PatientName from "../../../Patients/PatientName";
import PatientInfo from "../../../Patients/Search/Info";

class Info extends React.Component {

    state = {
        patient: null,
        showPatientInfo: false,
    };

    showPatientInfo = patient => {
        this.setState({
            showPatientInfo: true,
            patient
        })
    }

    handleClose = () => {
        this.setState({
            showPatientInfo: false,
            patient: null
        })
    }

    render() {
        const { t, Voucher } = this.props;
        return <>
            {this.state.showPatientInfo && this.state.patient ? <PatientInfo patient={this.state.patient} handleClose={this.handleClose} /> : ""}
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                        {t("Table.Options.Info")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=" mb-5" title={t("NumberAuto")}>
                        <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Voucher.Id}</span>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar me-2"></i>
                                    {t("Accounting.ReceiptVoucher.Forms.Date.Title")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.Date}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-sack-dollar me-2"></i>
                                    {t("Accounting.ReceiptVoucher.Forms.Value.Title")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.Value}
                                    <span className="ms-1">
                                        {Voucher.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-percent me-2"></i>
                                    {t("Currencies.Forms.Rate.Title")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.Rate}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12">
                            <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head border border-gray-900 border-dashed">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800">
                                        <th title={t("Accounting.Account.Info")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-file-user me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Accounting.Account.Info")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Accounting.Debited")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-scale-unbalanced-flip me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Accounting.Debited")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Accounting.Credited")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-scale-unbalanced me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Accounting.Credited")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Details")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-note me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Details")}
                                                </span>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Voucher.DebitedAccounts.map((account, index) => {
                                            return <tr key={`debited-accounts-table-${account.Id}-${index}`} >
                                                <td>{
                                                    account.patient ?
                                                        <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(account.patient)}>
                                                            <PatientName patient={account.patient} />
                                                        </Link>
                                                        :
                                                        <span>
                                                            {account.Number + " " + account.Name}
                                                        </span>
                                                }</td>
                                                <td>{account.Value.toFixed(2)}         {Voucher.Currency?.Name}</td>
                                                <td>0</td>
                                                <td>{account.Note}</td>
                                            </tr>
                                        })
                                    }
                                    {
                                        Voucher.CreditedAccounts.map((account, index) => {
                                            return <tr key={`credited-accounts-table-${account.Id}-${index}`} >
                                                <td>{
                                                    account.patient ?
                                                        <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(account.patient)}>
                                                            <PatientName patient={account.patient} />
                                                        </Link>
                                                        :
                                                        <span>
                                                            {account.Number + " " + account.Name}
                                                        </span>
                                                }</td>
                                                <td>0</td>
                                                <td>{account.Value.toFixed(2)}         {Voucher.Currency?.Name}</td>
                                                <td>{account.Note}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {
                        Voucher.Note ?
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-notes me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.Note.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.Note}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }

                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.User.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));