const OrderStatusReducers = (state = [], action) => {
    switch (action.type) {
        case "ORDER_STATUS_LOADING":
            return {
                orderStatus: [],
                isLoaded: false
            };
        case "ORDER_STATUS_INITIAL":
            return {
                orderStatus: action.orderStatus,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default OrderStatusReducers;