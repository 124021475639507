const LabExamTypesReducers = (state = [], action) => {
    switch (action.type) {
        case "LAB_EXAM_TYPES_LOADING":
            return {
                labExamTypes: [],
                isLoaded: false
            };
        case "LAB_EXAM_TYPES_INITIAL":
            return {
                labExamTypes: action.labExamTypes,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LabExamTypesReducers;