import serverAPI from "../../../../api/serverAPI";

export const fetchMeasurements = id => async dispatch => {
    dispatch({ type: 'MEASUREMENT_LOADING' });
    const response = await serverAPI.get(`/Measurements/${id}`);
    dispatch({ type: 'MEASUREMENT_INITIAL', measurement: response.data });
}

export const fetchMeasurementHistories = id => async dispatch => {
    dispatch({ type: 'MEASUREMENT_HISTORY_LOADING' });
    const response = await serverAPI.get(`/MeasurementHistories/${id}`);
    dispatch({ type: 'MEASUREMENT_HISTORY_INITIAL', measurementHistories: response.data });
}