import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Delete extends React.Component {

    render() {
        const { t, form } = this.props;

        return <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-trash me-2 text-danger "></i>
                    {t("PatientForms.Delete")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-5">
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-text me-1"></i>
                                {t("PatientForms.Forms.Title.Title")}
                            </span>
                            <span className="ms-10">
                                {form.Title}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-user me-1"></i>
                                {t("LastUpdatedUser")}
                            </span>
                            <span className="ms-10">
                                {form.User.Name}
                            </span>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("CreationDate")}
                            </span>
                            <span className="ms-10">
                                {form.CreationDate}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("LastUpdatedDate")}
                            </span>
                            <span className="ms-10">
                                {form.UpdateDate}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-sm btn-primary mx-1" title={t('PatientForms.Delete')} onClick={() => this.props.Delete()}>
                    <i className="me-1 fa-solid fa-trash"></i>
                    {t('PatientForms.Delete')}
                </button>
                <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                    <i className="me-1 fa-solid fa-xmark"></i>
                    {t('Close')}
                </button>
            </Modal.Footer>
        </Modal>;
    }
}


export default withTranslation('common')(Delete);