import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../api/serverAPI";
import SelectCurrencies from "../../shared/SelectList/SelectCurrencies";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, defaultCurrency: null };


    static getDerivedStateFromProps(props, state) {

        if (!state.isInitialize && props.currentPrice) {
            state.isInitialize = true;
            props.initialize({
                Price: props.currentPrice.Price,
                CurrencyId: props.currentPrice.Currency.Id
            });
        }
        return state;
    }

    componentDidMount() {
        if (this.props.currentPrice) {
            this.setState({
                defaultCurrency: [{ Id: this.props.currentPrice.Currency.Id, value: this.props.currentPrice.Currency.Id, label: this.props.currentPrice.Currency.Name }]
            });
        }
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = event => {
        const { t, Exam } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/RadiologyExamPrice/`, {
            ...this.props.formValues,
            ExamId: Exam.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return event.preventDefault();
    }

    setCurrency = Currency => {
        this.props.dispatch(change('formRadiologyExamPrice', 'CurrencyId', Currency.Id));
        this.setState({
            Currency
        });
    }

    render() {
        const { t, pristine, submitting } = this.props;

        return <>
            <form method="POST" name={`formRadiologyExamPrice`} className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={this.onSubmit}>
                <div className="row mb-5">

                    <div className="col-md-6">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor={`Price`}><i className="fa-regular text-dark fa-coin me-2"></i>{t('Services.Forms.Price.Title')}</label>
                        <Field
                            ref={Price => this.Price = Price}
                            name={`Price`}
                            id={`Price`}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Radiology.Exams.Price.Placeholder')}
                            title={t('Radiology.Exams.Price.Title')}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectCurrencies required onChange={this.setCurrency} defaultValue={this.state.defaultCurrency} />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>

            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Price || values.Price <= 0) {
        errors.Price = 'Radiology.Exams.Price.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formRadiologyExamPrice')(state),
        validate,
        user: state.user,
        radiologyExamPrice: state.radiologyExamPrice
    };
};

const formRadiologyExamPrice = reduxForm({
    form: 'formRadiologyExamPrice',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formRadiologyExamPrice(Form)));
