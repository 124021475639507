import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Table from "./Table";
import Form from "./Form";
import { fetchBankAccounts } from "./actions";
import New from "./New";

class BankAccounts extends React.Component {

    state = {
        searchKey: {
            Number: "",
            IsActive: true
        },
        showNewBankAccount: false
    };

    update = searchKey => {
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchBankAccounts(searchKey);
        } else {
            this.props.fetchBankAccounts(this.state.searchKey);
        }
    }

    showNewBankAccount = () => {
        this.setState({
            showNewBankAccount: true
        });
    }

    handleClose = () => {
        this.setState({
            showNewBankAccount: false
        });
    }

    render() {
        const { t, bankAccounts } = this.props;
        let isLoaded = false;
        if (bankAccounts) {
            isLoaded = bankAccounts.isLoaded;
        }
        return (<>
            {this.state.showNewBankAccount ? <New handleClose={this.handleClose} update={this.update} /> : ""}
            <Helmet>
                <title>{t("Accounting.BankAccounts.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <Link
                            to="#"
                            title={t('Accounting.JournalVoucher.New')}
                            className="btn btn-sm btn-sm-icon btn-light-primary "
                            onClick={() => this.showNewBankAccount()}>
                            <i className="me-1 fa-solid fa-plus"></i>
                            {t('Accounting.BankAccounts.New')}
                        </Link>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form {...this.props} onSubmit={this.update} />
                    </div>
                </div>
            </div>

            {
                isLoaded ? <div className="card">
                    <div className="card-header align-items-center ">
                        <h3 className="card-title">
                            <span className="fw-bolder mb-2 text-dark">
                                <i className="h3 fa-duotone fa-landmark  me-2 text-info mb-0"></i>
                                {t('Accounting.BankAccounts.Title')}
                            </span>
                        </h3>
                    </div>
                    <div className="card-body pt-5 px-2">
                        <div className="w-100">
                            <Table {...this.props} update={this.update} />
                        </div>
                    </div>
                </div> : ""
            }

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        bankAccounts: state.bankAccounts
    };
};

export default connect(mapStateToProps, { fetchBankAccounts })(withTranslation('common')(BankAccounts));