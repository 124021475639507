import React from "react";
import { withTranslation } from 'react-i18next';
import PatientsCard from "../Patients/PatientsCard";

class AppointmentCard extends React.Component {

    render() {
        const { t, Appointment } = this.props;
        return (<>
            {
                Appointment?.patient ?
                    <PatientsCard patient={Appointment.patient} />
                    :
                    <>
                        <div className="row mb-5">
                            {
                                Appointment.Clinic ?
                                    <div className="col-md-6">
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                                {t("HealthCenter.Clinic")}
                                            </span>
                                            <span className="ms-10">
                                                {Appointment.Clinic?.Name}
                                            </span>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                Appointment.DoctorUser ?
                                    <div className="col-md-6">
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-user-doctor me-1"></i>
                                                {t("ClinicScreen.Doctor")}
                                            </span>
                                            <span className="ms-10">
                                                {Appointment.DoctorUser?.Name}
                                            </span>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>
                        {
                            Appointment.ICD10 ?
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-book-medical me-1"></i>
                                                {t("Diagnosis.Title")}
                                            </span>
                                            <span className="ms-10">
                                                {Appointment.ICD10?.Code + " - " + Appointment.ICD10?.Note}
                                            </span>
                                        </div>
                                    </div>
                                </div> : ""
                        }
                        {
                            Appointment.Note ?
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-note-medical me-1"></i>
                                                {t("ClinicScreen.Note.Title")}
                                            </span>
                                            <span className="ms-10" style={{ whiteSpace: "pre-wrap" }}>{Appointment.Note}</span>
                                        </div>
                                    </div>
                                </div> : ""
                        }
                    </>
            }
            {
                Appointment.Date || (Appointment.StartTime && Appointment.EndTime) ? <div className="row mb-5">
                    {
                        Appointment.Date ? <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar me-2"></i>
                                    {t("Appointment.Forms.Date.Title")}
                                </span>
                                <span className="ms-10">
                                    {Appointment.Date}
                                </span>
                            </div>
                        </div> : ""
                    }
                    {
                        Appointment.StartTime && Appointment.EndTime ? <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-clock me-2"></i>
                                    {t("Appointment.Forms.Time.Title")}
                                </span>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span title={t("Appointment.Forms.Time.StartTime")}>
                                        {Appointment.StartTime}
                                    </span>
                                    <i className="fa-solid fa-dash mx-3"></i>
                                    <span title={t("Appointment.Forms.Time.EndTime")}>
                                        {Appointment.EndTime}
                                    </span>
                                </div>

                            </div>
                        </div> : ""
                    }
                    {
                        Appointment.ArrivalTime ?
                            <div className={`col-md-6 ${Appointment.StartTime && Appointment.EndTime ? "mt-5" : ""}`}>
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-clock me-2"></i>
                                        {t("Patient.ArrivalTime")}
                                    </span>
                                    <span className="ms-10">
                                        {Appointment.ArrivalTime}
                                    </span>
                                </div>
                            </div>
                            : ""
                    }
                </div> : ""
            }

            <div className="row mb-5">
                <div className="col-md-6">
                    <div className="row">
                        <span className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-shield me-1"></i>
                            {t("Appointment.VisitStatus.Title")}
                        </span>
                        <span className="ms-10">
                            <span className={`badge badge-square badge-${Appointment.VisitStatu.Color} badge-sm me-1 p-2`}>{t(`Appointment.VisitStatus.${Appointment.VisitStatu.Name}`)}</span>
                        </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <span className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-coin me-1"></i>
                            {t("Appointment.Type.Title")}
                        </span>
                        <span className="ms-10">
                            {
                                Appointment.IsNew !== null ?
                                    Appointment.IsNew ?
                                        <span className="badge badge-pill badge-primary badge-sm me-1">{t("Appointment.Type.New")}</span>
                                        :
                                        <span className="badge badge-pill badge-warning badge-sm me-1">{t("Appointment.Type.Review")}</span>
                                    : <></>
                            }
                        </span>
                    </div>
                </div>
            </div>

            <div className="separator separator-dashed border-dark mb-5"></div>
            <div className="row mb-5">
                <div className="col-md-12">
                    <div className="row">
                        <span className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-user me-1"></i>
                            {t("LastUpdatedUser")}
                        </span>
                        <span className="ms-10">
                            {Appointment.RegistrationUser.Name}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-6">
                    <div className="row">
                        <span className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                            {t("CreationDate")}
                        </span>
                        <span className="ms-10">
                            {Appointment.CreationDate}
                        </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <span className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                            {t("LastUpdatedDate")}
                        </span>
                        <span className="ms-10">
                            {Appointment.UpdateDate}
                        </span>
                    </div>
                </div>
            </div>
        </>);
    }
}


export default withTranslation('common')(AppointmentCard);