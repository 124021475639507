const VisitStatusReducers = (state = [], action) => {
    switch (action.type) {
        case "VISIT_STATUS_LOADING":
            return {
                visitStatus: [],
                isLoaded: false
            };
        case "VISIT_STATUS_INITIAL":
            return {
                visitStatus: action.visitStatus,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default VisitStatusReducers;