import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

class Form extends React.Component {

    state = { isSubmit: false, initialize: false };

    static getDerivedStateFromProps(props, state) {

        if (!state.initialize && props.isEdit && props.Dosage) {
            state.initialize = true;
            props.initialize({
                Days: props.Dosage.Days,
                Dosage: props.Dosage.Dosage,
                Time: props.Dosage.Time,
                Repeat: props.Dosage.Repeat
            });
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );


    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    onSubmit = () => {
        this.props.onSubmit(this.props.formValues);
        return false;
    }

    render() {
        const { t, pristine, handleSubmit, submitting } = this.props;

        return (<>
            <form method="POST" name="formDosage" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            }  >


                <div className="row mb-5">
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="Dosage"><i className="fa-regular text-dark fa-capsules me-2"></i>
                            {t("ClinicScreen.Orders.Drug.Dosage.Title")}
                        </label>
                        <Field
                            ref={Dosage => this.Dosage = Dosage}
                            name="Dosage"
                            id="Dosage"
                            required
                            type="number"
                            component={this.renderField}
                            placeholder={t('ClinicScreen.Orders.Drug.Dosage.Placeholder')}
                            title={t('ClinicScreen.Orders.Drug.Dosage.Title')}
                            className="form-control form-control-sm form-control-solid"
                        />
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="Repeat"><i className="fa-regular text-dark fa-tally me-2"></i>
                            {t("ClinicScreen.Orders.Drug.Dosage.Repeat.Title")}
                        </label>
                        <Field
                            ref={Repeat => this.Repeat = Repeat}
                            name="Repeat"
                            id="Repeat"
                            required
                            type="number"
                            component={this.renderField}
                            placeholder={t('ClinicScreen.Orders.Drug.Dosage.Repeat.Placeholder')}
                            title={t('ClinicScreen.Orders.Drug.Dosage.Repeat.Title')}
                            className="form-control form-control-sm form-control-solid"
                        />
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="Days"><i className="fa-regular text-dark fa-hashtag me-2"></i>
                            {t("ClinicScreen.Orders.Drug.Dosage.Days.Title")}
                        </label>
                        <Field
                            ref={Days => this.Days = Days}
                            name="Days"
                            id="Days"
                            required
                            type="number"
                            component={this.renderField}
                            placeholder={t('ClinicScreen.Orders.Drug.Dosage.Days.Placeholder')}
                            title={t('ClinicScreen.Orders.Drug.Dosage.Days.Title')}
                            className="form-control form-control-sm form-control-solid"
                        />
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Time"><i className="fa-regular text-dark fa-clock me-2"></i>
                            {t("ClinicScreen.Orders.Drug.Dosage.Time.Title")}
                        </label>
                        <Field
                            ref={Time => this.Time = Time}
                            name="Time"
                            id="Time"
                            component="select"
                            placeholder={t('ClinicScreen.Orders.Drug.Dosage.Time.Placeholder')}
                            title={t('ClinicScreen.Orders.Drug.Dosage.Time.Title')}
                            className="form-select form-select-sm form-select-solid">
                            <option>{t('ClinicScreen.Orders.Drug.Dosage.Time.Placeholder')}</option>
                            <option value={"AC"}>{t('ClinicScreen.Orders.Drug.Dosage.Time.Options.AC')}</option>
                            <option value={"PC"}>{t('ClinicScreen.Orders.Drug.Dosage.Time.Options.PC')}</option>
                            <option value={"HS"}>{t('ClinicScreen.Orders.Drug.Dosage.Time.Options.HS')}</option>
                            <option value={"SOS"}>{t('ClinicScreen.Orders.Drug.Dosage.Time.Options.SOS')}</option>
                        </Field>

                    </div>

                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('ClinicScreen.Orders.Drug.Dosage.Send')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-prescription-bottle-medical"></i>
                            {t('ClinicScreen.Orders.Drug.Dosage.Send')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose} disabled={submitting}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>);
    }
}

const validate = values => {
    const errors = {};
    if (!values.Days) {
        errors.Days = 'ClinicScreen.Orders.Drug.Dosage.Days.Error';
    }
    if (!values.Repeat) {
        errors.Repeat = 'ClinicScreen.Orders.Drug.Dosage.Repeat.Error';
    }
    if (!values.Dosage) {
        errors.Dosage = 'ClinicScreen.Orders.Drug.Dosage.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formDosage')(state),
        user: state.user
    };
};

const formDosage = reduxForm({
    form: 'formDosage',
    persistentSubmitErrors: true,
    touchOnBlur: true,
    validate
});

export default connect(mapStateToProps, {})(withTranslation('common')(formDosage(Form)));