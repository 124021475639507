import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Table from "./Table";
import Form from "./Form";
import { fetchServices as fetch } from "./actions";

class Services extends React.Component {

    state = { showCreateNew: false, isEdit: false, service: null, BillId: 0 };

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        if (this.props.Appointment) {
            this.props.fetch(this.props.Appointment.Id);
        }
    }

    update = () => {
        this.fetch();
        this.handleClose();
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false,
            isEdit: false,
            service: null,
            BillId: 0
        });
    }

    showEdit = (service, BillId) => {
        this.setState({
            showCreateNew: true,
            isEdit: true,
            service,
            BillId
        });
    }

    render() {
        const { t, Appointment, showCreate, services } = this.props;
        return <>
            {
                this.state.showCreateNew ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                {
                                    this.state.isEdit ?
                                        <>
                                            <i className="h3 fa-duotone fa-edit me-2 text-primary "></i>
                                            {t("Services.Edit")}
                                        </>
                                        :
                                        <>
                                            <i className="h3 fa-duotone fa-plus me-2 text-primary "></i>
                                            {t("Services.New")}
                                        </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form  {...this.props} Appointment={Appointment} service={this.state.service} BillId={this.state.BillId} isEdit={this.state.isEdit} update={this.update} handleClose={this.handleClose} />
                        </Modal.Body>
                    </Modal> : ""
            }

            <div className="card h-100">
                {
                    showCreate ? <div className="card-header align-items-center border-0 mt-4">
                        <div className="card-title h3 d-flex align-items-center">
                            <i className="h3 fa-duotone fa-file-invoice-dollar me-2 text-info"></i>
                            {t("Services.Title")}
                        </div>
                        <div className="card-toolbar">
                            <Link
                                to="#"
                                onClick={this.showCreateNew}
                                title={t("Services.New")}
                                className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                                <i className="fa-regular fa-plus"></i>
                                <span className="d-md-block d-none text-nowrap ms-1">
                                    {t("Services.New")}
                                </span>
                            </Link>
                        </div>
                    </div> : ""
                }

                <div className={`card-body ${showCreate ? "pt-5" : "p-0"} `}>
                    <div className="w-100">
                        <Table {...this.props} showCreateNew={this.showCreateNew} update={this.update} showEdit={this.showEdit} />
                    </div>
                </div>
                <div className={`card-footer ${showCreate ? "" : "pt-5 pb-0"} `} >
                    {
                        services?.services?.Currency ?
                            <>
                                <div className="row mb-5">
                                    {
                                        services.services.Discount ?
                                            <>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <span className="fw-bold text-muted mb-2">
                                                            <i className="fa-regular text-dark fa-sigma me-2"></i>
                                                            {t("Services.Amount")}
                                                            :
                                                        </span>
                                                        <span className="fs-5 ms-10">
                                                            {services.services.Amount}
                                                            <span className="mx-1">
                                                                {services.services.Currency.Name}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <span className="fw-bold text-muted mb-2">
                                                            <i className="fa-solid fa-percent me-2 text-dark "></i>
                                                            {t("Services.Discount.Title")}
                                                            :
                                                        </span>
                                                        <span className="fs-5 ms-10">
                                                            {services.services.Discount}
                                                            <span className="mx-1">
                                                                {services.services.Currency.Name}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </> : ""
                                    }
                                    <div className={`${services.services.Discount ? "col-md-4" : "w-100 d-flex justify-content-evenly"}`} title={t("Services.Total.Note")}>
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-calculator me-2"></i>
                                                {t("Services.Total.Title")}
                                                :
                                            </span>
                                            <span className="fs-5 ms-10">
                                                {services.services.Total}
                                                <span className="mx-1">
                                                    {services.services.Currency.Name}
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </>
                            : ""
                    }
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        services: state.services
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Services));