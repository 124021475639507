const PatientVisitsReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_VISITS_LOADING":
            return {
                visits: [],
                PatientId: 0,
                isLoaded: false
            };
        case "PATIENT_VISITS_INITIAL":
            return {
                visits: action.visits,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientVisitsReducers;