import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Info extends React.Component {

    render() {
        const { t, HealthCenter } = this.props;

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="mb-5" title={t("NumberAuto")}>
                    <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{HealthCenter.Id}</span>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-text me-1"></i>
                                {t("HealthCenter.Form.Name.Title")}
                            </span>
                            <span className="ms-5">
                                <i title={t(`IsActive.${HealthCenter.IsActive}`)} className={`fa-solid fa-${HealthCenter.IsActive === false ? "lock text-danger" : "lock-open text-success"}  me-3`}></i>
                                {HealthCenter.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-at me-1"></i>
                                {t("HealthCenter.Form.Email.Title")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.Email}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-phone me-1"></i>
                                {t("HealthCenter.Form.Phone.Title")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.Phone}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">

                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                {t("HealthCenter.Form.ClinicNumber.Title")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.ClinicsCount}
                                <span className="ms-1" title={t("HealthCenter.Form.ClinicNumber.Max")}>({HealthCenter.ClinicNumber})</span>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-map me-2"></i>
                                {t("Patient.Forms.City.Title")}
                            </span>
                            <span className="ms-5">
                                {HealthCenter.City?.Name}
                            </span>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-location-dot me-1"></i>
                                {t("HealthCenter.Form.Address.Title")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.Address}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    {
                        HealthCenter.HasLab ?
                            <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-microscope me-1"></i>
                                        {t("HealthCenter.Lab")}
                                    </span>
                                    <span className="ms-10">
                                        {HealthCenter.LabCenter?.Name}
                                    </span>
                                </div>
                            </div> : ""
                    }
                    {
                        HealthCenter.HasRad ?
                            <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-circle-radiation me-1"></i>
                                        {t("HealthCenter.Radiology")}
                                    </span>
                                    <span className="ms-10">
                                        {HealthCenter.RadCenter?.Name}
                                    </span>
                                </div>
                            </div> : ""
                    }
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-user me-1"></i>
                                {t("LastUpdatedUser")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.User?.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("CreationDate")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.CreationDate}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("LastUpdatedDate")}
                            </span>
                            <span className="ms-10">
                                {HealthCenter.UpdateDate}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));