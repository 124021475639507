import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import Table from "./Table";

class Diagnosis extends React.Component {


    render() {
        const { t } = this.props;
        return (<>
            <Helmet>
                <title>{t("Diagnosis.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-book-medical me-2 text-info mb-0"></i>
                            {t('Diagnosis.Title')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Table />
                    </div>
                </div>
            </div>

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Diagnosis));