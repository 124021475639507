import serverAPI from "../../../../api/serverAPI";

export const fetchHealthCenters = data => async dispatch => {
    dispatch({ type: 'HEALTH_CENTERS_LOADING' });
    const response = await serverAPI.get(`/HealthCenters/?Name=${data.Name ? data.Name : ""}&Email=${data.Email ? data.Email : ""}&Phone=${data.Phone ? data.Phone : ""}&CityId=${data.CityId ? data.CityId : "0"}`);
    dispatch({ type: 'HEALTH_CENTERS_INITIAL', healthCenters: response.data.data });
}

export const removeHealthCenter = (id) => async dispatch => {
    dispatch({ type: 'HEALTH_CENTERS_REMOVE', id });
}

export const changeLockedStatus = (id) => async dispatch => {
    dispatch({ type: 'HEALTH_CENTER_CHANGE_LOCKED_STATUS', id });
}