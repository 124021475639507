import React from "react";

class PrintFooter extends React.Component {

    render() {
        return <tfoot style={{
            height: '100px'
        }}>
            <tr>
                <td>
                    <div className="position-fixed bottom-0">footer</div>
                </td>
            </tr>
        </tfoot>
    }

}

export default PrintFooter;