import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import PatientInfo from "../../Patients/Search/Info";
import Info from "../../ClinicScreen/LabOrders/Modal/Info";
import Delete from "../../ClinicScreen/LabOrders/Modal/Delete";
import Accept from "./Accept";
import Take from "./Take";
import PatientName from "../../Patients/PatientName";
import serverAPI from "../../../api/serverAPI";

class Table extends React.Component {

    state = {
        patient: null,
        SearchByName: "",
        order: null,
        showPatientInfo: false,
        showInfo: false,
        showAccept: false,
        showDelete: false,
        showTakeSpecimen: false
    };

    handleClose = () => {
        this.setState({
            showTakeSpecimen: false,
            showPatientInfo: false,
            showDelete: false,
            showAccept: false,
            showInfo: false,
            order: null,
            patient: null
        });
    }


    showDelete = order => {
        this.setState({
            showDelete: true,
            order
        })
    }

    showInfo = order => {
        this.setState({
            showInfo: true,
            order
        })
    }

    showPatientInfo = patient => {
        this.setState({
            showPatientInfo: true,
            patient
        })
    }

    showAccept = order => {
        this.setState({
            showAccept: true,
            order
        })
    }

    showTakeSpecimen = order => {
        this.setState({
            showTakeSpecimen: true,
            order
        })
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    renderTableRow = () => {
        const { t, labOrders, user } = this.props;
        if (labOrders && labOrders.labOrders && labOrders.labOrders.length > 0) {

            let searchResult = labOrders.labOrders;
            const Names = this.state.SearchByName.split(' ');
            if (Names.length > 0) {
                Names.forEach(name => {
                    name = name.toLowerCase();
                    searchResult = searchResult.filter(p =>
                        p.patient?.Name?.FirstName?.toLowerCase().includes(name) ||
                        p.patient?.Name?.SecondName?.toLowerCase().includes(name) ||
                        p.patient?.Name?.ThirdName?.toLowerCase().includes(name) ||
                        p.patient?.Name?.LastName?.toLowerCase().includes(name) ||
                        p.patient?.NameEnglish?.FirstName?.toLowerCase().includes(name) ||
                        p.patient?.NameEnglish?.SecondName?.toLowerCase().includes(name) ||
                        p.patient?.NameEnglish?.ThirdName?.toLowerCase().includes(name) ||
                        p.patient?.NameEnglish?.LastName?.toLowerCase().includes(name)
                    )
                });
            }

            return searchResult.map((item, index) => {
                const hasExamReadyToProcessing = item.items.some(p => p.OrderItemStatus?.Id === 5);
                return <tr key={`lab-order-${index}-${item.Id}`}>
                    <td>
                        {item.CreationDate}
                    </td>
                    <td>
                        {
                            item.patient ? <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(item.patient)}>
                                <PatientName patient={item.patient} />
                            </Link> : ""
                        }
                    </td>
                    <td>
                        {
                            item.Doctor ?
                                <>
                                    <i className="fa-regular fa-user-doctor text-dark me-1"></i>
                                    {item.Doctor.Name}
                                </> :
                                <>
                                    {t("OutDoctor")}
                                </>
                        }
                    </td>
                    <td>
                        {
                            item.Clinic ?
                                <>
                                    {item.Clinic.Name}
                                    {
                                        item.Clinic?.HealthCenter?.Id !== user.user.HealthCenter?.Id ? <>
                                            <i className="fa-solid fa-minus text-dark mx-1"></i>
                                            {item.Clinic?.HealthCenter?.Name}
                                        </> : ""
                                    }
                                </> : ""
                        }
                    </td>
                    <td>
                        <span className={`badge badge-square badge-${item.Status.Color} badge-sm p-2`}>{t(`OrdersStatus.${item.Status.Name}`)}</span>
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    {
                                        item.Clinic ?
                                            item.Status.Id !== 4 && item.Status.Id !== 6 ?
                                                <>
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => this.showAccept(item)}>
                                                        <i className="fa-regular fa-vials text-dark me-1"></i>
                                                        {t("Table.Options.Accept.Labs")}
                                                    </Link>
                                                </> : ""
                                            :
                                            item.Status.Id === 2 ?
                                                <>
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => this.showDelete(item)}>
                                                        <i className="fa-regular fa-trash text-dark me-1"></i>
                                                        {t("Table.Options.Delete")}
                                                    </Link>
                                                </> : ""
                                    }
                                    {
                                        item.Status.Id !== 1 && hasExamReadyToProcessing ? <>
                                            <Link to="#"
                                                className="dropdown-item"
                                                onClick={() => this.showTakeSpecimen(item)}>
                                                <i className="fa-regular fa-syringe text-dark me-1"></i>
                                                {t("Table.Options.Take.Specimen")}
                                            </Link>
                                        </> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    Accept = () => {
        this.handleClose();
        this.props.update();
    }

    Delete = () => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/LabOutOrders/${this.state.order.Id}?VisitId=${this.state.order.VisitId}&PatientId=${this.state.order.patient.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.update();
            this.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    render() {
        const { t, labOrders } = this.props;

        let isLoaded = false;
        if (labOrders) {
            isLoaded = labOrders.isLoaded;
        }

        return <>
            {this.state.showPatientInfo && this.state.patient ? <PatientInfo patient={this.state.patient} handleClose={this.handleClose} /> : ""}
            {this.state.showInfo && this.state.order ? <Info Order={this.state.order} handleClose={this.handleClose} /> : ""}
            {this.state.showDelete && this.state.order ? <Delete Order={this.state.order} handleClose={this.handleClose} Delete={this.Delete} /> : ""}
            {this.state.showAccept && this.state.order ? <Accept Order={this.state.order} handleClose={this.handleClose} update={this.Accept} /> : ""}
            {this.state.showTakeSpecimen && this.state.order ? <Take Order={this.state.order} handleClose={this.handleClose} update={this.Accept} /> : ""}

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
            </div>

            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("Lab.SpecimenTaken.Orders.Date")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Lab.SpecimenTaken.Orders.Date")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Patient.Info")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Patient.Info")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("ClinicScreen.Doctor")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user-doctor me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("ClinicScreen.Doctor")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("HealthCenter.Clinic")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("HealthCenter.Clinic")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("OrdersStatus.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-circle me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("OrdersStatus.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        labOrders: state.labOrders
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));