import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../api/serverAPI";

class Form extends React.Component {

    state = { isSubmit: false };

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t, Appointment } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/Measurements/${Appointment.patient.Id}`, {
            ...this.props.formValues,
            VisitId: Appointment.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    render() {
        const { t, pristine, submitting } = this.props;

        return <>

            <form method="POST" name="formMeasurements" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <div className="col-md-4">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Height"><i className="fa-regular text-dark fa-ruler-vertical me-2"></i>{t('Measurements.Forms.Height.Title')}</label>
                        <div className="input-group input-group-sm mb-5">
                            <Field
                                ref={Height => this.Height = Height}
                                name="Height"
                                id="Height"
                                component={this.renderField}
                                type={"number"}
                                placeholder={t('Measurements.Forms.Height.Placeholder')}
                                title={t('Measurements.Forms.Height.Title')}
                                className="form-control"
                            />
                            <span className="input-group-text" title={t('Measurements.Forms.Height.Unit')} >CM</span>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Weight"><i className="fa-regular text-dark fa-weight-scale me-2"></i>{t('Measurements.Forms.Weight.Title')}</label>
                        <div className="input-group input-group-sm mb-5">
                            <Field
                                ref={Weight => this.Weight = Weight}
                                name="Weight"
                                id="Weight"
                                component={this.renderField}
                                type={"number"}
                                placeholder={t('Measurements.Forms.Weight.Placeholder')}
                                title={t('Measurements.Forms.Weight.Title')}
                                className="form-control"
                            />
                            <span className="input-group-text" title={t('Measurements.Forms.Weight.Unit')} >KG</span>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="HeadCircumference"><i className="fa-regular text-dark fa-head-side me-2"></i>{t('Measurements.Forms.HeadCircumference.Title')}</label>
                        <div className="input-group input-group-sm mb-5">
                            <Field
                                ref={HeadCircumference => this.HeadCircumference = HeadCircumference}
                                name="HeadCircumference"
                                id="HeadCircumference"
                                component={this.renderField}
                                type={"number"}
                                placeholder={t('Measurements.Forms.HeadCircumference.Placeholder')}
                                title={t('Measurements.Forms.HeadCircumference.Title')}
                                className="form-control"
                            />
                            <span className="input-group-text" title={t('Measurements.Forms.HeadCircumference.Unit')} >CM</span>
                        </div>
                    </div>

                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formMeasurements')(state),
        measurement: state.measurement,
        user: state.user
    };
};

const formMeasurements = reduxForm({
    form: 'formMeasurements',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formMeasurements(Form)));
