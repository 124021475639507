import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";

import serverAPI from "../../../api/serverAPI";
import { fetchChronicDisease } from "./actions";

class Form extends React.Component {

    state = {
        isInitialize: false,
        isSubmit: false,
        SelectedChronicDisease: []
    };

    constructor(props) {
        super(props);
        const { chronicDisease } = this.props;
        if (!chronicDisease || !chronicDisease.chronicDisease || chronicDisease.chronicDisease?.length === 0) {
            this.props.fetchChronicDisease();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.patientChronicDisease?.patientChronicDisease) {
            state.isInitialize = true;
            let SelectedChronicDisease = [];
            props.patientChronicDisease.patientChronicDisease.forEach(types => {
                types.Diseases.forEach(disease => {
                    SelectedChronicDisease.push({ ...disease })
                });
            });
            state.SelectedChronicDisease = SelectedChronicDisease;
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setSelectedChronicDisease = Drug => {
        const objIndex = this.state.SelectedChronicDisease.findIndex(p => p.Id === Drug.Id);
        let SelectedChronicDisease = this.state.SelectedChronicDisease;
        if (objIndex >= 0) {
            SelectedChronicDisease = SelectedChronicDisease.filter(p => p.Id !== Drug.Id);
        } else {
            SelectedChronicDisease.push(Drug);
        }
        this.setState({
            SelectedChronicDisease
        });
    }

    onSubmit = () => {
        const { t, patient } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const ChronicDiseases = this.state.SelectedChronicDisease.map(({ Id }) => Id);

        serverAPI.post(`/PatientChronicDiseases/${patient.Id}`, {
            ChronicDiseases
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    renderDiseases = diseases => {
        return diseases.map(disease => {
            return <li key={`chronic-disease-${disease.Id}`} className="d-flex py-2 align-items-center flex-wrap text-capitalize" >
                <div className="form-check form-check-custom mx-2">
                    <input
                        name="checkbox"
                        type="checkbox"
                        value={disease.Id}
                        onChange={() => this.setSelectedChronicDisease(disease)}
                        className="form-check-input"
                        checked={this.state.SelectedChronicDisease.some(p => p.Id === disease.Id)}
                    />
                </div>
                {disease.Name}.
            </li>;
        })
    }

    renderList() {
        const { chronicDisease } = this.props;
        return chronicDisease.chronicDisease.map(type => {
            return <li key={`chronic-disease-type-${type.Id}`} className="d-flex py-2 align-items-center flex-wrap" >
                <div className="row mb-5">
                    <div className="d-flex align-items-center text-capitalize mb-2">
                        <span className="bullet bg-primary ms-2"></span> {type.Name}.
                    </div>
                    <ul style={{
                        direction: 'ltr'
                    }}>
                        {this.renderDiseases(type.Diseases)}
                    </ul>
                </div>
            </li>;
        });
    }

    render() {
        const { t, chronicDisease } = this.props;
        return <>

            <form method="POST" name="formChronicDisease" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                {
                    chronicDisease && chronicDisease.chronicDisease ?
                        <div className="row">
                            <ul style={{
                                direction: 'ltr'
                            }}>
                                {this.renderList()}
                            </ul>
                        </div>
                        : ""
                }
                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        patientChronicDisease: state.patientChronicDisease,
        chronicDisease: state.chronicDisease,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetchChronicDisease })(withTranslation('common')(Form));