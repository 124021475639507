import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Table from "./Table";
import { fetchClinicServices } from "./actions";
import Edit from "./Edit";

class ClinicServices extends React.Component {

    state = { showCreateNew: false };

    constructor(props) {
        super(props);
        this.update();
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    update = () => {
        this.props.fetchClinicServices();
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false
        });
    }

    render() {
        const { t } = this.props;

        return (<>
            {this.state.showCreateNew ? <Edit handleClose={this.handleClose} update={this.update} /> : ""}
            <Helmet>
                <title>{t("Accounting.ClinicServices.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-circle-dollar me-2 text-info mb-0"></i>
                            {t('Accounting.ClinicServices.Title')}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <Link
                            to="#"
                            onClick={this.showCreateNew}
                            title={t("Services.New")}
                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                            <i className="fa-regular fa-plus"></i>
                            <span className="d-md-block d-none text-nowrap ms-1">
                                {t("Services.New")}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="card-body pt-5 px-2">
                    <div className="w-100">
                        <Table update={this.update} />
                    </div>
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        receiptVouchers: state.receiptVouchers
    };
};

export default connect(mapStateToProps, { fetchClinicServices })(withTranslation('common')(ClinicServices));