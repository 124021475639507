import React from "react";
import { withTranslation } from 'react-i18next';

import VisitStatus from "./VisitStatus";
import VisitType from "./VisitType";

class Widget extends React.Component {

    render() {

        const { t, appointmentVisitStatus } = this.props;

        return <div id="kt_carousel_1_carousel" className="card mt-3 carousel carousel-custom slide" data-bs-ride="carousel" data-bs-interval="10000">
            <div className="card-header align-items-center border-0 mt-4">
                <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bolder mb-2 text-dark">{t('Statistics')}</span>
                </h3>
                {
                    this.props.VisitType.length > 0 ?
                        <div className="card-toolbar">
                            <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                                <li data-bs-target="#kt_carousel_1_carousel" data-bs-slide-to="1" title={t('Appointment.Statistics.Type')} className="ms-1"></li>
                                <li data-bs-target="#kt_carousel_1_carousel" data-bs-slide-to="0" title={t('Appointment.Statistics.VisitStatus')} className="ms-1 active"></li>
                            </ol>
                        </div> : ""
                }
            </div>
            <div className="card-body pt-2 align-items-start d-flex">
                <div className="w-100">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <VisitStatus Data={appointmentVisitStatus} />
                        </div>
                        {
                            this.props.VisitType.length > 0 ?
                                <div className="carousel-item">
                                    <VisitType Data={this.props.VisitType} />
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div>;
    }

}

export default withTranslation('common')(Widget);