import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import PatientInfo from "../../../Patients/Search/Info";
import Info from "./Info";
import PatientName from "../../../Patients/PatientName";
import serverAPI from "../../../../api/serverAPI";
import JournalVoucherInfo from "../JournalVouchers/Info";

class Table extends React.Component {

    state = {
        patient: null,
        voucher: null,
        journalVoucher: null,
        SearchByName: "",
        showPatientInfo: false,
        showInfo: false,
        showJournalVoucher: false,
        Total: 0
    };

    static getDerivedStateFromProps(props, state) {
        let Total = 0;
        if (props.receiptVouchers && props.receiptVouchers.receiptVouchers?.length > 0) {
            let searchResult = props.receiptVouchers.receiptVouchers;

            const Names = state.SearchByName.split(' ');
            if (Names.length > 0) {
                Names.forEach(name => {
                    name = name.toLowerCase();
                    searchResult = searchResult.filter(p =>
                        p.Account.patient?.Name?.FirstName?.toLowerCase().includes(name) ||
                        p.Account.patient?.Name?.SecondName?.toLowerCase().includes(name) ||
                        p.Account.patient?.Name?.ThirdName?.toLowerCase().includes(name) ||
                        p.Account.patient?.Name?.LastName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.FirstName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.SecondName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.ThirdName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.LastName?.toLowerCase().includes(name) ||
                        p.Account.Name.toLowerCase().includes(name) ||
                        p.Account.Number.toLowerCase().includes(name)
                    )
                });
            }


            searchResult.forEach(voucher => {
                Total += voucher.Value * (voucher.Rate / props.user.user.HealthCenter.Currency.Rate);
            });
        }
        state.Total = Total;
        return state;
    }

    handleClose = () => {
        this.setState({
            showPatientInfo: false,
            showJournalVoucher: false,
            showInfo: false,
            journalVoucher: null,
            voucher: null,
            patient: null
        });
    }

    showInfo = voucher => {
        this.setState({
            showInfo: true,
            voucher
        })
    }

    showPatientInfo = patient => {
        this.setState({
            showPatientInfo: true,
            patient
        })
    }

    showJournalVoucher = voucher => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        serverAPI.get(`/JournalVoucher/${voucher.JournalVoucherId}`).then(response => {
            toast.dismiss(_toastId);
            this.setState({
                showJournalVoucher: true,
                journalVoucher: response.data.data
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    renderTableRow = () => {
        const { t, receiptVouchers } = this.props;
        if (receiptVouchers && receiptVouchers.receiptVouchers && receiptVouchers.receiptVouchers.length > 0) {

            let searchResult = receiptVouchers.receiptVouchers;
            const Names = this.state.SearchByName.split(' ');
            if (Names.length > 0) {
                Names.forEach(name => {
                    name = name.toLowerCase();
                    searchResult = searchResult.filter(p =>
                        p.Account.patient?.Name?.FirstName?.toLowerCase().includes(name) ||
                        p.Account.patient?.Name?.SecondName?.toLowerCase().includes(name) ||
                        p.Account.patient?.Name?.ThirdName?.toLowerCase().includes(name) ||
                        p.Account.patient?.Name?.LastName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.FirstName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.SecondName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.ThirdName?.toLowerCase().includes(name) ||
                        p.Account.patient?.NameEnglish?.LastName?.toLowerCase().includes(name) ||
                        p.Account.Name.toLowerCase().includes(name) ||
                        p.Account.Number.toLowerCase().includes(name)
                    )
                });
            }

            return searchResult.map((item, index) => {
                return <tr key={`receipt-vouchers-${index}-${item.Id}`}>
                    <td>
                        {item.Date}
                    </td>
                    <td>
                        {
                            item.Account.patient ?
                                <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(item.Account.patient)}>
                                    <PatientName patient={item.Account.patient} />
                                </Link>
                                :
                                <span>
                                    {item.Account.Number + " " + item.Account.Name}
                                </span>
                        }
                    </td>
                    <td>
                        {item.Value}
                        <span className="ms-1">
                            {item.Currency?.Name}
                        </span>
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.showJournalVoucher(item)}>
                                        <i className="fa-regular fa-ticket text-dark me-1"></i>
                                        {t("Accounting.JournalVoucher.Table.Options.View")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    render() {
        const { t, receiptVouchers, user } = this.props;

        let isLoaded = false;
        if (receiptVouchers) {
            isLoaded = receiptVouchers.isLoaded;
        }
        return <>
            {this.state.showPatientInfo && this.state.patient ? <PatientInfo patient={this.state.patient} handleClose={this.handleClose} /> : ""}
            {this.state.showInfo && this.state.voucher ? <Info Voucher={this.state.voucher} handleClose={this.handleClose} /> : ""}
            {this.state.showJournalVoucher && this.state.journalVoucher ? <JournalVoucherInfo Voucher={this.state.journalVoucher} handleClose={this.handleClose} /> : ""}

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
            </div>
            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("Accounting.ReceiptVoucher.Forms.Date.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.ReceiptVoucher.Forms.Date.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Accounting.ReceiptVoucher.Forms.FromAccount.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-file-user me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.ReceiptVoucher.Forms.FromAccount.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Accounting.ReceiptVoucher.Forms.Value.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-sack-dollar me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.ReceiptVoucher.Forms.Value.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                        <tfoot title={t("Accounting.ReceiptVoucher.Total.Note")}>
                            <tr>
                                <td></td>
                                <td>
                                    <span className="d-md-block d-none text-nowrap text-end">
                                        {t("Accounting.ReceiptVoucher.Total.Title")}
                                        :
                                    </span>
                                </td>
                                <td>
                                    <span className="fs-4 ">
                                        {this.state.Total}
                                        <span className="mx-1">
                                            {user.user.HealthCenter?.Currency.Name}
                                        </span>
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        receiptVouchers: state.receiptVouchers
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));