const LabExamsResultsReducers = (state = [], action) => {
    switch (action.type) {
        case "LAB_EXAMS_RESULTS_LOADING":
            return {
                labExamResults: [],
                isLoaded: false
            };
        case "LAB_EXAMS_RESULTS_INITIAL":
            return {
                labExamResults: action.labExamResults,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LabExamsResultsReducers;