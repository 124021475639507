const AccountsReducers = (state = [], action) => {
    switch (action.type) {
        case "ACCOUNTS_LOADING":
            return {
                accounts: [],
                isLoaded: false
            };
        case "ACCOUNTS_INITIAL":
            return {
                accounts: action.accounts,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default AccountsReducers;