const LabExamPriceReducers = (state = [], action) => {
    switch (action.type) {
        case "LAB_EXAM_PRICE_LOADING":
            return {
                labExamPrice: [],
                isLoaded: false
            };
        case "LAB_EXAM_PRICE_INITIAL":
            return {
                labExamPrice: action.labExamPrice,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LabExamPriceReducers;