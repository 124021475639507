import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import PatientName from "../../Patients/PatientName";

class History extends React.Component {

    renderVisitStatus = item => {
        if (item) {
            const { t } = this.props;
            return <span className={`badge badge-square badge-${item.Color} badge-sm me-1 p-2`}>{t(`Appointment.VisitStatus.${item.Name}`)}</span>;
        }
        return "";
    }

    render() {
        const { t, visitHistory, Patient } = this.props;

        let isLoaded = false;
        if (visitHistory) {
            isLoaded = visitHistory.isLoaded;
        }

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-rectangle-history-circle-user me-2 text-primary "></i>
                    {t('Appointment.History.Title')}

                    <span className="ms-3">
                        (
                        <PatientName patient={Patient} />
                        )
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="position-relative">
                    <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                        minHeight: '100px'
                    }}>
                        <div className="table-loading-message">
                            {t("Processing.Title")}
                        </div>
                        <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800">
                                    <th title={t("Appointment.History.Table.Date")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-calendar me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Appointment.History.Table.Date")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Appointment.Type.Title")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-coin me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Appointment.Type.Title")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Appointment.VisitStatus.Title")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-shield me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Appointment.VisitStatus.Title")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Appointment.History.Table.User")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-user me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Appointment.History.Table.User")}
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    visitHistory && visitHistory.visitHistory && visitHistory.visitHistory.length > 0 ?
                                        visitHistory.visitHistory.map(history => {
                                            return <tr key={`visit-history-${history.Id}-${visitHistory.PatientId}-${visitHistory.ClinicId}`}>
                                                <td>
                                                    {history.Date}
                                                </td>
                                                <td>
                                                    {
                                                        history.IsNew !== null ?
                                                            history.IsNew ?
                                                                <span className="badge badge-pill badge-primary badge-sm me-1">{t("Appointment.Type.New")}</span>
                                                                :
                                                                <span className="badge badge-pill badge-warning badge-sm me-1">{t("Appointment.Type.Review")}</span>
                                                            :
                                                            <></>
                                                    }
                                                </td>
                                                <td>
                                                    {this.renderVisitStatus(history.VisitStatu)}
                                                </td>
                                                <td>
                                                    {history.RegistrationUser.Name}
                                                </td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={4}>{t("NoRows")}</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                    <i className="me-1 fa-solid fa-xmark"></i>
                    {t('Close')}
                </button>
            </Modal.Footer>
        </Modal>;
    }
}

const mapStateToProps = state => {
    return {
        visitHistory: state.visitHistory
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(History));