const UserTypesReducers = (state = [], action) => {
    switch (action.type) {
        case "USER_TYPES_LOADING":
            return {
                userTypes: [],
                isLoaded: false
            };
        case "USER_TYPES_INITIAL":
            return {
                userTypes: action.userTypes,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default UserTypesReducers;