const CitiesReducers = (state = [], action) => {
    switch (action.type) {
        case "CITIES_LOADING":
            return {
                cities: [],
                isLoaded: false
            };
        case "CITIES_INITIAL":
            return {
                cities: action.cities,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default CitiesReducers;