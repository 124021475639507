const ClinicServicesReducers = (state = [], action) => {
    switch (action.type) {
        case "CLINIC_SERVICES_LOADING":
            return {
                clinicServices: [],
                isLoaded: false
            };
        case "CLINIC_SERVICES_INITIAL":
            return {
                clinicServices: action.clinicServices,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default ClinicServicesReducers;