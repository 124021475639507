const MeasurementsReducers = (state = [], action) => {
    switch (action.type) {
        case "MEASUREMENT_LOADING":
            return {
                measurement: null,
                isLoaded: false
            };
        case "MEASUREMENT_INITIAL":
            return {
                measurement: action.measurement,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default MeasurementsReducers;