const NotificationsReducers = (state = [], action) => {
    switch (action.type) {
        case "NOTIFICATION_SUB_LOADING":
            return {
                Notification: state.Notification,
                NotificationSub: [],
                count: state.count,
                isLoaded: false
            };
        case "NOTIFICATION_LOADING":
            return {
                Notification: [],
                NotificationSub: [],
                count: state.count,
                isLoaded: false
            };
        case "NOTIFICATION_COUNT_INITIAL":
            return {
                count: action.count,
                Notification: state.Notification,
                NotificationSub: state.NotificationSub,
                isLoaded: true
            };
        case "NOTIFICATION_INITIAL":
            return {
                Notification: action.Notification,
                NotificationSub: state.NotificationSub,
                count: state.count,
                isLoaded: true
            };
        case "NOTIFICATION_SUB_INITIAL":
            return {
                Notification: state.Notification,
                NotificationSub: action.NotificationSub,
                count: state.count,
                isLoaded: true
            };
        case "NOTIFICATION_IS_VEIW":
            return {
                Notification: UpdateNotification(state.Notification, action.id),
                NotificationSub: UpdateNotification(state.NotificationSub, action.id),
                count: state.count - 1,
                isLoaded: true
            };
        case "NOTIFICATION_COUNT_ZERO":
            return {
                Notification: state.Notification,
                NotificationSub: state.NotificationSub,
                count: 0,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default NotificationsReducers;

function UpdateNotification(Notifications, id) {
    if (Notifications) {
        const list = Notifications.map(Notification => {
            if (id && Notification.Id === id) {
                return {
                    ...Notification,
                    IsView: true
                };
            }
            return Notification;
        });
        return list;
    }
    return [];
}