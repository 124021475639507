import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Table from "./Table";
import Form from "./Form";
import { fetchUsers } from "./actions";
import New from "./New";

class Users extends React.Component {

    state = {
        searchKey: {},
        showCreateNew: false
    };

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false
        });
    }

    update = searchKey => {
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchUsers(searchKey);
        } else {
            this.props.fetchUsers(this.state.searchKey);
        }
    }

    render() {
        const { t, users } = this.props;

        let isLoaded = false;
        if (users) {
            isLoaded = users.isLoaded;
        }
        return (<>
            {this.state.showCreateNew ? <New handleClose={this.handleClose} update={this.update} /> : ""}
            <Helmet>
                <title>{t("Users.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <Link
                            to="#"
                            onClick={this.showCreateNew}
                            title={t("Users.New")}
                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                            <i className="fa-regular fa-plus"></i>
                            <span className="d-md-block d-none text-nowrap ms-1">
                                {t("Users.New")}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form update={this.update} />
                    </div>
                </div>
            </div>
            {
                isLoaded ? <div className="card">
                    <div className="card-header align-items-center ">
                        <h3 className="card-title">
                            <span className="fw-bolder mb-2 text-dark">
                                <i className="h3 fa-duotone fa-users me-2 text-info mb-0"></i>
                                {t('Users.Title')}
                            </span>
                        </h3>
                    </div>
                    <div className="card-body pt-5 px-2">
                        <div className="w-100">
                            <Table update={this.update} />
                        </div>
                    </div>
                </div> : ""
            }

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        users: state.users
    };
};

export default connect(mapStateToProps, { fetchUsers })(withTranslation('common')(Users));