const ServicesReducers = (state = [], action) => {
    switch (action.type) {
        case "SERVICES_LOADING":
            return {
                services: [],
                isLoaded: false
            };
        case "SERVICES_INITIAL":
            return {
                services: action.services,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default ServicesReducers;