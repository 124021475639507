import serverAPI from "../../../api/serverAPI";

export const fetchDiagnoses = data => async dispatch => {
    dispatch({ type: 'DIAGNOSES_LOADING' });
    const response = await serverAPI.get(`/Diagnoses/?Code=${data.Code ? data.Code : ""}&Note=${data.Note ? data.Note : ""}&isFavouriteDiagnoses=${data.isFavouriteDiagnoses ? data.isFavouriteDiagnoses : false}`);
    dispatch({ type: 'DIAGNOSES_INITIAL', diagnoses: response.data.data });
}

export const updateFavourite = (diagnose, id) => async dispatch => {
    dispatch({ type: 'DIAGNOSES_FAVPURITE_UPDATE', newNode: diagnose, id });
}
