import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

import { fetchRadiologyExamTypes } from "../../../ClinicScreen/RadiologyOrders/actions";
import { fetchRadiologyExamPrices } from "../../../Accounting/RadiologyExamsPrices/actions";

class SelectExam extends React.Component {

    state = {
        SearchByName: "",
        ExamTypes: [],
        SearchExamTypes: null,
        SelectedExam: [],
        initialize: false
    };

    constructor(props) {
        super(props);
        const { radiologyExamPrice, radiologyExamTypes } = this.props;
        if (!radiologyExamTypes || !radiologyExamTypes.radiologyExamTypes || radiologyExamTypes.radiologyExamTypes?.length === 0) {
            this.props.fetchRadiologyExamTypes();
        }
        if (!radiologyExamPrice || !radiologyExamPrice.radiologyExamPrice || radiologyExamPrice.radiologyExamPrice?.length === 0) {
            this.props.fetchRadiologyExamPrices();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.radiologyExamTypes && props.radiologyExamTypes.radiologyExamTypes) {
            state.ExamTypes = props.radiologyExamTypes.radiologyExamTypes.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }

        if (!state.initialize && props.SelectedExam) {
            state.initialize = true;
            state.SelectedExam = props.SelectedExam;
        }
        return state;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    setSearchExamTypes = SearchExamTypes => {
        this.setState({
            SearchExamTypes
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setSelectedExam = Exam => {
        const objIndex = this.state.SelectedExam.findIndex(p => p.Id === Exam.Id);
        let SelectedExam = this.state.SelectedExam;
        if (objIndex >= 0) {
            SelectedExam = SelectedExam.filter(p => p.Id !== Exam.Id);
        } else {
            SelectedExam.push(Exam);
        }
        this.setState({
            SelectedExam
        });
        this.props.setSelectedExam(SelectedExam);
    }

    renderTableRow = () => {
        const { t, radiologyExamPrice } = this.props;
        if (radiologyExamPrice && radiologyExamPrice.radiologyExamPrice && radiologyExamPrice.radiologyExamPrice.length > 0) {
            let searchResult = radiologyExamPrice.radiologyExamPrice.filter(p => p.IsActive && p.Exam.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()));
            if (this.state.SearchExamTypes) {
                searchResult = searchResult.filter(p => this.state.SearchExamTypes.value === p.Type.Id);
            }

            if (searchResult.length > 0) {
                return searchResult.map((exam) => {
                    return <tr key={`radiology-exam-price-${exam.Exam.Id}`}>
                        <td>
                            <div className="form-check form-check-custom">
                                <input
                                    name="checkbox"
                                    type="checkbox"
                                    value={exam.Exam.Id}
                                    onChange={() => this.setSelectedExam(exam)}
                                    className="form-check-input"
                                    checked={this.state.SelectedExam.some(p => p.Id === exam.Id)} />
                            </div>
                        </td>
                        <td>
                            {exam.Exam?.Name}
                        </td>
                        <td>
                            {exam.Price}
                            <span className="ms-1">
                                {exam.Currency?.Name}
                            </span>
                        </td>
                        <td>
                            {exam.Exam?.Type?.Name}
                        </td>
                    </tr>
                });
            }
            return <tr><td className="text-center" colSpan={4}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={4}>{t("NoRows")}</td></tr >;
    }

    render() {
        const { t, radiologyExamPrice, radiologyExamTypes } = this.props;

        let isLoaded = false;
        if (radiologyExamPrice) {
            isLoaded = radiologyExamPrice.isLoaded;
        }
        let isLoadedExamTypes = false;
        if (radiologyExamTypes) {
            isLoadedExamTypes = radiologyExamTypes.isLoaded;
        }

        return (<>
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
                <div className="d-flex align-items-center">
                    <Select
                        onChange={this.setSearchExamTypes}
                        isClearable={true}
                        name="ExamTypes"
                        id="ExamTypes"
                        placeholder={t('AdvancedSearch.Search.ByRadiologyExamTypes')}
                        title={t('AdvancedSearch.Search.ByRadiologyExamTypes')}
                        isRtl={true}
                        isLoading={!isLoadedExamTypes}
                        isSearchable={true}
                        ref={ExamTypes => this.ExamTypes = ExamTypes}
                        options={this.state.ExamTypes}
                        filterOption={this.filterOptions}
                    />

                </div>
            </div>
            <div className={`scroll h-450px border-1 border-dark border-dashed table-responsive ${isLoaded ? "" : "table-loading"}`} >
                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                    <thead>
                        <tr>
                            <th title={t("Select")}>
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-square-check"></i>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Radiology.Table.Name")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-biohazard me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Radiology.Table.Name")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Radiology.Exams.Price.Title")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-circle-dollar me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Radiology.Exams.Price.Title")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Radiology.Table.Type")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-radiation me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Radiology.Table.Type")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRow()}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        radiologyExamPrice: state.radiologyExamPrice,
        radiologyExamTypes: state.radiologyExamTypes
    };
};

export default connect(mapStateToProps, { fetchRadiologyExamTypes, fetchRadiologyExamPrices })(withTranslation('common')(SelectExam));