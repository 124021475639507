const RadiologyExamPriceReducers = (state = [], action) => {
    switch (action.type) {
        case "RADIOLOGY_EXAM_PRICE_LOADING":
            return {
                radiologyExamPrice: [],
                isLoaded: false
            };
        case "RADIOLOGY_EXAM_PRICE_INITIAL":
            return {
                radiologyExamPrice: action.radiologyExamPrice,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default RadiologyExamPriceReducers;