const PatientRadiologyReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_RADIOLOGY_LOADING":
            return {
                patientRadiology: [],
                PatientId: 0,
                isLoaded: false
            };
        case "PATIENT_RADIOLOGY_INITIAL":
            return {
                patientRadiology: action.patientRadiology,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientRadiologyReducers;