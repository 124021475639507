import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PatientName from "../../../Patients/PatientName";
import Form from "./Form";
import ServiceInfo from "../../../ClinicScreen/Services/Info";
import { toast } from "react-toastify";
import serverAPI from "../../../../api/serverAPI";

class Accept extends React.Component {

    state = { showServiceInfo: false, service: null }

    showServiceInfo = (service, type) => {
        this.setState({
            showServiceInfo: true,
            service: { ...service, Type: type.Type }
        });
    }

    handleClose = () => {
        this.setState({
            showServiceInfo: false,
            service: null
        });
    }

    onSubmit = () => {
        const { t, Bill } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let SelectedService = [];
        Bill.Types.forEach(type => {
            type.items.forEach(Service => {
                SelectedService.push(Service);
            });
        });

        serverAPI.post(`/PatientBills/${Bill.Visit.Id}`, {
            ...this.props.formValues,
            Services: SelectedService.map(({ Id }) => Id),
            PatientId: Bill.Visit.patient.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update("Cash", Bill.Bill.Total, this.props.user.user?.HealthCenter.Currency);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    render() {
        const { t, Bill } = this.props;

        return <> {
            this.state.showServiceInfo && this.state.service ?
                <ServiceInfo service={this.state.service} handleClose={this.handleClose} />
                :
                <Modal
                    size="lg"
                    centered
                    backdrop="static"
                    keyboard={false}
                    enforceFocus={false}
                    show={true}
                    onHide={this.props.handleClose}>
                    <Modal.Header closeButton >
                        <Modal.Title>
                            <i className="h3 fa-duotone fa-file-invoice me-2 text-primary "></i>
                            <span className="text-nowrap me-1">
                                {t("Table.Options.Accept.Bill")}
                            </span>
                            (
                            <PatientName patient={Bill.Visit.patient} />
                            )
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            Bill.Visit.Clinic ?
                                <Form  {...this.props} />
                                :
                                <>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                                <i className="fa-regular fa-file-invoice-dollar me-2 text-primary h3 mb-0"></i>
                                                {t('Services.Bill_Title')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="d-flex flex-column ms-10">
                                            {
                                                Bill.Types.map(type => {
                                                    return <div key={`service-type-item-${type.Type.Id}`} className="mb-1">
                                                        <div className="mb-3">
                                                            {t(`Services.Type.${type.Type.Name}`)} {type.items.length > 1 ? ` : ( ${type.items.length} )` : ""}
                                                        </div>
                                                        <div className="d-flex flex-column ms-10 mb-3">
                                                            {
                                                                type.items.map(service => {
                                                                    return <li key={`service-sub-item-${service.Id}`} className="d-flex align-items-center mb-3">
                                                                        <span className={`bullet me-3 bg-${service.IsDeported ? "success" : "dark"} `}></span>
                                                                        <div className="row me-5 flex-grow-1">
                                                                            <Link to="#" className="col-md-9" onClick={() => this.showServiceInfo(service, type)} >
                                                                                {service.Details}
                                                                            </Link>
                                                                            <div className="col-md-3">
                                                                                {service.Price + " " + service.Currency.Name}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="row ">
                                        {
                                            Bill.Bill.Discount ?
                                                <>
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <span className="fw-bold text-muted mb-2">
                                                                <i className="fa-regular text-dark fa-sigma me-2"></i>
                                                                {t("Services.Amount")}
                                                                :
                                                            </span>
                                                            <span className="fs-5 ms-10">
                                                                {Bill.Bill.Amount}
                                                                <span className="mx-1">
                                                                    {Bill.Bill.Currency.Name}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <span className="fw-bold text-muted mb-2">
                                                                <i className="fa-solid fa-percent me-2 text-dark "></i>
                                                                {t("Services.Discount.Title")}
                                                                :
                                                            </span>
                                                            <span className="fs-5 ms-10">
                                                                {Bill.Bill.Discount}
                                                                <span className="mx-1">
                                                                    {Bill.Bill.Currency.Name}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </> : ""
                                        }

                                        <div className={`${Bill.Bill.Discount ? "col-md-4" : "w-100 d-flex justify-content-evenly pt-3"}`} title={t("Services.Total.Note")}>
                                            <span className="d-md-block d-none text-nowrap">
                                                <i className="fa-regular text-dark fa-calculator me-2"></i>
                                                {t("Services.Total.Title")}
                                                :
                                            </span>
                                            <span className="fs-5 ms-10">
                                                {Bill.Bill.Total}
                                                <span className="mx-1">
                                                    {Bill.Bill.Currency.Name}
                                                </span>
                                            </span>
                                        </div>
                                    </div>


                                    <div className="modal-footer pb-0 px-0">
                                        <button type="button" onClick={() => this.onSubmit()} className="btn btn-sm btn-success mx-1" title={t('Accounting.ReceiveCash.Accept.Title')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                                            <span className="indicator-label">
                                                <i className="me-1 fa-solid fa-save"></i>
                                                {t('Accounting.ReceiveCash.Accept.Title')}
                                            </span>
                                            <span className="indicator-progress">{t('PleaseWait')}
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>

                                        <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                                            <i className="me-1 fa-solid fa-xmark"></i>
                                            {t('Close')}
                                        </button>
                                    </div>

                                </>
                        }
                    </Modal.Body>
                </Modal>
        }
        </>

    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Accept));