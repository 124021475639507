import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class NotFound extends React.Component {

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_aside").classList.add("d-none");
        document.getElementById("kt_header").classList.add("d-none");
        document.getElementById("kt_footer").classList.add("d-none");
        document.getElementById("kt_wrapper").classList.add("p-0");
        body.setAttribute('class', "bg-body");
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_aside").classList.remove("d-none");
        document.getElementById("kt_header").classList.remove("d-none");
        document.getElementById("kt_footer").classList.remove("d-none");
        document.getElementById("kt_wrapper").classList.remove("p-0");

        body.setAttribute('class', "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed");
    }

    render() {
        const { t } = this.props;
        return (<>
            <Helmet>
                <title>{t("Pages.NotFound")} | {t('SiteName')}</title>
            </Helmet>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-column flex-center flex-column-fluid p-10">
                    <img src="/assets/media/illustrations/sketchy-1/18.png" className="mw-100 mb-10 h-lg-450px" alt="" />
                    <h1 className="fw-bold mb-10">{t("NotFound")}</h1>
                    <Link to="/" className="btn btn-primary">{t("ReturnToHome")}</Link>
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(NotFound));