import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Table from "./Table";
import Form from "./Form";
import { fetchMeasurements as fetch } from "./actions";
import Card from "./Card";

class Measurements extends React.Component {

    state = { showCreateNew: false, showHistory: false };

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        if (this.props.Appointment?.patient) {
            this.props.fetch(this.props.Appointment.patient.Id);
        }
    }

    update = () => {
        this.fetch();
        this.handleClose();
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false,
            showHistory: false
        });
    }

    showHistory = () => {
        this.setState({
            showHistory: true
        });
    }

    renderBMI = measurement => {
        const { t } = this.props;
        const BMI = (measurement?.Weight / (measurement?.Height / 100 * measurement?.Height / 100)).toFixed(2);
        let title = null;
        if (BMI < 18.5) {
            title = <span className="badge badge-pill badge-warning badge-sm mx-1">{t("Measurements.BMI.S")}</span>
        } else if (BMI < 25) {
            title = <span className="badge badge-pill badge-success badge-sm mx-1">{t("Measurements.BMI.M")}</span>
        } else if (BMI < 30) {
            title = <span className="badge badge-pill badge-primary badge-sm mx-1">{t("Measurements.BMI.L")}</span>
        } else {
            title = <span className="badge badge-pill badge-danger badge-sm mx-1">{t("Measurements.BMI.XL")}</span>
        }
        return <>
            {BMI}
            <span className="ms-2">
                {t("Measurements.BMI.Unit")}
                <sup>
                    2
                </sup>
                {title}
            </span>
        </>;
    }

    render() {
        const { t, measurement } = this.props;
        return <>
            {
                this.state.showCreateNew ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-plus me-2 text-primary "></i>
                                {t("Measurements.New")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form {...this.props} update={this.update} />
                        </Modal.Body>
                    </Modal> : ""
            }
            {
                this.state.showHistory ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-rectangle-history-circle-user me-2 text-primary "></i>
                                {t("Measurements.History")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table {...this.props} renderBMI={this.renderBMI} />
                        </Modal.Body>
                    </Modal> : ""
            }
            {
                this.props.isForShow === true && measurement?.measurement?.length === 0 ? "" :

                    <div className="card h-100">
                        <div className="card-header align-items-center border-0 mt-4">
                            <div className="card-title h3 d-flex align-items-center">
                                <i className="h3 fa-duotone fa-ruler-combined me-2 text-info"></i>
                                {t("Measurements.Title")}
                            </div>
                            {
                                this.props.isForShow === true ? "" :
                                    <div className="card-toolbar">
                                        <Link
                                            to="#"
                                            onClick={this.showCreateNew}
                                            title={t("Measurements.New")}
                                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                                            <i className="fa-regular fa-plus"></i>
                                            <span className="d-md-block d-none text-nowrap ms-1">
                                                {t("Measurements.New")}
                                            </span>
                                        </Link>
                                    </div>
                            }
                        </div>
                        <div className="card-body pt-5">
                            <Card measurement={measurement.measurement} renderBMI={this.renderBMI} />
                        </div>
                        {
                            measurement.measurement ?
                                <div className="card-footer">
                                    <div className="w-100 d-flex justify-content-evenly" >
                                        <div className="btn btn-light-info ms-auto d-flex justify-content-center align-items-center w-100" onClick={() => this.showHistory()} title={t("Measurements.History")}>
                                            <i className="fa-regular fa-rectangle-history-circle-user me-1"></i>
                                            {t("Measurements.History")}
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
            }
        </>;
    }
}

const mapStateToProps = state => {
    return {
        measurement: state.measurement
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Measurements));