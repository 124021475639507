const PastSurgicalHistoryReducers = (state = [], action) => {
    switch (action.type) {
        case "PAST_SURGICAL_HISTORY_LOADING":
            return {
                pastSurgicalHistory: null,
                isLoaded: false
            };
        case "PAST_SURGICAL_HISTORY_INITIAL":
            return {
                pastSurgicalHistory: action.pastSurgicalHistory,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PastSurgicalHistoryReducers;