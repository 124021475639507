const PatientLabsReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_LABS_LOADING":
            return {
                patientLabs: [],
                PatientId: 0,
                isLoaded: false
            };
        case "PATIENT_LABS_INITIAL":
            return {
                patientLabs: action.patientLabs,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientLabsReducers;