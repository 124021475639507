import React from "react";
import { withTranslation } from 'react-i18next';

class PatientName extends React.Component {

    render() {
        const { t, patient, displayEnglishName } = this.props;

        return (<>
            {
                patient.Sex === null ? "" :
                    patient.Sex === "male" ?
                        <i className="fa-solid me-1 fa-mars text-primary" title={t("Patient.Forms.Sex.Options.Male")}></i>
                        :
                        <i className="fa-solid me-1 fa-venus text-danger" title={t("Patient.Forms.Sex.Options.Female")}></i>
            }
            <span className="text-nowrap">{patient.Name.FirstName + " " + patient.Name.SecondName + " " + patient.Name.ThirdName + " " + patient.Name.LastName}</span>
            {
                patient.NameEnglish.FirstName && patient.NameEnglish.LastName && displayEnglishName ?
                    <>
                        <div className="me-10" style={{
                            direction: 'ltr'
                        }}>
                            {
                                patient.Sex === null ? "" :
                                    patient.Sex === "male" ?
                                        <i className="fa-solid ms-1 fa-mars text-primary" title={t("Patient.Forms.Sex.Options.Male")}></i>
                                        :
                                        <i className="fa-solid ms-1 fa-venus text-danger" title={t("Patient.Forms.Sex.Options.Female")}></i>
                            }
                            <span className="text-nowrap">{patient.NameEnglish.FirstName + " " + patient.NameEnglish.SecondName + " " + patient.NameEnglish.ThirdName + " " + patient.NameEnglish.LastName}</span>
                        </div>
                    </> : ""
            }
        </>);
    }
}

export default withTranslation('common')(PatientName);