import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import Table from "./Table";
import Form from "./Form";
import { fetchAccounts } from "./actions";

class Accounts extends React.Component {

    state = { searchKey: null, showVoucher: false };

    update = searchKey => {
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchAccounts(searchKey);
        } else {
            this.props.fetchAccounts(this.state.searchKey);
        }
    }

    render() {
        const { t, accounts } = this.props;
        let isLoaded = false;
        if (accounts) {
            isLoaded = accounts.isLoaded;
        }
        return (<>
            <Helmet>
                <title>{t("Accounting.Accounts.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        {/* <Link
                            to="#"
                            title={t('Accounting.JournalVoucher.New')}
                            className="btn btn-sm btn-sm-icon btn-light-primary "
                            onClick={() => this.showVoucher()}>
                            <i className="me-1 fa-solid fa-plus"></i>
                            {t('Accounting.JournalVoucher.New')}
                        </Link> */}
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form {...this.props} onSubmit={this.update} />
                    </div>
                </div>
            </div>

            {
                isLoaded ? <div className="card">
                    <div className="card-header align-items-center ">
                        <h3 className="card-title">
                            <span className="fw-bolder mb-2 text-dark">
                                <i className="h3 fa-duotone fa-file-user me-2 text-info mb-0"></i>
                                {t('Accounting.Accounts.Title')}
                            </span>
                        </h3>
                    </div>
                    <div className="card-body pt-5 px-2">
                        <div className="w-100">
                            <Table {...this.props} update={this.update} />
                        </div>
                    </div>
                </div> : ""
            }

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        accounts: state.accounts
    };
};

export default connect(mapStateToProps, { fetchAccounts })(withTranslation('common')(Accounts));