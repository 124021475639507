import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Info from "./Info";
import Balances from "./Balances";

class Table extends React.Component {

    state = { showInfo: false, showBalancesInfo: false, account: null, showTransactionsInfo: false };

    showInfo = account => {
        this.setState({
            showInfo: true,
            account
        });
    }

    showTransactionsInfo = account => {
        this.setState({
            showTransactionsInfo: true,
            account
        });
    }

    showBalancesInfo = account => {
        this.setState({
            showBalancesInfo: true,
            account
        });
    }

    handleClose = () => {
        this.setState({
            showTransactionsInfo: false,
            showBalancesInfo: false,
            showInfo: false,
            account: null
        });
    }

    render() {
        const { t, accounts, isSelectable } = this.props;

        let isLoaded = false;
        if (accounts && accounts.accounts) {
            isLoaded = accounts.isLoaded;
        }

        return <>
            {this.state.showBalancesInfo && this.state.account ? <Balances Account={this.state.account} handleClose={this.handleClose} /> : ""}
            {this.state.showInfo && this.state.account ? <Info Account={this.state.account} handleClose={this.handleClose} /> : ""}
            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr>
                                <th title={t("Accounting.Account.Number")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Account.Number")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.Account.Name")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Account.Name")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.Accounts.Father")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-folder-tree me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Accounts.Father")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                accounts.accounts.length > 0 ?
                                    accounts.accounts.map(item => {
                                        return <tr key={`accounts-${item.Id}`}>
                                            <td>{item.Number}</td>
                                            <td>{item.Name}</td>
                                            <td>
                                                {item.FatherAccount ? item.FatherAccount.Number + " - " + item.FatherAccount?.Name : ""}
                                            </td>
                                            <td>
                                                {
                                                    isSelectable ?
                                                        <Link
                                                            to="#"
                                                            onClick={() => this.props.select(item)}
                                                            className="btn btn-sm btn-icon btn-light-dark ms-1"  >
                                                            <i className="fa-solid fa-check"></i>
                                                        </Link>
                                                        :
                                                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa-regular fa-gears"></i>
                                                                    <span className="d-md-block d-none text-nowrap ms-1">
                                                                        {t("Table.Options.Title")}
                                                                    </span>
                                                                </button>
                                                                <div className="dropdown-menu ">
                                                                    <Link to="#"
                                                                        onClick={() => this.showInfo(item)}
                                                                        className="dropdown-item"  >
                                                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                                                        {t("Table.Options.Info")}
                                                                    </Link>
                                                                    <Link to="#"
                                                                        onClick={() => this.showBalancesInfo(item)}
                                                                        className="dropdown-item"  >
                                                                        <i className="fa-regular fa-scale-balanced text-dark me-1"></i>
                                                                        {t("Accounting.Balances.Title")}
                                                                    </Link>
                                                                    {
                                                                        item.HasTransactions ?
                                                                            <Link to="#"
                                                                                onClick={() => this.showTransactionsInfo(item)}
                                                                                className="dropdown-item"  >
                                                                                <i className="fa-regular fa-rectangle-history text-dark me-1"></i>
                                                                                {t("Accounting.Transactions.Title")}
                                                                            </Link>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr><td className="text-center" colSpan={4}>{t("NoResult")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        accounts: state.accounts
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));