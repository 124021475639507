import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select';

import Info from "./Info";
import serverAPI from "../../../api/serverAPI";
import Edit from "./Edit";
import Delete from "./Delete";
import { fetchClinics } from "../../Manager/Clinics/actions";

class Table extends React.Component {

    state = {
        clinics: [],
        service: null,
        showDelete: false,
        SearchByName: "",
        showInfo: false,
        showEdit: false
    };

    Delete = () => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/ClinicServices/${this.state.service.Id}?ClinicId=${this.state.service.Clinic?.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.update();
            this.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    componentDidMount() {
        const { clinics } = this.props;
        if (!clinics || !clinics.clinics || clinics.clinics?.length === 0) {
            this.props.fetchClinics();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.clinics.length === 0 && props.clinics && props.clinics.clinics) {
            state.clinics = [];
            state.clinics = props.clinics.clinics.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    handleClose = () => {
        this.setState({
            showEdit: false,
            showInfo: false,
            showDelete: false,
            service: null
        });
    }

    showInfo = service => {
        this.setState({
            showInfo: true,
            service
        })
    }

    showEdit = service => {
        this.setState({
            showEdit: true,
            service
        })
    }

    showDelete = service => {
        this.setState({
            showDelete: true,
            service
        })
    }

    renderTableRow = () => {
        const { t, clinicServices } = this.props;
        if (clinicServices && clinicServices.clinicServices && clinicServices.clinicServices.length > 0) {

            let searchResult = clinicServices.clinicServices;
            if (this.state.currentClinic) {
                searchResult = searchResult.filter(p => this.state.currentClinic.value === p.Clinic?.Id);
            }

            if (this.state.SearchByName) {
                searchResult = searchResult.filter(p => p.Name.toLowerCase().includes(this.state.SearchByName));
            }

            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`clinic-services-${index}-${item.Id}`}>
                        <td>
                            {item.Name}
                        </td>
                        <td>
                            {item.Price}
                            <span className="ms-1">
                                {item.Currency?.Name}
                            </span>
                        </td>
                        <td>
                            {item.Clinic?.Name}
                        </td>
                        <td>
                            <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        <Link to="#"
                                            onClick={() => this.showInfo(item)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-info text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                        <Link to="#"
                                            className="dropdown-item"
                                            onClick={() => this.showEdit(item)}>
                                            <i className="fa-regular fa-edit text-dark me-1"></i>
                                            {t("Table.Options.Edit")}
                                        </Link>
                                        {
                                            item.Type?.Name === "other" ?
                                                <Link to="#"
                                                    className="dropdown-item"
                                                    onClick={() => this.showDelete(item)}>
                                                    <i className="fa-regular fa-trash text-dark me-1"></i>
                                                    {t("Table.Options.Delete")}
                                                </Link>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr >;
                });
            }
            return <tr><td className="text-center" colSpan={4}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={4}>{t("NoRows")}</td></tr>;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    updateClinic = currentClinic => {
        this.setState({
            currentClinic
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    render() {
        const { t, clinicServices, clinics } = this.props;

        let isLoaded = false;
        if (clinicServices) {
            isLoaded = clinicServices.isLoaded;
        }

        let isLoadedClinics = false;
        if (clinics) {
            isLoadedClinics = clinics.isLoaded;
        }
        return <>
            {this.state.showInfo && this.state.service ? <Info service={this.state.service} handleClose={this.handleClose} /> : ""}
            {this.state.showEdit && this.state.service ? <Edit isEdit={true} service={this.state.service} handleClose={this.handleClose} update={this.props.update} /> : ""}
            {this.state.showDelete && this.state.service ? <Delete service={this.state.service} handleClose={this.handleClose} Delete={this.Delete} /> : ""}

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
                <div className="d-flex align-items-center">
                    <Select
                        onChange={this.updateClinic}
                        isClearable={true}
                        name="Clinics "
                        id="Clinics "
                        placeholder={t('Clinic.Forms.Name.Select')}
                        title={t('Clinic.Forms.Name.Select')}
                        isLoading={!isLoadedClinics}
                        isSearchable={true}
                        ref={Clinics => this.Clinics = Clinics}
                        options={this.state.clinics}
                        filterOption={this.filterOptions}
                    />
                </div>
            </div>
            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("Services.Forms.Name.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Services.Forms.Name.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Services.Forms.Price.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-coin me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Services.Forms.Price.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("HealthCenter.Clinic")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("HealthCenter.Clinic")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        clinics: state.clinics,
        user: state.user,
        clinicServices: state.clinicServices
    };
};

export default connect(mapStateToProps, { fetchClinics })(withTranslation('common')(Table));