import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { fetchPatientDrugs as fetch } from "./actions";
import DrugCard from "../../ClinicScreen/DrugOrders/DrugCard";

class Drugs extends React.Component {

    state = { SearchByName: "", drug: null };

    componentDidMount() {
        const { patientDrugs } = this.props;
        if (!patientDrugs || !patientDrugs.patientDrugs || patientDrugs.patientDrugs?.length === 0 || patientDrugs.PatientId !== this.props.patient.Id) {
            this.props.fetch(this.props.patient.Id);
        }
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    showInfo = drug => {
        this.setState({
            showInfo: true,
            drug
        });
    }

    handleClose = () => {
        this.setState({
            showInfo: false,
            drug: null
        });
    }

    renderTableRow = () => {
        const { t, patientDrugs } = this.props;
        if (patientDrugs && patientDrugs.patientDrugs && patientDrugs.patientDrugs.length > 0) {
            let searchResult = patientDrugs.patientDrugs.filter(p => p.Drug.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()));
            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`patient-drugs-${index}-${item.Id}`}>
                        <td>
                            <span title={item.CreationDate}>
                                {moment(item.CreationDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")}
                            </span>
                        </td>
                        <td>
                            {item.Drug.Name}
                        </td>
                        <td>
                            <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        <Link to="#"
                                            onClick={() => this.showInfo(item)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-info text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr >;
                });
            }
            return <tr><td className="text-center" colSpan={3}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={3}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, patientDrugs } = this.props;

        let isLoaded = false;
        if (patientDrugs) {
            isLoaded = patientDrugs.isLoaded;
        }

        return <>
            {
                this.state.showInfo ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                                {t("Table.Options.Info")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DrugCard DrugItem={this.state.drug} showMore={true} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-prescription me-2 text-info mb-0"></i>
                            {t('MedicalRecords.Nav.Drug')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">

                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                                <input
                                    value={this.state.SearchByName}
                                    onChange={e => this.setSearch(e.target.value)}
                                    ref={SearchByName => this.SearchByName = SearchByName}
                                    name="SearchByName"
                                    id="SearchByName"
                                    autoComplete={"off"}
                                    type={"text"}
                                    placeholder={t('AdvancedSearch.Search.ByName')}
                                    title={t('AdvancedSearch.Search.ByName')}
                                    className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="position-relative">
                            <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                                minHeight: '300px'
                            }}>
                                <div className="table-loading-message">
                                    {t("Processing.Title")}
                                </div>
                                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                    <thead>
                                        <tr className="fw-bold fs-6 text-gray-800">
                                            <th title={t("Date")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-calendar me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Date")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("ClinicScreen.Orders.Drug.Table.Name")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-pills me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("ClinicScreen.Orders.Drug.Table.Name")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Table.Options.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-gears me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Table.Options.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableRow()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }

}

const mapStateToProps = state => {
    return {
        patientDrugs: state.patientDrugs
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Drugs));