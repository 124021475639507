import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from "../New/Form";

class Edit extends React.Component {

    render() {
        const { t, Clinic } = this.props;

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-edit me-2 text-primary "></i>
                    {t("Clinic.Edit")}
                    <span className="ms-1">   {Clinic.Name}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form isEdit={true} {...this.props} />
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(Edit);