import React from "react";
import { withTranslation } from 'react-i18next';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class VisitType extends React.Component {

    state = { data: [], label: [], backgroundColor: [] }

    static getDerivedStateFromProps(props, state) {
        let data = [];
        let label = [];
        let backgroundColor = [];
        if (props.Data) {
            if (props.Data.filter(p => p === true).length > 0) {
                backgroundColor.push('rgb(0 158 247)');
                label.push(props.t(`Appointment.Type.New`));
                data.push(props.Data.filter(p => p === true).length);
            }
            if (props.Data.filter(p => p === false).length > 0) {
                backgroundColor.push('rgb(255 199 0)');
                label.push(props.t(`Appointment.Type.Review`));
                data.push(props.Data.filter(p => p === false).length);
            }
        }
        state.data = data;
        state.label = label;
        state.backgroundColor = backgroundColor;
        return state;
    }

    render() {
        const { t } = this.props;

        const data = {
            labels: this.state.label,
            datasets: [
                {
                    label: `# ${t(`Total`)}`,
                    data: this.state.data,
                    backgroundColor: this.state.backgroundColor,
                    borderWidth: 1,
                },
            ],
        };
        return <>
            <h3 className="text-center mb-3">
                <i className="fa-regular text-dark fa-coin me-1"></i>
                {t('Appointment.Statistics.Type')}
            </h3>
            <Doughnut data={data} />
            <div className="row mt-3">
                <div className="table-responsive">
                    <table className="table table-sm table-bordered align-middle my-0">
                        <thead className="border-bottom border-gray-200 fs-6 fw-bold bg-lighten ">
                            <tr>
                                <th>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-coin me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Appointment.Type.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Total")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="fs-6 fw-semibold text-gray-600">
                            {
                                this.state.data.map((item, index) => {
                                    return <tr key={`visit-status-${index}`}>
                                        <td>
                                            {this.state.label[index]}
                                        </td>
                                        <td>
                                            {item}
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr className="border-top-dashed border-dark border-top-1">
                                <th>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-sigma me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Sum")}
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    {this.state.data.reduce((a, v) => a = a + v, 0)}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>;
    }

}

export default withTranslation('common')(VisitType);