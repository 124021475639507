import moment from "moment";

const getAge = (t, Birthday) => {
    let result = "";
    if (Birthday) {

        const years = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'years', false);
        const months = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'months', false);
        const days = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'days', false);

        if (years > 1) {
            result += years + " " + t("Age.Year") + " ";
            if (months % 12) {
                result += months % 12 + " " + t("Age.Month");
            }
        } else {
            if (months > 0) {
                result += months + " " + t("Age.Month") + " ";
                if (days % 30) {
                    result += days % 30 + " " + t("Age.Day");
                }
            } else {
                result += days + " " + t("Age.Day");
            }
        }
    }
    return result;
}

const FormTemplate = (props) => {
    const { t, patient, title, user, Clinic } = props;
    return `<div class="fs-6 m-5" style="direction: rtl" direction="rtl" dir="rtl" lang="ar">
        <div class="d-flex mb-2">
            <div class="d-flex ms-auto">
               <div class="fw-bold me-2">
                    ${t('Date')}:
                </div>
                <div class="fst-italic text-decoration-underline me-1">
                    ${moment().format('DD/MM/YYYY')}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-3">
            <div class="d-flex">
                <div class="fw-bold me-2">
                    ${t('Patient.Forms.FirstName.Title')}:
                </div>
                <div class="text-nowrap mx-1 fst-italic text-decoration-underline">${patient.Name.FirstName + " " + patient.Name.SecondName + " " + patient.Name.ThirdName + " " + patient.Name.LastName}</div>
            </div>
            <div class="d-flex">
                ${patient.Birthday ? `
                 <div class="fw-bold me-2">
                    ${t('Patient.Age')}:
                </div>
               <div class="text-nowrap mx-1 fst-italic text-decoration-underline">${getAge(t, patient.Birthday)}</div>
               ` : ""}
            </div>
        </div>
        <div class="fw-bolder text-nowrap text-decoration-underline fs-3 text-center mb-4">
            ${title}
        </div>
        <p class="mb-1 lh-sm text-capitalize text-justify">
           ${t('PatientForms.Forms.Body.Placeholder')} ...
        </p>
        <br />
        <div class="d-flex justify-content-between my-5">
            <div class="d-flex">
                 <div class="fw-bold mx-2 d-flex flex-column justify-content-center align-items-center">
                    <div class="mb-1">
                        ${Clinic.HealthCenter?.Name}
                    </div>
                     <div>
                         ${Clinic.Name}
                    </div>
                </div>
            </div>
            <div class="d-flex">
                 <div class="fw-bold mx-2 d-flex flex-column justify-content-center align-items-center">
                    <div class="mb-1">
                        ${t('Clinic.Forms.DoctorName.Title')}
                    </div>
                     <div>
                        ${user.user?.Name}
                    </div>
                </div>
            </div>
        </div>
    </div>`;
}
export default FormTemplate;