import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import moment from "moment";
import { Link } from "react-router-dom";

import Table from "./Table";
import Form from "./Form";
import { fetchRadiologyOrders } from "./actions";
import New from "./New";

class RadiologyReceivingPatients extends React.Component {

    state = {
        searchKey: {
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY')
        },
        showCreateNew: false
    };

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false
        });
    }

    update = searchKey => {
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchRadiologyOrders(searchKey);
        } else {
            this.props.fetchRadiologyOrders(this.state.searchKey);
        }
    }

    render() {
        const { t, radiologyOrders } = this.props;

        let isLoaded = false;
        if (radiologyOrders) {
            isLoaded = radiologyOrders.isLoaded;
        }
        return (<>
            {this.state.showCreateNew ? <New handleClose={this.handleClose} update={this.update} /> : ""}
            <Helmet>
                <title>{t("Radiology.ReceivingPatients.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <Link
                            to="#"
                            onClick={this.showCreateNew}
                            title={t("Radiology.New")}
                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                            <i className="fa-regular fa-plus"></i>
                            <span className="d-md-block d-none text-nowrap ms-1">
                                {t("Radiology.New")}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form fetchOrders={this.update} />
                    </div>
                </div>
            </div>
            {
                isLoaded ? <div className="card">
                    <div className="card-header align-items-center ">
                        <h3 className="card-title">
                            <span className="fw-bolder mb-2 text-dark">
                                <i className="h3 fa-duotone fa-x-ray me-2 text-info mb-0"></i>
                                {t('Radiology.ReceivingPatients.Title')}
                            </span>
                        </h3>
                    </div>
                    <div className="card-body pt-5 px-2">
                        <div className="w-100">
                            <Table update={this.update} />
                        </div>
                    </div>
                </div> : ""
            }

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        radiologyOrders: state.radiologyOrders
    };
};

export default connect(mapStateToProps, { fetchRadiologyOrders })(withTranslation('common')(RadiologyReceivingPatients));