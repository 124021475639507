import serverAPI from "../../../api/serverAPI";

export const loadPatients = () => async dispatch => {
    dispatch({ type: 'PATIENT_LOADING' });
}

export const fetchPatients = data => async dispatch => {
    dispatch({ type: 'PATIENT_LOADING' });
    const response = await serverAPI.get(`/PatientRegistration/?FirstName=${data.FirstName ? data.FirstName : ""}&FirstNameEnglish=${data.FirstNameEnglish ? data.FirstNameEnglish : ""}&LastName=${data.LastName ? data.LastName : ""}&LastNameEnglish=${data.LastNameEnglish ? data.LastNameEnglish : ""}&MotherName=${data.MotherName ? data.MotherName : ""}&MotherNameEnglish=${data.MotherNameEnglish ? data.MotherNameEnglish : ""}&SecondName=${data.SecondName ? data.SecondName : ""}&SecondNameEnglish=${data.SecondNameEnglish ? data.SecondNameEnglish : ""}&ThirdName=${data.ThirdName ? data.ThirdName : ""}&ThirdNameEnglish=${data.ThirdNameEnglish ? data.ThirdNameEnglish : ""}&DocumentNumber=${data.DocumentNumber ? data.DocumentNumber : ""}&Address=${data.Address ? data.Address : ""}&Phone=${data.Phone ? data.Phone : ""}&WhatsAppNumber=${data.WhatsAppNumber ? data.WhatsAppNumber : ""}&Email=${data.Email ? data.Email : ""}&Birthday=${data.Birthday ? data.Birthday : ""}&CityId=${data.CityId ? data.CityId : "0"}`);
    dispatch({ type: 'PATIENT_INITIAL', patients: response.data.data });
}

export const createPatients = patient => async dispatch => {
    dispatch({ type: 'PATIEN_ADD_NEW', newNode: patient });
}

export const updatePatients = (patient, id) => async dispatch => {
    dispatch({ type: 'PATIEN_UPDATE', newNode: patient, id });
}
