import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class PermissionError extends React.Component {

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_aside").classList.add("d-none");
        document.getElementById("kt_header").classList.add("d-none");
        document.getElementById("kt_footer").classList.add("d-none");
        document.getElementById("kt_wrapper").classList.add("p-0");
        body.setAttribute('class', "bg-body");
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_aside").classList.remove("d-none");
        document.getElementById("kt_header").classList.remove("d-none");
        document.getElementById("kt_footer").classList.remove("d-none");
        document.getElementById("kt_wrapper").classList.remove("p-0");

        body.setAttribute('class', "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed");
    }

    render() {
        const { t } = this.props;
        return (<>
            <Helmet>
                <title>{t("Pages.PermissionError")} | {t('SiteName')}</title>
            </Helmet>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                    <div className="pt-lg-10 mb-10">
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-10">{t("Pages.PermissionError")}</h1>
                        <div className="fw-bold fs-3 text-muted mb-15">
                            {t("PermissionError")}
                        </div>
                        <div className="text-center">
                            <Link to="/" className="btn btn-primary">{t("ReturnToHome")}</Link>
                        </div>
                    </div>
                    <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style={{
                        backgroundImage: 'url(/assets/media/illustrations/sketchy-1/17.png)'
                    }}></div>
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(PermissionError));