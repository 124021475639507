import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link } from "react-router-dom";

import Info from "./Info";
import Edit from "./Edit";
import { fetchLabExamTypes, fetchLabExams } from "../../ClinicScreen/LabOrders/actions";
import { fetchLabExamPrices } from "./actions";
import { toast } from "react-toastify";
import serverAPI from "../../../api/serverAPI";

class SelectExam extends React.Component {

    state = {
        SearchByName: "",
        ExamTypes: [],
        SearchExamTypes: null,
        SelectedExam: [],
        showEdit: false,
        showInfo: false,
        Exam: null,
        isInitialize: false
    };

    constructor(props) {
        super(props);
        const { labExams, labExamTypes, labExamPrice } = this.props;
        if (!labExamTypes || !labExamTypes.labExamTypes || labExamTypes.labExamTypes?.length === 0) {
            this.props.fetchLabExamTypes();
        }
        if (!labExams || !labExams.labExams || labExams.labExams?.length === 0) {
            this.props.fetchLabExams();
        }
        if (!labExamPrice || !labExamPrice.labExamPrice || labExamPrice.labExamPrice?.length === 0) {
            this.props.fetchLabExamPrices();
        }
    }

    fetchLabExamPrices = () => {
        this.props.fetchLabExamPrices();
        this.handleClose();
        this.setState({
            isInitialize: false
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.labExamTypes && props.labExamTypes.labExamTypes) {
            state.ExamTypes = props.labExamTypes.labExamTypes.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        if (!state.isInitialize && props.labExamPrice && props.labExamPrice.labExamPrice?.length > 0) {
            state.isInitialize = true;
            state.SelectedExam = props.labExamPrice.labExamPrice.filter(p => p.IsActive === true).map(item => {
                return { ...item.Exam };
            });
        }
        return state;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    showInfo = Exam => {
        this.setState({
            showInfo: true,
            Exam
        });
    }

    showEdit = Exam => {
        this.setState({
            showEdit: true,
            Exam
        });
    }

    handleClose = () => {
        const objIndex = this.state.SelectedExam.findIndex(p => p.Id === this.state.Exam.Id);
        let SelectedExam = this.state.SelectedExam;
        const hasExamPrice = this.props.labExamPrice.labExamPrice.some(p => p.Exam.Id === this.state.Exam.Id);
        if (!hasExamPrice) {
            if (objIndex >= 0) {
                SelectedExam = SelectedExam.filter(p => p.Id !== this.state.Exam.Id);
            }
        }
        this.setState({
            showInfo: false,
            showEdit: false,
            Exam: null,
            SelectedExam
        });
    }

    setSearchExamTypes = SearchExamTypes => {
        this.setState({
            SearchExamTypes
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setSelectedExam = Exam => {
        const objIndex = this.state.SelectedExam.findIndex(p => p.Id === Exam.Id);
        const hasExamPrice = this.props.labExamPrice.labExamPrice.filter(p => p.Exam.Id === Exam.Id)[0];
        let SelectedExam = this.state.SelectedExam;
        if (objIndex >= 0) {
            SelectedExam = SelectedExam.filter(p => p.Id !== Exam.Id);
        } else {
            SelectedExam.push(Exam);
            if (!hasExamPrice) {
                this.showEdit(Exam);
            }
        }
        if (hasExamPrice) {
            this.selectExam(hasExamPrice.Id, Exam);
        }
        this.setState({
            SelectedExam
        });
    }

    selectExam = (Id, Exam) => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/LabExamPrice/${Id}?ExamId=${Exam.Id}`,).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    renderTableRow = () => {
        const { t, labExams, labExamPrice } = this.props;
        if (labExams && labExams.labExams && labExams.labExams.length > 0) {
            let searchResult = labExams.labExams.filter(p => p.IsSelectable === true && p.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()));
            if (this.state.SearchExamTypes) {
                searchResult = searchResult.filter(p => this.state.SearchExamTypes.value === p.Type.Id);
            }
            if (searchResult.length > 0) {
                return searchResult.map((exam) => {
                    const hasExam = this.state.SelectedExam.some(p => p.Id === exam.Id);
                    const price = labExamPrice.labExamPrice.filter(p => p.Exam.Id === exam.Id)[0];
                    return <tr key={`lab-exam-${exam.Id}`}>
                        <td>
                            <div className="form-check form-check-custom">
                                <input
                                    name="checkbox"
                                    type="checkbox"
                                    value={exam.Id}
                                    onChange={() => this.setSelectedExam(exam)}
                                    className="form-check-input"
                                    checked={hasExam} />
                            </div>
                        </td>
                        <td>
                            {exam.Name}
                        </td>
                        <td>
                            {
                                hasExam && price ? <>
                                    {price.Price + " " + price.Currency.Name}
                                </> : t("Lab.Exams.Prices.NoResult")
                            }
                        </td>
                        <td>
                            {exam.Type.Name}
                        </td>
                        <th>
                            <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        <Link to="#"
                                            onClick={() => this.showInfo(exam)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-info text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                        <Link to="#"
                                            onClick={() => this.showEdit(exam)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-edit text-dark me-1"></i>
                                            {t("Table.Options.Edit")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                });
            }
            return <tr><td className="text-center" colSpan={5}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={5}>{t("NoRows")}</td></tr >;
    }

    render() {
        const { t, labExams, labExamTypes } = this.props;

        let isLoaded = false;
        if (labExams) {
            isLoaded = labExams.isLoaded;
        }
        let isLoadedExamTypes = false;
        if (labExamTypes) {
            isLoadedExamTypes = labExamTypes.isLoaded;
        }

        return (<>
            {this.state.showInfo && this.state.Exam ? <Info Exam={this.state.Exam} handleClose={this.handleClose} /> : ""}
            {this.state.showEdit && this.state.Exam ? <Edit Exam={this.state.Exam} handleClose={this.handleClose} update={this.fetchLabExamPrices} /> : ""}
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
                <div className="d-flex align-items-center">
                    <Select
                        onChange={this.setSearchExamTypes}
                        isClearable={true}
                        name="ExamTypes "
                        id="ExamTypes "
                        placeholder={t('AdvancedSearch.Search.ByExamTypes')}
                        title={t('AdvancedSearch.Search.ByExamTypes')}
                        isLoading={!isLoadedExamTypes}
                        isSearchable={true}
                        ref={ExamTypes => this.ExamTypes = ExamTypes}
                        options={this.state.ExamTypes}
                        filterOption={this.filterOptions}
                    />
                </div>
            </div>
            <div className={`scroll h-600px border-1 border-dark border-dashed table-responsive ${isLoaded ? "" : "table-loading"}`} >
                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                    <thead>
                        <tr>
                            <th title={t("Select")}>
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-square-check"></i>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Lab.Table.Name")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-vial me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Name")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Lab.Exams.Price.Title")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-circle-dollar me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Lab.Exams.Price.Title")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Lab.Table.Type")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-flask-vial me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Type")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Table.Options.Title")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-gears me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Table.Options.Title")}
                                    </span>
                                </span>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRow()}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        labExams: state.labExams,
        labExamTypes: state.labExamTypes,
        labExamPrice: state.labExamPrice
    };
};

export default connect(mapStateToProps, { fetchLabExams, fetchLabExamTypes, fetchLabExamPrices })(withTranslation('common')(SelectExam));