import serverAPI from "../../../../api/serverAPI";

export const fetchUsers = data => async dispatch => {
    dispatch({ type: 'USERS_LOADING' });
    const response = await serverAPI.get(`/Users/?Name=${data.Name ? data.Name : ""}&Email=${data.Email ? data.Email : ""}&Username=${data.Username ? data.Username : ""}&Phone=${data.Phone ? data.Phone : ""}&CenterId=${data.CenterId ? data.CenterId : "0"}`);
    dispatch({ type: 'USERS_INITIAL', users: response.data.data });
}

export const updateUser = (user, id) => async dispatch => {
    dispatch({ type: 'USERS_UPDATE', id: id, user: user });
}

export const removeUser = (id) => async dispatch => {
    dispatch({ type: 'USERS_REMOVE', id });
}

export const changeLockedStatus = (id) => async dispatch => {
    dispatch({ type: 'USERS_CHANGE_LOCKED_STATUS', id });
}

export const fetchUserTypes = () => async dispatch => {
    dispatch({ type: 'USER_TYPES_LOADING' });
    const response = await serverAPI.get(`/UserTypes/`);
    dispatch({ type: 'USER_TYPES_INITIAL', userTypes: response.data.data });
}

export const fetchUserClinics = () => async dispatch => {
    dispatch({ type: 'USER_CLINICS_LOADING' });
    const response = await serverAPI.get(`/UserClinics/`);
    dispatch({ type: 'USER_CLINICS_INITIAL', userClinics: response.data.data });
}