const CurrenciesReducers = (state = [], action) => {
    switch (action.type) {
        case "CURRENCIES_LOADING":
            return {
                currencies: [],
                isLoaded: false
            };
        case "CURRENCIES_INITIAL":
            return {
                currencies: action.currencies,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default CurrenciesReducers;