import serverAPI from "../../../../api/serverAPI";

export const fetchLabSpeciments = (startDate, endDate) => async dispatch => {
    dispatch({ type: 'LAB_ORDERS_SPECIMENTS_LOADING' });
    const response = await serverAPI.get(`/LabSpecimenTaken/?startDate=${startDate}&endDate=${endDate}`);
    dispatch({ type: 'LAB_ORDERS_SPECIMENTS_INITIAL', labSpeciments: response.data.data });
}

export const fetchLabExamResults = Id => async dispatch => {
    dispatch({ type: 'LAB_EXAMS_RESULTS_LOADING' });
    const response = await serverAPI.get(`/LabExamResult/${Id}`);
    dispatch({ type: 'LAB_EXAMS_RESULTS_INITIAL', labExamResults: response.data.data });
}