import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

import Form from "./Form";
import PatientName from "../../../Patients/PatientName";

class NewOrder extends React.Component {

    render() {
        const { t, Appointment, isEdit } = this.props;
        return <Modal
            size="xl"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-microscope me-2 text-primary "></i>
                    {isEdit ? t("ClinicScreen.Orders.Edit") : t("ClinicScreen.Orders.New")} &nbsp;
                    (
                    {Appointment && Appointment.patient && Appointment.patient.Name ? <PatientName patient={Appointment.patient} /> : ""}
                    )
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form {...this.props} />
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(NewOrder);