import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { fetchVitalSigns as fetch } from './actions';
import moment from "moment";
import New from "./New";
import Info from "./Modal/Info";
import Delete from "./Modal/Delete";
import serverAPI from "../../../api/serverAPI";
import { toast } from "react-toastify";

class VitalSigns extends React.Component {

    state = {
        showCreateNew: false,
        showDelete: false,
        showInfo: false,
        showEdit: false,
        CurrentVitalSigns: null
    };

    componentDidMount() {
        const { vitalSigns } = this.props;
        if (!vitalSigns || !vitalSigns.vitalSigns || vitalSigns.vitalSigns?.length === 0 || vitalSigns.PatientId !== this.props.Appointment.patient?.Id) {
            this.update();
        }
    }

    showEdit = CurrentVitalSigns => {
        this.setState({
            showEdit: true,
            CurrentVitalSigns
        });
    }

    showInfo = CurrentVitalSigns => {
        this.setState({
            showInfo: true,
            CurrentVitalSigns
        });
    }

    showDelete = CurrentVitalSigns => {
        this.setState({
            showDelete: true,
            CurrentVitalSigns
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false,
            showDelete: false,
            showInfo: false,
            showEdit: false,
            CurrentVitalSigns: null
        });
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    Delete = () => {
        const { t, Appointment } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/VitalSigns/${this.state.CurrentVitalSigns.Id}?VisitId=${Appointment.Id}&PatientId=${Appointment.patient?.Id}&ClinicId=${Appointment.Clinic.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.update();
            this.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    update = () => {
        this.props.fetch(this.props.Appointment.Id, this.props.Appointment.patient?.Id);
    }

    renderTableRow = () => {
        const { t, vitalSigns, showCreate } = this.props;
        if (vitalSigns && vitalSigns.vitalSigns && vitalSigns.vitalSigns.length > 0) {
            return vitalSigns.vitalSigns.map((item, index) => {
                return <tr key={`Vital-signs-${index}-${item.Id}`}>
                    <td>
                        {
                            item.Pain === 5 ?
                                <i title={t("VitalSigns.Pain.Status.sad")} className="fa-solid fa-face-sad-tear me-1 text-danger"></i>
                                : item.Pain === 4 ?
                                    <i title={t("VitalSigns.Pain.Status.angry")} className="fa-solid fa-face-angry me-1 text-warning"></i>
                                    : item.Pain === 3 ?
                                        <i title={t("VitalSigns.Pain.Status.meh")} className="fa-solid fa-face-meh me-1 text-dark"></i>
                                        : item.Pain === 2 ?
                                            <i title={t("VitalSigns.Pain.Status.smile")} className="fa-solid fa-face-smile me-1 text-info"></i>
                                            :
                                            <i title={t("VitalSigns.Pain.Status.happy")} className="fa-solid fa-face-smile-beam me-1 text-success"></i>
                        }
                        <span title={item.CreationDate}>
                            {moment(item.CreationDate, "DD/MM/YYYY HH:mm").format("HH:mm")}
                        </span>
                    </td>
                    <td>
                        <span>
                            <span title={t("VitalSigns.Pressure.Upper")}>
                                {item.PressureUpper}
                            </span>
                            <i className="fa-solid fa-slash-forward text-dark mx-1"></i>
                            <span title={t("VitalSigns.Pressure.Lower")}>
                                {item.PressureLower}
                            </span>
                            {
                                item.PressureFromLeftHand ?
                                    <>
                                        <i className="fa-solid fa-hand fa-flip-horizontal ms-1 text-primary" title={t("VitalSigns.Pressure.Source.Left")} ></i>
                                    </> :
                                    <>
                                        <i className="fa-solid fa-hand ms-1 text-primary" title={t("VitalSigns.Pressure.Source.Right")}></i>
                                    </>
                            }
                        </span>

                    </td>
                    <td>
                        {item.Temperature}
                    </td>
                    <td>
                        {item.Pulse}
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    {
                                        showCreate ? <>
                                            <Link to="#"
                                                className="dropdown-item"
                                                onClick={() => this.showEdit(item)}>
                                                <i className="fa-regular fa-edit text-dark me-1"></i>
                                                {t("Table.Options.Edit")}
                                            </Link>
                                            <Link to="#"
                                                className="dropdown-item"
                                                onClick={() => this.showDelete(item)}>
                                                <i className="fa-regular fa-trash text-dark me-1"></i>
                                                {t("Table.Options.Delete")}
                                            </Link>
                                        </> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={5}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, vitalSigns, showCreate } = this.props;

        let isLoaded = false;
        if (vitalSigns && vitalSigns.vitalSigns) {
            isLoaded = vitalSigns.isLoaded;
        }

        return <>
            {
                this.state.showCreateNew ?
                    <New
                        {...this.props}
                        update={this.update}
                        handleClose={this.handleClose}
                    />
                    : ""
            }
            {
                this.state.showEdit && this.state.CurrentVitalSigns ?
                    <New
                        {...this.props}
                        isEdit
                        VitalSigns={this.state.CurrentVitalSigns}
                        update={this.update}
                        handleClose={this.handleClose}
                    />
                    : ""
            }
            {
                this.state.showInfo && this.state.CurrentVitalSigns ?
                    <Info
                        handleClose={this.handleClose}
                        VitalSigns={this.state.CurrentVitalSigns}
                    />
                    : ""
            }
            {
                this.state.showDelete && this.state.CurrentVitalSigns ?
                    <Delete
                        Delete={this.Delete}
                        handleClose={this.handleClose}
                        VitalSigns={this.state.CurrentVitalSigns}
                    />
                    : ""
            }
            {
                showCreate ?
                    <div className="d-flex w-100">
                        <Link
                            to="#"
                            onClick={this.showCreateNew}
                            title={t("VitalSigns.New")}
                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                            <i className="fa-regular fa-plus"></i>
                            <span className="d-md-block d-none text-nowrap ms-1">
                                {t("VitalSigns.New")}
                            </span>
                        </Link>
                    </div> : ""
            }

            <div className="position-relative h-100">
                <div className={`table-responsive-md h-100 ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr>
                                <th title={t("VitalSigns.Time")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("VitalSigns.Time")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("VitalSigns.Pressure.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-stethoscope me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("VitalSigns.Pressure.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("VitalSigns.Temperature.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-thermometer me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("VitalSigns.Temperature.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("VitalSigns.Pulse.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-heart-pulse me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("VitalSigns.Pulse.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        vitalSigns: state.vitalSigns
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(VitalSigns));