import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";

import serverAPI from "../../../api/serverAPI";

class Info extends React.Component {

    state = { isLoaded: false, balances: [] };

    componentDidMount() {
        const { Account } = this.props;

        serverAPI.get(`/AccountBalances/${Account.Id}`).then(response => {
            this.setState({
                balances: response.data.data,
                isLoaded: true
            });
        }).catch(error => {
            this.setState({
                isLoaded: true
            });
        });
    }

    render() {
        const { t, Account } = this.props;

        return <>
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-scale-balanced me-2 text-primary "></i>
                        {t("Accounting.Balances.Title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=" mb-5" title={t("NumberAuto")}>
                        <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Account.Id}</span>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-text me-2"></i>
                                    {t("Accounting.Account.Name")}
                                </span>
                                <span className="ms-10">
                                    {Account.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-tally me-2"></i>
                                    {t("Accounting.Account.Number")}
                                </span>
                                <span className="ms-10">
                                    {Account.Number}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-unbalanced-flip me-2"></i>
                                    {t("Accounting.Debited")}
                                </span>
                                <span className="ms-10">
                                    {Account.Debited}
                                    <span className="ms-1">
                                        {Account.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-unbalanced me-2"></i>
                                    {t("Accounting.Credited")}
                                </span>
                                <span className="ms-10">
                                    {Account.Credited}
                                    <span className="ms-1">
                                        {Account.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-balanced me-2"></i>
                                    {t("Accounting.Balance")}
                                </span>
                                <span className="ms-10">
                                    {Account.Value}
                                    <span className="ms-1">
                                        {Account.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="position-relative">
                                <div className={`table-responsive-md ${this.state.isLoaded ? "" : "table-loading"}`}  >
                                    <div className="table-loading-message">
                                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                                        {t("Processing.Title")}
                                    </div>
                                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head border border-gray-900 border-dashed">
                                        <thead>
                                            <tr className="fw-bold fs-6 text-gray-800">
                                                <th title={t("Accounting.Balances.CreationDate")}>
                                                    <span className="d-flex align-items-center">
                                                        <i className="fa-regular text-dark fa-calender me-1"></i>
                                                        <span className="d-md-block d-none text-nowrap">
                                                            {t("Accounting.Balances.CreationDate")}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th title={t("Accounting.Debited")}>
                                                    <span className="d-flex align-items-center">
                                                        <i className="fa-regular text-dark fa-scale-unbalanced-flip me-1"></i>
                                                        <span className="d-md-block d-none text-nowrap">
                                                            {t("Accounting.Debited")}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th title={t("Accounting.Credited")}>
                                                    <span className="d-flex align-items-center">
                                                        <i className="fa-regular text-dark fa-scale-unbalanced me-1"></i>
                                                        <span className="d-md-block d-none text-nowrap">
                                                            {t("Accounting.Credited")}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th title={t("Accounting.Balance")}>
                                                    <span className="d-flex align-items-center">
                                                        <i className="fa-regular text-dark fa-balanced me-1"></i>
                                                        <span className="d-md-block d-none text-nowrap">
                                                            {t("Accounting.Balance")}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th title={t("Accounting.Balances.UpdateDate")}>
                                                    <span className="d-flex align-items-center">
                                                        <i className="fa-regular text-dark fa-calender me-1"></i>
                                                        <span className="d-md-block d-none text-nowrap">
                                                            {t("Accounting.Balances.UpdateDate")}
                                                        </span>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.balances.map(item => {
                                                    return <tr key={`account-balance-${item.Id}`}>
                                                        <td>
                                                            {item.CreationDate}
                                                        </td>
                                                        <td>
                                                            {item.Debited}
                                                            <span className="ms-1">
                                                                {item.Currency?.Name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {item.Credited}
                                                            <span className="ms-1">
                                                                {item.Currency?.Name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {item.Value}
                                                            <span className="ms-1">
                                                                {item.Currency?.Name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {item.UpdateDate}
                                                        </td>
                                                    </tr>
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));