import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import moment from "moment";

import Table from "./Table";
import Form from "./Form";
import { fetchPatientBills } from "./actions";
import NewReceiptVoucher from "../Vouchers/ReceiptVouchers/New";

class ReceiveCash extends React.Component {

    state = {
        searchKey: {
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY')
        },
        showReceiptVouchers: false,
        CashValue: 0,
        Account: null,
        Currency: null
    };

    update = searchKey => {
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchPatientBills(searchKey);
        } else {
            this.props.fetchPatientBills(this.state.searchKey);
        }
    }

    handleClose = () => {
        this.setState({
            showReceiptVouchers: false,
            Account: null,
            Currency: null,
            CashValue: 0
        });
    }

    showReceiptVouchers = (Account, CashValue, Currency) => {
        this.setState({
            showReceiptVouchers: true,
            Account,
            CashValue,
            Currency
        });
    }

    render() {
        const { t, patientBills } = this.props;

        let isLoaded = false;
        if (patientBills) {
            isLoaded = patientBills.isLoaded;
        }
        return (<>

            {this.state.showReceiptVouchers && this.state.Account ? <NewReceiptVoucher Account={this.state.Account} Currency={this.state.Currency} CashValue={this.state.CashValue} handleClose={this.handleClose} /> : ""}
            <Helmet>
                <title>{t("Accounting.ReceiveCash.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form fetchOrders={this.update} />
                    </div>
                </div>
            </div>
            {
                isLoaded ? <div className="card">
                    <div className="card-header align-items-center ">
                        <h3 className="card-title">
                            <span className="fw-bolder mb-2 text-dark">
                                <i className="h3 fa-duotone fa-box-dollar me-2 text-info mb-0"></i>
                                {t('Accounting.ReceiveCash.Title')}
                            </span>
                        </h3>
                    </div>
                    <div className="card-body pt-5 px-2">
                        <div className="w-100">
                            <Table showReceiptVouchers={this.showReceiptVouchers} update={this.update} />
                        </div>
                    </div>
                </div> : ""
            }

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        patientBills: state.patientBills
    };
};

export default connect(mapStateToProps, { fetchPatientBills })(withTranslation('common')(ReceiveCash));