const BloodGroupsReducers = (state = [], action) => {
    switch (action.type) {
        case "BLOOD_GROUPS_LOADING":
            return {
                bloodGroups: [],
                isLoaded: false
            };
        case "BLOOD_GROUPS_INITIAL":
            return {
                bloodGroups: action.bloodGroups,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default BloodGroupsReducers;