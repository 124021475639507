const RadiologyExamsReducers = (state = [], action) => {
    switch (action.type) {
        case "RADIOLOGY_EXAMS_LOADING":
            return {
                radiologyExams: [],
                isLoaded: false
            };
        case "RADIOLOGY_EXAMS_INITIAL":
            return {
                radiologyExams: action.radiologyExams,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default RadiologyExamsReducers;