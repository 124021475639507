
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getDwvVersion, App } from 'dwv';
import './DwvComponent.css';

class DMViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            versions: {
                dwv: getDwvVersion(),
                react: React.version
            },
            tools: {
                Scroll: {},
                ZoomAndPan: {},
                WindowLevel: {},
                Draw: {
                    options: ['Ruler']
                }
            },
            selectedTool: 'Select Tool',
            loadProgress: 0,
            dataLoaded: false,
            dwvApp: null,
            metaData: {},
            orientation: undefined,
            showDicomTags: false,
            dropboxDivId: 'dropBox',
            dropboxClassName: 'dropBox',
            borderClassName: 'dropBoxBorder',
            hoverClassName: 'hover'
        };
    }


    render() {
        return <>
            <div>
                <div className="btn btn-icon btn-danger" onClick={() => this.setTool("Scroll")}>
                    s
                </div>
                <div className="btn btn-icon btn-danger" onClick={() => this.setTool("ZoomAndPan")}>
                    z
                </div>
                <div className="btn btn-icon btn-danger" onClick={() => this.setTool("WindowLevel")}>
                    w
                </div>
                <div className="btn btn-icon btn-danger" onClick={() => this.setTool("Draw")}>
                    d
                </div>
            </div>
            <div id="dwv">

                <div id="layerGroup0" className="layerGroup">
                    <div id="dropBox"></div>
                </div>

                <div id="layerGroup1" className="layerGroup">
                    <div id="dropBox1"></div>
                </div>

            </div>
        </>;
    }

    setTool = selectedTool => {
        this.onChangeTool(selectedTool);
    }

    componentDidMount() {
        // create app
        const app = new App();
        // initialise app
        app.init({
            "dataViewConfigs": { '*': [{ divId: 'layerGroup0' }] },
            "tools": this.state.tools
        });

        // load events
        let nLoadItem = null;
        let nReceivedLoadError = null;
        let nReceivedLoadAbort = null;
        let isFirstRender = null;
        app.addEventListener('loadstart', (/*event*/) => {
            // reset flags
            nLoadItem = 0;
            nReceivedLoadError = 0;
            nReceivedLoadAbort = 0;
            isFirstRender = true;
            // hide drop box
            this.showDropbox(app, false);
        });
        app.addEventListener("loadprogress", (event) => {
            this.setState({ loadProgress: event.loaded });
        });
        app.addEventListener('renderend', (/*event*/) => {
            if (isFirstRender) {
                isFirstRender = false;
                // available tools
                let selectedTool = 'ZoomAndPan';
                if (app.canScroll()) {
                    selectedTool = 'Scroll';
                }
                selectedTool = 'Draw';
                this.onChangeTool(selectedTool);
            }
        });
        app.addEventListener("load", (event) => {
            // set dicom tags
            this.setState({ metaData: app.getMetaData(event.dataid) });
            // set data loaded flag
            this.setState({ dataLoaded: true });
        });
        app.addEventListener('loadend', (/*event*/) => {
            if (nReceivedLoadError) {
                this.setState({ loadProgress: 0 });
                alert('Received errors during load. Check log for details.');
                // show drop box if nothing has been loaded
                if (!nLoadItem) {
                    this.showDropbox(app, true);
                }
            }
            if (nReceivedLoadAbort) {
                this.setState({ loadProgress: 0 });
                alert('Load was aborted.');
                this.showDropbox(app, true);
            }
        });
        app.addEventListener('loaditem', (/*event*/) => {
            ++nLoadItem;
        });
        app.addEventListener('loaderror', (event) => {
            console.error(event.error);
            ++nReceivedLoadError;
        });
        app.addEventListener('loadabort', (/*event*/) => {
            ++nReceivedLoadAbort;
        });

        // handle key events
        app.addEventListener('keydown', (event) => {
            app.defaultOnKeydown(event);
        });
        // handle window resize
        window.addEventListener('resize', app.onResize);

        // store
        this.setState({ dwvApp: app });

        // setup drop box
        this.setupDropbox(app);

        // possible load from location
        app.loadFromUri(window.location.href);
    }

    showDropbox = (app, show) => {
        const box = document.getElementById(this.state.dropboxDivId);
        if (!box) {
            return;
        }
        const layerDiv = document.getElementById('layerGroup0');

        if (show) {
            // reset css class
            box.className = this.state.dropboxClassName + ' ' + this.state.borderClassName;
            // check content
            if (box.innerHTML === '') {
                const p = document.createElement('p');
                p.appendChild(document.createTextNode('Drag and drop data here or '));
                // input file
                const input = document.createElement('input');
                input.onchange = this.onInputFile;
                input.type = 'file';
                input.multiple = true;
                input.id = 'input-file';
                input.style.display = 'none';
                const label = document.createElement('label');
                label.htmlFor = 'input-file';
                const link = document.createElement('a');
                link.appendChild(document.createTextNode('click here'));
                link.id = 'input-file-link';
                label.appendChild(link);
                p.appendChild(input);
                p.appendChild(label);

                box.appendChild(p);
            }
            // show box
            box.setAttribute('style', 'display:initial');
            // stop layer listening
            if (layerDiv) {
                layerDiv.removeEventListener('dragover', this.defaultHandleDragEvent);
                layerDiv.removeEventListener('dragleave', this.defaultHandleDragEvent);
                layerDiv.removeEventListener('drop', this.onDrop);
            }
            // listen to box events
            box.addEventListener('dragover', this.onBoxDragOver);
            box.addEventListener('dragleave', this.onBoxDragLeave);
            box.addEventListener('drop', this.onDrop);
        } else {
            // remove border css class
            box.className = this.state.dropboxClassName;
            // remove content
            box.innerHTML = '';
            // hide box
            box.setAttribute('style', 'display:none');
            // stop box listening
            box.removeEventListener('dragover', this.onBoxDragOver);
            box.removeEventListener('dragleave', this.onBoxDragLeave);
            box.removeEventListener('drop', this.onDrop);
            // listen to layer events
            if (layerDiv) {
                layerDiv.addEventListener('dragover', this.defaultHandleDragEvent);
                layerDiv.addEventListener('dragleave', this.defaultHandleDragEvent);
                layerDiv.addEventListener('drop', this.onDrop);
            }
        }
    }

    onChangeTool = (tool) => {
        if (this.state.dwvApp) {
            this.setState({ selectedTool: tool });
            this.state.dwvApp.setTool(tool);
            if (tool === 'Draw') {
                this.onChangeShape(this.state.tools.Draw.options[0]);
            }
        }
    }

    canRunTool = (tool) => {
        let res;
        if (tool === 'Scroll') {
            res = this.state.dwvApp.canScroll();
        } else if (tool === 'WindowLevel') {
            res = this.state.dwvApp.canWindowLevel();
        } else {
            res = true;
        }
        return res;
    }

    toggleOrientation = () => {
        if (typeof this.state.orientation !== 'undefined') {
            if (this.state.orientation === 'axial') {
                this.state.orientation = 'coronal';
            } else if (this.state.orientation === 'coronal') {
                this.state.orientation = 'sagittal';
            } else if (this.state.orientation === 'sagittal') {
                this.state.orientation = 'axial';
            }
        } else {
            // default is most probably axial
            this.state.orientation = 'coronal';
        }
        // update data view config
        const config = {
            '*': [
                {
                    divId: 'layerGroup0',
                    orientation: this.state.orientation
                }
            ]
        };
        this.state.dwvApp.setDataViewConfigs(config);
        // render data
        const dataIds = this.state.dwvApp.getDataIds();
        for (const dataId of dataIds) {
            this.state.dwvApp.render(dataId);
        }
    }

    onChangeShape = (shape) => {
        if (this.state.dwvApp) {
            this.state.dwvApp.setToolFeatures({ shapeName: shape });
        }
    }

    onReset = () => {
        if (this.state.dwvApp) {
            this.state.dwvApp.resetDisplay();
        }
    }

    handleTagsDialogOpen = () => {
        this.setState({ showDicomTags: true });
    }

    handleTagsDialogClose = () => {
        this.setState({ showDicomTags: false });
    };

    setupDropbox = (app) => {
        this.showDropbox(app, true);
    }

    defaultHandleDragEvent = (event) => {
        // prevent default handling
        event.stopPropagation();
        event.preventDefault();
    }

    onBoxDragOver = (event) => {
        this.defaultHandleDragEvent(event);
        // update box border
        const box = document.getElementById(this.state.dropboxDivId);
        if (box && box.className.indexOf(this.state.hoverClassName) === -1) {
            box.className += ' ' + this.state.hoverClassName;
        }
    }

    onBoxDragLeave = (event) => {
        this.defaultHandleDragEvent(event);
        // update box class
        const box = document.getElementById(this.state.dropboxDivId);
        if (box && box.className.indexOf(this.state.hoverClassName) !== -1) {
            box.className = box.className.replace(' ' + this.state.hoverClassName, '');
        }
    }

    onDrop = (event) => {
        this.defaultHandleDragEvent(event);
        // load files
        this.state.dwvApp.loadFiles(event.dataTransfer.files);
    }

    onInputFile = (event) => {
        if (event.target && event.target.files) {
            this.state.dwvApp.loadFiles(event.target.files);
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(DMViewer));