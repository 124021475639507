import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Link } from "react-router-dom";

import serverAPI from "../../../../../api/serverAPI";
import New from "./Item";
import SelectCurrencies from "../../../../shared/SelectList/SelectCurrencies";

class Form extends React.Component {

    state = { items: [], Date: `${moment().format('DD/MM/YYYY')}`, isSubmit: false, Id: 0, Currency: null, showVoucherItem: false };


    componentDidMount() {
        this.props.initialize({
            Date: `${moment().format('DD/MM/YYYY')}`,
            Rate: "",
            CurrencyId: "",
        });
    }


    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.formValues.CurrencyId) {
            this.setState({
                isSubmit: true
            });

            serverAPI.post("/JournalVoucher/", {
                ...this.props.formValues,
                items: this.state.items
            }).then(response => {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
                this.props.update();
                this.props.handleClose();
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
            });

        } else {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.AllInformationIsRequired.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.AllInformationIsRequired.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        }
        return false;
    }

    setCurrency = Currency => {
        this.props.dispatch(change('formJournalVoucher', 'Rate', Currency.rate));
        this.props.dispatch(change('formJournalVoucher', 'CurrencyId', Currency.Id));
        this.setState({
            Currency
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            Date: picker.startDate.format('DD/MM/YYYY')
        });
        this.props.dispatch(change('formJournalVoucher', 'Date', picker.startDate.format('DD/MM/YYYY')));
    }

    showVoucherItem = () => {
        this.setState({
            showVoucherItem: true
        });
    }

    handleClose = () => {
        this.setState({
            showVoucherItem: false
        });
    }

    addItem = item => {
        let items = this.state.items.filter(p => p.account.Id !== item.account.Id);
        items.push(item);
        this.setState({
            showVoucherItem: false,
            items
        });
    }

    deleteItem = item => {
        let items = this.state.items.filter(p => p.account.Id !== item.account.Id);
        this.setState({
            showVoucherItem: false,
            items
        });
    }

    checkSubmit = () => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

        return false;
    }

    unBalanced = () => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.unBalanced.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.unBalanced.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

        return false;
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;

        let Debited = 0;
        let Credited = 0;
        return <>
            {this.state.showVoucherItem ? <New addItem={this.addItem} handleClose={this.handleClose} /> : ""}
            <form method="POST" name="formJournalVoucher" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0 || this.state.items.length === 0;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                const credited = this.state.items.reduce((a, { Credited }) => parseFloat(Credited) + a, 0);
                                const debited = this.state.items.reduce((a, { Debited }) => parseFloat(Debited) + a, 0);
                                if (credited === debited) {
                                    this.onSubmit();
                                } else {
                                    this.unBalanced();
                                }
                            }
                        })
                })
            } >
                <div className="row mb-5">

                    <div className="col-md-4">
                        <label className={`col-form-label required fw-bold fs-6`} ><i className="fa-regular text-dark fa-calendar me-2"></i>{t('Accounting.JournalVoucher.Forms.Date.Title')}</label>
                        <DateRangePicker
                            ref={SelectDate => this.SelectDate = SelectDate}
                            initialSettings={{
                                singleDatePicker: true,
                                opens: 'center',
                                autoUpdateInput: true,
                                buttonClasses: 'btn',
                                cancelClass: "btn-danger",
                                applyButtonClasses: "btn-primary",
                                showDropdowns: true,
                                format: 'DD/MM/YYYY',
                                timePicker: false,
                                maxDate: `${moment().format('DD/MM/YYYY')}`,
                                locale: {
                                    format: 'DD/MM/YYYY',
                                    cancelLabel: t('Close'),
                                    firstDay: 6,
                                    applyLabel: t('Apply'),
                                    customRangeLabel: t('DateRangePicker.Range.customRange'),
                                    monthNames: [
                                        `${t("DateRangePicker.Months.January")}`,
                                        `${t("DateRangePicker.Months.February")}`,
                                        `${t("DateRangePicker.Months.March")}`,
                                        `${t("DateRangePicker.Months.April")}`,
                                        `${t("DateRangePicker.Months.May")}`,
                                        `${t("DateRangePicker.Months.June")}`,
                                        `${t("DateRangePicker.Months.July")}`,
                                        `${t("DateRangePicker.Months.August")}`,
                                        `${t("DateRangePicker.Months.September")}`,
                                        `${t("DateRangePicker.Months.October")}`,
                                        `${t("DateRangePicker.Months.November")}`,
                                        `${t("DateRangePicker.Months.December")}`
                                    ],
                                    daysOfWeek: [
                                        t("DateRangePicker.DaysOfWeek.Sunday"),
                                        t("DateRangePicker.DaysOfWeek.Monday"),
                                        t("DateRangePicker.DaysOfWeek.Tuesday"),
                                        t("DateRangePicker.DaysOfWeek.Wednesday"),
                                        t("DateRangePicker.DaysOfWeek.Thursday"),
                                        t("DateRangePicker.DaysOfWeek.Friday"),
                                        t("DateRangePicker.DaysOfWeek.Saturday")
                                    ],
                                },
                            }}
                            onApply={this.handleApplyDate} >
                            <input
                                defaultValue={this.state.Date}
                                ref={Date => this.Date = Date}
                                name="Date"
                                id="Date"
                                autoComplete="off"
                                required
                                className="form-control ps-10"
                                type="text"
                                placeholder={t('Accounting.JournalVoucher.Forms.Date.Placeholder')}
                                title={t('Accounting.JournalVoucher.Forms.Date.Title')}
                            />
                        </DateRangePicker>

                    </div>

                    <div className={`col-md-4`}>
                        <SelectCurrencies required onChange={this.setCurrency} />
                    </div>

                    <div className="col-md-4">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Rate"><i className="fa-regular text-dark fa-percent me-2"></i>{t('Currencies.Forms.Rate.Title')}</label>
                        <Field
                            ref={Rate => this.Rate = Rate}
                            name="Rate"
                            id="Rate"
                            component={this.renderField}
                            type={"number"}
                            required
                            disabled={this.state.Currency && this.state.Currency.Id === 1 ? true : false}
                            placeholder={t('Currencies.Forms.Rate.Placeholder')}
                            title={t('Currencies.Forms.Rate.Title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row mt-10">
                    <div className="col-md-12">
                        <div className="w-100 d-flex align-items-center mb-5">
                            <h4 className="card-title mb-0">
                                <span className="fw-bolder mb-0 text-dark">
                                    {t('Accounting.JournalVoucher.Items.Title')}
                                </span>
                            </h4>
                            <div className="ms-auto">
                                <Link
                                    to="#"
                                    title={t('Accounting.JournalVoucher.Items.New')}
                                    className="btn btn-sm btn-sm-icon btn-light-info "
                                    onClick={() => this.showVoucherItem()}>
                                    <i className="me-1 fa-solid fa-plus"></i>
                                    {t('Accounting.JournalVoucher.Items.New')}
                                </Link>
                            </div>
                        </div>

                        <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head border border-gray-900 border-dashed">
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800">
                                    <th title={t("Accounting.Account.Info")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-file-user me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Accounting.Account.Info")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Accounting.Debited")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-scale-unbalanced-flip me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Accounting.Debited")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Accounting.Credited")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-scale-unbalanced me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Accounting.Credited")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Details")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-note me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Details")}
                                            </span>
                                        </span>
                                    </th>
                                    <th title={t("Table.Options.Title")}>
                                        <span className="d-flex align-items-center">
                                            <i className="fa-regular text-dark fa-gears me-1"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("Table.Options.Title")}
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.items.sort(function (a, b) {
                                        if (a.Credited === b.Credited) {
                                            return a.account.Number.localeCompare(b.account.Number);
                                        }
                                        return a.Credited - b.Credited;
                                    }).map((item, index) => {
                                        Debited += parseFloat(item.Debited);
                                        Credited += parseFloat(item.Credited);
                                        return <tr key={`account-journal-vouchers-${index}`}>
                                            <td>{item.account.Number + " - " + item.account.Name}</td>
                                            <td>{item.Debited}</td>
                                            <td>{item.Credited}</td>
                                            <td>{item.Note}</td>
                                            <td>
                                                <Link
                                                    to="#"
                                                    title={t("Remove")}
                                                    onClick={() => this.deleteItem(item)}
                                                    className="btn btn-sm btn-icon btn-light-danger"  >
                                                    <i className="fa-solid fa-trash"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{t("Sum")}</td>
                                    <td>{Debited}</td>
                                    <td>{Credited}</td>
                                    <td colSpan="2"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Note"><i className="fa-regular text-dark fa-note me-2"></i>{t('Accounting.JournalVoucher.Forms.Note.Title')}</label>
                        <Field
                            ref={Note => this.Note = Note}
                            name="Note"
                            id="Note"
                            rows={4}
                            component="textarea"
                            placeholder={t('Accounting.JournalVoucher.Forms.Note.Placeholder')}
                            title={t('Accounting.JournalVoucher.Forms.Note.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Date) {
        errors.Date = 'Accounting.JournalVoucher.Forms.Date.Error';
    }
    if (!values.Rate || values.Rate <= 0) {
        errors.Rate = 'Currencies.Forms.Rate.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formJournalVoucher')(state),
        validate,
        user: state.user
    };
};

const formJournalVoucher = reduxForm({
    form: 'formJournalVoucher',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formJournalVoucher(Form)));
