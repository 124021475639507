import serverAPI from "../../../api/serverAPI";

export const fetchVisitHistory = (PatientId, ClinicId, Take = 10) => async dispatch => {
    dispatch({ type: 'VISIT_HISTORY_LOADING' });
    const response = await serverAPI.get(`/PatientLastVisitToClinic/?PatientId=${PatientId}&ClinicId=${ClinicId}&Take=${Take}`);
    dispatch({ type: 'VISIT_HISTORY_INITIAL', visitHistory: response.data.data, PatientId, ClinicId });
}

export const fetchAppointments = (ClinicId, Date) => async dispatch => {
    dispatch({ type: 'APPOINTMENT_LOADING' });
    const response = await serverAPI.get(`/ClinicRegistration/${ClinicId}?Date=${Date}`);
    dispatch({ type: 'APPOINTMENT_INITIAL', appointments: response.data.data });
}