import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from 'react-select';

import SelectField from "../shared/SelectField";
import NewAppointment from "./New";
import EditAppointment from "./Edit";
import Info from "./Modal/Info";
import Accept from "./Modal/Accept";
import serverAPI from "../../api/serverAPI";
import { toast } from "react-toastify";
import Cancel from "./Modal/Cancel";
import ChangeVisitType from "./Modal/ChangeVisitType";
import Delete from "./Modal/Delete";
import History from "./Modal/History";
import Widget from "./Statistics/Widget.js";

import { fetchVisitHistory, fetchAppointments } from './actions';
import { fetchVisitStatus } from "../../actions";
import Card from "../Manager/Clinics/Card.js";
import PatientName from "../Patients/PatientName.js";
import { fetchClinics } from "../Manager/Clinics/actions/index.js";

class Appointment extends React.Component {

    state = {
        currentDay: 0,
        currentClinic: null,
        SearchByName: "",
        searchVisitStatus: [],
        clinics: [],
        visitStatus: [],
        VisitType: [],
        appointmentVisitStatus: [],
        showEdit: false,
        showDelete: false,
        showAccept: false,
        showCancel: false,
        showNewAppointment: false,
        showAppointmentInfo: false,
        showChangeVisitType: false
    };

    constructor(props) {
        super(props);
        const { visitStatus } = this.props;
        if (!visitStatus || !visitStatus.visitStatus || visitStatus.visitStatus?.length === 0) {
            this.props.fetchVisitStatus();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.clinics.length === 0 && props.clinics && props.clinics.clinics) {
            state.clinics = [];
            props.clinics.clinics.forEach(item => {
                state.clinics.push({
                    ...item,
                    id: item.Id,
                    label: item.Name
                });
            });
            if (props.clinics.clinics.length === 1) {
                state.currentClinic = props.clinics.clinics[0];
                const Date = `${moment().format('DD/MM/YYYY')}`;
                props.fetchAppointments(state.currentClinic.Id, Date);
            }
        }

        let appointmentVisitStatus = []
        let VisitType = [];
        if (props.appointments && props.appointments.appointments) {
            props.appointments.appointments.forEach(item => {
                appointmentVisitStatus.push(item.VisitStatu);
                if (item.IsNew === true || item.IsNew === false) {
                    VisitType.push(item.IsNew);
                }
            });
        }
        state.VisitType = VisitType;
        state.appointmentVisitStatus = appointmentVisitStatus;

        if (props.visitStatus && props.visitStatus.visitStatus) {
            state.visitStatus = props.visitStatus.visitStatus.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> <span className={`badge badge-square badge-${item.Color} badge-sm me-1 p-2`}>{props.t(`Appointment.VisitStatus.${item.Name}`)}</span></div>,
                };
            });
        }

        return state;
    }

    componentDidMount() {
        this.props.fetchClinics();
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_content_inner").classList.add("w-100");
        document.getElementById("kt_content_inner").classList.add("mx-5");
        document.getElementById("kt_content_inner").classList.remove("container-xxl");
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_content_inner").classList.add("container-xxl");
        document.getElementById("kt_content_inner").classList.remove("mx-5");
        document.getElementById("kt_content_inner").classList.remove("w-100");
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    setDay = currentDay => {
        this.setState({
            currentDay
        }, () => { this.getAppointment() });
    }

    renderWeekDayLink = () => {
        return [1, 2, 3, 4, 5, 6, 7].map(item => {
            return <Link
                to="#"
                className={`nav-link text-active-gray-800 me-4 ${this.state.currentDay === item ? "active" : ""}`}
                key={`day-link-${item}`}
                onClick={() => this.setDay(item)}>
                {moment().add(item, 'day').locale('ar-KW').format('dddd')}
            </Link>
        });
    }

    updateClinic = currentClinic => {
        this.setState({
            currentClinic,
            SearchByName: ""
        }, () => { this.getAppointment() });
    }

    getAppointment = () => {
        const Date = `${moment().add(this.state.currentDay, 'day').format('DD/MM/YYYY')}`;
        this.props.fetchAppointments(this.state.currentClinic.Id, Date);
    }

    showAccept = Appointment => {
        this.setState({
            showAccept: true,
            Appointment
        });
    }

    hideAccept = () => {
        this.setState({
            showAccept: false,
            Appointment: null
        });
    }

    Accept = (ClinicId, PatientId, VisitId, IsNew) => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/AcceptPatientToClinic/`, {
            IsNew,
            PatientId,
            VisitId,
            ClinicId
        }).then(response => {

            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Accept.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Accept.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.getAppointment();
            this.hideAccept();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    showEdit = Appointment => {
        this.setState({
            showEdit: true,
            Appointment
        });
    }

    hideEdit = () => {
        this.setState({
            showEdit: false,
            Appointment: null
        });
    }

    showChangeVisitType = Appointment => {
        this.setState({
            showChangeVisitType: true,
            Appointment
        });
    }

    hideChangeVisitType = () => {
        this.setState({
            showChangeVisitType: false,
            Appointment: null
        });
    }

    ChangeVisitType = (ClinicId, PatientId, VisitId, IsNew) => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.put(`/ClinicRegistration/${VisitId}`, {
            PatientId,
            ClinicId,
            IsNew
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Edit.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Edit.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.getAppointment();
            this.hideChangeVisitType();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    showCancel = Appointment => {
        this.setState({
            showCancel: true,
            Appointment
        });
    }

    hideCancel = () => {
        this.setState({
            showCancel: false,
            Appointment: null
        });
    }

    Cancel = (ClinicId, PatientId, VisitId) => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/CancelPatientAppointment/`, {
            PatientId,
            VisitId,
            ClinicId
        }).then(response => {

            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Cancel.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Cancel.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.getAppointment();
            this.hideCancel();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Cancel.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Cancel.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    showDelete = Appointment => {
        this.setState({
            showDelete: true,
            Appointment
        });
    }

    hideDelete = () => {
        this.setState({
            showDelete: false,
            Appointment: null
        });
    }

    Delete = (ClinicId, PatientId, VisitId) => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/ClinicRegistration/${VisitId}?PatientId=${PatientId}&ClinicId=${ClinicId}`).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.getAppointment();
            this.hideDelete();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    showNewAppointment = () => {
        this.setState({
            showNewAppointment: true
        });
    }

    hideNewAppointment = () => {
        this.setState({
            showNewAppointment: false
        });
    }

    showAppointmentInfo = Appointment => {
        this.setState({
            showAppointmentInfo: true,
            Appointment
        });
    }

    hideAppointmentInfo = () => {
        this.setState({
            showAppointmentInfo: false,
            Appointment: null
        });
    }

    showHistoryModal = Appointment => {
        if (Appointment.patient &&
            this.state.currentClinic &&
            (!this.props.visitHistory || this.props.visitHistory.PatientId !== Appointment.patient.Id || this.props.visitHistory.ClinicId !== this.state.currentClinic.Id)) {
            this.props.fetchVisitHistory(Appointment.patient.Id, this.state.currentClinic.Id);
        }
        this.setState({
            showHistoryModal: true,
            Appointment
        });
    }

    hideHistoryModal = () => {
        this.setState({
            showHistoryModal: false
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    renderVisitStatus = item => {
        if (item) {
            const { t } = this.props;
            return <span className={`badge badge-square badge-${item.Color} badge-sm me-1 p-2`}>{t(`Appointment.VisitStatus.${item.Name}`)}</span>;
        }
        return "";
    }

    filterAppointmentByName = (arrayToSearch, key) => {
        let searchResult = [];
        const subResult = arrayToSearch.filter(p =>
            p.patient.Name.FirstName.includes(key) ||
            p.patient.Name.SecondName.includes(key) ||
            p.patient.Name.ThirdName.includes(key) ||
            p.patient.Name.LastName.includes(key)
        );
        subResult.forEach(result => {
            if (!searchResult.some(p => p.Id === result.Id)) {
                searchResult.push(result);
            }
        });
        return searchResult;
    }

    setVisitStatus = searchVisitStatus => {
        this.setState({
            searchVisitStatus
        });
    }

    renderTableRow = () => {
        const { t, appointments } = this.props;
        if (appointments && appointments.appointments && appointments.appointments.length > 0) {
            const searchArray = this.state.SearchByName.split(' ');

            let searchResult = appointments.appointments;
            searchArray.forEach(item => {
                searchResult = this.filterAppointmentByName(searchResult, item);
            });


            if (this.state.searchVisitStatus?.length > 0) {
                searchResult = searchResult.filter(p => {
                    return this.state.searchVisitStatus.filter(c => c.value === p.VisitStatu.Id).length > 0
                });
            }

            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`appointments-${index}-${item.Id}`}>
                        <td>{index + 1}</td>
                        <td>
                            <PatientName patient={item.patient} />
                        </td>
                        <td>
                            <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{item.patient.Id}</span>
                            <span className="text-primary">{item.patient.DocumentNumber}</span>
                        </td>
                        <td>
                            {item.patient.Phone}
                        </td>
                        <td>
                            {
                                item.IsNew !== null ?
                                    item.IsNew ?
                                        <span className="badge badge-pill badge-primary badge-sm">{t("Appointment.Type.New")}</span>
                                        :
                                        <span className="badge badge-pill badge-warning badge-sm">{t("Appointment.Type.Review")}</span>
                                    : ""
                            }
                        </td>
                        <td>
                            {this.renderVisitStatus(item.VisitStatu)}
                        </td>
                        <td>
                            <div className="dropdown " role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        {
                                            item.VisitStatu.Id === 4 && item.IsNew === null && this.state.currentDay === 0 ?
                                                <Link to="#"
                                                    className="dropdown-item"
                                                    onClick={() => this.showAccept(item)}>
                                                    <i className="fa-regular fa-user-doctor-message text-dark me-1"></i>
                                                    {t("Appointment.Accept")}
                                                </Link> : ""
                                        }
                                        <Link to="#"
                                            onClick={() => this.showAppointmentInfo(item)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-id-card-clip text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                        {
                                            item.VisitStatu.Id === 4 ?
                                                <>
                                                    <Link to="#"
                                                        onClick={() => this.showEdit(item)}
                                                        className="dropdown-item"  >
                                                        <i className="fa-regular fa-calendar-lines-pen text-dark me-1"></i>
                                                        {t('Appointment.Edit')}
                                                    </Link>
                                                </>
                                                : ""
                                        }
                                        {
                                            item.IsNew !== null && item.VisitStatu.Id === 1 ?
                                                <>
                                                    <Link to="#"
                                                        onClick={() => this.showChangeVisitType(item)}
                                                        className="dropdown-item">
                                                        <i className="fa-regular fa-coin text-dark me-1"></i>
                                                        {t("Table.Options.Edit")}&nbsp;{t("Appointment.Type.Title")}
                                                    </Link>
                                                </>
                                                : ""
                                        }
                                        {
                                            item.VisitStatu.Id === 4 ?
                                                <>
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => this.showCancel(item)} >
                                                        <i className="fa-regular fa-ban text-dark me-1"></i>
                                                        {t("Appointment.Cancel")}
                                                    </Link>
                                                </>
                                                : ""
                                        }
                                        <Link to="#"
                                            onClick={() => this.showHistoryModal(item)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-rectangle-history-circle-user text-dark me-1"></i>
                                            {t('Appointment.History.Title')}
                                        </Link>
                                        {
                                            item.VisitStatu.Id === 1 || item.VisitStatu.Id === 4 || item.VisitStatu.Id === 5 ?
                                                <>
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => this.showDelete(item)}>
                                                        <i className="fa-regular fa-trash text-dark me-1"></i>
                                                        {t("Table.Options.Delete")}
                                                    </Link>
                                                </>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>;
                });
            }
            return <tr><td className="text-center" colSpan={7}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={7}>{t("NoRows")}</td></tr>;
    }

    renderClinic = () => {
        const { t } = this.props;
        const searchResult = this.state.clinics.filter(p =>
            p.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()) ||
            p.DoctorName.toLowerCase().includes(this.state.SearchByName.toLowerCase()));

        return searchResult.map((clinic, key) => {
            return <div key={`clinic_${clinic.Id}_${key}`} className="timeline-item">
                <div className="timeline-line w-40px"></div>
                <div className="timeline-icon symbol symbol-circle symbol-40px me-4" >
                    <div className="symbol-label bg-light ">
                        <i className="fas fa-house-medical-flag text-primary"></i>
                    </div>
                </div>
                <div className="timeline-content mb-0 mt-n1">
                    <div className="pe-3 my-3 d-flex justify-content-between" >
                        <div>
                            <div>
                                {clinic.Name}
                            </div>
                            {
                                clinic.DoctorName ? <div className="ms-10 fs-8 mt-2">
                                    <i className="fas fa-user-doctor text-primary me-2"></i>
                                    {clinic.DoctorName}
                                </div> : ""
                            }
                        </div>
                        <div>
                            <div className="btn btn-icon btn-light btn-active-light-info me-1" title={t('Table.Options.Select')} onClick={() => this.updateClinic(clinic)} >
                                <i className="fa-solid fa-check "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    render() {
        const { t, appointments, visitStatus } = this.props;

        let isLoaded = false;
        if (appointments) {
            isLoaded = appointments.isLoaded;
        }

        let isLoadedVisitStatus = false;
        if (visitStatus) {
            isLoadedVisitStatus = visitStatus.isLoaded;
        }

        return (<>
            <Helmet>
                <title>{t("Appointment.Title")} | {t('SiteName')}</title>
            </Helmet>
            {
                this.state.showNewAppointment ?
                    <NewAppointment
                        item={{
                            Date: moment().add(this.state.currentDay, 'day').format('DD/MM/YYYY')
                        }}
                        update={this.getAppointment}
                        currentClinic={this.state.currentClinic}
                        handleClose={this.hideNewAppointment} />
                    : ""
            }
            {
                this.state.showEdit && this.state.Appointment ?
                    <EditAppointment
                        update={this.getAppointment}
                        VisitId={this.state.Appointment.Id}
                        Appointment={this.state.Appointment}
                        Patient={this.state.Appointment.patient}
                        currentClinic={this.state.currentClinic}
                        handleClose={this.hideEdit}
                    />
                    : ""
            }
            {
                this.state.showAppointmentInfo && this.state.Appointment ?
                    <Info
                        currentClinic={this.state.currentClinic}
                        Appointment={this.state.Appointment}
                        handleClose={this.hideAppointmentInfo} />
                    : ""
            }
            {
                this.state.showAccept && this.state.Appointment && this.state.Appointment.patient ?
                    <Accept
                        Accept={this.Accept}
                        currentClinic={this.state.currentClinic}
                        VisitId={this.state.Appointment.Id}
                        Patient={this.state.Appointment.patient}
                        handleClose={this.hideAccept} />
                    : ""
            }
            {
                this.state.showCancel && this.state.Appointment && this.state.Appointment.patient ?
                    <Cancel
                        Cancel={this.Cancel}
                        currentClinic={this.state.currentClinic}
                        VisitId={this.state.Appointment.Id}
                        Patient={this.state.Appointment.patient}
                        handleClose={this.hideCancel} />
                    : ""
            }
            {
                this.state.showChangeVisitType && this.state.Appointment && this.state.Appointment.patient ?
                    <ChangeVisitType
                        ChangeVisitType={this.ChangeVisitType}
                        currentClinic={this.state.currentClinic}
                        VisitId={this.state.Appointment.Id}
                        Patient={this.state.Appointment.patient}
                        handleClose={this.hideChangeVisitType} />
                    : ""
            }
            {
                this.state.showDelete && this.state.Appointment && this.state.Appointment.patient ?
                    <Delete
                        Delete={this.Delete}
                        currentClinic={this.state.currentClinic}
                        VisitId={this.state.Appointment.Id}
                        Patient={this.state.Appointment.patient}
                        handleClose={this.hideDelete} />
                    : ""
            }
            {
                this.state.showHistoryModal && this.state.Appointment.patient ?
                    <History handleClose={this.hideHistoryModal} Patient={this.state.Appointment.patient} /> : ""
            }

            <div className={`row g-6 g-xl-9 mb-6 mb-xl-9 mt-0 ${this.state.currentClinic ? "" : "justify-content-center"}`}>
                <div className={` ${this.state.currentClinic ? "col-md-5 col-lg-4 col-xxl-3" : "col-md-6 col-sm-12"}`}>
                    <div className={`card `}>
                        <div className="card-header align-items-center border-0">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="fw-bolder mb-2 text-dark">
                                    <i className="h3 fa-duotone fa-calendar me-2 text-info mb-0"></i>
                                    {t('Appointment.Title')}
                                </span>
                            </h3>
                        </div>
                        <div className="card-body pt-5 align-items-start d-flex">
                            <div className="w-100">
                                {
                                    this.state.currentClinic ?
                                        <>
                                            {
                                                this.state.clinics.length > 1 ?
                                                    <div className="row mb-10">
                                                        <SelectField
                                                            Text={this.state.currentClinic ? this.state.currentClinic.Name + " - " + this.state.currentClinic.DoctorName : ""}
                                                            Title={t("Clinic.Forms.Name.Select")}
                                                            Placeholder={t("Clinic.Forms.Name.Select")}
                                                            update={this.updateClinic}
                                                            data={this.state.clinics} />
                                                    </div> : ""
                                            }
                                            {
                                                this.state.currentClinic ? <Card Clinic={this.state.currentClinic} /> : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="d-flex mb-3 justify-content-end">
                                                <div className="position-relative w-md-200px me-md-2">
                                                    <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid ps-10"
                                                        id="Search"
                                                        name="Search"
                                                        autoComplete="off"
                                                        onChange={(e) => this.setSearch(e.target.value)}
                                                        value={this.state.Search}
                                                        placeholder={t("AdvancedSearch.Search.ByName")} />
                                                </div>
                                            </div>
                                            <div className="timeline">
                                                {this.renderClinic()}
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                    {
                        this.state.currentClinic && this.state.appointmentVisitStatus.length > 0 ?
                            <Widget appointmentVisitStatus={this.state.appointmentVisitStatus} VisitType={this.state.VisitType} /> : ""
                    }
                </div>
                {
                    this.state.currentClinic ? <>
                        <div className="col-md-7 col-lg-8 col-xxl-9">
                            <div className="card h-100">
                                <div className="card-header align-items-center border-0 mt-4">
                                    <div className="w-100 d-flex align-items-center justify-content-center">
                                        <div className="nav nav-stretch fs-5 fw-semibold nav-line-tabs border-transparent">
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    className={`nav-link text-active-gray-800 me-4 ${this.state.currentDay === 0 ? "active" : ""}`}
                                                    onClick={() => this.setDay(0)}>
                                                    {t('DateRangePicker.Range.Today')}
                                                </Link>
                                                {this.renderWeekDayLink()}
                                            </li>
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 flex-wrap justify-content-between">
                                        <h3 className="card-title">
                                            <div className="d-flex align-items-start">
                                                <div className="d-flex align-items-start flex-column ">
                                                    <span className="fw-bolder mb-2 text-dark">
                                                        {t('Appointment.Current')}
                                                        <i className="mx-1"></i>
                                                        {
                                                            this.state.currentDay === 0 ?
                                                                t('DateRangePicker.Range.Today')
                                                                :
                                                                moment().add(this.state.currentDay, 'day').locale('ar-KW').format('dddd')
                                                        }
                                                    </span>
                                                    <span className="text-muted fw-bold fs-7">{moment().add(this.state.currentDay, 'day').format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                        </h3>
                                        <div className="card-toolbar">
                                            <Link
                                                to="#"
                                                title={t('Appointment.New')}
                                                className="btn btn-sm btn-sm-icon btn-light-primary "
                                                onClick={() => this.showNewAppointment()}>
                                                <i className="h3 fa-solid fa-calendar-plus me-1"></i>
                                                {t('Appointment.New')}
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body pt-5">
                                    <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                                            <div className="d-flex align-items-center">
                                                <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                                                <input
                                                    value={this.state.SearchByName}
                                                    onChange={e => this.setSearch(e.target.value)}
                                                    ref={SearchByName => this.SearchByName = SearchByName}
                                                    name="SearchByName"
                                                    id="SearchByName"
                                                    autoComplete={"off"}
                                                    type={"text"}
                                                    placeholder={t('AdvancedSearch.Search.ByName')}
                                                    title={t('AdvancedSearch.Search.ByName')}
                                                    className="form-control form-control-sm" />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Select
                                                    onChange={this.setVisitStatus}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    name="ByVisitStatus"
                                                    id="ByVisitStatus"
                                                    placeholder={t('AdvancedSearch.Search.ByVisitStatus')}
                                                    title={t('AdvancedSearch.Search.ByVisitStatus')}
                                                    isRtl={true}
                                                    isLoading={!isLoadedVisitStatus}
                                                    isSearchable={true}
                                                    ref={ByVisitStatus => this.ByVisitStatus = ByVisitStatus}
                                                    options={this.state.visitStatus}
                                                    filterOption={this.filterOptions}
                                                />

                                            </div>
                                        </div>
                                        <div className={`table-responsive-md h-100 ${isLoaded ? "" : "table-loading"}`} style={{
                                            minHeight: '400px'
                                        }}>
                                            <div className="table-loading-message">
                                                {t("Processing.Title")}
                                            </div>
                                            <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                                <thead className="border-bottom border-gray-200 fs-6 fw-bold ">
                                                    <tr>
                                                        <th title={t("Table.Number")}>
                                                            <i className="fa-regular text-dark fa-hashtag"></i>
                                                        </th>
                                                        <th title={t("Patient.Name")}>
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                                                <span className="d-md-block d-none text-nowrap">
                                                                    {t("Patient.Name")}
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th title={t("Table.ID")}>
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                                                <span className="d-md-block d-none text-nowrap">
                                                                    {t("Table.ID")}
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th title={t("Table.Phone")}>
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa-regular text-dark fa-phone me-1"></i>
                                                                <span className="d-md-block d-none text-nowrap">
                                                                    {t("Table.Phone")}
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th title={t("Appointment.Type.Title")}>
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa-regular text-dark fa-coin me-1"></i>
                                                                <span className="d-md-block d-none text-nowrap">
                                                                    {t("Appointment.Type.Title")}
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th title={t("Appointment.VisitStatus.Title")}>
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa-regular text-dark fa-shield me-1"></i>
                                                                <span className="d-md-block d-none text-nowrap">
                                                                    {t("Appointment.VisitStatus.Title")}
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th title={t("Table.Options.Title")}>
                                                            <span className="d-flex align-items-center">
                                                                <i className="fa-regular text-dark fa-gears me-1"></i>
                                                                <span className="d-md-block d-none text-nowrap">
                                                                    {t("Table.Options.Title")}
                                                                </span>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="fs-6 fw-semibold text-gray-600">
                                                    {this.renderTableRow()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ""
                }
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinics: state.clinics,
        visitStatus: state.visitStatus,
        appointments: state.appointments
    };
};

export default connect(mapStateToProps, { fetchClinics, fetchAppointments, fetchVisitHistory, fetchVisitStatus })(withTranslation('common')(Appointment));