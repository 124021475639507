import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Card from "../PatientsCard";

class Info extends React.Component {

    render() {
        const { t, patient } = this.props;
        return <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Card patient={patient} />

                {
                    patient.Name.MotherName || patient.NameEnglish.MotherName ? <div className="row mb-5">
                        {
                            patient.Name.MotherName ? <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        {t("Patient.Forms.MotherName.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {patient.Name.MotherName}
                                        {
                                            patient.NameEnglish.MotherName ?
                                                <span className="ms-10">
                                                    {patient.NameEnglish.MotherName}
                                                </span> : ""
                                        }
                                    </span>
                                </div>
                            </div> : ""
                        }
                    </div> : ""
                }
                {
                    patient.User ? <>
                        <div className="separator separator-dashed border-dark mb-5"></div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        {t("LastUpdatedUser")}
                                    </span>
                                    <span className="ms-10">
                                        {patient.User.Name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("CreationDate")}
                                    </span>
                                    <span className="ms-10">
                                        {patient.CreationDate}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("LastUpdatedDate")}
                                    </span>
                                    <span className="ms-10">
                                        {patient.UpdateDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </> : ""
                }
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(Info);