const MedicalSpecialistReducers = (state = [], action) => {
    switch (action.type) {
        case "MEDICAL_SPECIALIST_LOADING":
            return {
                medicalSpecialist: [],
                isLoaded: false
            };
        case "MEDICAL_SPECIALIST_INITIAL":
            return {
                medicalSpecialist: action.medicalSpecialist,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default MedicalSpecialistReducers;