import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { checkLogin } from '../actions/';

import Appointment from './Appointment/';
import Patients from './Patients/';

import ClinicScreen from './ClinicScreen/';
import Diagnosis from './Diagnosis/';
import MedicalRecords from "./MedicalRecords";

import SpecimenTaken from "./Laboratory/SpecimenTaken";
import LabExamsResult from "./Laboratory/Results";
import LabExams from "./Laboratory/Exams";

import RadiologyReceivingPatients from "./Radiology/ReceivingPatients";

import Accounts from "./Accounting/Accounts";
import ReceiveCash from "./Accounting/ReceiveCash";
import ClinicServices from "./Accounting/ClinicServices";
import JournalVouchers from "./Accounting/Vouchers/JournalVouchers";
import ReceiptVouchers from "./Accounting/Vouchers/ReceiptVouchers";
import RadiologyExamsPrices from "./Accounting/RadiologyExamsPrices";
import LaboratoryExamsPrices from "./Accounting/LaboratoryExamsPrices";
import BankAccounts from "./Accounting/BankAccounts";

import ControlPanel from './Pages/ControlPanel';
import DMViewer from './Radiology/DMViewer';
import RadiologyExams from "./Radiology/Exams";

// import Notifications from './Pages/User/Notifications';

import Profile from './Pages/User/Profile';
import LoginHistory from './Pages/User/Profile/LoginHistory';
import EditProfile from './Pages/User/Profile/EditProfile';
import ChangePassword from './Pages/User/Profile/ChangePassword';

import Login from './Pages/User/Login';
import Logout from './Pages/User/Logout';

import NotFound from "./shared/Errors/NotFound";
import PermissionError from "./shared/Errors/PermissionError";

import Users from "./Manager/Users";
import Clinics from "./Manager/Clinics";

import HealthCenters from "./Admin/HealthCenters";

class Layout extends React.Component {

    componentDidMount() {
        this.props.checkLogin();
    }

    deniedUserFromLoginPage = (SComponent, props) => {
        if (this.props.user && this.props.user.isLogin === true) {
            return <Profile {...props} />;
        }
        return <SComponent {...props} />
    }

    allowAdminUser = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} />;
        }
        else if (
            this.props.user &&
            this.props.user.user &&
            this.props.user.user.UserType &&
            this.props.user.user.UserType.Id !== 1
        ) {
            return <PermissionError {...props} />;
        }
        return <SComponent {...props} />
    }

    allowAllUser = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} />;
        }
        return <SComponent {...props} />
    }

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/" component={(props) => this.allowAllUser(ControlPanel, props)} />

                    <Route exact path="/DMViewer" component={(props) => this.allowAllUser(DMViewer, props)} />

                    {/*
                     <Route exact path="/Notifications" component={(props) => this.allowAllUser(Notifications, props)} />
                    <Route exact path="/MyCalendar" component={(props) => this.allowAllUser(MyCalendar, props)} />
 */}


                    {/* Admin User */}
                    <Route exact path="/HealthCenters" component={(props) => this.allowAllUser(HealthCenters, props)} />

                    {/* Manager User */}
                    <Route exact path="/Manager/Users" component={(props) => this.allowAllUser(Users, props)} />
                    <Route exact path="/Manager/Clinics" component={(props) => this.allowAllUser(Clinics, props)} />

                    {/* register User */}
                    <Route exact path="/Appointment" component={(props) => this.allowAllUser(Appointment, props)} />
                    <Route exact path="/Patients" component={(props) => this.allowAllUser(Patients, props)} />

                    {/* Doctor User */}
                    <Route exact path="/ClinicScreen" component={(props) => this.allowAllUser(ClinicScreen, props)} />
                    <Route exact path="/Diagnosis" component={(props) => this.allowAllUser(Diagnosis, props)} />
                    <Route exact path="/Patients/Medical/Records" component={(props) => this.allowAllUser(MedicalRecords, props)} />

                    {/* Lab User */}
                    <Route exact path="/Lab/Specimen/Taken" component={(props) => this.allowAllUser(SpecimenTaken, props)} />
                    <Route exact path="/Lab/Exams/Result" component={(props) => this.allowAllUser(LabExamsResult, props)} />
                    <Route exact path="/Lab/Exams" component={(props) => this.allowAllUser(LabExams, props)} />

                    {/* Accounting User */}
                    <Route exact path="/Accounting/Receive/Cash" component={(props) => this.allowAllUser(ReceiveCash, props)} />
                    <Route exact path="/Accounting/Lab/Exams/Prices" component={(props) => this.allowAllUser(LaboratoryExamsPrices, props)} />
                    <Route exact path="/Accounting/Radiology/Exams/Prices" component={(props) => this.allowAllUser(RadiologyExamsPrices, props)} />
                    <Route exact path="/Accounting/Receipt/Voucher" component={(props) => this.allowAllUser(ReceiptVouchers, props)} />
                    <Route exact path="/Accounting/Journal/Voucher" component={(props) => this.allowAllUser(JournalVouchers, props)} />
                    <Route exact path="/Accounting/Clinic/Services/Prices" component={(props) => this.allowAllUser(ClinicServices, props)} />
                    <Route exact path="/Accounting/Accounts" component={(props) => this.allowAllUser(Accounts, props)} />
                    <Route exact path="/Accounting/Bank/Accounts" component={(props) => this.allowAllUser(BankAccounts, props)} />

                    {/* Radiology User */}
                    <Route exact path="/Radiology/Receiving/Patients" component={(props) => this.allowAllUser(RadiologyReceivingPatients, props)} />
                    <Route exact path="/Radiology/Exams" component={(props) => this.allowAllUser(RadiologyExams, props)} />


                    <Route exact path="/Profile" component={(props) => this.allowAllUser(Profile, props)} />
                    <Route exact path="/Profile/Login/History" component={(props) => this.allowAllUser(LoginHistory, props)} />
                    <Route exact path="/Profile/Edit" component={(props) => this.allowAllUser(EditProfile, props)} />
                    <Route exact path="/Profile/ChangePassword" component={(props) => this.allowAllUser(ChangePassword, props)} />

                    <Route exact path="/Login" component={(props) => this.deniedUserFromLoginPage(Login, props)} />
                    <Route exact path="/Logout" component={Logout} />

                    <Route component={NotFound} />
                </Switch>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {
    checkLogin
})(withTranslation('common')(Layout));