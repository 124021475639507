import React from "react";
import { withTranslation } from 'react-i18next';
import Form from "./Form";
import moment from "moment";

class GridView extends React.Component {

    state = { data: [], Sum: 0, duplicateCheck: [] };

    addItem = item => {
        let Sum = 0;
        let data = this.state.data;
        data.push(item);
        data.forEach(item => {
            Sum += parseFloat(item.Value);
        });
        this.setState({
            data,
            Sum
        }, () => {
            this.duplicateCheck()
        });
    }

    delete = index => {
        let Sum = 0;
        let data = this.state.data;
        data.splice(index, 1);
        data.forEach(item => {
            Sum += parseFloat(item.Value);
        });
        this.setState({
            data,
            Sum
        });
    }

    editItem = (newItem, index) => {
        let Sum = 0;
        let data = this.state.data;
        data[index].Number = newItem.Number;
        data[index].Value = newItem.Value;
        data[index].Date = newItem.Date;
        data[index].Bank = newItem.Bank;
        data[index].CheckFrom = newItem.CheckFrom;
        data[index].Recipient = newItem.Recipient;
        data[index].BankBranch = newItem.BankBranch;
        data[index].AccountNumber = newItem.AccountNumber;
        data.forEach(item => {
            Sum += parseFloat(item.Value);
        });
        this.setState({
            data,
            Sum
        }, () => {
            this.duplicateCheck()
        });
    }

    repeat = index => {
        let data = this.state.data;
        let newCheck = { ...data[index] };
        newCheck.Number = parseInt(newCheck.Number) + 1;
        newCheck.Date = moment(newCheck.Date, 'DD/MM/YYYY').add(1, 'month').format('DD/MM/YYYY');
        data.push(newCheck);
        let Sum = 0;
        data.forEach(item => {
            Sum += parseFloat(item.Value);
        });
        this.setState({
            data,
            Sum
        }, () => {
            this.duplicateCheck()
        });
    }

    duplicateCheck = () => {
        const data = this.state.data;
        let duplicateCheck = [];

        for (let num in data) {
            for (let num2 in data) {
                if (num === num2) {
                    continue;
                }
                else {
                    if (
                        data[num].Bank.Id === data[num2].Bank.Id &&
                        data[num].Number === data[num2].Number &&
                        data[num].AccountNumber === data[num2].AccountNumber
                    ) {
                        let hasItem = false;
                        for (let num3 in duplicateCheck) {
                            if (
                                data[num].Bank.Id === duplicateCheck[num3].Bank.Id &&
                                data[num].Number === duplicateCheck[num3].Number &&
                                data[num].AccountNumber === duplicateCheck[num3].AccountNumber
                            ) {
                                hasItem = true;
                            }
                        }
                        if (!hasItem) {
                            duplicateCheck.push(data[num]);
                        }
                    }
                }
            }
        }
        this.setState({
            duplicateCheck
        });
        this.props.saveData(this.state.data, duplicateCheck.length > 0);
    }

    render() {
        const { t } = this.props;
        return (<div className="row mb-5">
            <div className="position-relative">
                {
                    this.state.duplicateCheck.length > 0 ?
                        <div className="alert bg-light-danger border-3 border-danger d-flex flex-column flex-sm-row p-5 mb-5">
                            <i className="fs-2hx text-danger me-2 mb-0 fa-regular fa-light-emergency-on fa-shake"></i>
                            <div className="d-flex flex-column pe-1 w-100">
                                <h5 className="mb-1">{t("Accounting.Check.Duplicate.Title")}</h5>
                                <span>{t("Accounting.Check.Duplicate.Body")}.</span>
                                <ul className="d-flex flex-column">
                                    {
                                        this.state.duplicateCheck.map((item, index) => {
                                            return <li className="d-flex align-items-center pb-1" key={`duplicate_check_${index}`}>
                                                <span className="bullet bg-dark mx-5"></span>
                                                {t("Banks.Forms.Select.Title")} :
                                                {item.Bank.Name}
                                                <span className="mx-2">
                                                    {t("Accounting.Check.Forms.AccountNumber.Title")} :
                                                    {item.AccountNumber}
                                                </span>
                                                <i className="fa-regular text-dark fa-hashtag mx-1"></i>
                                                {t("Accounting.Check.Forms.Number.Title")} :
                                                {item.Number}.

                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        : ""
                }

                <div className={`table-responsive-md`}>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head mt-5">
                        <thead>
                            <tr>
                                <th title={t("NumberAuto")}>
                                    <i className="fa-regular text-dark fa-tally "></i>
                                </th>

                                <th title={t("Accounting.Check.Forms.Number.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Check.Forms.Number.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.Check.Forms.AccountNumber.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-file-user me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Check.Forms.AccountNumber.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Banks.Forms.Select.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-landmark me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Banks.Forms.Select.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("BankBranches.Forms.Select.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-code-branch me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("BankBranches.Forms.Select.Title")}
                                        </span>
                                    </span>
                                </th>


                                <th title={t("Accounting.Check.Forms.Value.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-coin me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Check.Forms.Value.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.Check.Forms.Date.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-days me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Check.Forms.Date.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.Check.Forms.CheckFrom.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Check.Forms.CheckFrom.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Accounting.Check.Forms.Recipient.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.Check.Forms.Recipient.Title")}
                                        </span>
                                    </span>
                                </th>

                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears"></i>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <Form submitItem={this.addItem} key={`check_form_${this.state.data.length}`} form={`check_form_${this.state.data.length}`} CheckIndex={this.state.data.length + 1} hasControl={false} />
                            {
                                this.state.data?.map((item, index) => {
                                    const filter = this.state.data.filter(check => check.Number === item.Number && check.AccountNumber === item.AccountNumber && check.Bank.Id === item.Bank.Id);
                                    return <Form
                                        delete={this.delete}
                                        submitItem={this.editItem}
                                        key={`check_form_${item.Number}_${item.AccountNumber}_${index}`}
                                        form={`check_form_${item.Number}_${item.AccountNumber}_${index}`}
                                        CheckIndex={index + 1}
                                        item={item}
                                        hasControl={true}
                                        repeat={this.repeat}
                                        isDuplicate={filter.length > 1}
                                    />;
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5}>
                                    <div className={`fw-bold fs-6 text-center d-flex justify-content-center w-100`}>
                                        <div className="me-2">
                                            <i className="fa-regular text-dark fa-tally me-2"></i>
                                            {t("Accounting.Check.Count")}
                                            :
                                        </div>
                                        {this.state.data.length}
                                    </div>
                                </th>
                                <th colSpan={5}>
                                    <div className={`fw-bold fs-6 text-center d-flex justify-content-center w-100`}>
                                        <div className="me-2">
                                            <i className="fa-regular text-dark fa-sigma me-1"></i>
                                            {t("Accounting.Check.Sum")}
                                            :
                                        </div>
                                        {this.state.Sum}
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>);
    }
}

export default withTranslation('common')(GridView);