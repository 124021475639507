import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Info extends React.Component {

    render() {
        const { t, Check } = this.props;
        return <>
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                        {t("Table.Options.Info")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=" mb-5" title={t("NumberAuto")}>
                        <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Check.Id}</span>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-hashtag me-2"></i>
                                    {t("Accounting.Check.Forms.Number.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.Number}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-file-user me-2"></i>
                                    {t("Accounting.Check.Forms.AccountNumber.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.AccountNumber}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-coin me-2"></i>
                                    {t("Accounting.Check.Forms.Value.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.Value}
                                    <span className="ms-1">
                                        {Check.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-landmark me-2"></i>
                                    {t("Banks.Forms.Select.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.Bank.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-code-branch me-2"></i>
                                    {t("BankBranches.Forms.Select.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.BankBranch.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar me-2"></i>
                                    {t("Accounting.Check.Forms.Date.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.Date}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-text me-2"></i>
                                    {t("Accounting.Check.Forms.CheckFrom.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.CheckFrom}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-2"></i>
                                    {t("Accounting.Check.Forms.Recipient.Title")}
                                </span>
                                <span className="ms-10">
                                    {Check.Recipient}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-diagram-project me-2"></i>
                                    {t("Accounting.Check.Status.Title")}
                                </span>
                                <span className="ms-10">
                                    {t(`Accounting.Check.Status.${Check.CheckStatu.Name}`)}
                                    {
                                        Check.CheckStatu.Name === "send_to_other" || Check.CheckStatu.Name === "chash" ?
                                            <div>
                                                {Check.Account.Number + " - " + Check.Account.Name}
                                            </div> : ""
                                    }
                                    {
                                        Check.CheckStatu.Name === "add_in_bank_account" || Check.CheckStatu.Name === "collect_from_bank" ?
                                            <div>
                                                {Check.BankBox.Number + " - " + Check.BankBox.Name}
                                            </div> : ""
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {Check.User.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {Check.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {Check.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>;
    }
}


export default withTranslation('common')(Info);