import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import UserReducers from './UserReducers';

import CitiesReducers from './Patients/CitiesReducers';
import PatientsReducers from './Patients/PatientsReducers';
import BloodGroupsReducers from './Patients/BloodGroupsReducers';
import PatientVisitsReducers from './Patients/PatientVisitsReducers';
import PatientDrugsReducers from './Patients/PatientDrugsReducers';
import PatientRadiologyReducers from './Patients/PatientRadiologyReducers';
import PatientLabsReducers from './Patients/PatientLabsReducers';

import NotificationsReducers from './NotificationsReducers';

import VisitHistoryReducers from './Appointment/VisitHistoryReducers';
import AppointmentReducers from './Appointment/AppointmentReducers';
import VisitStatusReducers from './Appointment/VisitStatusReducers';

import DiagnosesReducers from './Doctor/DiagnosesReducers';
import PatientFormsReducers from './Doctor/PatientFormsReducers';
import FamilyHistoryReducers from './Doctor/FamilyHistoryReducers';
import PastMedicalHistoryReducers from './Doctor/PastMedicalHistoryReducers';
import PastSurgicalHistoryReducers from './Doctor/PastSurgicalHistoryReducers';
import ObstetricAndGyneHistoryReducers from './Doctor/ObstetricAndGyneHistoryReducers';
import GynecologyInfoesReducers from './Doctor/GynecologyInfoesReducers';
import GynecologyInfoHistoriesReducers from './Doctor/GynecologyInfoHistoriesReducers';

import VitalSignsReducers from './Nurse/VitalSignsReducers';
import MeasurementsReducers from './Nurse/MeasurementsReducers';
import MeasurementHistoriesReducers from './Nurse/MeasurementHistoriesReducers';
import PatientChronicDiseasesReducers from './Nurse/PatientChronicDiseasesReducers';

import LabExamsReducers from './Lab/LabExamsReducers';
import LabExamTypesReducers from './Lab/LabExamTypesReducers';
import LabCentersReducers from './Lab/LabCentersReducers';
import LabOrdersReducers from './Lab/LabOrdersReducers';
import LabSpecimentReducers from './Lab/LabSpecimentReducers';
import LabExamsResultsReducers from './Lab/LabExamsResultsReducers';

import RadiologyCentersReducers from './Radiology/RadiologyCentersReducers';
import RadiologyExamsReducers from './Radiology/RadiologyExamsReducers';
import RadiologyExamTypesReducers from './Radiology/RadiologyExamTypesReducers';
import RadiologyOrdersReducers from './Radiology/RadiologyOrdersReducers';

import PharmaciesReducers from './Drugs/PharmaciesReducers';
import DrugOrdersReducers from './Drugs/DrugOrdersReducers';
import DrugsReducers from './Drugs/DrugsReducers';
import DrugTypesReducers from './Drugs/DrugTypesReducers';
import DrugAllergyReducers from './Drugs/DrugAllergyReducers';

import ServicesReducers from './Accounter/ServicesReducers';
import PatientBillsReducers from './Accounter/PatientBillsReducers';
import RadiologyExamPriceReducers from './Accounter/RadiologyExamPriceReducers';
import LabExamPriceReducers from './Accounter/LabExamPriceReducers';
import ReceiptVouchersReducers from './Accounter/ReceiptVouchersReducers';
import JournalVouchersReducers from './Accounter/JournalVouchersReducers';
import ClinicServicesReducers from './Accounter/ClinicServicesReducers';
import AccountsReducers from './Accounter/AccountsReducers';
import BankAccountsReducers from './Accounter/BankAccountsReducers';
import CheckLifeCyclesReducers from './Accounter/CheckLifeCyclesReducers';

import BanksReducers from './Lookups/BanksReducers';
import BankBoxesReducers from './Lookups/BankBoxesReducers';
import UserTypesReducers from './Lookups/UserTypesReducers';
import CurrenciesReducers from './Lookups/CurrenciesReducers';
import OrderStatusReducers from './Lookups/OrderStatusReducers';
import BankBranchesReducers from './Lookups/BankBranchesReducers';
import HealthCentersReducers from './Lookups/HealthCentersReducers';
import ChronicDiseaseReducers from './Lookups/ChronicDiseaseReducers';
import MedicalSpecialistReducers from './Lookups/MedicalSpecialistReducers';

import UsersReducers from './Manager/UsersReducers';
import ClinicsReducers from './Manager/ClinicsReducers';
import UserClinicsReducers from './Manager/UserClinicsReducers';

export default combineReducers({
    user: UserReducers,
    cities: CitiesReducers,
    clinics: ClinicsReducers,
    patients: PatientsReducers,
    visitHistory: VisitHistoryReducers,
    appointments: AppointmentReducers,
    visitStatus: VisitStatusReducers,
    diagnoses: DiagnosesReducers,
    labExams: LabExamsReducers,
    labExamTypes: LabExamTypesReducers,
    labCenters: LabCentersReducers,
    labOrders: LabOrdersReducers,
    labExamResults: LabExamsResultsReducers,
    labSpeciments: LabSpecimentReducers,
    radiologyExams: RadiologyExamsReducers,
    radiologyExamTypes: RadiologyExamTypesReducers,
    radiologyCenters: RadiologyCentersReducers,
    radiologyOrders: RadiologyOrdersReducers,
    drugOrders: DrugOrdersReducers,
    pharmacies: PharmaciesReducers,
    drugs: DrugsReducers,
    drugTypes: DrugTypesReducers,
    drugAllergy: DrugAllergyReducers,
    familyHistory: FamilyHistoryReducers,
    pastSurgicalHistory: PastSurgicalHistoryReducers,
    pastMedicalHistory: PastMedicalHistoryReducers,
    vitalSigns: VitalSignsReducers,
    patientForms: PatientFormsReducers,
    services: ServicesReducers,
    currencies: CurrenciesReducers,
    measurement: MeasurementsReducers,
    measurementHistories: MeasurementHistoriesReducers,
    bloodGroups: BloodGroupsReducers,
    obstetricAndGyneHistory: ObstetricAndGyneHistoryReducers,
    gynecologyInfo: GynecologyInfoesReducers,
    gynecologyInfoHistories: GynecologyInfoHistoriesReducers,
    orderStatus: OrderStatusReducers,
    labExamPrice: LabExamPriceReducers,
    patientBills: PatientBillsReducers,
    radiologyExamPrice: RadiologyExamPriceReducers,
    receiptVouchers: ReceiptVouchersReducers,
    visits: PatientVisitsReducers,
    patientDrugs: PatientDrugsReducers,
    patientRadiology: PatientRadiologyReducers,
    patientLabs: PatientLabsReducers,
    journalVouchers: JournalVouchersReducers,
    clinicServices: ClinicServicesReducers,
    accounts: AccountsReducers,
    bankAccounts: BankAccountsReducers,
    chronicDisease: ChronicDiseaseReducers,
    patientChronicDisease: PatientChronicDiseasesReducers,
    users: UsersReducers,
    checkLifeCycles: CheckLifeCyclesReducers,
    banks: BanksReducers,
    bankBoxes: BankBoxesReducers,
    bankBranches: BankBranchesReducers,
    Notifications: NotificationsReducers,
    healthCenters: HealthCentersReducers,
    userTypes: UserTypesReducers,
    medicalSpecialist: MedicalSpecialistReducers,
    userClinics: UserClinicsReducers,
    form: formReducer
});