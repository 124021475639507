import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchHealthCenters } from "./actions";

class SelectHealthCenters extends React.Component {

    state = { healthCenters: [], isInitialize: false, HealthCentersId: React.createRef() };

    constructor(props) {
        super(props);
        const { healthCenters } = this.props;
        if (!healthCenters || !healthCenters.healthCenters || healthCenters.healthCenters?.length === 0) {
            this.props.fetchHealthCenters();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.defaultValue && !state.isInitialize && state.HealthCentersId.current) {
            state.isInitialize = true;
            state.HealthCentersId.current.setValue(props.defaultValue);
        }

        if (props.healthCenters?.healthCenters) {
            state.healthCenters = props.healthCenters.healthCenters.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        const { t, healthCenters, required, isClearable, hideTitle, className } = this.props;
        let isLoaded = false;
        if (healthCenters) {
            isLoaded = healthCenters.isLoaded;
        }
        return <>
            {
                hideTitle ? "" : <label className={`col-form-label ${required ? "required" : ""} fw-bold fs-6`} htmlFor="HealthCentersId"><i className="fa-regular text-dark fa-hospital me-2"></i>{t('HealthCenter.Form.Name.Title')}</label>
            }
            <Select
                name="HealthCentersId"
                id="HealthCentersId"
                placeholder={t('HealthCenter.Form.Name.Select')}
                title={t('HealthCenter.Form.Name.Title')}
                isLoading={!isLoaded}
                isSearchable={true}
                isClearable={isClearable}
                ref={this.state.HealthCentersId}
                required={required}
                onChange={this.onChange}
                options={this.state.healthCenters}
                filterOption={this.filterOptions}
                className={className}
            />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        healthCenters: state.healthCenters
    };
};

export default connect(mapStateToProps, { fetchHealthCenters })(withTranslation('common')(SelectHealthCenters));