import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from "./Form";

class Edit extends React.Component {

    render() {
        const { t, isEdit } = this.props;
        return <>
            <Modal
                size={isEdit ? "lg" : "xl"}
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        {
                            isEdit ?
                                <>
                                    <i className="h3 fa-duotone fa-edit me-2 text-primary "></i>
                                    {t("Services.Edit")}
                                </>
                                :
                                <>
                                    <i className="h3 fa-duotone fa-plus me-2 text-primary "></i>
                                    {t("Services.New")}
                                </>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form {...this.props} />
                </Modal.Body>
            </Modal>
        </>;
    }
}

export default withTranslation('common')(Edit);