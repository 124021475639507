import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { ConfigProvider, TimePicker } from "antd";
import dayjs from 'dayjs';

import serverAPI from "../../../api/serverAPI";
import History from "../Modal/History";
import { fetchVisitHistory } from '../actions';

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Date: `${moment().format('DD/MM/YYYY')}`,
            isInitialize: false,
            showHistoryModal: false,
            isSubmit: false,
            StartTime: '',
            EndTime: ''
        };
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.Appointment) {
            state.isInitialize = true;
            state.Date = props.Appointment.Date;
            state.StartTime = props.Appointment.StartTime;
            state.EndTime = props.Appointment.EndTime;
        }
        console.log(props.Appointment);
        return state;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            Date: picker.startDate.format('DD/MM/YYYY')
        });
    }

    showHistoryModal = () => {
        if (this.props.Patient &&
            this.props.currentClinic &&
            (!this.props.visitHistory || this.props.visitHistory.PatientId !== this.props.Patient.Id || this.props.visitHistory.ClinicId !== this.props.currentClinic.Id)) {
            this.props.fetchVisitHistory(this.props.Patient.Id, this.props.currentClinic.Id);
        }
        this.setState({
            showHistoryModal: true
        });
    }

    hideHistoryModal = () => {
        this.setState({
            showHistoryModal: false
        });
    }

    onChangeTime = (time, timeString) => {
        this.setState({ StartTime: timeString[0], EndTime: timeString[1] });
    }

    onSubmit = () => {
        const { t } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        serverAPI.put(`/ClinicRegistration/${this.props.VisitId}`, {
            Date: this.state.Date,
            StartTime: this.state.StartTime,
            EndTime: this.state.EndTime,
            PatientId: this.props.Patient.Id,
            ClinicId: this.props.currentClinic.Id
        }).then(response => {
            if (response.data.code === "patient_is_exist") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.patient_is_exist.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.patient_is_exist.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Save.Edit.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Edit.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.handleClose();
                this.props.update();
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    render() {
        const { t, Patient } = this.props;

        return <>
            {
                this.state.showHistoryModal && Patient ? <History handleClose={this.hideHistoryModal} Patient={Patient} /> : ""
            }
            <form method="POST" name="formAppointment" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <label className="col-form-label required fw-bold fs-6" htmlFor="Appointment_Date">{t('Appointment.Date')}</label>
                        <DateRangePicker
                            ref={SelectDate => this.SelectDate = SelectDate}
                            initialSettings={{
                                singleDatePicker: true,
                                opens: 'center',
                                autoUpdateInput: true,
                                buttonClasses: 'btn',
                                cancelClass: "btn-danger",
                                applyButtonClasses: "btn-primary",
                                showDropdowns: true,
                                format: 'DD/MM/YYYY',
                                timePicker: false,
                                minDate: `${moment().format('DD/MM/YYYY')}`,
                                locale: {
                                    format: 'DD/MM/YYYY',
                                    cancelLabel: t('Close'),
                                    firstDay: 6,
                                    applyLabel: t('Apply'),
                                    customRangeLabel: t('DateRangePicker.Range.customRange'),
                                    monthNames: [
                                        `${t("DateRangePicker.Months.January")}`,
                                        `${t("DateRangePicker.Months.February")}`,
                                        `${t("DateRangePicker.Months.March")}`,
                                        `${t("DateRangePicker.Months.April")}`,
                                        `${t("DateRangePicker.Months.May")}`,
                                        `${t("DateRangePicker.Months.June")}`,
                                        `${t("DateRangePicker.Months.July")}`,
                                        `${t("DateRangePicker.Months.August")}`,
                                        `${t("DateRangePicker.Months.September")}`,
                                        `${t("DateRangePicker.Months.October")}`,
                                        `${t("DateRangePicker.Months.November")}`,
                                        `${t("DateRangePicker.Months.December")}`
                                    ],
                                    daysOfWeek: [
                                        t("DateRangePicker.DaysOfWeek.Sunday"),
                                        t("DateRangePicker.DaysOfWeek.Monday"),
                                        t("DateRangePicker.DaysOfWeek.Tuesday"),
                                        t("DateRangePicker.DaysOfWeek.Wednesday"),
                                        t("DateRangePicker.DaysOfWeek.Thursday"),
                                        t("DateRangePicker.DaysOfWeek.Friday"),
                                        t("DateRangePicker.DaysOfWeek.Saturday")
                                    ],
                                },
                            }}
                            onApply={this.handleApplyDate} >
                            <input
                                defaultValue={this.state.Date}
                                ref={Date => this.Date = Date}
                                name="Date"
                                id="Appointment_Date"
                                autoComplete="off"
                                required
                                className="form-control form-control-solid ps-10"
                                type="text"
                                placeholder={t('Appointment.Forms.Date.Placeholder')}
                                title={t('Appointment.Forms.Date.Title')}
                            />
                        </DateRangePicker>
                    </div>
                    <div className="col-12 mb-3">
                        <label className="col-form-label fw-bold fs-6" htmlFor="Time">{t('Appointment.Forms.Time.Title')}</label>
                        <ConfigProvider theme={{
                            token: {
                                fontFamily: 'Du_Bold',
                            }
                        }}>
                            <TimePicker.RangePicker
                                inputReadOnly
                                id="Time"
                                name="Time"
                                needConfirm={true}
                                ref={Time => this.Time = Time}
                                format={'HH:mm a'}
                                defaultValue={this.state.StartTime ? [dayjs(this.state.StartTime, 'hh:mm a'), dayjs(this.state.EndTime, 'hh:mm a')] : []}
                                minuteStep={5}
                                size="large"
                                onChange={this.onChangeTime}
                                className="form-control form-control-solid ps-10 d-flex text-center"
                                placeholder={t('Appointment.Forms.Time.Placeholder')}
                                title={t('Appointment.Forms.Time.Title')}
                                activeBg={"#ff0000"}
                            />
                        </ConfigProvider>
                    </div>
                </div>
                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-info me-auto" title={t('Appointment.History.Title')} onClick={() => this.showHistoryModal()}>
                        <i className="me-1 fa-solid fa-rectangle-history-circle-user"></i>
                        {t('Appointment.History.Title')}
                    </button>
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Appointment.Edit')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-calendar-lines-pen"></i>
                            {t('Appointment.Edit')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        visitHistory: state.visitHistory
    };
};

export default connect(mapStateToProps, { fetchVisitHistory })(withTranslation('common')(Form));

