import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Field, getFormValues, reduxForm, formValueSelector, change } from "redux-form";
import { toast } from "react-toastify";

import {
    createPatients as create,
    updatePatients as update
} from "../action";
import serverAPI from "../../../api/serverAPI";
import SelectCities from "../../shared/SelectList/SelectCities";
import SelectBloodGroup from "../../shared/SelectList/SelectBloodGroup";

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.SelectBirthday = React.createRef();
        this.state = {
            initialize: false,
            isSubmit: false,
            defaultCity: null,
            defaultBloodGroup: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        const patient = props.patient;
        if (!state.initialize && patient) {
            state.initialize = true;
            props.initialize({
                FirstName: patient.Name.FirstName,
                LastName: patient.Name.LastName,
                SecondName: patient.Name.SecondName,
                ThirdName: patient.Name.ThirdName,
                MotherName: patient.Name.MotherName,
                ThirdNameEnglish: patient.NameEnglish.ThirdName,
                SecondNameEnglish: patient.NameEnglish.SecondName,
                MotherNameEnglish: patient.NameEnglish.MotherName,
                LastNameEnglish: patient.NameEnglish.LastName,
                FirstNameEnglish: patient.NameEnglish.FirstName,
                Address: patient.Address,
                CityId: patient.City ? patient.City.Id : "",
                BloodGroupId: patient.BloodGroup ? patient.BloodGroup.Id : "",
                Email: patient.Email,
                Phone: patient.Phone,
                Sex: patient.Sex,
                Birthday: patient.Birthday,
                WhatsAppNumber: patient.WhatsAppNumber,
                DocumentNumber: patient.DocumentNumber,
                DocumentTypeId: patient.DocumentType ? patient.DocumentType.Id : "",
            });
        }
        if (!state.initialize && props.DocumentNumber) {
            state.initialize = true;
            props.initialize({
                DocumentTypeId: 2,
                DocumentNumber: props.DocumentNumber
            });

        }
        return state;
    }

    componentDidMount() {
        this.clearDate();
        const { patient, DocumentNumber } = this.props;
        if (!patient) {
            this.props.initialize({
                DocumentTypeId: 2,
                DocumentNumber: DocumentNumber
            });
        } else {
            this.setState({
                defaultCity: this.props.patient.City ? { ...this.props.patient.City, value: this.props.patient.City.Id, label: this.props.patient.City.Name } : "",
                defaultBloodGroup: this.props.patient.BloodGroup ? { ...this.props.patient.BloodGroup, value: this.props.patient.BloodGroup.Id, label: this.props.patient.BloodGroup.Name } : ""
            });
            setTimeout(() => {
                this.SelectBirthday.current?.setStartDate(this.props.patient.Birthday);
                this.SelectBirthday.current?.setEndDate(this.props.patient.Birthday);
            }, 100);
        }
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    handleApplyDate = (event, picker) => {
        this.props.dispatch(change('formPatientCreate', 'Birthday', picker.startDate.format('DD/MM/YYYY')));
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
    }

    setBirthday = Birthday => {
        this.props.dispatch(change('formPatientCreate', 'Birthday', Birthday));
        this.SelectBirthday.current?.setStartDate(Birthday);
        this.SelectBirthday.current?.setEndDate(Birthday);
    }

    reset = () => {
        const { patient } = this.props;
        if (!patient) {
            this.props.reset();
            this.clearDate();
        }
    }

    clearDate = () => {
        this.props.dispatch(change('formPatientCreate', 'Birthday', ""));
        window.setNull("#CreateBirthday");
    }

    onSubmit = () => {
        const { t, patient } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let method = serverAPI.post;
        if (patient) {
            method = serverAPI.put;
        }

        method(`/PatientRegistration/${patient ? patient.Id : ""}`, {
            ...this.props.formValues
        }).then(response => {
            if (response.data.code === "fill_all_inputs") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.AllInformationIsRequired.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.AllInformationIsRequired.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "document_is_exist") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.document_is_exist.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.document_is_exist.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Save.${patient ? "Edit" : "New"}.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Save.${patient ? "Edit" : "New"}.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                if (patient) {
                    this.props.update(response.data.data, patient.Id);
                } else {
                    this.props.create(response.data.data);
                }
                this.props.handleClose();
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });

            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    setCity = City => {
        this.props.dispatch(change('formPatientCreate', 'CityId', City.Id));
    }

    setBloodGroup = BloodGroup => {
        this.props.dispatch(change('formPatientCreate', 'BloodGroupId', BloodGroup.Id));
    }

    checkDocumentNumber = DocumentNumber => {
        const { t, patient, DocumentTypeValue } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        serverAPI.post(`/CheckDocumentNumber/`, {
            DocumentNumber,
            DocumentTypeId: DocumentTypeValue,
            PatientId: patient ? patient.Id : 0
        }).then(response => {
            if (response.data.code === "document_is_exist") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.document_is_exist.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.document_is_exist.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else {
                toast.dismiss();
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });

            this.setState({
                isSubmit: false
            });
        });
    }

    render() {
        const { t, pristine, submitting, DocumentTypeValue, handleSubmit } = this.props;

        return <>
            <form method="POST" name="formPatientCreate" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password"
                onSubmit={
                    handleSubmit(values => {
                        return new Promise(resolve => resolve())
                            .then(() => {
                                const errors = validate(values, this.props);
                                return Object.keys(errors).length !== 0;
                            })
                            .then(errors => {
                                if (errors) {
                                    this.checkSubmit();
                                } else {
                                    this.onSubmit();
                                }
                            })
                    })
                }   >

                <div className="row mb-5">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="DocumentTypeId">{t('Patient.Forms.DocumentType.Title')}</label>
                        <Field
                            ref={DocumentTypeId => this.DocumentTypeId = DocumentTypeId}
                            component="select"
                            className="form-select form-select-solid"
                            name="DocumentTypeId"
                            id="DocumentTypeId"
                            title={t("Patient.Forms.DocumentType.Title")}
                            placeholder={t("Patient.Forms.DocumentType.Placeholder")}    >
                            <option value={"2"}>{t("Patient.Forms.DocumentType.Options.NId")}  </option>
                            <option value={"1"}>{t("Patient.Forms.DocumentType.Options.None")}  </option>
                            <option value={"3"}>{t("Patient.Forms.DocumentType.Options.Passport")}  </option>
                        </Field>
                    </div>
                    {
                        DocumentTypeValue === "1" || DocumentTypeValue === 1 ? "" :
                            <div className="col-md-3 col-sm-6">
                                <label className={`col-form-label fw-bold required fs-6`} htmlFor="DocumentNumber">
                                    <i className="fa-regular text-dark fa-hashtag me-2"></i>
                                    {DocumentTypeValue === "3" || DocumentTypeValue === 3 ? t("Patient.Forms.DocumentType.Options.Passport") : t('Patient.Forms.ID.Title')}
                                </label>
                                <Field
                                    ref={DocumentNumber => this.DocumentNumber = DocumentNumber}
                                    name="DocumentNumber"
                                    id="DocumentNumber"
                                    component={this.renderField}
                                    onBlur={e => this.checkDocumentNumber(e.target.value)}
                                    touchOnBlur={e => this.checkDocumentNumber(e.target.value)}
                                    type={"text"}
                                    required
                                    placeholder={t('Patient.Forms.ID.Placeholder')}
                                    title={t('Patient.Forms.ID.Title')}
                                    className="form-control form-control-solid"
                                />
                            </div>
                    }
                </div>

                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="FirstName"><i className="fa-regular text-dark fa-user-injured me-2"></i>{t('Patient.Forms.FirstName.Title')}</label>
                        <Field
                            ref={FirstName => this.FirstName = FirstName}
                            name="FirstName"
                            id="FirstName"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Patient.Forms.FirstName.Placeholder')}
                            title={t('Patient.Forms.FirstName.Title')}
                            className="form-control form-control-solid required"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="SecondName">{t('Patient.Forms.SecondName.Title')}</label>
                        <Field
                            ref={SecondName => this.SecondName = SecondName}
                            name="SecondName"
                            id="SecondName"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Patient.Forms.SecondName.Placeholder')}
                            title={t('Patient.Forms.SecondName.Title')}
                            className="form-control form-control-solid required"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="ThirdName">{t('Patient.Forms.ThirdName.Title')}</label>
                        <Field
                            ref={ThirdName => this.ThirdName = ThirdName}
                            name="ThirdName"
                            id="ThirdName"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Patient.Forms.ThirdName.Placeholder')}
                            title={t('Patient.Forms.ThirdName.Title')}
                            className="form-control form-control-solid required"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="LastName">{t('Patient.Forms.LastName.Title')}</label>
                        <Field
                            ref={LastName => this.LastName = LastName}
                            name="LastName"
                            id="LastName"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Patient.Forms.LastName.Placeholder')}
                            title={t('Patient.Forms.LastName.Title')}
                            className="form-control form-control-solid required"
                        />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="FirstNameEnglish">{t('Patient.Forms.EnglishName.FirstName.Title')}</label>
                        <Field
                            ref={FirstNameEnglish => this.FirstNameEnglish = FirstNameEnglish}
                            name="FirstNameEnglish"
                            id="FirstNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.FirstName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.FirstName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="SecondNameEnglish">{t('Patient.Forms.EnglishName.SecondName.Title')}</label>
                        <Field
                            ref={SecondNameEnglish => this.SecondNameEnglish = SecondNameEnglish}
                            name="SecondNameEnglish"
                            id="SecondNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.SecondName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.SecondName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="ThirdNameEnglish">{t('Patient.Forms.EnglishName.ThirdName.Title')}</label>
                        <Field
                            ref={ThirdNameEnglish => this.ThirdNameEnglish = ThirdNameEnglish}
                            name="ThirdNameEnglish"
                            id="ThirdNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.ThirdName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.ThirdName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="LastNameEnglish">{t('Patient.Forms.EnglishName.LastName.Title')}</label>
                        <Field
                            ref={LastNameEnglish => this.LastNameEnglish = LastNameEnglish}
                            name="LastNameEnglish"
                            id="LastNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.LastName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.LastName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="Sex"><i className="fa-regular text-dark me-2 fa-venus-mars"></i>{t('Patient.Forms.Sex.Title')}</label>
                        <div className="d-flex justify-content-around flex-wrap align-items-center pt-3">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field name="Sex" component="input" type="radio" value={"male"} id="sex-male" className="form-check-input" required />
                                <label className="form-check-label" htmlFor="sex-male">
                                    <i className="fa-solid me-1 fa-mars text-primary" title={t("Patient.Forms.Sex.Options.Male")}></i>
                                    {t("Patient.Forms.Sex.Options.Male")}
                                </label>
                            </div>

                            <div className="form-check form-check-custom form-check-danger form-check-solid">
                                <Field name="Sex" component="input" type="radio" value={"female"} id="sex-female" className="form-check-input" required />
                                <label className="form-check-label" htmlFor="sex-female">
                                    <i className="fa-solid me-1 fa-venus text-danger" title={t("Patient.Forms.Sex.Options.Female")}></i>
                                    {t("Patient.Forms.Sex.Options.Female")}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="CreateBirthday"><i className="fa-regular text-dark fa-cake-candles me-2"></i>{t('Patient.Forms.Birthday.Title')}</label>
                        <div className="input-group position-relative">
                            <DateRangePicker
                                ref={this.SelectBirthday}
                                initialSettings={{
                                    singleDatePicker: true,
                                    opens: 'center',
                                    autoUpdateInput: true,
                                    buttonClasses: 'btn',
                                    cancelClass: "btn-danger",
                                    applyButtonClasses: "btn-primary",
                                    showDropdowns: true,
                                    format: 'DD/MM/YYYY',
                                    timePicker: false,
                                    maxDate: `${moment().format('DD/MM/YYYY')}`,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        customRangeLabel: t('DateRangePicker.Range.customRange'),
                                        monthNames: [
                                            `${t("DateRangePicker.Months.January")}`,
                                            `${t("DateRangePicker.Months.February")}`,
                                            `${t("DateRangePicker.Months.March")}`,
                                            `${t("DateRangePicker.Months.April")}`,
                                            `${t("DateRangePicker.Months.May")}`,
                                            `${t("DateRangePicker.Months.June")}`,
                                            `${t("DateRangePicker.Months.July")}`,
                                            `${t("DateRangePicker.Months.August")}`,
                                            `${t("DateRangePicker.Months.September")}`,
                                            `${t("DateRangePicker.Months.October")}`,
                                            `${t("DateRangePicker.Months.November")}`,
                                            `${t("DateRangePicker.Months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("DateRangePicker.DaysOfWeek.Sunday"),
                                            t("DateRangePicker.DaysOfWeek.Monday"),
                                            t("DateRangePicker.DaysOfWeek.Tuesday"),
                                            t("DateRangePicker.DaysOfWeek.Wednesday"),
                                            t("DateRangePicker.DaysOfWeek.Thursday"),
                                            t("DateRangePicker.DaysOfWeek.Friday"),
                                            t("DateRangePicker.DaysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    onBlur={e => this.setBirthday(e.target.value)}
                                    pristine="false"
                                    ref={CreateBirthday => this.CreateBirthday = CreateBirthday}
                                    name="CreateBirthday"
                                    id="CreateBirthday"
                                    autoComplete="off"
                                    className="form-control form-control-solid"
                                    type="text"
                                    placeholder={t('Patient.Forms.Birthday.Placeholder')}
                                    title={t('Patient.Forms.Birthday.Title')}
                                />
                            </DateRangePicker>
                            <button className="btn btn-danger btn-icon" type="button" onClick={() => this.clearDate()}>
                                <i className="fa-regular fa-broom"></i>
                            </button>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="MotherName">{t('Patient.Forms.MotherName.Title')}</label>
                        <Field
                            ref={MotherName => this.MotherName = MotherName}
                            name="MotherName"
                            id="MotherName"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.MotherName.Placeholder')}
                            title={t('Patient.Forms.MotherName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="MotherNameEnglish">{t('Patient.Forms.EnglishName.MotherName.Title')}</label>
                        <Field
                            ref={MotherNameEnglish => this.MotherNameEnglish = MotherNameEnglish}
                            name="MotherNameEnglish"
                            id="MotherNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.MotherName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.MotherName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <SelectBloodGroup onChange={this.setBloodGroup} defaultValue={this.state.defaultBloodGroup} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <SelectCities onChange={this.setCity} defaultValue={this.state.defaultCity} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Address"><i className="fa-regular text-dark fa-location-dot me-2"></i>{t('Patient.Forms.Address.Title')}</label>
                        <Field
                            ref={Address => this.Address = Address}
                            name="Address"
                            id="Address"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.Address.Placeholder')}
                            title={t('Patient.Forms.Address.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="row mb-10">

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Email"><i className="fa-regular text-dark fa-at me-2"></i>{t('Patient.Forms.Email.Title')}</label>
                        <Field
                            ref={Email => this.Email = Email}
                            name="Email"
                            id="Email"
                            component={this.renderField}
                            type={"email"}
                            placeholder={t('Patient.Forms.Email.Placeholder')}
                            title={t('Patient.Forms.Email.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Phone"><i className="fa-regular text-dark fa-phone me-2"></i>{t('Patient.Forms.Phone.Title')}</label>
                        <Field
                            ref={Phone => this.Phone = Phone}
                            name="Phone"
                            id="Phone"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.Phone.Placeholder')}
                            title={t('Patient.Forms.Phone.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="WhatsAppNumber"> <i className="fa-brands fa-whatsapp me-2 text-success"></i>{t('Patient.Forms.WhatsAppNumber.Title')}</label>
                        <Field
                            ref={WhatsAppNumber => this.WhatsAppNumber = WhatsAppNumber}
                            name="WhatsAppNumber"
                            id="WhatsAppNumber"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.WhatsAppNumber.Placeholder')}
                            title={t('Patient.Forms.WhatsAppNumber.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                </div>

                <div className="modal-footer pb-0 px-0 justify-content-start pt-3">
                    <button type="submit" className="btn btn-sm btn-primary mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="reset" className="btn btn-sm btn-danger mx-1" title={t('Reset')} disabled={submitting} onClick={() => this.reset()}>
                        <i className="me-1 fa-solid fa-broom"></i>
                        {t('Reset')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const validate = values => {
    const errors = {};

    if (!values.FirstName) {
        errors.FirstName = 'Patient.Forms.FirstName.Error';
    }
    if (!values.SecondName) {
        errors.SecondName = 'Patient.Forms.SecondName.Error';
    }
    if (!values.ThirdName) {
        errors.ThirdName = 'Patient.Forms.ThirdName.Error';
    }
    if (!values.LastName) {
        errors.LastName = 'Patient.Forms.LastName.Error';
    }
    if (!values.Sex) {
        errors.Sex = 'Patient.Forms.Sex.Error';
    }
    if (!values.DocumentNumber && values.DocumentTypeId !== "1" && values.DocumentTypeId !== 1) {
        errors.DocumentNumber = 'Patient.Forms.ID.Error';
    }
    return errors;
}

const formPatientCreate = reduxForm({
    form: 'formPatientCreate',
    touchOnBlur: true,
    validate
});

const selector = formValueSelector('formPatientCreate');
const mapStateToProps = state => {
    return {
        formValues: getFormValues('formPatientCreate')(state),
        user: state.user,
        DocumentTypeValue: selector(state, 'DocumentTypeId')
    };
};

export default connect(mapStateToProps, { create, update })(withTranslation('common')(formPatientCreate(Form)));