import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Field, reduxForm, change } from "redux-form";
import Table from "./Table";
import Form from "./Form";
import { fetchAccounts } from "./actions";

class AccountField extends React.Component {

    state = { showModal: false, Id: 0 };

    static getDerivedStateFromProps(props, state) {
        if (props.account && props.account.Id !== state.Id) {
            state.Id = props.account.Id;
            props.dispatch(change('formSelectAccount', 'Accounts', props.account.Number + " - " + props.account.Name));
        } else if (props.account == null) {
            state.Id = 0;
            props.dispatch(change('formSelectAccount', 'Accounts', ""));
        }
        return state;
    }

    update = searchKey => {
        console.log(searchKey);
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchAccounts(searchKey);
        } else {
            this.props.fetchAccounts(this.state.searchKey);
        }
    }

    showModal = () => {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} readOnly={readOnly} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Select')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    select = currentItem => {
        this.handleClose();
        this.props.update(currentItem);
    }

    render() {
        const { t, account, accounts } = this.props;

        let isLoaded = false;
        if (accounts) {
            isLoaded = accounts.isLoaded;
        }
        return (<>
            {
                this.state.showModal ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-file-user me-2 text-info"></i>
                                {t("Accounting.Account.Select")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="w-100 mb-5">
                                <Form HasChildren={false} onSubmit={this.update} />
                            </div>
                            <div className="w-100">
                                {
                                    isLoaded ? <Table isSelectable select={this.select} /> : ""
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <div name="formSelectAccount" autoComplete="new-password">
                <div className="input-group position-relative">
                    <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6" style={{
                        zIndex: 9
                    }}>
                        <i className="fa-regular fa-magnifying-glass"></i>
                    </span>
                    <Field
                        ref={Accounts => this.Accounts = Accounts}
                        name="Accounts"
                        id="Accounts"
                        component={this.renderFieldGroup}
                        disabled="disabled"
                        readOnly={true}
                        type={"text"}
                        placeholder={t("Accounting.Account.Info")}
                        title={account ? account.Number + " - " + account.Name : t("Accounting.Account.Info")}
                        defaultValue={account ? account.Number + " - " + account.Name : ""}
                        value={account ? account.Number + " - " + account.Name : ""}
                        className="form-control form-control-solid disabled ps-10"
                    />
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        accounts: state.accounts
    };
};

const formSelectAccount = reduxForm({
    form: 'formSelectAccount'
});

export default connect(mapStateToProps, { fetchAccounts })(withTranslation('common')(formSelectAccount(AccountField)));