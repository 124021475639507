import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Card from "../Card";

class Delete extends React.Component {

    render() {
        const { t, VitalSigns } = this.props;

        return <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-trash me-2 text-danger "></i>
                    {t("VitalSigns.Delete")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card VitalSigns={VitalSigns} />
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-sm btn-primary mx-1" title={t('VitalSigns.Delete')} onClick={() => this.props.Delete()}>
                    <i className="me-1 fa-solid fa-trash"></i>
                    {t('VitalSigns.Delete')}
                </button>
                <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                    <i className="me-1 fa-solid fa-xmark"></i>
                    {t('Close')}
                </button>
            </Modal.Footer>
        </Modal>;
    }
}


export default withTranslation('common')(Delete);