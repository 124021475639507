const ClinicsReducers = (state = [], action) => {
    switch (action.type) {
        case "CLINIC_LOADING":
            return {
                clinics: [],
                toRemove: [],
                isLoaded: false
            };
        case "CLINIC_INITIAL":
            return {
                clinics: action.clinics,
                toRemove: [],
                isLoaded: true
            };
        case "CLINIC_CHANGE_LOCKED_STATUS":
            return {
                clinics: changeClincLockedStatus(state.clinics, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "CLINIC_QUEUE_FOR_REMOVAL":
            return {
                clinics: state.clinics,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "CLINIC_CLEAN_NODES":
            return {
                clinics: state.toRemove.some(v => v === action.id) ? state.clinics.filter(v => v.Id !== action.id) : state.clinics,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "CLINIC_UNDO":
            return {
                clinics: state.clinics,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "CLINIC_UPDATE":
            state.clinics = state.clinics.filter(v => v.Id !== action.id);
            if (state.clinics) {
                state.clinics.unshift(action.newNode)
            } else {
                state.clinics = [action.newNode];
            }
            return {
                clinics: state.clinics,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "CLINIC_ADD_NEW":
            if (state.clinics) {
                state.clinics.unshift(action.newNode)
            } else {
                state.clinics = [action.newNode];
            }
            return {
                clinics: state.clinics,
                toRemove: state.toRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

function changeClincLockedStatus(clinics, id) {
    const list = clinics.map(clinic => {
        if (id && clinic.Id === id) {
            return { ...clinic, IsActive: !clinic.IsActive };
        }
        return clinic;
    });
    return list;
}

export default ClinicsReducers;