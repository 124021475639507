const VisitHistoryReducers = (state = [], action) => {
    switch (action.type) {
        case "VISIT_HISTORY_LOADING":
            return {
                visitHistory: [],
                PatientId: 0,
                ClinicId: 0,
                isLoaded: false
            };
        case "VISIT_HISTORY_INITIAL":
            return {
                visitHistory: action.visitHistory,
                PatientId: action.PatientId,
                ClinicId: action.ClinicId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default VisitHistoryReducers;