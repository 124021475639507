const UsersReducers = (state = [], action) => {
    switch (action.type) {
        case "USERS_LOADING":
            return {
                users: [],
                isLoaded: false
            };
        case "USERS_INITIAL":
            return {
                users: action.users,
                isLoaded: true
            };
        case "USERS_REMOVE":
            return {
                users: state.users.filter(p => p.Id !== action.id),
                isLoaded: true
            };
        case "USERS_CHANGE_LOCKED_STATUS":
            return {
                users: changeLockedStatus(state.users, action.id),
                isLoaded: true
            };
        case "USERS_UPDATE":
            return {
                users: UpdateUser(action.newUser, state.users, action.id),
                isLoaded: true
            };
        case "USERS_CREATE":
            return {
                users: CreateNewNode(action.newNode, state.users, action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

function UpdateUser(newUser, users, id) {
    const list = users.map(user => {
        if (id && user.Id === id) {
            return { ...user, ...newUser };
        }
        return user;
    });
    return list;
}

function changeLockedStatus(users, id) {
    const list = users.map(user => {
        if (id && user.Id === id) {
            return { ...user, IsActive: !user.IsActive };
        }
        return user;
    });
    return list;
}

function CreateNewNode(newNode, nodes, id) {
    let copy = [];
    if (!id) {
        copy = [
            ...nodes,
            {
                label: newNode.label,
                id: newNode.id,
                isOpen: false,
                nodes: []
            }
        ];
    } else {
        nodes.map(node => {
            if (node.id === id) {
                node.nodes = [
                    ...node.nodes,
                    {
                        label: newNode.label,
                        id: newNode.id,
                        isOpen: false,
                        nodes: []
                    }
                ];
            }
            copy.push({
                ...node,
                nodes: CreateNewNode(newNode, node.nodes, id)
            });
            return '';
        });
    }
    return copy;
}

export default UsersReducers;