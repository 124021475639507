const ReceiptVouchersReducers = (state = [], action) => {
    switch (action.type) {
        case "RECEIPT_VOUCHERS_LOADING":
            return {
                receiptVouchers: [],
                isLoaded: false
            };
        case "RECEIPT_VOUCHERS_INITIAL":
            return {
                receiptVouchers: action.receiptVouchers,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default ReceiptVouchersReducers;