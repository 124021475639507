import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";

import serverAPI from "../../../../api/serverAPI";

class Permissions extends React.Component {

    state = { Selected: [], isSubmit: false };

    constructor(props) {
        super(props);
        const { userTypes } = this.props;
        if (!userTypes || !userTypes.userTypes || userTypes.userTypes?.length === 0) {
            this.props.fetchUserTypes();
        }
    }

    componentDidMount() {
        if (this.props.User) {
            this.setState({
                Selected: this.props.User.UserPermission.map(type => type)
            });
        }
    }

    onSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.setState({
            isSubmit: true
        });

        serverAPI.post('/UserPermission/' + this.props.User.Id, {
            UserPermissionIds: this.state.Selected.map(type => type.Id)
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.update();
            this.props.handleClose();
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    setSelected = type => {
        const objIndex = this.state.Selected.findIndex(p => p.Id === type.Id);
        let Selected = this.state.Selected;
        if (objIndex >= 0) {
            Selected = Selected.filter(p => p.Id !== type.Id);
        } else {
            Selected.push(type);
        }
        this.setState({
            Selected
        });
    }

    renderTableRow = () => {
        const { t, userTypes, User, user } = this.props;
        if (userTypes && userTypes.userTypes && userTypes.userTypes.length > 0) {

            return userTypes.userTypes.map((type) => {
                let canView = false;
                if (type.Id === 1) {
                    if (user && user.user && user.user.UserPermission.some(p => p.Id === 1)) {
                        canView = true;
                    }
                } else {
                    canView = true;
                }

                if (canView) {
                    return <tr key={`user-type-${User.Id}-${type.Id}`}>
                        <td>
                            <div className="form-check form-check-custom">
                                <input
                                    name="checkbox"
                                    type="checkbox"
                                    value={type.Id}
                                    onChange={() => this.setSelected(type)}
                                    className="form-check-input"
                                    checked={this.state.Selected.some(p => p.Id === type.Id)}
                                />
                            </div>
                        </td>
                        <td>
                            {type.Name}
                        </td>
                    </tr>
                }
                return <></>;
            });
        }
        return <tr><td className="text-center" colSpan={2}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, User, userTypes } = this.props;

        let isLoaded = false;
        if (userTypes) {
            isLoaded = userTypes.isLoaded;
        }
        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-shield-keyhole me-2 text-primary "></i>
                    {t("Table.Options.Permissions")}
                    <span className="ms-1">
                        {User.Name}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row" >
                    <div className="col-md-7 ">
                        <div className={` border-1 border-dark border-dashed table-responsive ${isLoaded ? "" : "table-loading"}`} >
                            <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                                <thead>
                                    <tr>
                                        <th title={t("Select")}>
                                            <span className="d-flex align-items-center ms-2">
                                                <i className="fa-regular text-dark fa-square-check"></i>
                                            </span>
                                        </th>
                                        <th title={t("Users.Permissions.Name")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-shield-keyhole me-md-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Users.Permissions.Name")}
                                                </span>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderTableRow()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex justify-content-between flex-column">
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                                <div className="d-flex align-items-center">
                                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                        <i className="fa-regular fa-shield-keyhole me-2 text-primary h3 mb-0"></i>
                                        {t('Users.Permissions.Selected')}
                                        :
                                        ({this.state.Selected.length})
                                    </div>
                                </div>
                            </div>
                            <div className={` border-1 p-3  mb-2`} >
                                <div className="d-flex flex-column">
                                    {
                                        this.state.Selected.map(item => {
                                            return <li key={`type-item-${item.Id}`} className="d-flex align-items-center py-2">
                                                <span className="bullet me-5 bg-primary"></span> {item.Name}
                                            </li>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                    <span className="indicator-label">
                        <i className="me-1 fa-solid fa-save"></i>
                        {t('Save')}
                    </span>
                    <span className="indicator-progress">{t('PleaseWait')}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                    <i className="me-1 fa-solid fa-xmark"></i>
                    {t('Close')}
                </button>
            </Modal.Footer>
        </Modal>;
    }
}

export default withTranslation('common')(Permissions);