import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Create from "../Create";
import Info from "./Info";
import PatientName from "../PatientName";

class Table extends React.Component {

    state = { patient: null, showEdit: false, showInfo: false };

    showEdit = patient => {
        this.setState({
            showEdit: true,
            patient
        })
    }
    handleClose = () => {
        this.setState({
            showInfo: false,
            showEdit: false
        });
    }

    showInfo = patient => {
        this.setState({
            showInfo: true,
            patient
        })
    }

    getAge = Birthday => {
        let result = "";
        if (Birthday) {
            const { t } = this.props;

            const years = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'years', false);
            const months = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'months', false);
            const days = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'days', false);

            if (years > 1) {
                result += years + " " + t("Age.Year") + " ";
                if (months % 12) {
                    result += months % 12 + " " + t("Age.Month");
                }
            } else {
                if (months > 0) {
                    result += months + " " + t("Age.Month") + " ";
                    if (days % 30) {
                        result += days % 30 + " " + t("Age.Day");
                    }
                } else {
                    result += days + " " + t("Age.Day");
                }
            }
        }
        return result;
    }

    render() {
        const { t, patients, isForSearch } = this.props;

        let isLoaded = false;
        if (patients) {
            isLoaded = patients.isLoaded;
        }

        return <>
            {this.state.showEdit ? <Create patient={this.state.patient} handleClose={this.handleClose} isUpdate /> : ""}
            {this.state.showInfo ? <Info patient={this.state.patient} handleClose={this.handleClose} /> : ""}
            {
                patients && patients.patients ?
                    <div className="position-relative">
                        <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                            minHeight: '300px'
                        }}>
                            <div className="table-loading-message">
                                {t("Processing.Title")}
                            </div>

                            <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800">
                                        <th title={t("Patient.Name")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Patient.Name")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Table.ID")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Table.ID")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Table.Phone")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-phone me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Table.Phone")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Patient.Forms.Birthday.Title")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-cake-candles me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Patient.Forms.Birthday.Title")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Patient.Forms.Address.Title")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-map me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Patient.Forms.Address.Title")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Table.Options.Title")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-gears me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Table.Options.Title")}
                                                </span>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        patients && patients.patients && patients.patients.length > 0 ?
                                            patients.patients.map(patient => {
                                                const age = this.getAge(patient.Birthday);
                                                return <tr key={`patient-row-${patient.Id}`}>
                                                    <td>
                                                        <PatientName patient={patient} />
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{patient.Id}</span>
                                                        <span className="text-primary">{patient.DocumentNumber}</span>
                                                    </td>
                                                    <td>
                                                        {patient.Phone}
                                                    </td>
                                                    <td>
                                                        {patient.Birthday}
                                                        <span title={t('Patient.Age')} className="badge badge-light-primary text-nowrap">{age}</span>
                                                    </td>
                                                    <td>
                                                        {patient.City ? patient.City.Name : ""}
                                                        {patient.City && patient.Address ? ", " + patient.Address : ""}
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            isForSearch ?
                                                                <Link to="#"
                                                                    onClick={() => this.props.select(patient)}
                                                                    title={t("Table.Options.Select")}
                                                                    className="btn btn-sm btn-icon btn-dark me-1 mb-1" >
                                                                    <i className="fa-regular fa-check"></i>
                                                                </Link>
                                                                :
                                                                <div className="dropdown " role="group" aria-label="Button group with nested dropdown">
                                                                    <div className="btn-group" role="group">
                                                                        <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="fa-regular fa-gears"></i>
                                                                            <span className="d-md-block d-none text-nowrap ms-1">
                                                                                {t("Table.Options.Title")}
                                                                            </span>
                                                                        </button>
                                                                        <div className="dropdown-menu ">
                                                                            <Link to="#"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.showEdit(patient)} >
                                                                                <i className="fa-regular fa-pen-to-square text-dark me-1"></i>
                                                                                {t("Table.Options.Edit")}
                                                                            </Link>
                                                                            <Link to="#"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.showInfo(patient)} >
                                                                                <i className="fa-regular fa-id-card-clip text-dark me-1"></i>
                                                                                {t("Table.Options.Info")}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </td>
                                                </tr>;
                                            })
                                            :
                                            <tr>
                                                <td className="text-center" colSpan={7}>{t("NoRows")}</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ""
            }
        </>;
    }
}

const mapStateToProps = state => {
    return {
        patients: state.patients
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));