const BankBranchesReducers = (state = [], action) => {
    switch (action.type) {
        case "BANK_BRANCHES_LOADING":
            return {
                bankBranches: [],
                isLoaded: false
            };
        case "BANK_BRANCHES_INITIAL":
            return {
                bankBranches: action.bankBranches,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default BankBranchesReducers;