import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change, formValueSelector } from "redux-form";
import { toast } from "react-toastify";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize) {
            state.isInitialize = true;
            props.initialize({
                Amount: props.Amount,
                Value: props.Value,
                Rate: props.Rate,
                Total: props.Amount - props.Value
            });
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    setRate = Rate => {
        if (Rate > 0 && Rate <= 100) {
            const Amount = this.props.Amount;
            const Value = Amount * Rate / 100 / 1.00;
            this.props.dispatch(change('formDiscountServices', 'Rate', Rate));
            this.props.dispatch(change('formDiscountServices', 'Value', Value));
            this.props.dispatch(change('formDiscountServices', 'Total', (Amount - Value) / 1.00));
        } else {
            this.props.dispatch(change('formDiscountServices', 'Rate', 0));
            this.props.dispatch(change('formDiscountServices', 'Value', 0));
            this.props.dispatch(change('formDiscountServices', 'Total', this.props.Amount));
        }
    }

    setValue = Value => {
        const Amount = this.props.Amount;
        if (Value > 0 && Value <= Amount) {
            const Rate = (Value / Amount * 100) / 1.00;
            this.props.dispatch(change('formDiscountServices', 'Rate', Rate.toFixed(2)));
            this.props.dispatch(change('formDiscountServices', 'Value', Value));
            this.props.dispatch(change('formDiscountServices', 'Total', (Amount - Value) / 1.00));
        } else {
            this.props.dispatch(change('formDiscountServices', 'Rate', 0));
            this.props.dispatch(change('formDiscountServices', 'Value', 0));
            this.props.dispatch(change('formDiscountServices', 'Total', this.props.Amount));
        }
    }

    onSubmit = () => {
        this.props.update({ ...this.props.formValues, });
        return false;
    }

    render() {
        const { t, handleSubmit, pristine, submitting, DiscountValue, Amount } = this.props;

        return <>
            <form method="POST" name="formDiscountServices" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0 || DiscountValue > Amount;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            } >
                <div className="row mb-5">
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Amount"><i className="fa-regular text-dark fa-sigma me-2"></i>{t('Services.Amount')}</label>
                        <Field
                            ref={Amount => this.Amount = Amount}
                            name="Amount"
                            id="Amount"
                            component={this.renderField}
                            type={"number"}
                            disabled={true}
                            placeholder={t('Services.Amount')}
                            title={t('Services.Amount')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Value"><i className="fa-regular text-dark fa-coin me-2"></i>{t('Services.Discount.Forms.Value.Title')}</label>
                        <Field
                            ref={Value => this.Value = Value}
                            name="Value"
                            id="Value"
                            component={this.renderField}
                            type={"number"}
                            onChange={e => this.setValue(e.target.value)}
                            required
                            placeholder={t('Services.Discount.Forms.Value.Placeholder')}
                            title={t('Services.Discount.Forms.Value.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Rate"><i className="fa-regular text-dark fa-percent me-2"></i>{t('Services.Discount.Forms.Rate.Title')}</label>
                        <Field
                            ref={Rate => this.Rate = Rate}
                            name="Rate"
                            id="Rate"
                            onChange={e => this.setRate(e.target.value)}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Services.Discount.Forms.Rate.Placeholder')}
                            title={t('Services.Discount.Forms.Rate.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Total"><i className="fa-regular text-dark fa-calculator me-2"></i>{t('Services.Total.Title')}</label>
                        <Field
                            ref={Total => this.Total = Total}
                            name="Total"
                            id="Total"
                            component={this.renderField}
                            type={"number"}
                            disabled={true}
                            placeholder={t('Services.Total.Title')}
                            title={t('Services.Total.Title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Value || values.Value < 0 || values.Value > values.Amount) {
        errors.Value = 'Services.Discount.Forms.Value.Error';
    }
    if (!values.Rate || values.Rate < 0 || values.Rate > 100) {
        errors.Rate = 'Services.Discount.Forms.Rate.Error';
    }
    return errors;
}

const selector = formValueSelector('formDiscountServices');
const formDiscountServices = reduxForm({
    form: 'formDiscountServices',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formDiscountServices')(state),
        validate,
        user: state.user,
        DiscountValue: selector(state, 'Value')
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(formDiscountServices(Form)));