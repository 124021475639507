import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import PatientInfo from "../../../Patients/Search/Info";
import Info from "./Info";
import PatientName from "../../../Patients/PatientName";

class Table extends React.Component {

    state = {
        patient: null,
        voucher: null,
        SearchByName: "",
        showPatientInfo: false,
        showInfo: false,
        showAccept: false
    };

    handleClose = () => {
        this.setState({
            showPatientInfo: false,
            showAccept: false,
            showInfo: false,
            voucher: null,
            patient: null
        });
    }

    showInfo = voucher => {
        this.setState({
            showInfo: true,
            voucher
        })
    }

    showPatientInfo = patient => {
        this.setState({
            showPatientInfo: true,
            patient
        })
    }

    showAccept = voucher => {
        this.setState({
            showAccept: true,
            voucher
        })
    }

    renderTableRow = () => {
        const { t, journalVouchers } = this.props;
        if (journalVouchers && journalVouchers.journalVouchers && journalVouchers.journalVouchers.length > 0) {

            let searchResult = journalVouchers.journalVouchers;
            const Names = this.state.SearchByName.split(' ');
            if (Names.length > 0) {
                Names.forEach(name => {
                    name = name.toLowerCase();
                    searchResult = searchResult.filter(p =>
                        p.CreditedAccounts?.some(c => c.Name.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.Number.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.DocumentNumber?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.Name?.FirstName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.Name?.SecondName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.Name?.ThirdName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.Name?.LastName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.NameEnglish?.FirstName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.NameEnglish?.SecondName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.NameEnglish?.ThirdName?.toLowerCase().includes(name)) ||
                        p.CreditedAccounts?.some(c => c.patient?.NameEnglish?.LastName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.Name.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.Number.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.DocumentNumber?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.Name?.FirstName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.Name?.SecondName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.Name?.ThirdName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.Name?.LastName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.NameEnglish?.FirstName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.NameEnglish?.SecondName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.NameEnglish?.ThirdName?.toLowerCase().includes(name)) ||
                        p.DebitedAccounts?.some(c => c.patient?.NameEnglish?.LastName?.toLowerCase().includes(name))
                    )
                });
            }

            return searchResult.map((item, index) => {

                const CreditedAccounts = [...new Map(item.CreditedAccounts.map(item => [item['Id'], item])).values()];
                const DebitedAccounts = [...new Map(item.DebitedAccounts.map(item => [item['Id'], item])).values()];

                return <tr key={`journal-vouchers-${index}-${item.Id}`}>
                    <td>
                        {item.Number}
                    </td>
                    <td>
                        {item.Date}
                    </td>
                    <td>
                        <div className="d-flex flex-column">
                            {
                                DebitedAccounts.map(account => {
                                    return <li key={`debited-accounts-${account.Id}`} className="d-flex align-items-center ">
                                        <span className="bullet me-2 bg-success"></span>
                                        {
                                            account.patient ?
                                                <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(account.patient)}>
                                                    <PatientName patient={account.patient} />
                                                </Link>
                                                :
                                                <span>
                                                    {account.Number + " " + account.Name}
                                                </span>
                                        }
                                    </li>
                                })
                            }
                        </div>
                    </td>
                    <td>
                        <div className="d-flex flex-column">
                            {
                                CreditedAccounts.map(account => {
                                    return <li key={`credited-accounts-${account.Id}`} className="d-flex align-items-center ">
                                        <span className="bullet me-2 bg-primary"></span>
                                        {
                                            account.patient ?
                                                <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(account.patient)}>
                                                    <PatientName patient={account.patient} />
                                                </Link>
                                                :
                                                <span>
                                                    {account.Number + " " + account.Name}
                                                </span>
                                        }
                                    </li>
                                })
                            }
                        </div>
                    </td>
                    <td>
                        {item.Value}
                        <span className="ms-1">
                            {item.Currency?.Name}
                        </span>
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    Accept = () => {
        this.handleClose();
        this.props.update();
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    render() {
        const { t, journalVouchers } = this.props;

        let isLoaded = false;
        if (journalVouchers) {
            isLoaded = journalVouchers.isLoaded;
        }
        return <>
            {this.state.showPatientInfo && this.state.patient ? <PatientInfo patient={this.state.patient} handleClose={this.handleClose} /> : ""}
            {this.state.showInfo && this.state.voucher ? <Info Voucher={this.state.voucher} handleClose={this.handleClose} /> : ""}

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
            </div>
            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("Accounting.VoucherNumber")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.VoucherNumber")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Accounting.JournalVoucher.Forms.Date.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.JournalVoucher.Forms.Date.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Accounting.JournalVoucher.Forms.DebitedAccounts.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-scale-unbalanced-flip me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.JournalVoucher.Forms.DebitedAccounts.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Accounting.JournalVoucher.Forms.CreditedAccounts.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-scale-unbalanced me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.JournalVoucher.Forms.CreditedAccounts.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Accounting.JournalVoucher.Forms.Value.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-sack-dollar me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.JournalVoucher.Forms.Value.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        journalVouchers: state.journalVouchers
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));