import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Layout from "./Layout";
import Menu from "./Menu";
import history from "./history";
// import NotificationsMenu from "./Notifications/NotificationsMenu";

class App extends React.Component {

  state = {
    closeAside: false
  };

  componentDidMount() {
    this.setState({
      closeAside: false
    });
    setTimeout(() => {
      const titleList = document.title.split('|');
      if (titleList && titleList[0]) {
        this.setState({
          title: titleList[0]
        });
      }
    }, 100);

    this.unlisten = history.listen(() => {
      setTimeout(() => {
        const titleList = document.title.split('|');
        if (titleList && titleList[0]) {
          this.setState({
            title: titleList[0]
          });
        }
      }, 100);

    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  setCloseAside = () => {
    const aside = document.getElementById('kt_aside');

    const body = document.getElementsByTagName('body')[0];

    if (this.state.closeAside) {
      aside.classList.remove('drawer');
      aside.classList.remove('drawer-start');
      aside.classList.remove('drawer-on');
      body.setAttribute('data-kt-drawer-aside', "off");
      body.setAttribute('data-kt-drawer', "off");
    } else {
      aside.classList.add('drawer');
      aside.classList.add('drawer-start');
      aside.classList.add('drawer-on');
      body.setAttribute('data-kt-drawer-aside', "on");
      body.setAttribute('data-kt-drawer', "on");
    }

    this.setState({
      closeAside: !this.state.closeAside
    });
  }

  render() {
    const { t, user, Notifications } = this.props;
    return (
      <div className="page d-flex flex-row flex-column-fluid">
        <Menu />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <div id="kt_header" className="header align-items-stretch">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
              <div className="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                  <Link to="/Profile" className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                    <i className="fa-solid fa-user me-2 text-dark fs-3"></i>
                    {user && user.user ? user.user.Name : ""}
                  </Link>
                  <span className="h-20px border-gray-200 border-start mx-3"></span>
                  <div className="mx-1">
                    {/* <NotificationsMenu /> */}
                  </div>
                  <span className="h-20px border-gray-200 border-start mx-3"></span>
                  <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                      <Link to={"/"}>
                        {t('ControlPanel')}
                      </Link>
                    </li>
                    {
                      this.state.title ?
                        <>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                          </li>
                          <li className="breadcrumb-item text-dark">
                            {this.state.title}
                          </li>
                        </>
                        : ""
                    }

                  </ul>
                </div>
                <div className="d-flex align-items-center py-1">


                </div>
              </div>

              <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu" >
                <Link to="/Notifications" className="btn btn-icon btn-light-primary btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px" title={t('Notifications.Title')}>
                  <i className="fa-duotone fa-bell "></i>
                  {
                    Notifications && Notifications.count > 0 ?
                      <span className="position-absolute top-0 start-100 translate-middle badge badge-sm badge-circle badge-danger"> {Notifications.count}</span>
                      : ""
                  }
                </Link>
                <Link to="/Profile" className="mx-2 btn btn-icon btn-light-info btn-active-light-info position-relative w-30px h-30px w-md-40px h-md-40px" title={user && user.user ? user.user.Name : ""}>
                  <i className="fa-duotone fa-user "></i>
                </Link>
                <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle" onClick={() => this.setCloseAside()} >
                  <span className="svg-icon svg-icon-2x mt-1">
                    <i className="h2 fa-solid fa-bars"></i>
                  </span>
                </div>

              </div>

            </div>
          </div>

          <div id="kt_content" className="content d-flex flex-column flex-column-fluid">

            <div className="post d-flex flex-column-fluid" id="kt_post">
              <div id="kt_content_inner" className="container-xxl">
                <div className="row gy-5 g-xl-8 mt-1">
                  <Layout />
                </div>
              </div>
            </div>

          </div>

          <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
              <div className="text-dark order-2 order-md-1">
                <span className="text-gray-800 text-hover-primary">
                  {t('CopyRights')}
                  <i className="fa-regular fa-copyright mx-1"></i>
                </span>
                <span className="text-muted fw-bold">
                  {new Date().getFullYear()}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    Notifications: state.Notifications
  };
};

export default connect(mapStateToProps, {})(withTranslation('common')(App));