const PatientBillsReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_BILLS_LOADING":
            return {
                patientBills: [],
                isLoaded: false
            };
        case "PATIENT_BILLS_INITIAL":
            return {
                patientBills: action.patientBills,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientBillsReducers;