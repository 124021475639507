import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import 'moment/locale/ar';

import serverAPI from "../../../../api/serverAPI";

class LoginHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            startDate: `${moment().subtract(15, 'days').format('DD/MM/YYYY')}`,
            endDate: `${moment().format('DD/MM/YYYY')}`,
            date: `${moment().subtract(15, 'days').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`
        };
    }

    componentDidMount() {
        this.updateData();
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    updateData = () => {
        serverAPI.get('/LoginHistory/?startDate=' + this.state.startDate + "&endDate=" + this.state.endDate,).then(response => {
            this.setState({
                Data: response.data.data
            });
        });
    }

    renderInfo = () => {
        if (this.state.Data) {
            return this.state.Data.map(item => {
                return <tr key={`login_history_info_${item.Id}`}>
                    <td>{item.CreationDate}</td>
                    <td>
                        <div>
                            <div>{item.Details.IP}</div>
                            <div>{item.Details.UserAgent.split(')').map((browser, index) => {
                                return <div key={`login_history_browser_${item.Id}_${index}`}> {browser + (index < 2 ? ")" : "")} </div>
                            })}</div>
                        </div>
                    </td>
                </tr>
            });
        }
        return "";
    }

    render() {
        const { t } = this.props;

        let ranges = {};
        ranges[t("DateRangePicker.Range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("DateRangePicker.Range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("DateRangePicker.Range.Last7Days")] = [moment().subtract(6, 'days'), moment(), moment().toDate()];
        ranges[t("DateRangePicker.Range.Last30Days")] = [moment().subtract(30, 'days'), moment(), moment().toDate()];
        ranges[t("DateRangePicker.Range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("DateRangePicker.Range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("DateRangePicker.Range.ThisYear")] = [moment().startOf('year'), moment().endOf('year')];

        return (<>
            <Helmet>
                <title>{t("Pages.LoginHistory")} | {t('SiteName')}</title>
            </Helmet>

            <div className="card">
                <div className="card-header">
                    <div className="card-title fs-3 fw-bolder">
                        <i className="h3 fa-duotone fa-history me-2 text-primary"></i>
                        {t("Pages.LoginHistory")}
                    </div>
                    <div className="card-toolbar m-0 align-items-center d-flex">
                        <div className="d-flex">
                            <DateRangePicker
                                ref={Date => this.Date = Date}
                                initialSettings={{
                                    opens: 'center',
                                    ranges: ranges,
                                    autoUpdateInput: false,
                                    buttonClasses: 'btn',
                                    cancelClass: "btn-danger",
                                    applyButtonClasses: "btn-primary",
                                    showDropdowns: true,
                                    timePicker: false,
                                    maxDate: `${moment().format('DD/MM/YYYY')}`,
                                    minYear: 2021,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        customRangeLabel: t('DateRangePicker.Range.customRange'),
                                        monthNames: [
                                            `${t("DateRangePicker.Months.January")}`,
                                            `${t("DateRangePicker.Months.February")}`,
                                            `${t("DateRangePicker.Months.March")}`,
                                            `${t("DateRangePicker.Months.April")}`,
                                            `${t("DateRangePicker.Months.May")}`,
                                            `${t("DateRangePicker.Months.June")}`,
                                            `${t("DateRangePicker.Months.July")}`,
                                            `${t("DateRangePicker.Months.August")}`,
                                            `${t("DateRangePicker.Months.September")}`,
                                            `${t("DateRangePicker.Months.October")}`,
                                            `${t("DateRangePicker.Months.November")}`,
                                            `${t("DateRangePicker.Months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("DateRangePicker.DaysOfWeek.Sunday"),
                                            t("DateRangePicker.DaysOfWeek.Monday"),
                                            t("DateRangePicker.DaysOfWeek.Tuesday"),
                                            t("DateRangePicker.DaysOfWeek.Wednesday"),
                                            t("DateRangePicker.DaysOfWeek.Thursday"),
                                            t("DateRangePicker.DaysOfWeek.Friday"),
                                            t("DateRangePicker.DaysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    defaultValue={this.state.date}
                                    name="Date"
                                    autoComplete="off"
                                    required
                                    className="form-control form-control-solid ps-10"
                                    type="text"
                                    placeholder={t('AdvancedSearch.Search.ByDate')}
                                    title={t('AdvancedSearch.Search.ByDate')}
                                />
                            </DateRangePicker>
                            <div>
                                <button className="btn btn-primary ms-2 btn-sm" onClick={() => this.updateData()}>
                                    <i className="fa-regular fa-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">

                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-6">
                        <i className="fa-regular fa-circle-info h1 text-primary me-4 mb-0"></i>
                        <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-bold">
                                <div className="fs-6 text-gray-700">
                                    {t("Pages.Info.LoginHistory")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table id="kt_datatable_example_1" className="table table-striped table-row-bordere table-hover align-middle table-row-dashed fs-6 gy-5">
                            <thead>
                                <tr>
                                    <th>
                                        {t("Date")}
                                    </th>
                                    <th>
                                        {t("Table.Options.Info")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderInfo()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>);
    }

}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(LoginHistory));