
import React from "react";

class PrintHeader extends React.Component {

    render() {
        return <thead style={{
            height: '100px'
        }}>
            <tr>
                <td>
                    <div className="position-fixed top-0" >header</div>
                </td>
            </tr>
        </thead>
    }

}

export default PrintHeader;