import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";

import Table from "./Table";
import Form from "./Form";
import { fetchPatientForms as fetch } from "./actions";
import PatientName from "../../Patients/PatientName";

class PatientForms extends React.Component {

    state = { showCreateNew: false, isEdit: false, patientForm: null };

    fetch = () => {
        if (this.props.patient) {
            this.props.fetch(this.props.patient.Id);
        }
    }

    update = () => {
        this.fetch();
        this.handleClose();
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false,
            isEdit: false,
            patientForm: null
        });
    }

    showEdit = patientForm => {
        this.setState({
            showCreateNew: true,
            isEdit: true,
            patientForm
        });
    }

    render() {
        const { t, patient } = this.props;
        return <>
            {
                this.state.showCreateNew ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                {
                                    this.state.isEdit ?
                                        <>
                                            <i className="h3 fa-duotone fa-edit me-2 text-primary "></i>
                                            {t("PatientForms.Edit")}
                                        </>
                                        :
                                        <>
                                            <i className="h3 fa-duotone fa-plus me-2 text-primary "></i>
                                            {t("PatientForms.New")}
                                        </>
                                }
                                &nbsp;
                                (
                                {patient && patient.Name ? <PatientName patient={patient} /> : ""}
                                )
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form  {...this.props} patientForm={this.state.patientForm} isEdit={this.state.isEdit} update={this.update} handleClose={this.handleClose} />
                        </Modal.Body>
                    </Modal> : ""
            }
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-file-lines me-2 text-primary "></i>
                        {t("PatientForms.Title")}&nbsp;
                        (
                        {patient && patient.Name ? <PatientName patient={patient} /> : ""}
                        )
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table {...this.props} showCreateNew={this.showCreateNew} update={this.update} showEdit={this.showEdit} />
                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        patientForms: state.patientForms
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(PatientForms));