import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Field, getFormValues, reduxForm } from "redux-form";
import { toast } from "react-toastify";

import { fetchDiagnoses as fetch, updateFavourite } from "./actions";
import serverAPI from "../../api/serverAPI";

class Table extends React.Component {

    onSubmit = event => {
        event.preventDefault();
        this.props.fetch({ ...this.props.formValues });
        return false;
    }

    removeFromFavourite = diagnose => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        serverAPI.delete(`/FavouriteDiagnoses/${diagnose.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t(`toast.Delete.Title`)}
                    </div>
                    <div className="h6">
                        {t(`toast.Delete.Body`)}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.updateFavourite({ ...diagnose, isFavouriteDiagnoses: false }, diagnose.Id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    setAsFavourite = diagnose => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        serverAPI.post("/FavouriteDiagnoses/", {
            CodeId: diagnose.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t(`toast.Save.New.Title`)}
                    </div>
                    <div className="h6">
                        {t(`toast.Save.New.Body`)}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.updateFavourite({ ...diagnose, isFavouriteDiagnoses: true }, diagnose.Id);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    render() {
        const { t, pristine, submitting, diagnoses, isSelectable } = this.props;

        let isLoaded = false;
        if (diagnoses && diagnoses.diagnoses) {
            isLoaded = diagnoses.isLoaded;
        } else if (diagnoses.length === 0) {
            isLoaded = true;
        }

        return <>
            <form method="POST" name="formDiagnosisSearch" className="form fv-plugins-bootstrap5 fv-plugins-framework mb-4" autoComplete="new-password"
                onSubmit={e => this.onSubmit(e)}>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="d-flex align-items-center mb-2">
                        <div className="input-group input-group-sm">
                            <Field
                                ref={Code => this.Code = Code}
                                name="Code"
                                id="Search_SearchByCode"
                                component={this.renderField}
                                autoComplete={"off"}
                                type={"text"}
                                placeholder={t('AdvancedSearch.Search.ByCode')}
                                title={t('AdvancedSearch.Search.ByCode')}
                                className="form-control" />
                            <span className="input-group-text">
                                <i className="fa-solid fa-book-medical text-dark"></i>
                            </span>
                            <Field
                                ref={Note => this.Note = Note}
                                name="Note"
                                id="Search_SearchByName"
                                component={this.renderField}
                                autoComplete={"off"}
                                type={"text"}
                                placeholder={t('AdvancedSearch.Search.ByName')}
                                title={t('AdvancedSearch.Search.ByName')}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="form-check form-switch form-check-custom form-check-solid form-check-success mb-2" title={t('AdvancedSearch.Search.ByFavouriteDiagnoses')}>
                        <Field
                            ref={isFavouriteDiagnoses => this.isFavouriteDiagnoses = isFavouriteDiagnoses}
                            name="isFavouriteDiagnoses"
                            id="Search_isFavouriteDiagnoses"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"checkbox"}
                            placeholder={t('AdvancedSearch.Search.ByName')}
                            title={t('AdvancedSearch.Search.ByName')}
                            className="form-check-input h-20px w-30px"
                        />
                        <label className="form-check-label" htmlFor="Search_isFavouriteDiagnoses">
                            {t('AdvancedSearch.Search.ByFavouriteDiagnoses')}
                        </label>
                    </div>
                    <button type="submit" className="btn btn-sm btn-info mb-2" title={t('Search')} disabled={pristine || submitting || !isLoaded} data-kt-indicator={!isLoaded ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-magnifying-glass"></i>
                            {t('Search')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </form >
            {
                diagnoses && diagnoses.diagnoses ?
                    <div className="position-relative">
                        <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                            minHeight: '300px'
                        }}>
                            <div className="table-loading-message">
                                {t("Processing.Title")}
                            </div>
                            <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                <thead>
                                    <tr>
                                        <th title={t("Diagnosis.Code")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-code me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Diagnosis.Code")}
                                                </span>
                                            </span>
                                        </th>
                                        <th title={t("Diagnosis.Title")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-book-medical me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Diagnosis.Title")}
                                                </span>
                                            </span>
                                        </th>

                                        <th title={t("Table.Options.Title")}>
                                            <span className="d-flex align-items-center">
                                                <i className="fa-regular text-dark fa-gears me-1"></i>
                                                <span className="d-md-block d-none text-nowrap">
                                                    {t("Table.Options.Title")}
                                                </span>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        diagnoses.diagnoses.length > 0 ?
                                            diagnoses.diagnoses.map(item => {
                                                return <tr key={`diagnoses-${item.Id}`}>
                                                    <td>{item.Code}</td>
                                                    <td>{item.Note}</td>
                                                    <td>
                                                        <Link
                                                            to="#"
                                                            onClick={() => item.isFavouriteDiagnoses ? this.removeFromFavourite(item) : this.setAsFavourite(item)}
                                                            className={`btn btn-sm btn-icon ${item.isFavouriteDiagnoses ? " btn-danger" : " btn-light-danger"} `} >
                                                            <i className="fa-solid fa-heart"></i>
                                                        </Link>
                                                        {
                                                            isSelectable ?
                                                                <Link
                                                                    to="#"
                                                                    onClick={() => this.props.select(item)}
                                                                    className="btn btn-sm btn-icon btn-light-dark ms-1"  >
                                                                    <i className="fa-solid fa-check"></i>
                                                                </Link>
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr><td className="text-center" colSpan={3}>{t("NoResult")}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ""
            }
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formDiagnosisSearch')(state),
        diagnoses: state.diagnoses
    };
};

const formDiagnosisSearch = reduxForm({
    form: 'formDiagnosisSearch',
    enableReinitialize: true,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetch, updateFavourite })(withTranslation('common')(formDiagnosisSearch(Table)));