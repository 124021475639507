import React from "react";
import { withTranslation } from 'react-i18next'


class TreeView extends React.Component {

    setCurrentItem = item => {
        item.isOpen = !item.isOpen;
        this.props.setCurrentItem(item);
    }

    renderTree(treeNode, hasType, isOpen) {
        const { t } = this.props;

        hasType = hasType === false ? (this.props.Search ? this.HasType(this.props.Search, treeNode) : true) : true;
        if (hasType) {
            return (
                <>
                    <div className="timeline-line w-40px"></div>
                    <div className="timeline-icon symbol symbol-circle symbol-40px me-4" onClick={() => this.setCurrentItem(treeNode)}>
                        <div className="symbol-label bg-light ">
                            <i className="fas fa-house-medical-flag text-primary"></i>
                        </div>
                    </div>
                    <div className="timeline-content mb-0 mt-n1">
                        <div className="pe-3 my-3 d-flex justify-content-between" >
                            <div onClick={() => this.setCurrentItem(treeNode)}>
                                <div>
                                    {treeNode.label}

                                </div>
                                {
                                    treeNode.DoctorName ? <div className="ms-10 fs-8 mt-2">
                                        <i className="fas fa-user-doctor text-primary me-2"></i>
                                        {treeNode.DoctorName}
                                    </div> : ""
                                }
                            </div>
                            {
                                this.props.isAdmin ?
                                    <div>
                                        <div className="btn btn-icon btn-light btn-active-light-info me-1" title={t('Table.Options.Info')} onClick={() => this.props.showInfoModal(treeNode)} >
                                            <i className="fa-solid fa-info "></i>
                                        </div>
                                        <div className="btn btn-icon btn-light btn-active-light-success me-1" title={t('Table.Options.Edit')} onClick={() => this.props.showEditModal(treeNode)} >
                                            <i className="fa-solid fa-edit"></i>
                                        </div>
                                        <div className="btn btn-icon btn-light btn-active-light-primary me-1" title={t('New')} onClick={() => this.props.showNewModal(treeNode)} >
                                            <i className="fa-solid fa-plus"></i>
                                        </div>
                                        {
                                            (treeNode.nodes && treeNode.nodes.length === 0) ?
                                                <div className="btn btn-icon btn-light btn-active-light-danger me-1" title={t('Table.Options.Delete')} onClick={() => this.props.showDelete(treeNode)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </div> : ""
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className="btn btn-icon btn-light btn-active-light-info me-1" title={t('Table.Options.Select')} onClick={() => this.props.select(treeNode)} >
                                            <i className="fa-solid fa-check "></i>
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            (treeNode.nodes && treeNode.nodes.length > 0 && isOpen && hasType) ?
                                <div className="timeline mb-10 border-bottom border-bottom-dashed border-gray-300 pl-0">
                                    {this.renderSubNode(treeNode.nodes, hasType)}
                                </div>
                                : ""
                        }
                    </div>
                </>
            );
        }
        return "";
    }

    HasType(type, node) {
        if (node) {
            return (node.label ? node.label.toLowerCase().includes(type?.toLowerCase()) : false) || this.hasSelectedNodeTypeInChildren(type, node.nodes);
        }
        return false;
    }

    hasSelectedNodeTypeInChildren(type, nodes) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                result = result || (node.label ? node.label.toLowerCase().includes(type?.toLowerCase()) : false) || this.hasSelectedNodeTypeInChildren(type, node.nodes);
            });
        }
        return result;
    }

    HasNode(node) {
        if (node) {
            return (this.props.currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                result = result || (this.props.currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes);
            });
        }
        return result;
    }

    renderSubNode(nodes, hasType) {
        if (nodes) {
            return nodes.map((node, key) =>
                this.props.type && node.type !== this.props.type ? "" :
                    <div className="timeline-item" key={`node-${key}`}>
                        {this.renderSubNodeIsOpen(node, hasType)}
                    </div>
            );
        }
    }

    renderSubNodeIsOpen(node, hasType) {
        const isOpen = this.HasNode(node) || (this.props.Search && this.HasType(this.props.Search, node));
        return this.renderTree(node, hasType, isOpen);
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    render() {
        const { Search, startFrom } = this.props;
        const hasSearch = Search ? this.HasType(this.props.Search, this.props.data) : true;

        let data = this.props.data;
        if (startFrom) {
            data = this.GetNode(data, startFrom);
        }
        return (
            <>

                <div className="timeline">
                    {
                        data ? this.renderSubNode(data, hasSearch) : ""
                    }
                </div>
            </>
        );
    }
}

export default withTranslation('common')(TreeView);