const PatientDrugsReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_DRUGS_LOADING":
            return {
                patientDrugs: [],
                PatientId: 0,
                isLoaded: false
            };
        case "PATIENT_DRUGS_INITIAL":
            return {
                patientDrugs: action.patientDrugs,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientDrugsReducers;