import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import SelectDrugs from "./SelectDrugs";
import serverAPI from "../../../../api/serverAPI";
import SelectField from "../../../shared/SelectField";

import { fetchPharmacies } from '../actions';
import Dosage from "../Modal/Dosage";
import DrugAllergyAlert from "../../DrugAllergy/DrugAllergyAlert";
import ChronicDiseaseAlert from "../../ChronicDisease/ChronicDiseaseAlert";

class Form extends React.Component {

    state = { Id: 0, isSubmit: false, SelectedDrugs: [], pharmacies: [], CurrentDrugCenters: "", initialize: false, showDosage: false, Drug: null };

    constructor(props) {
        super(props);
        const { pharmacies } = this.props;
        if (!pharmacies || !pharmacies.pharmacies || pharmacies.pharmacies?.length === 0) {
            this.props.fetchPharmacies();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.pharmacies && state.pharmacies.length === 0 && props.pharmacies.pharmacies) {
            state.pharmacies = [];
            props.pharmacies.pharmacies.forEach(item => {
                state.pharmacies.push({
                    ...item,
                    id: item.Id,
                    label: item.HealthCenter.Name + " - " + item.Name
                });
            });
        }
        if (!state.initialize && props.isEdit && props.Order) {
            state.initialize = true;
            props.initialize({
                OrderNote: props.Order.Note
            });
            state.Id = props.Order.Id;
            state.SelectedDrugs = props.Order.items;
            state.CurrentDrugCenters = props.Order.Pharmacy;
        }
        return state;
    }

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} readOnly={readOnly} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Select')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderTextArea = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        rows,
        meta: { touched, error }
    }) => (
        <>
            <textarea {...input} id={id} title={title} rows={rows} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    onSubmit = event => {
        const { t, Appointment } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let method = serverAPI.post;
        if (this.props.isEdit === true) {
            method = serverAPI.put;
        }
        method(`/VisitDrugOrders/${Appointment.Id}`, {
            ...this.props.formValues,
            DrugOrderId: this.state.Id,
            PatientId: Appointment.patient.Id,
            ClinicId: this.props.currentClinic.Id,
            PharmacyId: this.state.CurrentDrugCenters ? this.state.CurrentDrugCenters.Id : null,
            Drugs: this.state.SelectedDrugs.map((item) => { return { Id: item.Drug.Id, Dosage: item.Dosage } })
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Order.Drug.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Order.Drug.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
            this.props.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return event.preventDefault();
    }

    setSelectedDrugs = SelectedDrugs => {
        this.setState({
            SelectedDrugs
        });
    }

    setCurrentDrugCenters = CurrentDrugCenters => {
        this.setState({
            CurrentDrugCenters
        });
    }

    handleCloseDosage = () => {
        this.setState({
            showDosage: false,
            Drug: null
        });
    }

    EditDrug = Drug => {
        this.setState({
            showDosage: true,
            Drug
        });
    }

    submitSelectedDrugs = Dosage => {
        let SelectedDrugs = this.state.SelectedDrugs;
        const objIndex = SelectedDrugs.findIndex(p => p.Drug.Id === this.state.Drug.Drug.Id);
        if (objIndex >= 0) {
            SelectedDrugs[objIndex].Dosage = Dosage;
            this.setState({
                SelectedDrugs
            });
            this.handleCloseDosage();
        }
    }

    render() {
        const { t, pristine, submitting } = this.props;
        return (<>
            {
                this.state.showDosage && this.state.Drug ? <Dosage isEdit Drug={this.state.Drug.Drug} Dosage={this.state.Drug.Dosage} onSubmit={this.submitSelectedDrugs} handleClose={this.handleCloseDosage} /> : ""
            }
            <div className="row mb-5" >
                <div className="col-md-6 ">
                    <DrugAllergyAlert />
                </div>
                <div className="col-md-6 ">
                    <ChronicDiseaseAlert />
                </div>
            </div>
            <div className="row mb-5" >
                <div className="col-md-7 ">
                    <SelectDrugs SelectedDrugs={this.state.SelectedDrugs} setSelectedDrugs={this.setSelectedDrugs} />
                </div>
                <div className="col-md-5">
                    <div className="d-flex justify-content-between flex-column">
                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                    <i className="fa-regular fa-pills me-2 text-primary h3 mb-0"></i>
                                    {t('ClinicScreen.Orders.Drug.SelectedDrugs')}
                                    :
                                    ({this.state.SelectedDrugs.length})
                                </div>
                            </div>
                        </div>
                        <div className={`scroll h-350px border-1 p-3  mb-2`} >
                            <div className="d-flex flex-column">
                                {
                                    this.state.SelectedDrugs.map(item => {
                                        return <li key={`drug-item-${item.Drug.Id}`} className="d-flex align-items-center py-2">
                                            <span className="bullet me-5 bg-primary"></span>
                                            <div className="d-flex flex-column w-100">
                                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                                    <div>
                                                        {item.Drug.Name}
                                                    </div>
                                                    <div className="btn btn-sm btn-icon btn-light-warning" onClick={() => this.EditDrug(item)}>
                                                        <i className="fa-regular fa-edit"></i>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <span className="d-flex align-items-center ms-5" title={t('ClinicScreen.Orders.Drug.Dosage.Title')}>
                                                            <i className="fa-regular text-dark fa-capsules me-2"></i>
                                                            {item.Dosage.Dosage}
                                                        </span>
                                                        <i className="fa-regular fa-xmark mx-2 text-primary"></i>
                                                        <span className="d-flex align-items-center" title={t("ClinicScreen.Orders.Drug.Dosage.Repeat.Title")}>
                                                            <i className="fa-regular text-dark fa-tally me-2"></i>
                                                            {item.Dosage.Repeat}
                                                        </span>
                                                        <i className="fa-regular fa-xmark mx-2 text-primary"></i>
                                                        <span className="d-flex align-items-center" title={t("ClinicScreen.Orders.Drug.Dosage.Days.Title")}>
                                                            <i className="fa-regular text-dark fa-hashtag me-2"></i>
                                                            {item.Dosage.Days}
                                                        </span>
                                                    </div>
                                                    {
                                                        item.Dosage.Time ? <>
                                                            <span className="d-flex align-items-center ms-5" title={t("ClinicScreen.Orders.Drug.Dosage.Time.Title")}>
                                                                <i className="fa-regular text-dark fa-clock me-2"></i>
                                                                {t(`ClinicScreen.Orders.Drug.Dosage.Time.Options.${item.Dosage.Time}`)}
                                                            </span>
                                                        </> : ""
                                                    }

                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <div className="row w-100 ">
                                {
                                    this.state.pharmacies?.length > 0 ?
                                        <SelectField
                                            Text={this.state.CurrentDrugCenters ? this.state.CurrentDrugCenters.HealthCenter.Name + " - " + this.state.CurrentDrugCenters.Name : ""}
                                            Title={t("ClinicScreen.Orders.Drug.Center.Title")}
                                            Placeholder={t("ClinicScreen.Orders.Drug.Center.Placeholder")}
                                            update={this.setCurrentDrugCenters}
                                            data={this.state.pharmacies} /> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form method="POST" name="formNewDrugOrder" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={this.onSubmit}>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="OrderNote"><i className="fa-regular text-dark fa-note me-2"></i>{t('ClinicScreen.Orders.Forms.Note.Title')}</label>
                        <Field
                            ref={OrderNote => this.OrderNote = OrderNote}
                            name="OrderNote"
                            id="OrderNote"
                            rows={5}
                            component={this.renderTextArea}
                            placeholder={t('ClinicScreen.Orders.Forms.Note.Placeholder')}
                            title={t('ClinicScreen.Orders.Forms.Note.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('ClinicScreen.Orders.Drug.Send')} disabled={(pristine || submitting || this.state.isSubmit || this.state.SelectedDrugs.length === 0) && (this.state.SelectedDrugs.length === 0 || this.state.isSubmit)} data-kt-indicator={this.state.isSubmit ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-prescription"></i>
                            {t('ClinicScreen.Orders.Drug.Send')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose} disabled={submitting}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formNewDrugOrder')(state),
        pharmacies: state.pharmacies,
        user: state.user
    };
};

const formNewDrugOrder = reduxForm({
    form: 'formNewDrugOrder',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetchPharmacies })(withTranslation('common')(formNewDrugOrder(Form)));