import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";

import PrintOrder from "../../PrintTemplate/PrintOrder";

class PrintResult extends React.Component {

    renderTableRow = () => {
        const { t, Order, labExamResults } = this.props;
        if (Order && Order.items && Order.items.length > 0) {
            let searchResult = Order.items;

            return searchResult.map((exam) => {
                var examResult = labExamResults.labExamResults?.filter(p => p.Exam.Id === exam.Exam.Id && p.IsApproved === true)[0];
                if (examResult?.Result) {
                    return <tr className="text-dark" key={`lab-exam-result-${exam.Exam.Id}`}>
                        <td>
                            {exam.Exam.Type.Name}
                        </td>
                        <td>
                            {exam.Exam?.Name}
                        </td>
                        <td>
                            {examResult?.Result}
                        </td>
                        <td>

                        </td>
                    </tr>
                }
                return "";
            });
        }
        return <tr><td className="text-center" colSpan={4}>{t("NoRows")}</td></tr >;
    }

    renderResult = () => {
        const { t, Order } = this.props;

        return <>
            <table className="table table-sm table-row-gray-800 table-rounded border table-bordered w-100 mt-3 mb-5" style={{
                borderCollapse: 'collapse'
            }}>
                <thead className="fs-6 fw-bold align-middle border border-gray-800"  >
                    <tr>
                        <th title={t("ClinicScreen.Orders.Lab.Table.Type")}  >
                            <span className="d-flex align-items-center">
                                <i className="fa-regular text-dark fa-flask-vial mx-1"></i>
                                {t("ClinicScreen.Orders.Lab.Table.Type")}
                            </span>
                        </th>
                        <th title={t("ClinicScreen.Orders.Lab.Table.Name")}  >
                            <span className="d-flex align-items-center">
                                <i className="fa-regular text-dark fa-vial mx-1"></i>
                                {t("ClinicScreen.Orders.Lab.Table.Name")}
                            </span>
                        </th>
                        <th title={t("Lab.Results.Table.Result")} >
                            <span className="d-flex align-items-center">
                                <i className="fa-regular text-dark fa-vial-virus mx-1"></i>
                                {t("Lab.Results.Table.Result")}
                            </span>
                        </th>
                        <th title={t("Lab.Results.Table.Range.Title")}  >
                            <span className="d-flex align-items-center">
                                <i className="fa-regular text-dark fa-tags mx-1"></i>
                                {t("Lab.Results.Table.Range.Title")}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody className="fs-6 fw-semibold text-gray-800 border border-gray-800" style={{
                    borderCollapse: 'collapse'
                }}>
                    {this.renderTableRow()}
                </tbody>
            </table>
            <div className="d-flex justify-content-between my-5">
                <div className="d-flex">
                    <div className="fw-bold mx-2 d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-1">
                            {Order.LabCenter.HealthCenter?.Name}
                        </div>
                        <div>
                            {Order.LabCenter.Name}
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="fw-bold mx-2 d-flex flex-column justify-content-center align-items-center">
                        <div className="mb-1">
                            {t('AprovedByUser')}
                        </div>
                        <div>
                            {Order.LabUser?.Name}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    render() {
        const { t } = this.props;

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-print me-2 text-primary "></i>
                    {t(`Print`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center"></div>
                    <div className="d-flex align-items-center">
                        <ReactToPrint
                            copyStyles={true}
                            documentTitle={t("ClinicScreen.Orders.Lab.Result")}
                            trigger={() => {
                                return <Link to="#" className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center" title={t('Print')}>
                                    <i className="fa-regular fa-print"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t('Print')}
                                    </span>
                                </Link>;
                            }}
                            content={() => this.componentRef}
                        />
                    </div>
                </div>

                {this.renderResult()}

                <div ref={el => (this.componentRef = el)}>
                    <PrintOrder patient={this.props.Order.patient} content={this.renderResult()} />
                </div>

            </Modal.Body>
        </Modal>
    }
}
export default withTranslation('common')(PrintResult);