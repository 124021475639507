import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import SelectExam from "./SelectExam";

class Exams extends React.Component {

    render() {
        const { t } = this.props;

        return (<>
            <Helmet>
                <title>{t("Radiology.Exams.Current.Title")} | {t('SiteName')}</title>
            </Helmet>

            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-biohazard me-2 text-info mb-0"></i>
                            {t('Radiology.Exams.Current.Title')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5 px-2">
                    <div className="w-100">
                        <SelectExam />
                    </div>
                </div>
            </div>

        </>);
    }
}

export default withTranslation('common')(Exams);