import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";

class Info extends React.Component {

    render() {
        const { t, Account } = this.props;
        return <>
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                        {t("Table.Options.Info")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="d-flex w-100 mb-5">
                        <span className="badge badge-square badge-secondary badge-sm me-2" title={t("NumberAuto")}> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Account.Id}</span>
                        <div title={t(`IsActive.${Account.IsActive}`)}>
                            <i className={`fa-solid text-${Account.IsActive ? "success" : "danger"} fa-circle me-1`}></i>
                            {t(`IsActive.${Account.IsActive}`)}
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-text me-2"></i>
                                    {t("Accounting.BankAccounts.Forms.Name.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-tally me-2"></i>
                                    {t("Accounting.BankAccounts.Forms.Number.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.Number}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-landmark me-2"></i>
                                    {t("Banks.Forms.Select.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.Bank?.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-code-branch me-2"></i>
                                    {t("BankBranches.Forms.Select.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.BankBranch?.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-wallet me-2"></i>
                                    {t("Currencies.Forms.Select.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.Currency?.Name}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-tally me-2"></i>
                                    {t("Accounting.BankAccounts.Forms.DepositChecksDaysAgo.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.DepositChecksDaysAgo}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-percentage me-2"></i>
                                    {t("Accounting.BankAccounts.Forms.PercentageCommissionOnCollection.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.PercentageCommissionOnCollection}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-unbalanced-flip me-2"></i>
                                    {t("Accounting.Debited")}
                                </span>
                                <span className="ms-10">
                                    {Account.MainAccount?.Debited}
                                    <span className="ms-1">
                                        {Account.MainAccount?.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-unbalanced me-2"></i>
                                    {t("Accounting.Credited")}
                                </span>
                                <span className="ms-10">
                                    {Account.MainAccount?.Credited}
                                    <span className="ms-1">
                                        {Account.MainAccount?.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-balanced me-2"></i>
                                    {t("Accounting.Balance")}
                                </span>
                                <span className="ms-10">
                                    {Account.MainAccount?.Value}
                                    <span className="ms-1">
                                        {Account.MainAccount?.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-coin me-2"></i>
                                    {t("Accounting.BankAccounts.Forms.CommissionForCollectionChecks.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.CommissionForCollectionChecks?.value}
                                    <span className="ms-1">
                                        {Account.CommissionForCollectionChecks?.Currency.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-coin me-2"></i>
                                    {t("Accounting.BankAccounts.Forms.CommissionForReturnChecks.Title")}
                                </span>
                                <span className="ms-10">
                                    {Account.CommissionForReturnChecks?.value}
                                    <span className="ms-1">
                                        {Account.CommissionForReturnChecks?.Currency.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {Account.User.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {Account.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {Account.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));