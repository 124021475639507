import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";

import PrintHeader from "./PrintHeader";
import PrintFooter from "./PrintFooter";

class PrintOrder extends React.Component {

    getAge = (t, Birthday) => {
        let result = "";
        if (Birthday) {

            const years = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'years', false);
            const months = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'months', false);
            const days = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'days', false);

            if (years > 1) {
                result += years + " " + t("Age.Year") + " ";
                if (months % 12) {
                    result += months % 12 + " " + t("Age.Month");
                }
            } else {
                if (months > 0) {
                    result += months + " " + t("Age.Month") + " ";
                    if (days % 30) {
                        result += days % 30 + " " + t("Age.Day");
                    }
                } else {
                    result += days + " " + t("Age.Day");
                }
            }
        }
        return result;
    }

    render() {
        const { t, patient, content } = this.props;

        return <div className="w-100 d-none d-print-block p-5">
            <table className="w-100" direction="rtl" dir="rtl" >
                <PrintHeader />
                <tbody className="w-100">
                    <tr className="w-100">
                        <td className="w-100 px-5">
                            <div>
                                <div className="d-flex mb-2">
                                    <div className="d-flex ms-auto">
                                        <div className="fw-bold me-2">
                                            {t('Date')}:
                                        </div>
                                        <div className="fst-italic text-decoration-underline me-1">
                                            {moment().format('DD/MM/YYYY')}
                                        </div>
                                    </div>
                                </div>
                                {
                                    patient ?
                                        <div className="d-flex justify-content-between mb-3">
                                            <div className="d-flex">
                                                <div className="fw-bold me-2">
                                                    {t('Patient.Forms.FirstName.Title')}:
                                                </div>
                                                <div className="text-nowrap mx-1 fst-italic text-decoration-underline">{patient?.Name.FirstName + " " + patient?.Name.SecondName + " " + patient?.Name.ThirdName + " " + patient?.Name.LastName}</div>
                                            </div>
                                            <div className="d-flex">
                                                {patient?.Birthday ? <>
                                                    <div className="fw-bold me-2">
                                                        {t('Patient.Age')}:
                                                    </div>
                                                    <div className="text-nowrap mx-1 fst-italic text-decoration-underline">{this.getAge(t, patient?.Birthday)}</div>
                                                </> : ""}
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                            {content}
                        </td>
                    </tr>
                </tbody>
                <PrintFooter />
            </table>
        </div>
    }
}
export default withTranslation('common')(PrintOrder);