import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Table from "./Table";
import Form from "./Form";
import { fetcGynecologyInfoes as fetch } from "./actions";
import Card from "./Card";

class GynecologyInfo extends React.Component {

    state = { showCreateNew: false, showHistory: false };

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        if (this.props.Appointment) {
            this.props.fetch(this.props.Appointment.patient.Id);
        } else if (this.props.patient) {
            this.props.fetch(this.props.patient.Id);
        }
    }

    update = () => {
        this.fetch();
        this.handleClose();
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false,
            showHistory: false
        });
    }

    showHistory = () => {
        this.setState({
            showHistory: true
        });
    }

    render() {
        const { t, gynecologyInfo } = this.props;
        return <>
            {
                this.state.showCreateNew ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-plus me-2 text-primary "></i>
                                {t("GynecologyInfoes.New")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form {...this.props} gynecologyInfo={gynecologyInfo.gynecologyInfo} update={this.update} />
                        </Modal.Body>
                    </Modal> : ""
            }
            {
                this.state.showHistory ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-rectangle-history-circle-user me-2 text-primary "></i>
                                {t("GynecologyInfoes.History")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table {...this.props} />
                        </Modal.Body>
                    </Modal> : ""
            }

            <div className="card h-100">
                <div className="card-header align-items-center border-0 mt-4">
                    <div className="card-title h3 d-flex align-items-center">
                        <i className="h3 fa-duotone fa-person-pregnant me-2 text-info"></i>
                        {t("GynecologyInfoes.Title")}
                    </div>
                    {
                        this.props.isForShow === true ? "" :
                            <div className="card-toolbar">
                                <Link
                                    to="#"
                                    onClick={this.showCreateNew}
                                    title={t("GynecologyInfoes.New")}
                                    className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                                    <i className="fa-regular fa-plus"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("GynecologyInfoes.New")}
                                    </span>
                                </Link>
                            </div>
                    }
                </div>
                <div className="card-body pt-5">
                    <Card gynecologyInfo={gynecologyInfo.gynecologyInfo} />
                </div>
                {
                    gynecologyInfo.gynecologyInfo ?
                        <div className="card-footer">
                            <div className="w-100 d-flex justify-content-evenly" >
                                <div className="btn btn-light-info ms-auto d-flex justify-content-center align-items-center w-100" onClick={() => this.showHistory()} title={t("GynecologyInfoes.History")}>
                                    <i className="fa-regular fa-rectangle-history-circle-user me-1"></i>
                                    {t("GynecologyInfoes.History")}
                                </div>
                            </div>
                        </div>
                        : ""
                }
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        gynecologyInfo: state.gynecologyInfo
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(GynecologyInfo));