const RadiologyExamTypesReducers = (state = [], action) => {
    switch (action.type) {
        case "RADIOLOGY_EXAM_TYPES_LOADING":
            return {
                radiologyExamTypes: [],
                isLoaded: false
            };
        case "RADIOLOGY_EXAM_TYPES_INITIAL":
            return {
                radiologyExamTypes: action.radiologyExamTypes,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default RadiologyExamTypesReducers;