import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import serverAPI from "../../../../api/serverAPI";
import SelectHealthCenters from "../../../shared/SelectList/SelectHealthCenters";

const SelectBirthday = React.createRef();

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, Id: 0, Birthday: "", defaultCenter: [] };

    componentDidMount() {
        if (!this.props.isEdit) {
            this.clearDate();
        } else if (this.props.isEdit && this.props.User) {
            this.setState({
                defaultCenter: { ...this.props.User.HealthCenter, value: this.props.User.HealthCenter.Id, label: this.props.User.HealthCenter.Name },
            });
        }
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.props.dispatch(change('formCreateUser', 'Birthday', picker.startDate.format('DD/MM/YYYY')));
        this.setState({
            Birthday: picker.startDate.format('DD/MM/YYYY')
        });
    }

    clearDate = () => {
        this.props.dispatch(change('formCreateUser', 'Birthday', ""));
        window.setNull("#UserBirthday");
    }

    static getDerivedStateFromProps(props, state) {

        if (!state.isInitialize && props.isEdit === true && props.User) {
            state.isInitialize = true;
            props.initialize({
                Name: props.User.Name,
                Email: props.User.Email,
                Username: props.User.Username,
                Birthday: props.User.Birthday,
                Address: props.User.Address,
                Phone: props.User.Phone,
                CenterId: props.User.HealthCenter.Id
            });
            state.Id = props.User.Id;
            state.Birthday = props.User.Birthday;
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.setState({
            isSubmit: true
        });

        let url = '/Users/';
        let method = serverAPI.post;
        if (this.props.isEdit) {
            method = serverAPI.put;
            url = '/Users/' + this.state.Id;
        }

        method(url, {
            ...this.props.formValues
        }).then(response => {
            if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.update();
                this.props.handleClose();
            } else {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Error.${response.data.code}.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Error.${response.data.code}.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    setCenter = Center => {
        this.props.dispatch(change('formCreateUser', 'CenterId', Center?.Id));
    }

    doNothing = () => { }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }
    render() {
        const { t, pristine, handleSubmit, submitting, user } = this.props;

        return <>

            <form method="POST" name="formCreateUser" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            } >
                <div className="row mb-5">
                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Name"><i className="fa-regular text-dark fa-text me-2"></i>{t('Users.Form.Name.Title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Name"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Users.Form.Name.Placeholder')}
                            title={t('Users.Form.Name.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Email"><i className="fa-regular text-dark fa-at me-2"></i>{t('Users.Form.Email.Title')}</label>
                        <Field
                            ref={Email => this.Email = Email}
                            name="Email"
                            id="Email"
                            component={this.renderField}
                            type={"email"}
                            required
                            placeholder={t('Users.Form.Email.Placeholder')}
                            title={t('Users.Form.Email.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Username"><i className="fa-regular text-dark fa-user me-2"></i>{t('Users.Form.Username.Title')}</label>
                        <Field
                            ref={Username => this.Username = Username}
                            name="Username"
                            id="Username"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Users.Form.Username.Placeholder')}
                            title={t('Users.Form.Username.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Phone"><i className="fa-regular text-dark fa-phone me-2"></i>{t('Users.Form.Phone.Title')}</label>
                        <Field
                            ref={Phone => this.Phone = Phone}
                            name="Phone"
                            id="Phone"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Users.Form.Phone.Placeholder')}
                            title={t('Users.Form.Phone.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className="col-md-4 mb-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="UserBirthday"><i className="fa-regular text-dark fa-cake-candles me-2"></i>{t('Users.Form.Birthday.Title')}</label>
                        <div className="input-group position-relative">
                            <DateRangePicker
                                ref={SelectBirthday}
                                initialSettings={{
                                    singleDatePicker: true,
                                    opens: 'center',
                                    autoUpdateInput: true,
                                    buttonClasses: 'btn',
                                    cancelClass: "btn-danger",
                                    applyButtonClasses: "btn-primary",
                                    showDropdowns: true,
                                    format: 'DD/MM/YYYY',
                                    timePicker: false,
                                    maxDate: `${moment().format('DD/MM/YYYY')}`,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        customRangeLabel: t('DateRangePicker.Range.customRange'),
                                        monthNames: [
                                            `${t("DateRangePicker.Months.January")}`,
                                            `${t("DateRangePicker.Months.February")}`,
                                            `${t("DateRangePicker.Months.March")}`,
                                            `${t("DateRangePicker.Months.April")}`,
                                            `${t("DateRangePicker.Months.May")}`,
                                            `${t("DateRangePicker.Months.June")}`,
                                            `${t("DateRangePicker.Months.July")}`,
                                            `${t("DateRangePicker.Months.August")}`,
                                            `${t("DateRangePicker.Months.September")}`,
                                            `${t("DateRangePicker.Months.October")}`,
                                            `${t("DateRangePicker.Months.November")}`,
                                            `${t("DateRangePicker.Months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("DateRangePicker.DaysOfWeek.Sunday"),
                                            t("DateRangePicker.DaysOfWeek.Monday"),
                                            t("DateRangePicker.DaysOfWeek.Tuesday"),
                                            t("DateRangePicker.DaysOfWeek.Wednesday"),
                                            t("DateRangePicker.DaysOfWeek.Thursday"),
                                            t("DateRangePicker.DaysOfWeek.Friday"),
                                            t("DateRangePicker.DaysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    onChange={() => this.doNothing()}
                                    value={this.state.Birthday}
                                    pristine="false"
                                    ref={UserBirthday => this.UserBirthday = UserBirthday}
                                    name="UserBirthday"
                                    id="UserBirthday"
                                    autoComplete="off"
                                    className="form-control form-control-solid"
                                    type="text"
                                    placeholder={t('Users.Form.Birthday.Placeholder')}
                                    title={t('Users.Form.Birthday.Title')}
                                />
                            </DateRangePicker>
                            <button className="btn btn-danger btn-icon" type="button" onClick={() => this.clearDate()}>
                                <i className="fa-regular fa-broom"></i>
                            </button>
                        </div>
                    </div>

                    <div className={`col-md-4`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Address"><i className="fa-regular text-dark fa-location-dot me-2"></i>{t('Users.Form.Address.Title')}</label>
                        <Field
                            ref={Address => this.Address = Address}
                            name="Address"
                            id="Address"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Users.Form.Address.Placeholder')}
                            title={t('Users.Form.Address.Title')}
                            className="form-control"
                        />
                    </div>
                </div>

                {
                    user && user.user && user.user.UserPermission.some(p => p.Id === 1) ?
                        <div className="row mb-5 d-flex justify-content-center">
                            <div className={`col-md-6`}>
                                <SelectHealthCenters onChange={this.setCenter} required={true} defaultValue={this.state.defaultCenter} />
                            </div>
                        </div>
                        : ""
                }
                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Name) {
        errors.Name = 'Users.Form.Name.Error';
    }
    if (!values.Email) {
        errors.Email = 'Users.Form.Email.Error';
    }
    if (!values.Username) {
        errors.Username = 'Users.Form.Username.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formCreateUser')(state),
        validate,
        user: state.user
    };
};

const formCreateUser = reduxForm({
    form: 'formCreateUser',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formCreateUser(Form)));