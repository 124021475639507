import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";

class Info extends React.Component {

    render() {
        const { t, Account } = this.props;
        return <>
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                        {t("Table.Options.Info")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=" mb-5" title={t("NumberAuto")}>
                        <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Account.Id}</span>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-text me-2"></i>
                                    {t("Accounting.Account.Name")}
                                </span>
                                <span className="ms-10">
                                    {Account.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-tally me-2"></i>
                                    {t("Accounting.Account.Number")}
                                </span>
                                <span className="ms-10">
                                    {Account.Number}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-unbalanced-flip me-2"></i>
                                    {t("Accounting.Debited")}
                                </span>
                                <span className="ms-10">
                                    {Account.Debited}
                                    <span className="ms-1">
                                        {Account.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-unbalanced me-2"></i>
                                    {t("Accounting.Credited")}
                                </span>
                                <span className="ms-10">
                                    {Account.Credited}
                                    <span className="ms-1">
                                        {Account.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-scale-balanced me-2"></i>
                                    {t("Accounting.Balance")}
                                </span>
                                <span className="ms-10">
                                    {Account.Value}
                                    <span className="ms-1">
                                        {Account.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    {
                        Account.FatherAccount ?
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-folder-tree me-2"></i>
                                            {t("Accounting.Accounts.Father")}
                                        </span>
                                        <span className="ms-10">
                                            {Account.FatherAccount ? Account.FatherAccount.Number + " - " + Account.FatherAccount?.Name : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {Account.User.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {Account.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {Account.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));