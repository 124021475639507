import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import jsonData from "./data.json";
import serverAPI from "../../../api/serverAPI";

class Form extends React.Component {

    state = {
        isInitialize: false,
        isSubmit: false,
        SelectedDrug: []
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize &&
            props.drugAllergy &&
            props.drugAllergy.drugAllergy &&
            props.drugAllergy.drugAllergy.Name &&
            props.drugAllergy.drugAllergy.Name.Drugs) {

            state.isInitialize = true;

            let SelectedDrug = [];
            props.drugAllergy.drugAllergy.Name.Drugs.forEach(item => {
                SelectedDrug.push({ Id: item })
            });
            state.SelectedDrug = SelectedDrug;

            props.initialize({
                others: props.drugAllergy.drugAllergy.Name.others
            });
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setSelectedDrug = Drug => {
        const objIndex = this.state.SelectedDrug.findIndex(p => p.Id === Drug.Id);
        let SelectedDrug = this.state.SelectedDrug;
        if (objIndex >= 0) {
            SelectedDrug = SelectedDrug.filter(p => p.Id !== Drug.Id);
        } else {
            SelectedDrug.push(Drug);
        }
        this.setState({
            SelectedDrug
        });
    }

    renderList() {
        const { t } = this.props;
        return jsonData.DrugAllergy.map(drug => {
            return <li key={`drug-allergy-${drug.Id}`} className="d-flex py-2 align-items-center flex-wrap" >
                <div className="form-check form-check-custom mx-2 ">
                    <input
                        name="checkbox"
                        type="checkbox"
                        value={drug.Id}
                        onChange={() => this.setSelectedDrug(drug)}
                        className="form-check-input"
                        checked={this.state.SelectedDrug.some(p => p.Id === drug.Id)}
                    />
                </div>
                {drug.Name}.
                {
                    drug.Id === "others" && this.state.SelectedDrug.some(p => p.Id === drug.Id) ? <div><Field
                        ref={others => this.others = others}
                        name="others"
                        id="others"
                        component={this.renderField}
                        placeholder={t('DrugAllergy.Others.Placeholder')}
                        title={t('DrugAllergy.Others.Title')}
                        className="form-control form-control-sm mx-2"
                    />  </div> : ""
                }
            </li>;
        });
    }

    onSubmit = () => {

        const { t, patient } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const Drugs = {
            ...this.props.formValues,
            Drugs: this.state.SelectedDrug.map(({ Id }) => Id)
        };


        serverAPI.post(`/DrugAllergy/${patient.Id}`, {
            Drugs
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    render() {
        const { t, drugAllergy } = this.props;

        return <>

            <form method="POST" name="formDrugAllergy" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <ul style={{
                        direction: 'ltr'
                    }}>
                        {this.renderList()}
                    </ul>
                </div>
                {
                    drugAllergy && drugAllergy.drugAllergy ? <>
                        <div className="separator separator-dashed border-dark mb-5"></div>
                        <div className="row">
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        {t("LastUpdatedUser")}
                                    </span>
                                    <span className="ms-10">
                                        {drugAllergy.drugAllergy.User.Name}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-hospital me-1"></i>
                                        {t("HealthCenter.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {drugAllergy.drugAllergy.User.HealthCenter.Name}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("CreationDate")}
                                    </span>
                                    <span className="ms-10">
                                        {drugAllergy.drugAllergy.CreationDate}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("LastUpdatedDate")}
                                    </span>
                                    <span className="ms-10">
                                        {drugAllergy.drugAllergy.UpdateDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </> : ""
                }
                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formDrugAllergy')(state),
        drugAllergy: state.drugAllergy,
        user: state.user
    };
};

const formDrugAllergy = reduxForm({
    form: 'formDrugAllergy',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formDrugAllergy(Form)));
