import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchBloodGroups } from "./actions";

class SelectBloodGroup extends React.Component {

    state = { bloodGroups: [], isInitialize: false, BloodGroupId: React.createRef() };

    constructor(props) {
        super(props);
        const { bloodGroups } = this.props;
        if (!bloodGroups || !bloodGroups.bloodGroups || bloodGroups.bloodGroups?.length === 0) {
            this.props.fetchBloodGroups();
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.defaultValue && !state.isInitialize && state.BloodGroupId.current) {
            state.isInitialize = true;
            state.BloodGroupId.current.setValue(props.defaultValue);
        }

        if (props.bloodGroups?.bloodGroups) {
            state.bloodGroups = props.bloodGroups.bloodGroups.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        const { t, bloodGroups, required, isClearable, hideTitle, className, name } = this.props;
        let isLoaded = false;
        if (bloodGroups) {
            isLoaded = bloodGroups.isLoaded;
        }

        return <>
            {
                hideTitle ? "" :
                    <label className={`col-form-label ${required ? "required" : ""} fw-bold fs-6`} htmlFor={name ? name : "BloodGroupId"}><i className="fa-regular text-danger fa-droplet me-2"></i>{t('Patient.Forms.BloodGroup.Title')}</label>
            }
            <Select
                name={name ? name : "BloodGroupId"}
                id={name ? name : "BloodGroupId"}
                placeholder={t('Patient.Forms.BloodGroup.Placeholder')}
                title={t('Patient.Forms.BloodGroup.Title')}
                isLoading={!isLoaded}
                isSearchable={true}
                isClearable={isClearable}
                ref={this.state.BloodGroupId}
                required={required}
                onChange={this.onChange}
                options={this.state.bloodGroups}
                filterOption={this.filterOptions}
                className={className}
            />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        bloodGroups: state.bloodGroups
    };
};

export default connect(mapStateToProps, { fetchBloodGroups })(withTranslation('common')(SelectBloodGroup));