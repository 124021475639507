import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchBankBranches } from "./actions";

class SelectBankBranches extends React.Component {

    state = { bankBranches: [], isInitialize: false, BankBranchId: React.createRef() };

    constructor(props) {
        super(props);
        const { bankBranches } = this.props;
        if (!bankBranches || !bankBranches.bankBranches || bankBranches.bankBranches?.length === 0) {
            this.props.fetchBankBranches();
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.defaultValue && !state.isInitialize && state.BankBranchId.current) {
            state.isInitialize = true;
            state.BankBranchId.current.setValue(props.defaultValue);
        } else {
            const BankId = state.BankBranchId.current?.getValue()[0]?.Bank?.Id;
            if (BankId && BankId !== props.BankId && state.BankBranchId.current) {
                state.BankBranchId.current.setValue(null);
            }
        }

        if (props.bankBranches?.bankBranches) {
            let bankBranches = props.bankBranches.bankBranches;
            if (props.BankId) {
                bankBranches = bankBranches.filter(p => p.Bank.Id === props.BankId);
            }
            state.bankBranches = bankBranches.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Bank?.Name + " - " + item.Name,
                    label: item.Bank?.Name + " - " + item.Name,
                };
            });
        }
        return state;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        const { t, bankBranches, required, isClearable, hideTitle, className, name } = this.props;
        let isLoaded = false;
        if (bankBranches) {
            isLoaded = bankBranches.isLoaded;
        }
        return <>
            {
                hideTitle ? "" :
                    <label className={`col-form-label ${required ? "required" : ""} fw-bold fs-6`} htmlFor={name ? name : "BankBranchId"}><i className="fa-regular text-dark fa-code-branch me-2"></i>{t('BankBranches.Forms.Select.Title')}</label>
            }
            <Select
                name={name ? name : "BankBranchId"}
                id={name ? name : "BankBranchId"}
                placeholder={t('BankBranches.Forms.Select.Placeholder')}
                title={t('BankBranches.Forms.Select.Title')}
                isLoading={!isLoaded}
                isSearchable={true}
                isClearable={isClearable}
                ref={this.state.BankBranchId}
                required={required}
                onChange={this.onChange}
                options={this.state.bankBranches}
                filterOption={this.filterOptions}
                className={className}
            />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        bankBranches: state.bankBranches
    };
};

export default connect(mapStateToProps, { fetchBankBranches })(withTranslation('common')(SelectBankBranches));