const BankBoxesReducers = (state = [], action) => {
    switch (action.type) {
        case "BANK_BOXES_LOADING":
            return {
                bankBoxes: [],
                isLoaded: false
            };
        case "BANK_BOXES_INITIAL":
            return {
                bankBoxes: action.bankBoxes,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default BankBoxesReducers;