const GynecologyInfoHistoriesReducers = (state = [], action) => {
    switch (action.type) {
        case "GYNE_INFO_HISTORY_LOADING":
            return {
                gynecologyInfoHistories: null,
                isLoaded: false
            };
        case "GYNE_INFO_HISTORY_INITIAL":
            return {
                gynecologyInfoHistories: action.gynecologyInfoHistories,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default GynecologyInfoHistoriesReducers;