import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchCities } from "./actions";

class SelectCities extends React.Component {

    state = { cities: [], isInitialize: false, CityId: React.createRef() };

    constructor(props) {
        super(props);
        const { cities } = this.props;
        if (!cities || !cities.cities || cities.cities?.length === 0) {
            this.props.fetchCities();
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.defaultValue && !state.isInitialize && state.CityId.current) {
            state.isInitialize = true;
            state.CityId.current.setValue(props.defaultValue);
        }

        if (props.cities?.cities) {
            state.cities = props.cities.cities.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        const { t, cities, required, isClearable, hideTitle, className, name } = this.props;
        let isLoaded = false;
        if (cities) {
            isLoaded = cities.isLoaded;
        }

        return <>
            {
                hideTitle ? "" :
                    <label className={`col-form-label ${required ? "required" : ""} fw-bold fs-6`} htmlFor={name ? name : "CityId"}><i className="fa-regular text-dark fa-map me-2"></i>{t('Patient.Forms.City.Title')}</label>
            }
            <Select
                name={name ? name : "CityId"}
                id={name ? name : "CityId"}
                placeholder={t('Patient.Forms.City.Placeholder')}
                title={t('Patient.Forms.City.Title')}
                isLoading={!isLoaded}
                isSearchable={true}
                isClearable={isClearable}
                ref={this.state.CityId}
                required={required}
                onChange={this.onChange}
                options={this.state.cities}
                filterOption={this.filterOptions}
                className={className}
            />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        cities: state.cities
    };
};

export default connect(mapStateToProps, { fetchCities })(withTranslation('common')(SelectCities));