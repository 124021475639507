const PatientFormsReducers = (state = [], action) => {
    switch (action.type) {
        case "PATIENT_FORMS_LOADING":
            return {
                patientForms: null,
                isLoaded: false
            };
        case "PATIENT_FORMS_INITIAL":
            return {
                patientForms: action.patientForms,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PatientFormsReducers;