import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchMedicalSpecialist } from "./actions";

class SelectMedicalSpecialist extends React.Component {

    state = { medicalSpecialist: [], isInitialize: false, MedicalSpecialistId: React.createRef() };

    constructor(props) {
        super(props);
        const { medicalSpecialist } = this.props;
        if (!medicalSpecialist || !medicalSpecialist.medicalSpecialist || medicalSpecialist.medicalSpecialist?.length === 0) {
            this.props.fetchMedicalSpecialist();
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.defaultValue && !state.isInitialize && state.MedicalSpecialistId.current) {
            state.isInitialize = true;
            state.MedicalSpecialistId.current.setValue(props.defaultValue);
        }

        if (props.medicalSpecialist?.medicalSpecialist) {
            state.medicalSpecialist = props.medicalSpecialist.medicalSpecialist.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        const { t, medicalSpecialist, required, isClearable, hideTitle, className, name } = this.props;
        let isLoaded = false;
        if (medicalSpecialist) {
            isLoaded = medicalSpecialist.isLoaded;
        }

        return <>
            {
                hideTitle ? "" :
                    <label className={`col-form-label ${required ? "required" : ""} fw-bold fs-6`} htmlFor={name ? name : "MedicalSpecialistId"}><i className="fa-regular text-dark fa-kit-medical me-2"></i>{t('Clinic.Forms.MedicalSpecialist.Select')}</label>
            }
            <Select
                name={name ? name : "MedicalSpecialistId"}
                id={name ? name : "MedicalSpecialistId"}
                placeholder={t('Clinic.Forms.MedicalSpecialist.Placeholder')}
                title={t('Clinic.Forms.MedicalSpecialist.Title')}
                isLoading={!isLoaded}
                isSearchable={true}
                isClearable={isClearable}
                ref={this.state.MedicalSpecialistId}
                required={required}
                onChange={this.onChange}
                options={this.state.medicalSpecialist}
                filterOption={this.filterOptions}
                className={className}
            />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        medicalSpecialist: state.medicalSpecialist
    };
};

export default connect(mapStateToProps, { fetchMedicalSpecialist })(withTranslation('common')(SelectMedicalSpecialist));