const DrugsReducers = (state = [], action) => {
    switch (action.type) {
        case "DRUGS_LOADING":
            return {
                drugs: [],
                isLoaded: false
            };
        case "DRUGS_INITIAL":
            return {
                drugs: action.drugs,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default DrugsReducers;