import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

class SelectService extends React.Component {

    state = {
        SelectedService: [],
        Total: 0,
        isInitialize: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.Bill && props.Bill.Types) {
            state.isInitialize = true;
            props.Bill.Types.forEach(type => {
                type.items.forEach(Service => {
                    if (Service.IsDeported) {
                        state.SelectedService.push(Service);
                    }
                });
            });
            let Total = 0;
            const Rate = props.user.user?.HealthCenter.Currency.Rate;
            state.SelectedService.forEach(Service => {
                if (Service.IsDeported === false) {
                    Total += Service.Price * (Service.Currency.Rate / Rate);
                }
            });
            state.Total = Total;
        }
        return state;
    }

    setTotal = () => {
        const { user } = this.props;
        let Total = 0;
        const Rate = user.user?.HealthCenter.Currency.Rate;

        this.state.SelectedService.forEach(Service => {
            if (Service.IsDeported === false) {
                Total += Service.Price * (Service.Currency.Rate / Rate);
            }
        });
        this.setState({
            Total
        }, () => {
            this.props.setSelectedService(this.state.SelectedService);
        });
    }

    setSelectedService = Service => {
        const objIndex = this.state.SelectedService.findIndex(p => p.Id === Service.Id);
        let SelectedService = this.state.SelectedService;
        if (objIndex >= 0) {
            SelectedService = SelectedService.filter(p => p.Id !== Service.Id);
        } else {
            SelectedService.push(Service);
        }
        this.setState({
            SelectedService
        }, () => { this.setTotal() });
    }

    setSelectedAll = isChecked => {
        let SelectedService = [];
        if (isChecked) {
            this.props.Bill.Types.forEach(type => {
                type.items.forEach(Service => {
                    SelectedService.push(Service);
                });
            });
        } else {
            this.props.Bill.Types.forEach(type => {
                type.items.forEach(Service => {
                    if (Service.IsDeported === true) {
                        SelectedService.push(Service);
                    }
                });
            });

        }
        this.setState({
            SelectedService
        }, () => { this.setTotal() });
    }

    renderTableRow = () => {
        const { t, Bill } = this.props;

        return Bill.Types.map((type) => {
            return type.items.map((service) => {
                const hasService = this.state.SelectedService.some(p => p.Id === service.Id);
                return <tr key={`selected-lab-exam-${service.Id}`}>
                    <td>
                        <div className={`form-check form-check-custom form-check-${service.BillItemStatus?.Color}`}>
                            <input
                                name="checkbox"
                                type="checkbox"
                                value={service.Id}
                                onChange={() => this.setSelectedService(service)}
                                className="form-check-input"
                                disabled={service.IsDeported ? true : false}
                                checked={hasService}
                            />
                        </div>
                    </td>
                    <td>
                        <span className="me-1 d-inline-block">
                            {t(`Services.Type.${type.Type.Name}`)}
                        </span>
                        {service.Details}
                    </td>
                    <td>
                        {service.Price + " " + service.Currency.Name}
                    </td>
                </tr>
            })
        });
    }

    render() {
        const { t, user } = this.props;
        return (<>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                        <i className="fa-regular fa-file-invoice-dollar me-2 text-primary h3 mb-0"></i>
                        {t('Services.Bill_Title')}
                    </div>
                </div>
                <div>
                    <div className="form-check form-check-custom">
                        <input
                            name="checkbox"
                            type="checkbox"
                            id="SelectAll"
                            onChange={(e) => this.setSelectedAll(e.target.checked)}
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="SelectAll">
                            {t('SelectAll')}
                        </label>
                    </div>
                </div>
            </div>
            <div className={`  border-1 border-dark border-dashed table-responsive`} >
                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                    <thead>
                        <tr>
                            <th title={t("Select")}>
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-square-check"></i>
                                </span>
                            </th>
                            <th title={t("Services.Forms.Name.Title")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-text me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Services.Forms.Name.Title")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Services.Forms.Price.Title")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-coin me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Services.Forms.Price.Title")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRow()}
                    </tbody>
                </table>
                <div className="card-footer">
                    <div className="w-100 d-flex justify-content-evenly" title={t("Sum_Note")}>
                        <span className="d-md-block d-none text-nowrap">
                            {t("Sum")}
                            :
                        </span>
                        <span className="fs-4 ">
                            {this.state.Total}
                            <span className="mx-1">
                                {user.user?.HealthCenter.Currency.Name}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(SelectService));