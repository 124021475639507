import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Field, reduxForm, change } from "redux-form";
import Table from "./Table";
import PatientName from "../Patients/PatientName";

class DiagnosisField extends React.Component {

    state = { showModal: false, Id: 0 };

    static getDerivedStateFromProps(props, state) {
        if (props.Diagnos && props.Diagnos.Id !== state.Id) {
            state.Id = props.Diagnos.Id;
            props.dispatch(change('formDiagnosis', 'Diagnosis', props.Diagnos.Code + " - " + props.Diagnos.Note));
        } else if (props.Diagnos == null) {
            state.Id = 0;
            props.dispatch(change('formDiagnosis', 'Diagnosis', ""));
        }
        return state;
    }

    showModal = () => {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} readOnly={readOnly} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Select')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    select = currentItem => {
        this.handleClose();
        this.props.update(currentItem);
    }

    render() {
        const { t, Diagnos, Appointment } = this.props;

        return (<>
            {
                this.state.showModal ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-book-medical me-2 text-info"></i>
                                {t("Diagnosis.Title")} &nbsp;
                                (

                                {Appointment && Appointment.patient && Appointment.patient.Name ? <PatientName patient={Appointment.patient} /> : ""}
                                )
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table isSelectable select={this.select} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <form name="formDiagnosis" autoComplete="new-password">
                <div className="input-group position-relative">
                    <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6" style={{
                        zIndex: 9
                    }}>
                        <i className="fa-regular fa-magnifying-glass"></i>
                    </span>
                    <Field
                        ref={Diagnosis => this.Diagnosis = Diagnosis}
                        name="Diagnosis"
                        id="Diagnosis"
                        component={this.renderFieldGroup}
                        disabled="disabled"
                        readOnly={true}
                        type={"text"}
                        placeholder={t("Diagnosis.Placeholder")}
                        title={t("Diagnosis.Title")}
                        defaultValue={Diagnos ? Diagnos.Code + " - " + Diagnos.Note : ""}
                        value={Diagnos ? Diagnos.Code + " - " + Diagnos.Note : ""}
                        className="form-control form-control-solid disabled ps-10"
                    />
                </div>
            </form>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const formDiagnosis = reduxForm({
    form: 'formDiagnosis'
});

export default connect(mapStateToProps, {})(withTranslation('common')(formDiagnosis(DiagnosisField)));