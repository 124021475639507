import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

class ChronicDiseaseAlert extends React.Component {

    renderDiseases = diseases => {
        return diseases.map(disease => {
            return <li key={`chronic-disease-${disease.Id}`} className="d-flex py-1 align-items-center flex-wrap text-capitalize" >
                <span className="bullet bg-dark ms-2 me-5"></span>   {disease.Name}.
            </li>;
        })
    }

    renderList() {
        const { patientChronicDisease } = this.props;
        return patientChronicDisease.patientChronicDisease.map(type => {
            return <li key={`chronic-disease-type-${type.Id}`} className="d-flex py-1 align-items-center flex-wrap" >
                <div className="row mb-1">
                    <div className="d-flex align-items-center text-capitalize">
                        <span className="bullet bg-primary ms-2"></span> {type.Name}.
                    </div>
                    <ul style={{
                        direction: 'ltr'
                    }}>
                        {this.renderDiseases(type.Diseases)}
                    </ul>
                </div>
            </li>;
        });
    }


    render() {
        const { t, patientChronicDisease } = this.props;
        if (patientChronicDisease && patientChronicDisease.patientChronicDisease?.length > 0) {
            return <div className="alert alert-dismissible bg-light-warning border-3 border-warning d-flex flex-column flex-sm-row p-5 mb-5">
                <i className="fs-2hx text-warning me-2 mb-0 fa-regular fa-viruses"></i>
                <div className="d-flex flex-column pe-1 w-100">
                    <h5 className="mb-1">{t("ChronicDisease.Title")}</h5>
                    <span>{t("ChronicDisease.Alert")}.</span>
                    <ul className="d-flex flex-column" style={{
                        direction: "ltr"
                    }}>
                        {this.renderList()}
                    </ul>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <i className="ki-duotone ki-cross fs-1 text-warning fa-regular fa-xmark"></i>
                </button>
            </div>;
        }
        return <></>;
    }
}

const mapStateToProps = state => {
    return {
        patientChronicDisease: state.patientChronicDisease
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(ChronicDiseaseAlert));