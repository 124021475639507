import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";

import SelectExam from "./SelectExam";
import serverAPI from "../../../../api/serverAPI";
import PatientName from "../../../Patients/PatientName";

class Take extends React.Component {

    state = { SelectedExam: [], isSubmit: false };

    setSelectedExam = SelectedExam => {
        this.setState({
            SelectedExam
        });
    }

    onSubmit = () => {
        const { t, Order } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/RadiologyReceivingPatients/${Order.Id}`, {
            Exams: this.state.SelectedExam.map(({ RadiologyExam }) => RadiologyExam.Id),
            PatientId: Order.patient.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    render() {
        const { t, Order } = this.props;

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-x-ray me-2 text-primary "></i>
                    <span className="text-nowrap me-1">
                        {t("Table.Options.Take.Radiology")}
                    </span>
                    (
                    <PatientName patient={Order.patient} />
                    )
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-dismissible bg-light-info border-3 border-info d-flex flex-column flex-sm-row p-5 mb-3">
                    <i className="fs-2hx text-info me-4 mb-0 fa-regular fa-info"></i>
                    <div className="d-flex flex-column pe-0 pe-sm-10 w-100">
                        <h5 className="mb-1">{t("Directions")}</h5>
                        <span>{t("Radiology.Exams.Take.Alert")}.</span>

                    </div>
                    <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                        <i className="ki-duotone ki-cross fs-1 text-info fa-regular fa-xmark"></i>
                    </button>
                </div>
                <SelectExam {...this.props} setSelectedExam={this.setSelectedExam} />
                {
                    Order.Note ?
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-note me-2"></i>
                                        {t("ClinicScreen.Orders.Forms.Note.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {Order.Note}
                                    </span>
                                </div>
                            </div>
                        </div>
                        : ""
                }
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={() => this.onSubmit()} className="btn btn-sm btn-success mx-1" title={t('Radiology.Exams.Take.Title')} disabled={this.state.isSubmit || this.state.SelectedExam.length === 0} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                    <span className="indicator-label">
                        <i className="me-1 fa-solid fa-save"></i>
                        {t('Radiology.Exams.Take.Title')}
                    </span>
                    <span className="indicator-progress">{t('PleaseWait')}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                    <i className="me-1 fa-solid fa-xmark"></i>
                    {t('Close')}
                </button>
            </Modal.Footer>
        </Modal>;
    }
}

export default withTranslation('common')(Take);