import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";

import { userLogin } from '../../../actions/';
import history from "../../history";
import serverAPI, { setToken } from "../../../api/serverAPI";

class Login extends React.Component {

    state = { isSubmit: false, showPassword: false };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_aside").classList.add("d-none");
        document.getElementById("kt_header").classList.add("d-none");
        document.getElementById("kt_footer").classList.add("d-none");
        document.getElementById("kt_wrapper").classList.add("p-0");
        document.getElementById("kt_wrapper").classList.add("login-background");
        document.getElementById("kt_wrapper").classList.add("bg-overlay");
        body.setAttribute('class', "bg-body");
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_aside").classList.remove("d-none");
        document.getElementById("kt_header").classList.remove("d-none");
        document.getElementById("kt_footer").classList.remove("d-none");
        document.getElementById("kt_wrapper").classList.remove("p-0");
        document.getElementById("kt_wrapper").classList.remove("bg-overlay");
        document.getElementById("kt_wrapper").classList.remove("login-background");

        body.setAttribute('class', "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed");
    }

    Submit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.setState({
            isSubmit: true
        });

        serverAPI.post(`/Login/`, {
            Email: this.Email.ref.current.getValue(),
            Password: this.Password.ref.current.getValue()
        }).then(response => {
            if (response.data.code === "username_or_password_error") {
                toast.update(this._toastId.current, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Login.username_or_password_error.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Login.username_or_password_error.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "user_is_locked") {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Login.user_is_locked.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Login.user_is_locked.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else {

                setToken(response.data.data.token);
                localStorage.setItem('token', response.data.data.token);
                this.props.userLogin(response.data.data);

                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Login.success.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Login.success.Body')} {response.data.data.Name}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });

                history.push("/");
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });

            this.setState({
                isSubmit: false
            });
        });

        e.preventDefault();
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    showPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;
        return (<>
            <Helmet>
                <title>{t("Pages.Login")} | {t('SiteName')}</title>
            </Helmet>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" id="Login" onSubmit={
                                handleSubmit(values => {
                                    return new Promise(resolve => resolve())
                                        .then(() => {
                                            const errors = validate(values, this.props);
                                            return Object.keys(errors).length !== 0;
                                        })
                                        .then(errors => {
                                            if (errors) {
                                                this.checkSubmit();
                                            } else {
                                                this.onSubmit();
                                            }
                                        })
                                })
                            } autoComplete="new-password" >

                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3">{t("Pages.Login")}</h1>
                                </div>
                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <label className="form-label fs-6 fw-bolder text-dark" htmlFor="Email">
                                        <i className={`me-2 fas fa-at text-dark`}></i>
                                        {t('Login.Email.Title')}
                                    </label>
                                    <Field
                                        ref={Email => this.Email = Email}
                                        name="Email"
                                        id="Email"
                                        component={this.renderField}
                                        type={"email"}
                                        required={true}
                                        placeholder={t('Login.Email.Placeholder')}
                                        title={t('Login.Email.Title')}
                                        className="form-control form-control-solid"
                                    />
                                </div>

                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <div className="d-flex flex-stack mb-2">
                                        <label className="form-label fw-bolder text-dark fs-6 mb-0" htmlFor="Password">
                                            <i className={`me-2 fas fa-key text-dark`}></i> {t('Login.Password.Title')}</label>
                                    </div>
                                    <div className="position-relative">

                                        <Field
                                            ref={Password => this.Password = Password}
                                            name="Password"
                                            id="Password"
                                            component={this.renderField}
                                            type={this.state.showPassword ? "text" : "password"}
                                            required={true}
                                            placeholder={t('Login.Password.Placeholder')}
                                            title={t('Login.Password.Title')}
                                            className="form-control form-control-solid"
                                        />

                                        <div className="d-flex justify-content-center align-items-center cursor-pointer h-100" title={this.state.showPassword ? t('Pages.Register.Password.hide') : t('Pages.Register.Password.show')} style={{
                                            left: 0,
                                            top: 0,
                                            width: '50px',
                                            position: 'absolute'
                                        }} onClick={() => this.showPassword()}>
                                            <i className={`fas ${this.state.showPassword ? "fa-eye-slash" : "fa-eye text-dark "}`}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={this.Submit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} disabled={pristine || submitting || this.state.isSubmit}>
                                        <span className="indicator-label">{t("Pages.Login")} </span>
                                        <span className="indicator-progress">{t('PleaseWait')}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

const validate = values => {
    const errors = {};

    if (!values.Password) {
        errors.Password = 'Login.Password.Error';
    }

    if (!values.Email) {
        errors.Email = 'Login.Email.Error';
    }

    return errors;
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const formLogin = reduxForm({
    form: 'Login',
    validate
});

export default connect(mapStateToProps, { userLogin })(withTranslation('common')(formLogin(Login)));