
const UserReducers = (state = [], action) => {
    switch (action.type) {
        case "USER_INITIAL":
            return {
                isLogin: true,
                user: action.user
            };
        case "USER_IS_ACTIVE": {
            state.user.IsActive = true;
            return {
                isLogin: true,
                user: state.user
            };
        }
        case "USER_NOT_LOGIN":
            return {
                user: '',
                isLogin: false
            };
        default:
            return state;
    }
}

export default UserReducers;