const DrugTypesReducers = (state = [], action) => {
    switch (action.type) {
        case "DRUG_TYPES_LOADING":
            return {
                drugTypes: [],
                isLoaded: false
            };
        case "DRUG_TYPES_INITIAL":
            return {
                drugTypes: action.drugTypes,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default DrugTypesReducers;