import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import serverAPI from "../../../../../api/serverAPI";
import AccountField from "../../../Accounts/AccountField";
import SelectCurrencies from "../../../../shared/SelectList/SelectCurrencies";

class Form extends React.Component {

    state = { Date: `${moment().format('DD/MM/YYYY')}`, isSubmit: false, Currency: null, account: null, IsIncludeTax: true };

    componentDidMount() {
        this.props.initialize({
            Date: `${moment().format('DD/MM/YYYY')}`,
            Value: 0,
            TaxRate: 0,
            TaxValue: 0,
            IsIncludeTax: true,
            Rate: "",
            CurrencyId: "",
        });
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t, Url } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.formValues.CurrencyId) {
            this.setState({
                isSubmit: true
            });

            serverAPI.post(Url, {
                ...this.props.formValues,
                AccountId: this.state.account.Id
            }).then(response => {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
                this.props.update();
                this.props.handleClose();
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
            });

        } else {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.AllInformationIsRequired.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.AllInformationIsRequired.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        }
        return false;
    }


    setCurrency = Currency => {
        this.props.dispatch(change('formCreditMemo', 'Rate', Currency.rate));
        this.props.dispatch(change('formCreditMemo', 'CurrencyId', Currency.Id));
        this.setState({
            Currency
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            Date: picker.startDate.format('DD/MM/YYYY')
        });
        this.props.dispatch(change('formCreditMemo', 'Date', picker.startDate.format('DD/MM/YYYY')));
    }

    checkSubmit = () => {
        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

        return false;
    }

    setAccount = account => {
        this.setState({
            account
        });
    }

    setTaxValue = () => {
        let TaxValue = 0;
        if (this.state.IsIncludeTax) {
            const AmountBeforeVat = this.props.formValues.Value / ((this.props.formValues.TaxRate / 100) + 1);
            TaxValue = this.props.formValues.Value - AmountBeforeVat;

        } else {
            TaxValue = this.props.formValues.Value * this.props.formValues.TaxRate / 100;
        }
        this.props.dispatch(change('formCreditMemo', 'TaxValue', TaxValue));
    }

    setIsIncludeTax = IsIncludeTax => {
        this.setState({
            IsIncludeTax
        }, () => {
            this.setTaxValue()
        });
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;


        return <>
            <form method="POST" name="formCreditMemo" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0 || this.state.account === null;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            } >
                <div className="row mb-5">

                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} ><i className="fa-regular text-dark fa-calendar me-2"></i>{t('Accounting.JournalVoucher.Forms.Date.Title')}</label>
                        <DateRangePicker
                            ref={SelectDate => this.SelectDate = SelectDate}
                            initialSettings={{
                                singleDatePicker: true,
                                opens: 'center',
                                autoUpdateInput: true,
                                buttonClasses: 'btn',
                                cancelClass: "btn-danger",
                                applyButtonClasses: "btn-primary",
                                showDropdowns: true,
                                format: 'DD/MM/YYYY',
                                timePicker: false,
                                maxDate: `${moment().format('DD/MM/YYYY')}`,
                                locale: {
                                    format: 'DD/MM/YYYY',
                                    cancelLabel: t('Close'),
                                    firstDay: 6,
                                    applyLabel: t('Apply'),
                                    customRangeLabel: t('DateRangePicker.Range.customRange'),
                                    monthNames: [
                                        `${t("DateRangePicker.Months.January")}`,
                                        `${t("DateRangePicker.Months.February")}`,
                                        `${t("DateRangePicker.Months.March")}`,
                                        `${t("DateRangePicker.Months.April")}`,
                                        `${t("DateRangePicker.Months.May")}`,
                                        `${t("DateRangePicker.Months.June")}`,
                                        `${t("DateRangePicker.Months.July")}`,
                                        `${t("DateRangePicker.Months.August")}`,
                                        `${t("DateRangePicker.Months.September")}`,
                                        `${t("DateRangePicker.Months.October")}`,
                                        `${t("DateRangePicker.Months.November")}`,
                                        `${t("DateRangePicker.Months.December")}`
                                    ],
                                    daysOfWeek: [
                                        t("DateRangePicker.DaysOfWeek.Sunday"),
                                        t("DateRangePicker.DaysOfWeek.Monday"),
                                        t("DateRangePicker.DaysOfWeek.Tuesday"),
                                        t("DateRangePicker.DaysOfWeek.Wednesday"),
                                        t("DateRangePicker.DaysOfWeek.Thursday"),
                                        t("DateRangePicker.DaysOfWeek.Friday"),
                                        t("DateRangePicker.DaysOfWeek.Saturday")
                                    ],
                                },
                            }}
                            onApply={this.handleApplyDate} >
                            <input
                                defaultValue={this.state.Date}
                                ref={Date => this.Date = Date}
                                name="Date"
                                id="Date"
                                autoComplete="off"
                                required
                                className="form-control ps-10"
                                type="text"
                                placeholder={t('Accounting.JournalVoucher.Forms.Date.Placeholder')}
                                title={t('Accounting.JournalVoucher.Forms.Date.Title')}
                            />
                        </DateRangePicker>
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} ><i className="fa-regular text-dark fa-file-user me-2"></i>{t('Accounting.Account.Info')}</label>
                        <AccountField account={this.state.account} update={this.setAccount} />
                    </div>

                    <div className={`col-md-3`}>
                        <SelectCurrencies required onChange={this.setCurrency} />
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Rate"><i className="fa-regular text-dark fa-percent me-2"></i>{t('Currencies.Forms.Rate.Title')}</label>
                        <Field
                            ref={Rate => this.Rate = Rate}
                            name="Rate"
                            id="Rate"
                            component={this.renderField}
                            type={"number"}
                            required
                            disabled={this.state.Currency && this.state.Currency.Id === 1 ? true : false}
                            placeholder={t('Currencies.Forms.Rate.Placeholder')}
                            title={t('Currencies.Forms.Rate.Title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row mb-5">

                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} ><i className="fa-regular text-dark fa-sack-dollar me-2"></i>{t('Accounting.JournalVoucher.Forms.Value.Title')}</label>
                        <Field
                            ref={Value => this.Value = Value}
                            name="Value"
                            id="Value"
                            onBlur={this.setTaxValue}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Accounting.JournalVoucher.Forms.Value.Placeholder')}
                            title={t('Accounting.JournalVoucher.Forms.Value.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="TaxRate"><i className="fa-regular text-dark fa-badge-percent me-2"></i>{t('Accounting.Forms.TaxRate.Title')}</label>
                        <Field
                            ref={TaxRate => this.TaxRate = TaxRate}
                            name="TaxRate"
                            id="TaxRate"
                            component={this.renderField}
                            onBlur={this.setTaxValue}
                            type={"number"}
                            required
                            placeholder={t('Accounting.Forms.TaxRate.Placeholder')}
                            title={t('Accounting.Forms.TaxRate.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-end h-100 w-100">
                            <div className="form-check form-switch form-check-custom form-check-solid form-check-success mb-5" title={t('Accounting.Forms.IsIncludeTax.Title')}>
                                <Field
                                    ref={IsIncludeTax => this.IsIncludeTax = IsIncludeTax}
                                    name="IsIncludeTax"
                                    id="IsIncludeTax"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"checkbox"}
                                    checked={this.state.IsIncludeTax}
                                    onChange={e => this.setIsIncludeTax(e.target.checked)}
                                    title={t('Accounting.Forms.IsIncludeTax.Title')}
                                    className="form-check-input h-20px w-30px"
                                />
                                <label className="form-check-label" htmlFor="IsIncludeTax">
                                    {t('Accounting.Forms.IsIncludeTax.Title')}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="TaxValue"><i className="fa-regular text-dark fa-sigma me-2"></i>{t('Accounting.Forms.TaxValue.Title')}</label>
                        <Field
                            ref={TaxValue => this.TaxValue = TaxValue}
                            name="TaxValue"
                            id="TaxValue"
                            component={this.renderField}
                            type={"number"}
                            disabled={true}
                            placeholder={t('Accounting.Forms.TaxValue.Placeholder')}
                            title={t('Accounting.Forms.TaxValue.Title')}
                            className="form-control disabled"
                        />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Note"><i className="fa-regular text-dark fa-note me-2"></i>{t('Accounting.JournalVoucher.Forms.Note.Title')}</label>
                        <Field
                            ref={Note => this.Note = Note}
                            name="Note"
                            id="Note"
                            rows={4}
                            component="textarea"
                            placeholder={t('Accounting.JournalVoucher.Forms.Note.Placeholder')}
                            title={t('Accounting.JournalVoucher.Forms.Note.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Date) {
        errors.Date = 'Accounting.JournalVoucher.Forms.Date.Error';
    }
    if (!values.Rate || values.Rate <= 0) {
        errors.Rate = 'Currencies.Forms.Rate.Error';
    }
    if (!values.Value || values.Value <= 0) {
        errors.Value = 'Accounting.JournalVoucher.Forms.Value.Error';
    }
    if (values.TaxRate < 0 || values.TaxRate > 100) {
        errors.TaxRate = 'Accounting.Forms.TaxRate.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formCreditMemo')(state),
        validate,
        user: state.user
    };
};

const formCreditMemo = reduxForm({
    form: 'formCreditMemo',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formCreditMemo(Form)));
