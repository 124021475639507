import React from "react";
import { withTranslation } from 'react-i18next';

class Card extends React.Component {

    render() {
        const { t, measurement, moreInfo } = this.props;
        if (measurement) {
            return (<>
                <div className="w-100">
                    <div className={`row mb-${moreInfo ? 5 : 0}`}>
                        <div className="col-md-6">
                            <div className="row mb-5">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-solid fa-ruler-vertical text-dark me-2"></i>
                                    {t("Measurements.Forms.Height.Title")}
                                </span>
                                <span className="ms-10">
                                    {
                                        measurement?.Height ?
                                            <>
                                                {measurement?.Height}
                                                <span className="ms-2">
                                                    {t("Measurements.Forms.Height.Unit")}
                                                </span>
                                            </>
                                            : ""
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mb-5">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-solid fa-weight-scale text-dark me-2"></i>
                                    {t("Measurements.Forms.Weight.Title")}
                                </span>
                                <span className="ms-10">
                                    {
                                        measurement?.Weight ?
                                            <>
                                                {measurement?.Weight}
                                                <span className="ms-2">
                                                    {t("Measurements.Forms.Weight.Unit")}
                                                </span>
                                            </>
                                            : ""
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mb-5">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-solid fa-weight-hanging text-dark me-2"></i>
                                    {t("Measurements.BMI.Title")}
                                </span>
                                <span className="ms-10">
                                    {measurement?.Weight && measurement?.Height && this.props.renderBMI ? this.props.renderBMI(measurement) : ""}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mb-5">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-solid fa-head-side text-dark me-2"></i>
                                    {t("Measurements.Forms.HeadCircumference.Title")}
                                </span>
                                <span className="ms-10">
                                    {
                                        measurement?.HeadCircumference ?
                                            <>
                                                {measurement?.HeadCircumference}
                                                <span className="ms-2">
                                                    {t("Measurements.Forms.HeadCircumference.Unit")}
                                                </span>
                                            </>
                                            : ""
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                        moreInfo ? <>
                            <div className="separator separator-dashed border-dark mb-5"></div>
                            <div className="row">
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-user me-1"></i>
                                            {t("LastUpdatedUser")}
                                        </span>
                                        <span className="ms-10">
                                            {measurement?.User?.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-hospital me-1"></i>
                                            {t("HealthCenter.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {measurement?.User?.HealthCenter.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("CreationDate")}
                                        </span>
                                        <span className="ms-10">
                                            {measurement?.CreationDate}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("LastUpdatedDate")}
                                        </span>
                                        <span className="ms-10">
                                            {measurement?.UpdateDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </> : ""
                    }
                </div>
            </>);
        }
        return <div className="w-100">
            <div className="alert alert-dismissible bg-light-info border-3 border-info d-flex flex-column flex-sm-row p-5 mb-10">
                <i className="fs-2hx text-info me-4 mb-0 fa-regular fa-ruler-combined"></i>
                <div className="d-flex flex-column pe-0 pe-sm-10 w-100">
                    <h5 className="mb-1">{t("Measurements.Title")}</h5>
                    <span>{t("Measurements.Alert")}.</span>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <i className="ki-duotone ki-cross fs-1 text-info fa-regular fa-xmark"></i>
                </button>
            </div>
        </div>;
    }
}


export default withTranslation('common')(Card);