import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Info extends React.Component {

    render() {
        const { t, service } = this.props;
        return <>
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                        {t("Table.Options.Info")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-text me-1"></i>
                                    {t("Services.Forms.Name.Title")}
                                </span>
                                <span className="ms-10" title={t(`Services.Type.${service.Type?.Name}`)} >
                                    {service.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-coin me-1"></i>
                                    {t("Services.Forms.Price.Title")}
                                </span>
                                <span className="ms-10">
                                    {service.Price}
                                    <span className="mx-1">
                                        {service.Currency.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                    {t("HealthCenter.Clinic")}
                                </span>
                                <span className="ms-10">
                                    {service.Clinic?.Name}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-dashed border-dark mb-5"></div>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {service.User.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {service.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {service.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>;
    }
}

export default withTranslation('common')(Info);