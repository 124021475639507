import serverAPI from "../../../../api/serverAPI";

export const fetchDrugs = (Name, DrugTypeId) => async dispatch => {
    dispatch({ type: 'DRUGS_LOADING' });
    const response = await serverAPI.get(`/Drugs/?Name=${Name ? Name : ""}&TypeId=${DrugTypeId ? DrugTypeId : "0"}`);
    dispatch({ type: 'DRUGS_INITIAL', drugs: response.data.data });
}

export const fetchPharmacies = () => async dispatch => {
    dispatch({ type: 'PHARMACY_LOADING' });
    const response = await serverAPI.get(`/Pharmacies/`);
    dispatch({ type: 'PHARMACY_INITIAL', pharmacies: response.data.data });
}

export const fetchDrugOrders = (Id, PatientId) => async dispatch => {
    dispatch({ type: 'DRUG_ORDERS_LOADING' });
    const response = await serverAPI.get(`/VisitDrugOrders/${Id}`);
    dispatch({ type: 'DRUG_ORDERS_INITIAL', drugOrders: response.data.data, PatientId });
}

export const fetchDrugTypes = () => async dispatch => {
    dispatch({ type: 'DRUG_TYPES_LOADING' });
    const response = await serverAPI.get(`/DrugTypes/`);
    dispatch({ type: 'DRUG_TYPES_INITIAL', drugTypes: response.data.data });
}