
import serverAPI from '../api/serverAPI';
import { getToken } from "../context/auth";
import jwt_decode from "jwt-decode";

export const checkLogin = () => async dispatch => {
    const token = getToken();
    if (token) {
        const response = await serverAPI.post(`/CheckLogin/`);
        if (response.data.data) {
            dispatch({ type: 'USER_INITIAL', user: response.data.data });
            // getUserNotificationsCount(dispatch);
            setTimeout(() => {
                localStorage.removeItem("token");
                dispatch({ type: 'USER_NOT_LOGIN', user: {} });
            }, checkTokenExpirationMiddleware(token));
        } else {
            localStorage.removeItem("token");
            dispatch({ type: 'USER_NOT_LOGIN', user: {} });
        }
    } else {
        dispatch({ type: 'USER_NOT_LOGIN', user: {} });
    }
}

export const userLogin = user => async dispatch => {
    setTimeout(() => {
        localStorage.removeItem("token");
        dispatch({ type: 'USER_NOT_LOGIN', user });
    }, checkTokenExpirationMiddleware(user.token));
    dispatch({ type: 'USER_INITIAL', user });
    if (user) {
        // getUserNotificationsCount(dispatch);
    }
}

// export const fetchNotifications = (object = {}) => async dispatch => {
//     const response = await serverAPI.get(`/Notifications/?IsView=${object.IsView}&startDate=${object.startDate ? object.startDate : ""}&endDate=${object.endDate ? object.endDate : ""}&RowsCount=${object.RowsCount}`);
//     if (object.isSub) {
//         dispatch({ type: 'NOTIFICATION_SUB_INITIAL', NotificationSub: response.data.data });
//     } else {
//         dispatch({ type: 'NOTIFICATION_INITIAL', Notification: response.data.data });
//     }
// }
// export const updateNotificationIsView = Id => async dispatch => {
//     dispatch({ type: 'NOTIFICATION_IS_VEIW', id: Id });
// }
// export const clearNotification = () => async dispatch => {
//     dispatch({ type: 'NOTIFICATION_SUB_LOADING' });
// }

// export const clearAllNotification = () => async dispatch => {
//     dispatch({ type: 'NOTIFICATION_COUNT_ZERO' });
// }

// const getUserNotificationsCount = async dispatch => {
//     const notification = await serverAPI.get(`/Notifications/`);
//     if (notification.data) {
//         dispatch({ type: 'NOTIFICATION_COUNT_INITIAL', count: notification.data.count });
//     }
// }

const checkTokenExpirationMiddleware = token => {
    if (token) {
        return (jwt_decode(token).exp - Date.now() / 1000) * 1000;
    }
    return 0;
};

export const userLogout = user => async dispatch => {
    dispatch({ type: 'USER_NOT_LOGIN', user });
}

export const fetchVisitStatus = () => async dispatch => {
    dispatch({ type: 'VISIT_STATUS_LOADING' });
    const response = await serverAPI.get(`/VisitStatus/`);
    dispatch({ type: 'VISIT_STATUS_INITIAL', visitStatus: response.data.data });
}

export const fetchOrderStatus = () => async dispatch => {
    dispatch({ type: 'ORDER_STATUS_LOADING' });
    const response = await serverAPI.get(`/OrderStatus/`);
    dispatch({ type: 'ORDER_STATUS_INITIAL', orderStatus: response.data.data });
}