import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../../api/serverAPI";
import history from "../../../history";

class ChangePassword extends React.Component {

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = event => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        const postData = {
            ...this.props.formValues
        };

        serverAPI.put("/ChangePassword/", postData).then(response => {
            if (response.data.code === "fill_all_inputs") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.fill_all_inputs.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.fill_all_inputs.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "old_password_error") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.old_password_error.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.old_password_error.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "same_as_last_3_password_error") {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.Error.same_as_last_3_password_error.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.same_as_last_3_password_error.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Save.Edit.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Save.Edit.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                history.push("/Profile");
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

        return event.preventDefault();
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    render() {
        const { t, pristine, reset, submitting, handleSubmit } = this.props;
        return (<>
            <Helmet>
                <title>{t("Profile.Title")} - {t("Profile.ChangePassword.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="d-flex justify-content-center col-12">
                <div className="card mb-5 mb-xl-10 col-xl-8 ">
                    <div className="card-header cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">
                                <i className="fa-solid h2 fa-key me-2 text-primary"></i>
                                {t("Profile.Title")} - {t("Profile.ChangePassword.Title")}
                            </h3>
                        </div>
                        <div className="align-self-center">
                            <Link to={"/Profile"} className="btn btn-sm btn-info me-2">
                                <i className="fa-regular fa-address-card me-1"></i>
                                {t("Profile.Title")}
                            </Link>
                        </div>
                    </div>
                    <form method="POST" id="formChangePassword" name="formChangePassword" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        onSubmit={
                            handleSubmit(values => {
                                return new Promise(resolve => resolve())
                                    .then(() => {
                                        const errors = validate(values, this.props);
                                        return Object.keys(errors).length !== 0;
                                    })
                                    .then(errors => {
                                        if (errors) {
                                            this.checkSubmit();
                                        } else {
                                            this.onSubmit();
                                        }
                                    })
                            })
                        }
                    >
                        <div className="card-body p-9">

                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-6">
                                <i className="fa-regular fa-circle-info h1 text-primary me-4 mb-0"></i>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-bold">
                                        <div className="fs-6 text-gray-700">
                                            {t("Profile.ChangePassword.New.TypeError")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label className="col-lg-4 required col-form-label fs-6 fw-bold">
                                    {t("Profile.ChangePassword.Current.Title")}
                                </label>
                                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                    <Field
                                        ref={CurrentPassword => this.CurrentPassword = CurrentPassword}
                                        name="CurrentPassword"
                                        component={this.renderField}
                                        type={"password"}
                                        placeholder={t('Profile.ChangePassword.Current.Placeholder')}
                                        title={t('Profile.ChangePassword.Current.Title')}
                                        className="form-control form-control-solid"
                                    />
                                </div>
                            </div>

                            <div className="row mb-6">
                                <label className="col-lg-4 required col-form-label fs-6 fw-bold">
                                    {t("Profile.ChangePassword.New.Title")}
                                </label>
                                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                    <Field
                                        ref={NewPassword => this.NewPassword = NewPassword}
                                        name="NewPassword"
                                        component={this.renderField}
                                        type={"password"}
                                        placeholder={t('Profile.ChangePassword.New.Placeholder')}
                                        title={t('Profile.ChangePassword.New.Title')}
                                        className="form-control form-control-solid"
                                    />
                                </div>
                            </div>

                            <div className="row ">
                                <label className="col-lg-4 required col-form-label fs-6 fw-bold">
                                    {t("Profile.ChangePassword.Confirm.Title")}
                                </label>
                                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                    <Field
                                        ref={ConfirmPassword => this.ConfirmPassword = ConfirmPassword}
                                        name="ConfirmPassword"
                                        component={this.renderField}
                                        type={"password"}
                                        placeholder={t('Profile.ChangePassword.Confirm.Placeholder')}
                                        title={t('Profile.ChangePassword.Confirm.Title')}
                                        className="form-control form-control-solid"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" disabled={pristine || submitting} className="btn btn-sm btn-success me-2">
                                <i className="fa-regular fa-save me-1"></i>
                                {t("Save")}
                            </button>
                            <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-light btn-sm btn-active-light-primary">
                                <i className="fa-regular fa-xmark me-1"></i>
                                {t("Reset")}
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </>);
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formChangePassword')(state),
        user: state.user
    };
};

const validate = values => {
    const errors = {};
    if (!values.CurrentPassword) {
        errors.CurrentPassword = 'Profile.ChangePassword.Current.Error';
    }
    if (!values.NewPassword) {
        errors.NewPassword = 'Profile.ChangePassword.New.Error';
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(values.NewPassword)) {
        errors.NewPassword = 'Profile.ChangePassword.New.TypeError';
    } else if (values.NewPassword === values.CurrentPassword) {
        errors.NewPassword = 'Profile.ChangePassword.New.SameAsOld';
    }

    if (!values.ConfirmPassword) {
        errors.ConfirmPassword = 'Profile.ChangePassword.Confirm.Error';
    } else if (values.NewPassword !== values.ConfirmPassword) {
        errors.ConfirmPassword = 'Profile.ChangePassword.Confirm.NotMatch';
    }

    return errors;
}

const formChangePassword = reduxForm({
    form: 'formChangePassword',
    validate,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formChangePassword(ChangePassword)));