import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Info extends React.Component {

    render() {
        const { t, Exam, radiologyExamPrice } = this.props;

        const currentPrice = radiologyExamPrice?.radiologyExamPrice.filter(p => p.Exam.Id === Exam?.Id)[0];

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-5">
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-vial me-1"></i>
                                {t("ClinicScreen.Orders.Radiology.Table.Name")}
                            </span>
                            <span className="ms-10">
                                {Exam.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-flask-vial me-1"></i>
                                {t("ClinicScreen.Orders.Radiology.Table.Type")}
                            </span>
                            <span className="ms-10">
                                {Exam.Type?.Name}
                            </span>
                        </div>
                    </div>
                </div>
                {
                    currentPrice ? <>

                        <div className="row mb-5">
                            <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-coin me-1"></i>
                                        {t("Services.Forms.Price.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {currentPrice.Price}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-wallet me-1"></i>
                                        {t("Currencies.Forms.Select.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {currentPrice.Currency?.Name}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-4">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        {t("LastUpdatedUser")}
                                    </span>
                                    <span className="ms-10">
                                        {currentPrice.User.Name}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("CreationDate")}
                                    </span>
                                    <span className="ms-10">
                                        {currentPrice.CreationDate}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("LastUpdatedDate")}
                                    </span>
                                    <span className="ms-10">
                                        {currentPrice.UpdateDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </> : ""
                }
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        radiologyExamPrice: state.radiologyExamPrice
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));