import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';

import { fetchDrugs, fetchDrugTypes } from '../actions';
import Dosage from "../Modal/Dosage/";

class SelectDrugs extends React.Component {

    state = {
        SearchByName: "",
        DrugTypes: [],
        SearchDrugTypes: null,
        SelectedDrugs: [],
        initialize: false,
        showDosage: false,
        Drug: null
    };

    constructor(props) {
        super(props);
        const { drugs, drugTypes } = this.props;
        if (!drugs || !drugs.drugs || drugs.drugs?.length === 0) {
            this.props.fetchDrugs();
        }
        if (!drugTypes || !drugTypes.drugTypes || drugTypes.drugTypes?.length === 0) {
            this.props.fetchDrugTypes();
        }
    }

    Sync = () => {
        this.props.fetchDrugs(this.state.SearchByName, this.state.SearchDrugTypes?.value);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.drugTypes && props.drugTypes.drugTypes) {
            state.DrugTypes = props.drugTypes.drugTypes;
        }
        if (!state.initialize && props.SelectedDrugs) {
            state.initialize = true;
            state.SelectedDrugs = props.SelectedDrugs;
        }
        return state;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    setSearchDrugTypes = SearchDrugTypes => {
        this.setState({
            SearchDrugTypes
        });
    }

    onChange = (SearchDrugTypes) => {
        this.setState({
            SearchDrugTypes
        });
    };

    setSelectedDrugs = Drug => {
        let SelectedDrugs = this.state.SelectedDrugs;
        const objIndex = SelectedDrugs.findIndex(p => p.Drug.Id === Drug.Id);
        if (objIndex >= 0) {
            SelectedDrugs = SelectedDrugs.filter(p => p.Drug.Id !== Drug.Id);
            this.setState({
                SelectedDrugs
            });
            this.props.setSelectedDrugs(SelectedDrugs);
        } else {
            this.setState({
                showDosage: true,
                Drug
            });
        }
    }

    submitSelectedDrugs = Dosage => {
        let SelectedDrugs = this.state.SelectedDrugs;
        SelectedDrugs.push({ Drug: this.state.Drug, Dosage });
        this.setState({
            SelectedDrugs
        });
        this.props.setSelectedDrugs(SelectedDrugs);
        this.handleCloseDosage();
    }

    handleCloseDosage = () => {
        this.setState({
            showDosage: false,
            Drug: null
        });
    }

    renderTableRow = () => {
        const { t, drugs } = this.props;
        if (drugs && drugs.drugs && drugs.drugs.length > 0) {
            let searchResult = drugs.drugs.filter(p => p.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()));

            if (searchResult.length > 0) {
                return searchResult.map((type) => {
                    return <tr key={`drug-rx-${type.Id}`}>
                        <td>
                            <div className="form-check form-check-custom">
                                <input
                                    name="checkbox"
                                    type="checkbox"
                                    value={type.Id}
                                    onChange={() => this.setSelectedDrugs(type)}
                                    className="form-check-input"
                                    checked={this.state.SelectedDrugs.some(p => p.Drug.Id === type.Id)} />
                            </div>
                        </td>
                        <td>
                            {type.Name}
                        </td>
                        <td>
                            {type.Type.Name}
                        </td>
                    </tr>
                });
            }
            return <tr><td className="text-center" colSpan={3}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={3}>{t("NoRows")}</td></tr >;
    }

    render() {
        const { t, drugs } = this.props;

        let isLoaded = false;
        if (drugs) {
            isLoaded = drugs.isLoaded;
        }

        return (<>
            {
                this.state.showDosage && this.state.Drug ? <>
                    <Dosage Drug={this.state.Drug} onSubmit={this.submitSelectedDrugs} handleClose={this.handleCloseDosage} />
                </> : ""
            }
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">

                    <div className="input-group">
                        <input
                            value={this.state.SearchByName}
                            onChange={e => this.setSearch(e.target.value)}
                            ref={SearchByName => this.SearchByName = SearchByName}
                            name="SearchByName"
                            id="SearchByName"
                            autoComplete={"off"}
                            type={"text"}
                            placeholder={t('AdvancedSearch.Search.ByName')}
                            title={t('AdvancedSearch.Search.ByName')}
                            className="form-control form-control-sm" />
                        <button className="input-group-text btn btn-sm btn-info" disabled={!isLoaded} onClick={() => this.Sync()} data-kt-indicator={!isLoaded ? "on" : "off"} >
                            <span className="indicator-label">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <span className="indicator-progress">
                                <span className="spinner-border spinner-border-sm align-middle m-0"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <DropdownTreeSelect
                        ref={DrugTypes => this.DrugTypes = DrugTypes}
                        data={this.state.DrugTypes}
                        name="DrugTypes"
                        id="DrugTypes"
                        onChange={this.onChange}
                        texts={{
                            placeholder: this.state.SearchDrugTypes ? this.state.SearchDrugTypes.label : t('AdvancedSearch.Search.ByDrugDrugTypes'),
                            inlineSearchPlaceholder: t('AdvancedSearch.Search.ByDrugDrugTypes'),
                            label: t('AdvancedSearch.Search.ByDrugDrugTypes'),
                            noMatches: t("NoResult")
                        }}
                        mode="radioSelect"
                        clearSearchOnChange
                        keepTreeOnSearch
                        inlineSearchInput={true}
                        className="bootstrap-demo"
                    />

                </div>
            </div>
            <div className={`scroll h-400px border-1 border-dark border-dashed table-responsive ${isLoaded ? "" : "table-loading"}`} >
                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                    <thead>
                        <tr>
                            <th title={t("Select")}>
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-square-check"></i>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Drug.Table.Name")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-pills me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Drug.Table.Name")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Drug.Table.Type")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-tablets me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Drug.Table.Type")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRow()}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        drugs: state.drugs,
        drugTypes: state.drugTypes
    };
};

export default connect(mapStateToProps, { fetchDrugs, fetchDrugTypes })(withTranslation('common')(SelectDrugs));