import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class Info extends React.Component {

    render() {
        const { t, User } = this.props;

        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="mb-5" title={t("NumberAuto")}>
                    <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{User.Id}</span>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-text me-1"></i>
                                {t("Users.Form.Name.Title")}
                            </span>
                            <span className="ms-5">
                                <i title={t(`IsActive.${User.IsActive}`)} className={`fa-solid fa-${User.IsActive === false ? "lock text-danger" : "lock-open text-success"}  me-3`}></i>
                                {User.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-at me-1"></i>
                                {t("Users.Form.Email.Title")}
                            </span>
                            <span className="ms-10">
                                {User.Email}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-phone me-1"></i>
                                {t("Users.Form.Phone.Title")}
                            </span>
                            <span className="ms-10">
                                {User.Phone}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-user me-1"></i>
                                {t("Users.Form.Username.Title")}
                            </span>
                            <span className="ms-10">
                                {User.Username}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-hospital me-1"></i>
                                {t("HealthCenter.Title")}
                            </span>
                            <span className="ms-10">
                                {User.HealthCenter.Name}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-cake-candles me-1"></i>
                                {t("Users.Form.Birthday.Title")}
                            </span>
                            <span className="ms-10">
                                {User.Birthday}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-location-dot me-1"></i>
                                {t("Users.Form.Address.Title")}
                            </span>
                            <span className="ms-10">
                                {User.Address}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <span className="fw-bold text-muted mb-2">
                            <i className="fa-regular text-dark fa-shield-keyhole me-1"></i>
                            {t("Users.Form.Permission.Title")}
                        </span>
                        <div className="d-flex flex-column ms-10">
                            {
                                User.UserPermission.map(permission => {
                                    return <li key={`permissions-${permission.Id}`} className="d-flex align-items-center pb-1">
                                        <span className={`bullet bullet-lg me-2 bg-primary`}></span>
                                        <span>
                                            {permission.Name}
                                        </span>
                                    </li>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-right-to-bracket me-1"></i>
                                {t("Profile.Form.LastLogin")}
                            </span>
                            <span className="ms-10">
                                {User.LastLogin}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("CreationDate")}
                            </span>
                            <span className="ms-10">
                                {User.CreationDate}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("LastUpdatedDate")}
                            </span>
                            <span className="ms-10">
                                {User.UpdateDate}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));