const AppointmentReducers = (state = [], action) => {
    switch (action.type) {
        case "APPOINTMENT_LOADING":
            return {
                appointments: [],
                isLoaded: false
            };
        case "APPOINTMENT_INITIAL":
            return {
                appointments: action.appointments,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default AppointmentReducers;