import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import SelectExam from "./SelectExam";
import serverAPI from "../../../../api/serverAPI";
import Discount from "../../../ClinicScreen/Services/Discount";
import SearchByDocumentNumber from "../../../Patients/Search/ByDocumentNumber/";

class Form extends React.Component {

    state = { Id: 0, isSubmit: false, SelectedExam: [], labCenters: [], initialize: false, Discount: 0, DiscountRate: 0, showDiscount: false, Amount: 0, Patient: null };

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.Order) {
            state.initialize = true;
            props.initialize({
                OrderNote: props.Order.Note
            });
            state.Id = props.Order.Id;
            let SelectedExam = [];
            props.Order.items.forEach(item => {
                SelectedExam.push({
                    Id: item.LabExam.Price.Id,
                    Exam: {
                        Id: item.LabExam.Price.Id,
                        Name: item.LabExam.Name
                    },
                    Currency: { ...item.LabExam.Price.Currency },
                    Price: item.LabExam.Price.Price,
                    IsActive: item.LabExam.Price.IsActive
                });
            });
            state.SelectedExam = SelectedExam;
        }
        let Amount = 0;
        state.SelectedExam.forEach(item => {
            Amount += item.Price * item.Currency.Rate / props.user.user.HealthCenter.Currency.Rate;
        });
        state.Amount = Amount;

        return state;
    }

    renderFieldGroup = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} readOnly={readOnly} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            <button className="btn btn-primary" type="button" onClick={() => this.showModal()}>
                <i className="fa-regular fa-magnifying-glass"></i>
                {this.props.t('Select')}
            </button>
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderTextArea = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        rows,
        meta: { touched, error }
    }) => (
        <>
            <textarea {...input} id={id} title={title} rows={rows} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    onSubmit = event => {
        const { t } = this.props;
        if (this.state.Patient) {

            this.setState({
                isSubmit: true
            });
            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            let method = serverAPI.post;
            if (this.props.isEdit === true) {
                method = serverAPI.put;
            }
            method(`/LabOutOrders/`, {
                ...this.props.formValues,
                Discount: this.state.Discount,
                LabOrderId: this.state.Id,
                PatientId: this.state.Patient.Id,
                Exams: this.state.SelectedExam.map(({ Id }) => Id)
            }).then(response => {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Order.Lab.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Order.Lab.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
                this.props.update();
                this.props.handleClose();
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
                this.setState({
                    isSubmit: false
                });
            });
        }
        else {
            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.AllInformationIsRequired.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.AllInformationIsRequired.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        }
        return event.preventDefault();
    }

    showDiscount = () => {
        this.setState({
            showDiscount: true
        });
    }

    handleClose = () => {
        this.setState({
            showDiscount: false
        });
    }

    setSelectedExam = SelectedExam => {
        this.setState({
            SelectedExam,
            DiscountRate: 0,
            Discount: 0
        });
    }

    update = result => {
        this.setState({
            DiscountRate: result.Rate,
            Discount: result.Value
        });
        this.handleClose();
    }

    selectPatient = Patient => {
        this.setState({
            Patient
        });
    }

    render() {
        const { t, pristine, submitting, user } = this.props;
        return (<>
            {this.state.showDiscount ? <Discount handleClose={this.handleClose} Value={this.state.Discount} Rate={this.state.DiscountRate} Amount={this.state.Amount} update={this.update} /> : ""}
            <div className="alert alert-dismissible bg-light-info border-3 border-info d-flex flex-column flex-sm-row p-5 mb-5">
                <i className="fs-2hx text-info me-4 mb-0 fa-regular fa-info"></i>
                <div className="d-flex flex-column pe-0 pe-sm-10 w-100">
                    <h5 className="mb-1">{t("Directions")}</h5>
                    <span>{t("Lab.Exams.Accept.Alert")}.</span>

                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <i className="ki-duotone ki-cross fs-1 text-info fa-regular fa-xmark"></i>
                </button>
            </div>
            <form method="POST" name="formNewLabOrder" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={this.onSubmit}>
                <div className="row mb-5" >
                    <div className="col-lg-7 ">
                        <SelectExam SelectedExam={this.state.SelectedExam} setSelectedExam={this.setSelectedExam} />
                    </div>
                    <div className="col-lg-5">
                        <div className="d-flex justify-content-between flex-column">
                            <div className="mb-5">
                                <SearchByDocumentNumber update={this.selectPatient} />
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center pb-0`}>
                                        <i className="fa-regular fa-vial me-2 text-primary h3 mb-0"></i>
                                        {t('ClinicScreen.Orders.Lab.SelectedExam')}
                                        :
                                        ({this.state.SelectedExam.length})
                                    </div>
                                </div>
                            </div>
                            <div className={`scroll h-250px border-1 p-3`} >
                                <div className="d-flex flex-column">
                                    {
                                        this.state.SelectedExam.map(item => {
                                            return <li key={`exam-item-${item.Id}`} className="d-flex align-items-center py-2">
                                                <span className="bullet me-5 bg-primary"></span> {item.Exam?.Name}
                                            </li>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="row w-100 ">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-sigma me-2"></i>
                                                {t("Services.Amount")}
                                                :
                                            </span>
                                            <span className="fs-5 ms-5">
                                                {this.state.Amount}
                                                <span className="mx-1">
                                                    {user.user.HealthCenter.Currency.Name}
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <Link to="#" className="row" onClick={() => this.showDiscount()}>
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-solid fa-percent me-2 text-dark "></i>
                                                {t("Services.Discount.Title")}
                                                :
                                            </span>
                                            <span className="fs-5 ms-5">
                                                {this.state.Discount}
                                                <span className="mx-1">
                                                    {user.user.HealthCenter.Currency.Name}
                                                </span>
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="col-md-4" title={t("Services.Total.Note")}>
                                        <div className="row">
                                            <span className="fw-bold text-muted mb-2">
                                                <i className="fa-regular text-dark fa-calculator me-2"></i>
                                                {t("Services.Total.Title")}
                                                :
                                            </span>
                                            <span className="fs-5 ms-5">
                                                {this.state.Amount - this.state.Discount}
                                                <span className="mx-1">
                                                    {user.user.HealthCenter.Currency.Name}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="OrderNote"><i className="fa-regular text-dark fa-note me-2"></i>{t('ClinicScreen.Orders.Forms.Note.Title')}</label>
                        <Field
                            ref={OrderNote => this.OrderNote = OrderNote}
                            name="OrderNote"
                            id="OrderNote"
                            rows={5}
                            component={this.renderTextArea}
                            placeholder={t('ClinicScreen.Orders.Forms.Note.Placeholder')}
                            title={t('ClinicScreen.Orders.Forms.Note.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Lab.Send')} disabled={(pristine || submitting || this.state.isSubmit || this.state.SelectedExam.length === 0 || this.state.Patient === null) && (this.state.SelectedExam.length === 0 || this.state.isSubmit || this.state.Patient === null)} data-kt-indicator={this.state.isSubmit ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-microscope"></i>
                            {t('Lab.Send')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose} disabled={submitting}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formNewLabOrder')(state),
        labCenters: state.labCenters,
        user: state.user
    };
};

const formNewLabOrder = reduxForm({
    form: 'formNewLabOrder',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formNewLabOrder(Form)));