const VitalSignsReducers = (state = [], action) => {
    switch (action.type) {
        case "VITAL_SIGNS_LOADING":
            return {
                vitalSigns: null,
                PatientId: 0,
                isLoaded: false
            };
        case "VITAL_SIGNS_INITIAL":
            return {
                vitalSigns: action.vitalSigns,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default VitalSignsReducers;