import React from "react";
import { withTranslation } from 'react-i18next';

class DrugCard extends React.Component {

    render() {
        const { t, DrugItem, showMore } = this.props;

        return <div className="d-flex flex-column w-100">
            <div className="mb-2">
                {DrugItem.Drug.Name}
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-wrap align-items-center">
                    <span className="d-flex align-items-center ms-5" title={t('ClinicScreen.Orders.Drug.Dosage.Title')}>
                        <i className="fa-regular text-dark fa-capsules me-2"></i>
                        {DrugItem.Dosage.Dosage}
                    </span>
                    <i className="fa-regular fa-xmark mx-2 text-primary"></i>
                    <span className="d-flex align-items-center" title={t("ClinicScreen.Orders.Drug.Dosage.Repeat.Title")}>
                        <i className="fa-regular text-dark fa-tally me-2"></i>
                        {DrugItem.Dosage.Repeat}
                    </span>
                    <i className="fa-regular fa-xmark mx-2 text-primary"></i>
                    <span className="d-flex align-items-center" title={t("ClinicScreen.Orders.Drug.Dosage.Days.Title")}>
                        <i className="fa-regular text-dark fa-hashtag me-2"></i>
                        {DrugItem.Dosage.Days}
                    </span>
                </div>
                {
                    DrugItem.Dosage.Time ? <>
                        <span className="d-flex align-items-center ms-5" title={t("ClinicScreen.Orders.Drug.Dosage.Time.Title")}>
                            <i className="fa-regular text-dark fa-clock me-2"></i>
                            {t(`ClinicScreen.Orders.Drug.Dosage.Time.Options.${DrugItem.Dosage.Time}`)}
                        </span>
                    </> : ""
                }
            </div>
            {
                showMore ? <>
                    <div className="separator separator-dashed border-dark my-5"></div>
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {DrugItem.User?.Name}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {DrugItem.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {DrugItem.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>
                </> : ""
            }
        </div>;
    }
}


export default withTranslation('common')(DrugCard);