import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from "react-i18next";
import { configureStore } from '@reduxjs/toolkit';
import moment from "moment";

import reducers from './reducers';

import App from './component/App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next'
import common_ar from "./language/ar.json";
import history from './component/history';

import 'react-toastify/dist/ReactToastify.css';
import { Router } from "react-router-dom";

moment.locale('en');

i18n
  .init({
    interpolation: { escapeValue: false },
    lng: 'ar',
    resources: {
      ar: {
        common: common_ar,
        title: 'اللغة العربية',
        icon: '/assets/media/svg/flags/008-saudi-arabia.svg',
        stylePath: '/custom/ar.css'
      },
    },
    react: {
      useSuspense: true,
    }
  });

const store = configureStore({
  reducer: reducers,
  middleware: [thunk]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router history={history} >
          <Suspense fallback={""}>
            <App />
          </Suspense>
        </Router >
      </I18nextProvider>
    </Provider>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      pauseOnHover={true}
      progress={undefined}
      rtl
      pauseOnFocusLoss
      draggable={true}
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
