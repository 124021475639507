import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";
import Select from 'react-select';

import serverAPI from "../../../api/serverAPI";
import SelectCurrencies from "../../shared/SelectList/SelectCurrencies";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, Id: 0, Services: [], defaultCurrency: null };

    constructor(props) {
        super(props);
        this.ServiceId = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {

        if (props.currentClinic?.Services) {
            state.Services = props.currentClinic.Services.filter(p => p.Type.Name === "other").map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }

        if (!state.isInitialize && props.isEdit === true && props.service) {
            state.isInitialize = true;
            props.initialize({
                Price: props.service.Price,
                Rate: props.service.Rate,
                CurrencyId: props.service.Currency.Id
            });
            state.Id = props.service.Id;
            state.Currency = props.service.Currency;
        }
        return state;
    }

    componentDidMount() {
        if (this.props.isEdit && this.props.service) {
            this.setState({
                defaultCurrency: [{ Id: this.props.service.Currency.Id, value: this.props.service.Currency.Id, label: this.props.service.Currency.Name }]
            });
        }
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t, Appointment, currentClinic, BillId } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let Id = Appointment.Id;
        let method = serverAPI.post;
        if (this.props.isEdit) {
            method = serverAPI.put;
            Id = this.state.Id;
        }

        method(`/Services/${Id}`, {
            ...this.props.formValues,
            PatientId: Appointment.patient.Id,
            ClinicId: currentClinic.Id,
            VisitId: Appointment.Id,
            BillId: BillId
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    setRate = Currency => {
        this.props.dispatch(change('formServices', 'Rate', Currency.rate));
        this.props.dispatch(change('formServices', 'CurrencyId', Currency.Id));
        this.setState({
            Currency
        });
    }

    setService = Service => {
        this.props.dispatch(change('formServices', 'CurrencyId', Service.Currency.Id));
        this.props.dispatch(change('formServices', 'Rate', Service.Currency.Rate));
        this.props.dispatch(change('formServices', 'Price', Service.Price));
        this.props.dispatch(change('formServices', 'ServiceId', Service.Id));
        this.setState({
            defaultCurrency: { ...Service.Currency, rate: Service.Currency.Rate, Id: Service.Currency.Id, value: Service.Currency.Id, label: Service.Currency.Name },
            Currency: Service.Currency
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    render() {
        const { t } = this.props;

        return <>

            <form method="POST" name="formServices" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="ServiceId"><i className="fa-regular text-dark fa-wallet me-2"></i>{t('Services.Forms.Name.Title')}</label>
                        {
                            this.props.isEdit ?
                                <input
                                    placeholder={t('Services.Forms.Name.Placeholder')}
                                    title={t('Services.Forms.Name.Title')}
                                    value={this.props.service.Details}
                                    className="form-control"
                                    disabled={true}
                                />
                                :
                                <Select
                                    name="ServiceId"
                                    id="ServiceId"
                                    placeholder={t('Services.Forms.Name.Placeholder')}
                                    title={t('Services.Forms.Name.Title')}
                                    isSearchable={true}
                                    onChange={this.setService}
                                    required
                                    ref={this.ServiceId}
                                    options={this.state.Services}
                                    filterOption={this.filterOptions}
                                />
                        }

                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Price"><i className="fa-regular text-dark fa-coin me-2"></i>{t('Services.Forms.Price.Title')}</label>
                        <Field
                            ref={Price => this.Price = Price}
                            name="Price"
                            id="Price"
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Services.Forms.Price.Placeholder')}
                            title={t('Services.Forms.Price.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <SelectCurrencies required onChange={this.setRate} defaultValue={this.state.defaultCurrency} />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Rate"><i className="fa-regular text-dark fa-percent me-2"></i>{t('Currencies.Forms.Rate.Title')}</label>
                        <Field
                            ref={Rate => this.Rate = Rate}
                            name="Rate"
                            id="Rate"
                            component={this.renderField}
                            type={"number"}
                            required
                            disabled={this.state.Currency && this.state.Currency.Id === 1 ? true : false}
                            placeholder={t('Currencies.Forms.Rate.Placeholder')}
                            title={t('Currencies.Forms.Rate.Title')}
                            className="form-control"
                        />
                    </div>

                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Price) {
        errors.Price = 'Services.Forms.Price.Error';
    }
    if (!values.Rate || values.Rate <= 0) {
        errors.Rate = 'Currencies.Forms.Rate.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formServices')(state),
        validate,
        user: state.user
    };
};

const formServices = reduxForm({
    form: 'formServices',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formServices(Form)));
