import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import jsonData from "./data.json";

class DrugAllergyAlert extends React.Component {

    renderItem = Drugs => {
        if (Drugs) {
            return Drugs.filter(p => p !== "others").map((DrugId, index) => {
                const drug = jsonData.DrugAllergy.filter(p => p.Id === DrugId)[0];
                if (drug) {
                    return <li key={`Drug-Allergy-Alert-${index}`} className="d-flex align-items-center pb-1">
                        <span className="bullet bg-dark mx-5"></span> {drug.Name}.
                    </li>
                }
                return <></>;
            });
        }
        return <></>;
    }

    renderOtherItem = (Drugs, drug) => {
        const hasDrugs = Drugs.some(p => p === "others");
        if (drug && hasDrugs) {
            const otherDrug = jsonData.DrugAllergy.filter(p => p.Id === "others")[0];
            return <li className="d-flex align-items-center">
                <span className="bullet bg-dark mx-5"></span> {otherDrug.Name} :  ({drug}).
            </li>
        };
        return <></>;
    }

    render() {
        const { t, drugAllergy } = this.props;
        if (drugAllergy && drugAllergy.drugAllergy && drugAllergy.drugAllergy.Name && drugAllergy.drugAllergy.Name.Drugs?.length > 0) {
            return <div className="alert alert-dismissible bg-light-danger border-3 border-danger d-flex flex-column flex-sm-row p-5 mb-5">
                <i className="fs-2hx text-danger me-2 mb-0 fa-regular fa-light-emergency-on fa-shake"></i>
                <div className="d-flex flex-column pe-1 w-100">
                    <h5 className="mb-1">{t("DrugAllergy.Title")}</h5>
                    <span>{t("DrugAllergy.Alert")}.</span>
                    <ul className="d-flex flex-column" style={{
                        direction: "ltr"
                    }}>
                        {this.renderItem(drugAllergy.drugAllergy.Name.Drugs)}
                        {this.renderOtherItem(drugAllergy.drugAllergy.Name.Drugs, drugAllergy.drugAllergy.Name.others)}
                    </ul>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <i className="ki-duotone ki-cross fs-1 text-danger fa-regular fa-xmark"></i>
                </button>
            </div>;
        }
        return <></>;
    }
}

const mapStateToProps = state => {
    return {
        drugAllergy: state.drugAllergy
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(DrugAllergyAlert));