import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { getFormValues, reduxForm } from "redux-form";
import Select from 'react-select';

import SearchByDocumentNumber from "../../Patients/Search/ByDocumentNumber/";
import { fetchOrderStatus as fetch } from "../../../actions";

class Form extends React.Component {

    state = {
        Patient: null,
        isSubmit: false,
        searchOrderStatus: [],
        startDate: moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    };

    componentDidMount() {
        const { orderStatus } = this.props;
        if (!orderStatus || !orderStatus.orderStatus || orderStatus.orderStatus?.length === 0) {
            this.props.fetch();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.orderStatus && props.orderStatus.orderStatus) {
            state.orderStatus = props.orderStatus.orderStatus.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    label: <div> <span className={`badge badge-square badge-${item.Color} badge-sm me-1 p-2`}>{props.t(`OrdersStatus.${item.Name}`)}</span></div>,
                };
            });
        }
        return state;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.fetchOrders({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            PatientId: this.state.Patient?.Id,
            OrderStatus: this.state.searchOrderStatus.map(item => item.Id)
        });
        return false;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setOrderStatus = searchOrderStatus => {
        this.setState({
            searchOrderStatus
        });
    }

    selectPatient = Patient => {
        this.setState({
            Patient
        });
    }

    render() {
        const { t, orderStatus, labOrders } = this.props;

        let isLoadedOrderStatus = false;
        if (orderStatus) {
            isLoadedOrderStatus = orderStatus.isLoaded;
        }

        let isLoaded = false;
        if (labOrders && labOrders.labOrders) {
            isLoaded = labOrders.isLoaded;
        } else if (labOrders.length === 0) {
            isLoaded = true;
        }

        return <>
            <form method="POST" name="formLabSpecimenTakenSearch" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password"
                onSubmit={e => this.onSubmit(e)}   >
                <div className="row mb-5">
                    <div className="col-md-4">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Date"><i className="fa-regular text-dark fa-calendar me-2"></i>{t('Lab.SpecimenTaken.Orders.Date')}</label>
                        <DateRangePicker
                            ref={SelectDate => this.SelectDate = SelectDate}
                            initialSettings={{
                                opens: 'center',
                                autoUpdateInput: true,
                                buttonClasses: 'btn',
                                cancelClass: "btn-danger",
                                applyButtonClasses: "btn-primary",
                                showDropdowns: true,
                                format: 'DD/MM/YYYY',
                                timePicker: false,
                                maxDate: `${moment().format('DD/MM/YYYY')}`,
                                locale: {
                                    format: 'DD/MM/YYYY',
                                    cancelLabel: t('Close'),
                                    firstDay: 6,
                                    applyLabel: t('Apply'),
                                    customRangeLabel: t('DateRangePicker.Range.customRange'),
                                    monthNames: [
                                        `${t("DateRangePicker.Months.January")}`,
                                        `${t("DateRangePicker.Months.February")}`,
                                        `${t("DateRangePicker.Months.March")}`,
                                        `${t("DateRangePicker.Months.April")}`,
                                        `${t("DateRangePicker.Months.May")}`,
                                        `${t("DateRangePicker.Months.June")}`,
                                        `${t("DateRangePicker.Months.July")}`,
                                        `${t("DateRangePicker.Months.August")}`,
                                        `${t("DateRangePicker.Months.September")}`,
                                        `${t("DateRangePicker.Months.October")}`,
                                        `${t("DateRangePicker.Months.November")}`,
                                        `${t("DateRangePicker.Months.December")}`
                                    ],
                                    daysOfWeek: [
                                        t("DateRangePicker.DaysOfWeek.Sunday"),
                                        t("DateRangePicker.DaysOfWeek.Monday"),
                                        t("DateRangePicker.DaysOfWeek.Tuesday"),
                                        t("DateRangePicker.DaysOfWeek.Wednesday"),
                                        t("DateRangePicker.DaysOfWeek.Thursday"),
                                        t("DateRangePicker.DaysOfWeek.Friday"),
                                        t("DateRangePicker.DaysOfWeek.Saturday")
                                    ],
                                },
                            }}
                            onApply={this.handleApplyDate} >
                            <input
                                ref={Date => this.Date = Date}
                                name="Date"
                                id="Date"
                                autoComplete="off"
                                required
                                className="form-control form-control-solid ps-10"
                                type="text"
                                placeholder={t('Lab.SpecimenTaken.Orders.Date')}
                                title={t("Lab.SpecimenTaken.Orders.Date")}
                            />
                        </DateRangePicker>
                    </div>

                    <div className="col-md-4">
                        <SearchByDocumentNumber update={this.selectPatient} />
                    </div>

                    <div className="col-md-4">
                        <label className="col-form-label fw-bold fs-6" htmlFor="DocumentNumber"><i className="fa-regular text-dark fa-circle me-2"></i>{t('OrdersStatus.Title')}</label>
                        <div className="d-flex align-items-center mb-2 w-100">
                            <Select
                                onChange={this.setOrderStatus}
                                isClearable={true}
                                name="ByOrderStatus"
                                id="ByOrderStatus"
                                className="w-100"
                                placeholder={t('AdvancedSearch.Search.ByOrderStatus')}
                                title={t('AdvancedSearch.Search.ByOrderStatus')}
                                isRtl={true}
                                isLoading={!isLoadedOrderStatus}
                                isSearchable={true}
                                isMulti={true}
                                ref={ByOrderStatus => this.ByOrderStatus = ByOrderStatus}
                                options={this.state.orderStatus}
                                filterOption={this.filterOptions}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0 justify-content-start pt-3">
                    <button type="submit" className="btn btn-sm btn-info mx-1" title={t('Search')} disabled={!isLoaded} data-kt-indicator={!isLoaded ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-magnifying-glass"></i>
                            {t('Search')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </form>
        </>;
    }
}


const mapStateToProps = state => {
    return {
        formValues: getFormValues('formLabSpecimenTakenSearch')(state),
        user: state.user,
        orderStatus: state.orderStatus,
        labOrders: state.labOrders
    };
};

const formLabSpecimenTakenSearch = reduxForm({
    form: 'formLabSpecimenTakenSearch',
    enableReinitialize: true,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetch })(withTranslation('common')(formLabSpecimenTakenSearch(Form)));