import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import moment from "moment";

import Table from "./Table";
import Form from "./Form";
import { fetchJournalVouchers } from "./actions";
import New from "./New";
import CreditMemo from "./Memo/CreditMemo";
import DebitMemo from "./Memo/DebitMemo";

class JournalVouchers extends React.Component {

    state = {
        searchKey: {
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY')
        }, showVoucher: false, showCreditMemo: false
    };

    update = searchKey => {
        if (searchKey) {
            this.setState({
                searchKey
            });
            this.props.fetchJournalVouchers(searchKey);
        } else {
            this.props.fetchJournalVouchers(this.state.searchKey);
        }
    }

    showCreditMemo = () => {
        this.setState({
            showCreditMemo: true
        });
    }

    showDebitMemo = () => {
        this.setState({
            showDebitMemo: true
        });
    }

    showVoucher = () => {
        this.setState({
            showVoucher: true
        });
    }

    handleClose = () => {
        this.setState({
            showVoucher: false,
            showDebitMemo: false,
            showCreditMemo: false
        });
    }

    render() {
        const { t, journalVouchers } = this.props;

        let isLoaded = false;
        if (journalVouchers) {
            isLoaded = journalVouchers.isLoaded;
        }
        return (<>
            {this.state.showVoucher ? <New update={this.update} handleClose={this.handleClose} /> : ""}
            {this.state.showCreditMemo ? <CreditMemo update={this.update} handleClose={this.handleClose} /> : ""}
            {this.state.showDebitMemo ? <DebitMemo update={this.update} handleClose={this.handleClose} /> : ""}
            <Helmet>
                <title>{t("Accounting.JournalVoucher.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                    <div className="card-toolbar">

                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-light-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1 me-2">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        title={t('Accounting.JournalVoucher.New')}
                                        onClick={() => this.showVoucher()}
                                        className="dropdown-item"  >
                                        <i className="me-1 fa-solid fa-plus"></i>
                                        {t('Accounting.JournalVoucher.New')}
                                    </Link>
                                    <Link to="#"
                                        title={t('Accounting.DebitMemo.New')}
                                        onClick={() => this.showDebitMemo()}
                                        className="dropdown-item"  >
                                        <i className="me-1 fa-solid fa-scale-unbalanced"></i>
                                        {t('Accounting.DebitMemo.New')}
                                    </Link>
                                    <Link to="#"
                                        title={t('Accounting.CreditMemo.New')}
                                        onClick={() => this.showCreditMemo()}
                                        className="dropdown-item"  >
                                        <i className="me-1 fa-solid fa-scale-unbalanced-flip"></i>
                                        {t('Accounting.CreditMemo.New')}
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form fetchOrders={this.update} />
                    </div>
                </div>
            </div>
            {
                isLoaded ? <div className="card">
                    <div className="card-header align-items-center ">
                        <h3 className="card-title">
                            <span className="fw-bolder mb-2 text-dark">
                                <i className="h3 fa-duotone fa-ticket me-2 text-info mb-0"></i>
                                {t('Accounting.JournalVoucher.Title')}
                            </span>
                        </h3>
                    </div>
                    <div className="card-body pt-5 px-2">
                        <div className="w-100">
                            <Table update={this.update} />
                        </div>
                    </div>
                </div> : ""
            }

        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        journalVouchers: state.journalVouchers
    };
};

export default connect(mapStateToProps, { fetchJournalVouchers })(withTranslation('common')(JournalVouchers));