import React from "react";
import { withTranslation } from 'react-i18next';

class Card extends React.Component {

    render() {
        const { t, Order, forPrint } = this.props;

        return (<>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                        <i className="fa-regular fa-vial me-2 text-primary h3 mb-0"></i>
                        {t('ClinicScreen.Orders.Lab.SelectedExam')}
                        :
                        ({Order.items.filter(p => p.LabExam.IsSelectable === true).length})
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="d-flex flex-column ms-10">
                    {
                        Order.items.filter(p => p.LabExam.IsSelectable === true).map(item => {
                            return <li key={`lab-exam-item-${item.Id}`} className="d-flex align-items-center py-2">
                                <span title={t(`OrdersStatus.${item.OrderItemStatus?.Name}`)} className={`bullet bullet-lg me-5 bg-${item.OrderItemStatus?.Color}`}></span>
                                <span title={item.LabExam.Type.Name}>
                                    {item.LabExam.Name}
                                </span>
                            </li>
                        })
                    }
                </div>
            </div>
            <div className="row mb-5">
                {
                    Order.Note ?
                        <div className={`col-md-6`}>
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-note me-2"></i>
                                    {t("ClinicScreen.Orders.Forms.Note.Title")}
                                </span>
                                <span className={`${forPrint ? "" : "ms-10"}`}>
                                    {Order.Note}
                                </span>
                            </div>
                        </div>
                        : ""
                }
                {
                    forPrint ? "" : <div className={`col-md-6`}>
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className={`fa-solid text-${Order.Status?.Color} fa-circle me-1`}></i>
                                {t("OrdersStatus.Title")}
                            </span>
                            <span className="ms-10">
                                <span className={`badge badge-square badge-${Order.Status?.Color} badge-sm me-1 p-2`}>{t(`OrdersStatus.${Order.Status?.Name}`)}</span>
                            </span>
                        </div>
                    </div>
                }
            </div>
            {
                forPrint ? "" :
                    <div className="row mb-5">
                        <div className={`${forPrint ? "col-6" : "col-md-6"}`}>
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-hospital me-2"></i>
                                    {t("HealthCenter.Title")}
                                </span>
                                <span className={`${forPrint ? "mx-10" : "ms-10"}`} >
                                    {Order.LabCenter.HealthCenter.Name}
                                </span>
                            </div>
                        </div>
                        <div className={`${forPrint ? "col-6" : "col-md-6"}`}>
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-microscope me-2"></i>
                                    {t("HealthCenter.Lab")}
                                </span>
                                <span className={`${forPrint ? "mx-10" : "ms-10"}`}>
                                    {Order.LabCenter.Name}
                                </span>
                            </div>
                        </div>
                    </div>
            }
        </>);
    }
}

export default withTranslation('common')(Card);