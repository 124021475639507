import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";

import { userLogout, checkLogin } from '../../../actions/';
import history from '../../history';
import { setToken } from '../../../api/serverAPI';

class Logout extends React.Component {

    componentDidMount() {
        const { t } = this.props;

        localStorage.removeItem("token");
        this.props.userLogout(this.props.user);
        setToken("");

        var toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        toast.update(toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Logout.Title')}
                </div>
                <div className="h6">
                    {t('toast.Logout.Body')}
                </div>
            </div>,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000
        });
        history.push("/Login");
    }

    render() {
        return "";
    }

}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, { userLogout, checkLogin })(withTranslation('common')(Logout));