const LabSpecimentReducers = (state = [], action) => {
    switch (action.type) {
        case "LAB_ORDERS_SPECIMENTS_LOADING":
            return {
                labSpeciments: [],
                isLoaded: false
            };
        case "LAB_ORDERS_SPECIMENTS_INITIAL":
            return {
                labSpeciments: action.labSpeciments,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LabSpecimentReducers;