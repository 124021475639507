const DiagnosesReducers = (state = [], action) => {
    switch (action.type) {
        case "DIAGNOSES_LOADING":
            return {
                diagnoses: [],
                isLoaded: false
            };
        case "DIAGNOSES_INITIAL":
            return {
                diagnoses: action.diagnoses,
                isLoaded: true
            };

        case "DIAGNOSES_FAVPURITE_UPDATE":
            let objIndex = state.diagnoses.findIndex(v => v.Id === action.id);
            state.diagnoses[objIndex] = action.newNode;
            return {
                diagnoses: state.diagnoses,
                isLoaded: true,
                newNode: action.newNode
            };
        default:
            return state;
    }
}

export default DiagnosesReducers;