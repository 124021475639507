import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from "redux-form";

import { fetchBankAccounts as fetch } from "./actions";
import SelectCurrencies from "../../shared/SelectList/SelectCurrencies";
import SelectBanks from "../../shared/SelectList/SelectBanks";
import SelectBankBranches from "../../shared/SelectList/SelectBankBranches";

class Form extends React.Component {

    state = { IsActive: true }

    onSubmit = event => {
        event.preventDefault();
        this.props.fetch({ ...this.props.formValues });
        return false;
    }

    constructor(props) {
        super(props);
        this.props.initialize({
            Name: "",
            Number: "",
            Show: 100,
            BankId: 0,
            CurrencyId: 0,
            BankBranchId: 0,
            IsActive: true,
            BalanceStart: null,
            BalanceEnd: null,
            HasChildren: this.props.HasChildren !== null ? this.props.HasChildren : null
        });
    }

    setIsActive = IsActive => {
        this.setState({
            IsActive
        });
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} checked={checked} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setCurrency = Currency => {
        this.props.dispatch(change('formBankAccountsSearch', 'CurrencyId', Currency ? Currency.Id : 0));
    }

    setBank = Bank => {
        this.props.dispatch(change('formBankAccountsSearch', 'BankId', Bank ? Bank.Id : 0));
    }

    setBankBranch = BankBranch => {
        this.props.dispatch(change('formBankAccountsSearch', 'BankBranchId', BankBranch ? BankBranch.Id : 0));
    }

    reset = () => {
        this.props.reset();
        this.props.dispatch(change('formBankAccountsSearch', 'CurrencyId', 0));
    }

    render() {
        const { t, submitting, bankAccounts } = this.props;

        let isLoaded = false;
        if (bankAccounts && bankAccounts.bankAccounts) {
            isLoaded = bankAccounts.isLoaded;
        } else if (bankAccounts.length === 0) {
            isLoaded = true;
        }

        return <>
            <form method="POST" name="formBankAccountsSearch" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >

                <div className="row mb-5">
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_SearchByNumber" ><i className="fa-regular text-dark fa-hashtag me-2"></i>{t('Accounting.BankAccounts.Forms.Number.Title')}</label>
                        <Field
                            ref={Number => this.Number = Number}
                            name="Number"
                            id="Search_SearchByNumber"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"text"}
                            placeholder={t('AdvancedSearch.Search.ByNumber')}
                            title={t('AdvancedSearch.Search.ByNumber')}
                            className="form-control" />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_SearchByName" ><i className="fa-regular text-dark fa-text me-2"></i>{t('Accounting.BankAccounts.Forms.Name.Title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Search_SearchByName"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"text"}
                            placeholder={t('AdvancedSearch.Search.ByName')}
                            title={t('AdvancedSearch.Search.ByName')}
                            className="form-control " />

                    </div>
                    <div className="col-md-3">
                        <SelectCurrencies onChange={this.setCurrency} isClearable />
                    </div>
                    <div className="col-md-3">
                        <SelectBanks onChange={this.setBank} isClearable />
                    </div>

                </div>

                <div className="row mb-5">
                    <div className="col-md-3">
                        <SelectBankBranches onChange={this.setBankBranch} isClearable BankId={this.props.formValues?.BankId} />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_ByBalanceStart" ><i className="fa-regular text-dark fa-scale-balanced me-2"></i>{t('AdvancedSearch.Search.ByBalance.Title')}</label>
                        <div className="d-flex align-items-center mb-2">
                            <div className="input-group input-group">
                                <Field
                                    ref={BalanceStart => this.BalanceStart = BalanceStart}
                                    name="BalanceStart"
                                    id="Search_ByBalanceStart"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"number"}
                                    placeholder={t('AdvancedSearch.Search.ByBalance.Start')}
                                    title={t('AdvancedSearch.Search.ByBalance.Start')}
                                    className="form-control" />
                                <label className="input-group-text" htmlFor="Search_ByBalanceEnd">
                                    <i className="fa-solid fa-arrows-left-right text-dark"></i>
                                </label>
                                <Field
                                    ref={BalanceEnd => this.BalanceEnd = BalanceEnd}
                                    name="BalanceEnd"
                                    id="Search_ByBalanceEnd"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"number"}
                                    placeholder={t('AdvancedSearch.Search.ByBalance.End')}
                                    title={t('AdvancedSearch.Search.ByBalance.End')}
                                    className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_SearchByShow" ><i className="fa-regular text-dark fa-tally me-2"></i>{t('AdvancedSearch.Search.Show')}</label>
                        <Field
                            ref={Show => this.Show = Show}
                            name="Show"
                            id="Search_SearchByShow"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"number"}
                            placeholder={t('AdvancedSearch.Search.Show')}
                            title={t('AdvancedSearch.Search.Show')}
                            className="form-control" />
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-end h-100">
                            <div className="form-check form-switch form-check-custom form-check-solid form-check-success mb-5" title={t('AdvancedSearch.Search.ByIsActive')}>
                                <Field
                                    ref={IsActive => this.IsActive = IsActive}
                                    name="IsActive"
                                    id="Search_IsActive"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"checkbox"}
                                    checked={this.state.IsActive}
                                    onChange={e => this.setIsActive(e.target.checked)}
                                    title={t('AdvancedSearch.Search.ByIsActive')}
                                    className="form-check-input h-20px w-30px"
                                />
                                <label className="form-check-label" htmlFor="Search_IsActive">
                                    {t('AdvancedSearch.Search.ByIsActive')}
                                </label>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="modal-footer pb-0 px-0 justify-content-start pt-3">
                    <button type="button" onClick={e => this.onSubmit(e)} className="btn btn-sm btn-info mb-2" title={t('Search')} disabled={!isLoaded} data-kt-indicator={!isLoaded ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-magnifying-glass"></i>
                            {t('Search')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="reset" className="btn btn-sm btn-danger mx-1" title={t('Reset')} disabled={submitting} onClick={() => this.reset()}>
                        <i className="me-1 fa-solid fa-broom"></i>
                        {t('Reset')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formBankAccountsSearch')(state),
        bankAccounts: state.bankAccounts
    };
};

const formBankAccountsSearch = reduxForm({
    form: 'formBankAccountsSearch',
    enableReinitialize: true,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetch })(withTranslation('common')(formBankAccountsSearch(Form)));