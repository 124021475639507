const PastMedicalHistoryReducers = (state = [], action) => {
    switch (action.type) {
        case "PAST_MEDICAL_HISTORY_LOADING":
            return {
                pastMedicalHistory: null,
                isLoaded: false
            };
        case "PAST_MEDICAL_HISTORY_INITIAL":
            return {
                pastMedicalHistory: action.pastMedicalHistory,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PastMedicalHistoryReducers;