import React from "react";
import { withTranslation } from 'react-i18next';

class Card extends React.Component {

    render() {
        const { t, VitalSigns } = this.props;
        return (<>
            <div className="d-flex align-items-center justify-content-evenly">
                <div className="me-5">
                    {
                        VitalSigns.Pain === 5 ?
                            <div className="d-flex flex-column align-items-center">
                                <i title={t("VitalSigns.Pain.Status.sad")} className="fa-solid fa-face-sad-tear me-1 display-6 text-danger"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("VitalSigns.Pain.Status.sad")}
                                </span>
                            </div>
                            : VitalSigns.Pain === 4 ?
                                <div className="d-flex flex-column align-items-center">
                                    <i title={t("VitalSigns.Pain.Status.angry")} className="fa-solid fa-face-angry me-1 display-6 text-warning"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("VitalSigns.Pain.Status.angry")}
                                    </span>
                                </div>
                                : VitalSigns.Pain === 3 ?
                                    <div className="d-flex flex-column align-items-center">
                                        <i title={t("VitalSigns.Pain.Status.meh")} className="fa-solid fa-face-meh me-1 display-6 text-dark"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("VitalSigns.Pain.Status.meh")}
                                        </span>
                                    </div>
                                    : VitalSigns.Pain === 2 ?
                                        <div className="d-flex flex-column align-items-center">
                                            <i title={t("VitalSigns.Pain.Status.smile")} className="fa-solid fa-face-smile me-1 display-6 text-info"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("VitalSigns.Pain.Status.smile")}
                                            </span>
                                        </div>
                                        :
                                        <div className="d-flex flex-column align-items-center">
                                            <i title={t("VitalSigns.Pain.Status.happy")} className="fa-solid fa-face-smile-beam me-1 display-6 text-success"></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("VitalSigns.Pain.Status.happy")}
                                            </span>
                                        </div>
                    }
                </div>
                <div>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-stethoscope me-2"></i>
                                    {t("VitalSigns.Pressure.Title")}
                                </span>
                                <span className="ms-10">

                                    <span>
                                        <span title={t("VitalSigns.Pressure.Upper")}>
                                            {VitalSigns.PressureUpper}
                                        </span>
                                        <i className="fa-solid fa-slash-forward text-dark mx-1"></i>
                                        <span title={t("VitalSigns.Pressure.Lower")}>
                                            {VitalSigns.PressureLower}
                                        </span>
                                        {
                                            VitalSigns.PressureFromLeftHand ?
                                                <>
                                                    <i className="fa-solid fa-hand fa-flip-horizontal ms-1 text-primary" title={t("VitalSigns.Pressure.Source.Left")} ></i>
                                                </> :
                                                <>
                                                    <i className="fa-solid fa-hand ms-1 text-primary" title={t("VitalSigns.Pressure.Source.Right")}></i>
                                                </>
                                        }
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-thermometer me-2"></i>
                                    {t("VitalSigns.Temperature.Title")}
                                </span>
                                <span className="ms-10">
                                    {VitalSigns.Temperature}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-heart-pulse me-2"></i>
                                    {t("VitalSigns.Pulse.Title")}
                                </span>
                                <span className="ms-10">
                                    {VitalSigns.Pulse}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-droplet-percent me-2"></i>
                                    {t("VitalSigns.Oxygen.Title")}
                                </span>
                                <span className="ms-10">
                                    {VitalSigns.Oxygen}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>


            </div>


        </>);
    }
}


export default withTranslation('common')(Card);