import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from "./Form";

class Create extends React.Component {

    render() {
        const { t, isUpdate } = this.props;
        return <Modal
            size="xl"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    {
                        isUpdate ?
                            <>
                                <i className="h3 fa-solid fa-edit mx-1 text-success "></i>
                                {t('Patient.Update')}
                            </>
                            :
                            <>
                                <i className="h3 fa-solid fa-plus mx-1 text-primary "></i>
                                {t('Patient.New')}
                            </>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form {...this.props} />
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(Create);