import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class Menu extends React.Component {

    state = {
        closeAside: false
    };

    componentDidMount() {
        this.setState({
            closeAside: false
        });
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
    }

    setCloseAside = () => {
        const body = document.getElementsByTagName('body')[0];
        if (this.state.closeAside) {
            body.setAttribute('data-kt-aside-minimize', "off");
        } else {
            body.setAttribute('data-kt-aside-minimize', "on");
        }

        this.setState({
            closeAside: !this.state.closeAside
        });
    }

    render() {
        const { t, user } = this.props;
        return (
            <div id="kt_aside" className="aside aside-light aside-hoverable">
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                    <div className="mt-5 w-100 align-items-center d-flex">
                        {/* <img alt="Logo" src="/assets/media/logos/favicon.png" className="h-50px logo" /> */}
                        <div className={`ms-3 aside-menu `}>
                            <div className="h5 menu-title text-truncate">
                                {t('SiteName')}
                            </div>
                            <div className="text-primary menu-title text-truncate">
                                {user?.user?.HealthCenter?.Name}
                            </div>
                        </div>
                    </div>
                    <div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" onClick={() => this.setCloseAside()}>
                        <i className={`h3 fa-duotone fa-angles-${this.state.closeAside ? "left" : "right"}`}></i>
                    </div>
                </div>
                <div className="aside-menu flex-column-fluid h-75  scroll-y">
                    <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" >
                        <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 " id="#kt_aside_menu">

                            <div className="menu-item">
                                <Link to={"/"} className="menu-link">
                                    <span className="menu-icon">
                                        <i className="h3 fa-duotone fa-game-board-simple"></i>
                                    </span>
                                    <span className="menu-title">
                                        {t('ControlPanel')}
                                    </span>
                                </Link>
                            </div>


                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 5) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-user me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Registration')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Appointment"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-calendar text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Appointment.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Patients"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-user-injured text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Patient.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                    </> : ""
                            }
                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 2) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-user-doctor me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Doctor')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/ClinicScreen"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-user-doctor-message text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('ClinicScreen.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Patients/Medical/Records"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-rectangle-history-circle-user text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('MedicalRecords.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Diagnosis"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-book-medical text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Diagnosis.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                    </> : ""
                            }
                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 4) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-microscope me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Lab')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Lab/Specimen/Taken"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-syringe text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Lab.SpecimenTaken.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Lab/Exams/Result"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-flask-round-potion text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Lab.Results.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Lab/Exams"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-vial text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Lab.Exams.Current.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                    </> : ""
                            }
                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 3) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-circle-radiation me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Radiologist')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Radiology/Receiving/Patients"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-x-ray text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Radiology.ReceivingPatients.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Radiology/Exams"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-biohazard text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Radiology.Exams.Current.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                    </> : ""
                            }
                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 6) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-cash-register me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Accounting')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Accounting/Receive/Cash"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-box-dollar text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Accounting.ReceiveCash.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Accounting/Receipt/Voucher"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-receipt text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Accounting.ReceiptVoucher.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Accounting/Journal/Voucher"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-ticket text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Accounting.JournalVoucher.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Accounting/Bank/Accounts"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-landmark text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Accounting.BankAccounts.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Accounting/Accounts"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-file-user text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Accounting.Accounts.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item menu-accordion ">
                                            <span className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-circle-dollar text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Prices.Menu')}
                                                </span>
                                                <span className="menu-arrow">
                                                </span>
                                            </span>
                                            <div className="menu-sub menu-sub-accordion">
                                                <div className="menu-item">
                                                    <Link to={"/Accounting/Radiology/Exams/Prices"} className="menu-link">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">
                                                            {t('Radiology.Exams.Prices.Menu')}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="menu-item">
                                                    <Link to={"/Accounting/Lab/Exams/Prices"} className="menu-link">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">
                                                            {t('Lab.Exams.Prices.Menu')}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="menu-item">
                                                    <Link to={"/Accounting/Clinic/Services/Prices"} className="menu-link">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">
                                                            {t('Accounting.ClinicServices.Menu')}
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                            }
                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 9 || p.Id === 1) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-user-tie me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Manager')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Manager/Users"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-users text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Users.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/Manager/Clinics"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-house-medical-flag text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('Clinic.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                    </> : ""
                            }
                            {
                                user && user.user && user.user.UserPermission.some(p => p.Id === 1) ?
                                    <>
                                        <div className="menu-item">
                                            <div className="menu-content pb-2">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-regular fa-user-crown me-2"></i>
                                                </span>
                                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                                    {t('Permissions.Admin')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <Link to={"/HealthCenters/"} className="menu-link">
                                                <span className="menu-icon">
                                                    <i className="h3 fa-duotone fa-hospital text-primary"></i>
                                                </span>
                                                <span className="menu-title">
                                                    {t('HealthCenter.Menu')}
                                                </span>
                                            </Link>
                                        </div>
                                    </> : ""
                            }
                        </div>
                    </div>
                </div>
                <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
                    <Link to="/Logout" className="btn btn-custom btn-danger w-100">
                        <i className="me-1 fa-solid fa-arrow-right-from-bracket"></i>
                        <span className="btn-label">
                            {t('Logout')}
                        </span>
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Menu));