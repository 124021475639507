import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Card from "../../Patients/PatientsCard";

class ChangeVisitType extends React.Component {

    render() {
        const { t, Patient, currentClinic, VisitId } = this.props;
        const MainPrice = currentClinic.Services.filter(p => p.Type.Name === "clinic")[0];
        const ReviewPrice = currentClinic.Services.filter(p => p.Type.Name === "review")[0];
        return <Modal
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-coin  me-2 text-primary "></i>
                    {t("Table.Options.Edit")}&nbsp;{t("Appointment.Type.Title")}
                    <span className="ms-3">
                        ({currentClinic.Name})
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card patient={Patient} />
            </Modal.Body>
            <Modal.Footer>
                <div className="row w-100 ">
                    <div className="col-md-6">
                        <button className="btn btn-block btn-primary w-100" onClick={() => this.props.ChangeVisitType(currentClinic.Id, Patient.Id, VisitId, true)}>
                            <i className=" fa-solid fa-user-doctor-message me-1"></i>
                            {t("Appointment.Type.New")}
                            {
                                MainPrice.Price > 0 ?
                                    <span className="ms-1">
                                        (
                                        {MainPrice.Price + " " + MainPrice.Currency.Name}
                                        )
                                    </span> : ""
                            }
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-block btn-warning w-100" onClick={() => this.props.ChangeVisitType(currentClinic.Id, Patient.Id, VisitId, false)}>
                            <i className=" fa-solid fa-user-doctor-message me-1"></i>
                            {t("Appointment.Type.Review")}
                            {
                                ReviewPrice.Price > 0 ?
                                    <span className="ms-1">
                                        (
                                        {ReviewPrice.Price + " " + ReviewPrice.Currency.Name}
                                        )
                                    </span> : ""
                            }
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}


export default withTranslation('common')(ChangeVisitType);