import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import PatientName from "./PatientName";

class PatientsCard extends React.Component {

    getAge = Birthday => {
        let result = "";
        if (Birthday) {
            const { t } = this.props;

            const years = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'years', false);
            const months = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'months', false);
            const days = moment().diff(moment(Birthday, "DD/MM/YYYY"), 'days', false);

            if (years > 1) {
                result += years + " " + t("Age.Year") + " ";
                if (months % 12) {
                    result += months % 12 + " " + t("Age.Month");
                }
            } else {
                if (months > 0) {
                    result += months + " " + t("Age.Month") + " ";
                    if (days % 30) {
                        result += days % 30 + " " + t("Age.Day");
                    }
                } else {
                    result += days + " " + t("Age.Day");
                }
            }
        }
        return result;
    }

    render() {
        const { t, patient } = this.props;

        return (<>
            <div className=" mb-5" title={t("Patient.Number")}>
                <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{patient.Id}</span>
            </div>
            <div className="row mb-5">
                <span className="fw-bold text-muted mb-2">
                    <i className="fa-regular text-dark fa-user-injured me-2"></i>
                    {t("Patient.Name")}
                </span>
                <div className="ms-5">
                    <PatientName patient={patient} displayEnglishName />
                </div>
            </div>
            {
                patient.DocumentType.Id !== 1 || patient.BloodGroup ?
                    <div className="row mb-5">
                        {
                            patient.DocumentType.Id !== 1 ?
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                            {patient.DocumentType.Id === 3 ? t("Patient.Forms.DocumentType.Options.Passport") : t('Patient.Forms.ID.Title')}
                                        </span>
                                        <span className="ms-5">
                                            <span className="text-primary">{patient.DocumentNumber}</span>
                                        </span>
                                    </div>
                                </div>
                                : ""
                        }
                        {
                            patient.BloodGroup ?
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-solid text-danger fa-droplet me-1"></i>
                                            {t("Patient.Forms.BloodGroup.Title")}
                                        </span>
                                        <span className="ms-5">
                                            <span className="text-danger">{patient.BloodGroup.Name}</span>
                                        </span>
                                    </div>
                                </div> : ""
                        }
                    </div>
                    : ""
            }
            {
                patient.Birthday ? <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-cake-candles me-2"></i>
                                {t("Patient.Forms.Birthday.Title")}
                            </span>
                            <span className="ms-5">
                                {patient.Birthday}
                                <span title={t('Patient.Age')} className="badge badge-light-primary mx-2 text-nowrap">{this.getAge(patient.Birthday)}</span>
                            </span>
                        </div>
                    </div>
                </div> : ""
            }
            {
                patient.WhatsAppNumber || patient.Phone ? <div className="row mb-5">
                    {
                        patient.Phone ? <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-phone me-2"></i>
                                    {t("Patient.Forms.Phone.Title")}
                                </span>
                                <span className="ms-5">
                                    {patient.Phone}
                                </span>
                            </div>
                        </div> : ""
                    }
                    {
                        patient.WhatsAppNumber ? <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-brands fa-whatsapp me-2 text-success"></i>
                                    {t("Patient.Forms.WhatsAppNumber.Title")}
                                </span>
                                <span className="ms-5">
                                    {patient.WhatsAppNumber}
                                </span>
                            </div>
                        </div> : ""
                    }
                </div> : ""
            }
            {
                patient.Address || patient.City ? <div className="row mb-5">
                    {
                        patient.City ? <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-map me-2"></i>
                                    {t("Patient.Forms.City.Title")}
                                </span>
                                <span className="ms-5">
                                    {patient.City.Name}
                                </span>
                            </div>
                        </div> : ""
                    }
                    {
                        patient.Address ? <div className="col-md-6">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-location-dot me-2"></i>
                                    {t("Patient.Forms.Address.Title")}
                                </span>
                                <span className="ms-5">
                                    {patient.Address}
                                </span>
                            </div>
                        </div> : ""
                    }
                </div> : ""
            }
            {
                patient.Email ? <div className="row mb-5">
                    <span className="fw-bold text-muted mb-2">
                        <i className="fa-regular text-dark fa-at me-2"></i>
                        {t("Email")}
                    </span>
                    <span className="ms-5">
                        {patient.Email}
                    </span>
                </div> : ""
            }
        </>);
    }
}


export default withTranslation('common')(PatientsCard);