import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, getFormValues, reduxForm, change } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../../api/serverAPI";
import SelectCurrencies from "../../../shared/SelectList/SelectCurrencies";
import SelectBanks from "../../../shared/SelectList/SelectBanks";
import SelectBankBranches from "../../../shared/SelectList/SelectBankBranches";

class Form extends React.Component {

    state = {
        Id: 0,
        isInitialize: false,
        accountNumberIsExist: false,
        isSubmit: false,
        IsActive: true,
        defaultBank: null,
        defaultCurrency: null,
        defaultBankBranch: null,
        defaultCommissionForReturnChecksCurrency: null,
        defaultCommissionForCollectionChecksCurrency: null
    };

    constructor(props) {
        super(props);
        this.props.initialize({
            Name: "",
            Number: "",
            BankId: 0,
            BankBranchId: 0,
            CurrencyId: 0,
            CommissionForReturnChecks: 0,
            CommissionForCollectionChecks: 0,
            PercentageCommissionOnCollection: 0,
            DepositChecksDaysAgo: 0,
            IsActive: true,
            CommissionForReturnChecksCurrencyId: 0,
            CommissionForCollectionChecksCurrencyId: 0,
        });
    }

    componentDidMount() {
        if (this.props.isEdit && this.props.Account) {
            this.setState({
                defaultCurrency: { ...this.props.Account.Currency, value: this.props.Account.Currency.Id, label: this.props.Account.Currency.Name },
                defaultCommissionForReturnChecksCurrency: { ...this.props.Account.CommissionForReturnChecks.Currency, value: this.props.Account.CommissionForReturnChecks.Currency.Id, label: this.props.Account.CommissionForReturnChecks.Currency.Name },
                defaultCommissionForCollectionChecksCurrency: { ...this.props.Account.CommissionForCollectionChecks.Currency, value: this.props.Account.CommissionForCollectionChecks.Currency.Id, label: this.props.Account.CommissionForCollectionChecks.Currency.Name },
                defaultBank: { ...this.props.Account.Bank, value: this.props.Account.Bank.Id, label: this.props.Account.Bank.Name },
                defaultBankBranch: [{ ...this.props.Account.BankBranch, Bank: { ...this.props.Account.Bank }, value: this.props.Account.BankBranch.Id, label: this.props.Account.BankBranch.Name }]
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.Account && props.isEdit) {
            state.isInitialize = true;
            props.initialize({
                Id: props.Account.Id,
                Name: props.Account.Name,
                Number: props.Account.Number,
                BankId: props.Account.Bank.Id,
                BankBranchId: props.Account.BankBranch.Id,
                CurrencyId: props.Account.Currency.Id,
                CommissionForReturnChecks: props.Account.CommissionForReturnChecks.value,
                CommissionForCollectionChecks: props.Account.CommissionForCollectionChecks.value,
                PercentageCommissionOnCollection: props.Account.PercentageCommissionOnCollection,
                DepositChecksDaysAgo: props.Account.DepositChecksDaysAgo,
                IsActive: props.Account.IsActive,
                CommissionForReturnChecksCurrencyId: props.Account.CommissionForReturnChecks.Currency.Id,
                CommissionForCollectionChecksCurrencyId: props.Account.CommissionForCollectionChecks.Currency.Id,
            });
            state.Id = props.Account.Id;
            state.IsActive = props.Account.IsActive;

        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        checked,
        step,
        min,
        max,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} step={step} min={min} max={max} checked={checked} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setIsActive = IsActive => {
        this.props.dispatch(change('formBankAccount', 'IsActive', IsActive));
        this.setState({
            IsActive
        });
    }

    setBank = Bank => {
        this.props.dispatch(change('formBankAccount', 'BankId', Bank ? Bank.Id : 0));
    }

    setBankBranch = BankBranch => {
        this.props.dispatch(change('formBankAccount', 'BankBranchId', BankBranch ? BankBranch.Id : 0));
    }

    setCurrency = Currency => {
        this.props.dispatch(change('formBankAccount', 'CurrencyId', Currency ? Currency.Id : 0));
    }

    setCommissionForReturnChecksCurrencyId = Currency => {
        this.props.dispatch(change('formBankAccount', 'CommissionForReturnChecksCurrencyId', Currency ? Currency.Id : 0));
    }

    setCommissionForCollectionChecksCurrencyId = Currency => {
        this.props.dispatch(change('formBankAccount', 'CommissionForCollectionChecksCurrencyId', Currency ? Currency.Id : 0));
    }

    onSubmit = () => {

        const { t } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let method = serverAPI.post;
        if (this.props.isEdit) {
            method = serverAPI.put;
        }

        method(`/BankAccounts/`, {
            ...this.props.formValues,
        }).then(response => {

            if (response.data.code === "fill_all_inputs") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.AllInformationIsRequired.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.AllInformationIsRequired.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "account_number_is_exist") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.account_number_is_exist.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.account_number_is_exist.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "cannot_change_account_currency") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.cannot_change_account_currency.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.cannot_change_account_currency.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else if (response.data.code === "cannot_change_account_bank") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.cannot_change_account_bank.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.cannot_change_account_bank.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.update();
                this.props.handleClose();
            }
            this.setState({
                accountNumberIsExist: false,
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    checkAccountNumber = AccountNumber => {
        const { t } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        serverAPI.post(`/CheckBankAccountNumber/`, {
            AccountNumber
        }).then(response => {
            if (response.data.code === "account_number_is_exist") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Error.account_number_is_exist.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Error.account_number_is_exist.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
                this.setState({
                    accountNumberIsExist: true,
                    isSubmit: false
                });
            } else {
                this.setState({
                    accountNumberIsExist: false,
                    isSubmit: false
                });
                toast.dismiss();
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });

            this.setState({
                isSubmit: false
            });
        });
    }

    render() {
        const { t, handleSubmit, pristine, submitting } = this.props;
        return <>
            <form method="POST" name="formBankAccount" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0 || this.state.accountNumberIsExist;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            }  >

                <div className="row mb-5">
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Number" ><i className="fa-regular text-dark fa-hashtag me-2"></i>{t('Accounting.BankAccounts.Forms.Number.Title')}</label>
                        <Field
                            onBlur={e => this.checkAccountNumber(e.target.value)}
                            touchOnBlur={e => this.checkAccountNumber(e.target.value)}
                            ref={Number => this.Number = Number}
                            name="Number"
                            id="Number"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"text"}
                            required
                            placeholder={t('Accounting.BankAccounts.Forms.Number.Placeholder')}
                            title={t('Accounting.BankAccounts.Forms.Number.Title')}
                            className="form-control" />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Name" ><i className="fa-regular text-dark fa-text me-2"></i>{t('Accounting.BankAccounts.Forms.Name.Title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Name"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"text"}
                            required
                            placeholder={t('Accounting.BankAccounts.Forms.Name.Placeholder')}
                            title={t('Accounting.BankAccounts.Forms.Name.Title')}
                            className="form-control " />

                    </div>
                    <div className="col-md-3">
                        <SelectBanks required onChange={this.setBank} defaultValue={this.state.defaultBank} />
                    </div>
                    <div className="col-md-3">
                        <SelectBankBranches onChange={this.setBankBranch} required BankId={this.props.formValues && this.props.formValues.BankId ? this.props.formValues.BankId :
                            this.props.Account && this.props.isEdit ? this.props.Account.Bank.Id : ""
                        } defaultValue={this.state.defaultBankBranch} />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-3">
                        <SelectCurrencies required onChange={this.setCurrency} defaultValue={this.state.defaultCurrency} />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="DepositChecksDaysAgo" ><i className="fa-regular text-dark fa-tally me-2"></i>{t('Accounting.BankAccounts.Forms.DepositChecksDaysAgo.Title')}</label>
                        <Field
                            ref={DepositChecksDaysAgo => this.DepositChecksDaysAgo = DepositChecksDaysAgo}
                            name="DepositChecksDaysAgo"
                            id="DepositChecksDaysAgo"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"number"}
                            required
                            step={1}
                            min={0}
                            placeholder={t('Accounting.BankAccounts.Forms.DepositChecksDaysAgo.Placeholder')}
                            title={t('Accounting.BankAccounts.Forms.DepositChecksDaysAgo.Title')}
                            className="form-control" />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="PercentageCommissionOnCollection" ><i className="fa-regular text-dark fa-percentage me-2"></i>{t('Accounting.BankAccounts.Forms.PercentageCommissionOnCollection.Title')}</label>
                        <Field
                            ref={PercentageCommissionOnCollection => this.PercentageCommissionOnCollection = PercentageCommissionOnCollection}
                            name="PercentageCommissionOnCollection"
                            id="PercentageCommissionOnCollection"
                            component={this.renderField}
                            autoComplete={"off"}
                            type={"number"}
                            required
                            step={1}
                            min={0}
                            max={100}
                            placeholder={t('Accounting.BankAccounts.Forms.PercentageCommissionOnCollection.Placeholder')}
                            title={t('Accounting.BankAccounts.Forms.PercentageCommissionOnCollection.Title')}
                            className="form-control" />
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-end h-100">
                            <div className="form-check form-switch form-check-custom form-check-solid form-check-success mb-5" title={t('Accounting.BankAccounts.Forms.IsActive')}>
                                <Field
                                    ref={IsActive => this.IsActive = IsActive}
                                    name="IsActive"
                                    id="IsActive"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"checkbox"}
                                    checked={this.state.IsActive}
                                    onChange={e => this.setIsActive(e.target.checked)}
                                    title={t('Accounting.BankAccounts.Forms.IsActive')}
                                    className="form-check-input h-20px w-30px"
                                />
                                <label className="form-check-label" htmlFor="Search_IsActive">
                                    {t('Accounting.BankAccounts.Forms.IsActive')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-6">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="CommissionForCollectionChecks" ><i className="fa-regular text-dark fa-coin me-2"></i>{t('Accounting.BankAccounts.Forms.CommissionForCollectionChecks.Title')}</label>
                        <div className="d-flex align-items-center mb-2">
                            <div className="input-group input-group-sm">
                                <Field
                                    ref={CommissionForCollectionChecks => this.CommissionForCollectionChecks = CommissionForCollectionChecks}
                                    name="CommissionForCollectionChecks"
                                    id="CommissionForCollectionChecks"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"number"}
                                    required
                                    step={1}
                                    min={0}
                                    placeholder={t('Accounting.BankAccounts.Forms.CommissionForCollectionChecks.Placeholder')}
                                    title={t('Accounting.BankAccounts.Forms.CommissionForCollectionChecks.Title')}
                                    className="form-control" />

                                <label className="input-group-text"  >
                                    <i className="fa-solid fa-wallet text-dark"></i>
                                </label>
                                <SelectCurrencies hideTitle={true} required onChange={this.setCommissionForCollectionChecksCurrencyId} defaultValue={this.state.defaultCommissionForCollectionChecksCurrency} className="form-control p-0" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="CommissionForReturnChecks" ><i className="fa-regular text-dark fa-coin me-2"></i>{t('Accounting.BankAccounts.Forms.CommissionForReturnChecks.Title')}</label>
                        <div className="d-flex align-items-center mb-2">
                            <div className="input-group input-group-sm">
                                <Field
                                    ref={CommissionForReturnChecks => this.CommissionForReturnChecks = CommissionForReturnChecks}
                                    name="CommissionForReturnChecks"
                                    id="CommissionForReturnChecks"
                                    component={this.renderField}
                                    autoComplete={"off"}
                                    type={"number"}
                                    required
                                    step={1}
                                    min={0}
                                    placeholder={t('Accounting.BankAccounts.Forms.CommissionForReturnChecks.Placeholder')}
                                    title={t('Accounting.BankAccounts.Forms.CommissionForReturnChecks.Title')}
                                    className="form-control" />

                                <label className="input-group-text"  >
                                    <i className="fa-solid fa-wallet text-dark"></i>
                                </label>
                                <SelectCurrencies hideTitle={true} required onChange={this.setCommissionForReturnChecksCurrencyId} defaultValue={this.state.defaultCommissionForReturnChecksCurrency} className="form-control p-0" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>


            </form>
        </>;
    }
}

const validate = values => {
    const errors = {};

    if (!values.Name) {
        errors.Name = 'Accounting.BankAccounts.Forms.Name.Error';
    }
    if (!values.Number) {
        errors.Number = 'Accounting.BankAccounts.Forms.Number.Error';
    }
    if (!values.CurrencyId) {
        errors.CurrencyId = 'Currencies.Forms.Select.Error';
    }
    if (!values.BankId) {
        errors.BankId = 'Banks.Forms.Select.Error';
    }
    if (!values.BankBranchId) {
        errors.BankBranchId = 'BankBranches.Forms.Select.Error';
    }

    if (values.DepositChecksDaysAgo < 0) {
        errors.DepositChecksDaysAgo = 'Accounting.BankAccounts.Forms.DepositChecksDaysAgo.Error';
    }
    if (values.CommissionForReturnChecks < 0) {
        errors.CommissionForReturnChecks = 'Accounting.BankAccounts.Forms.CommissionForReturnChecks.Error';
    }
    if (values.CommissionForCollectionChecks < 0) {
        errors.CommissionForCollectionChecks = 'Accounting.BankAccounts.Forms.CommissionForCollectionChecks.Error';
    }
    if (values.PercentageCommissionOnCollection < 0 || values.PercentageCommissionOnCollection >= 100) {
        errors.PercentageCommissionOnCollection = 'Accounting.BankAccounts.Forms.PercentageCommissionOnCollection.Error';
    }

    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formBankAccount')(state),
        validate,
        user: state.user
    };
};

const formBankAccount = reduxForm({
    form: 'formBankAccount',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formBankAccount(Form)));