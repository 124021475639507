import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../api/serverAPI";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.pastSurgicalHistory && props.pastSurgicalHistory.pastSurgicalHistory) {
            state.isInitialize = true;
            props.initialize({
                Note: props.pastSurgicalHistory.pastSurgicalHistory.Note
            });
        }
        return state;
    }

    renderTextArea = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        rows,
        meta: { touched, error }
    }) => (
        <>
            <textarea {...input} id={id} title={title} rows={rows} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t, patient } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/PastSurgicalHistory/${patient.Id}`, {
            ...this.props.formValues
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    render() {
        const { t, pastSurgicalHistory, pristine, submitting } = this.props;

        return <>

            <form method="POST" name="formPastSurgicalHistory" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Note"><i className="fa-regular text-dark fa-note me-2"></i>{t('PastSurgicalHistory.Title')}</label>
                        <Field
                            ref={Note => this.Note = Note}
                            name="Note"
                            id="Note"
                            rows={5}
                            component={this.renderTextArea}
                            placeholder={t('PastSurgicalHistory.Placeholder')}
                            title={t('PastSurgicalHistory.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>
                {
                    pastSurgicalHistory && pastSurgicalHistory.pastSurgicalHistory ? <>
                        <div className="separator separator-dashed border-dark mb-5"></div>
                        <div className="row">
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                        {t("LastUpdatedUser")}
                                    </span>
                                    <span className="ms-10">
                                        {pastSurgicalHistory.pastSurgicalHistory.User.Name}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-hospital me-1"></i>
                                        {t("HealthCenter.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {pastSurgicalHistory.pastSurgicalHistory.User.HealthCenter.Name}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("CreationDate")}
                                    </span>
                                    <span className="ms-10">
                                        {pastSurgicalHistory.pastSurgicalHistory.CreationDate}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3 col-md-6">
                                <div className="row">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        {t("LastUpdatedDate")}
                                    </span>
                                    <span className="ms-10">
                                        {pastSurgicalHistory.pastSurgicalHistory.UpdateDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </> : ""
                }
                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formPastSurgicalHistory')(state),
        pastSurgicalHistory: state.pastSurgicalHistory,
        user: state.user
    };
};

const formPastSurgicalHistory = reduxForm({
    form: 'formPastSurgicalHistory',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formPastSurgicalHistory(Form)));