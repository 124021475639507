import React from "react";
import { withTranslation } from 'react-i18next';

class Card extends React.Component {

    render() {
        const { t, Clinic } = this.props;

        return (<>
            <div className="row mb-5">
                <span className="fw-bold text-muted mb-2">
                    {t("Clinic.Forms.Name.Title")}
                </span>
                <span className="ms-10">
                    {Clinic.Name}
                </span>
            </div>
            <div className="row mb-5">
                <span className="fw-bold text-muted mb-2">
                    {t("Clinic.Forms.DoctorName.Title")}
                </span>
                <span className="ms-10">
                    {Clinic.DoctorName}
                </span>
            </div>
            <div className="row mb-5">
                <span className="fw-bold text-muted mb-2">
                    {t("Clinic.Forms.MedicalSpecialist.Title")}
                </span>
                <span className="ms-10">
                    {Clinic.MedicalSpecialist.Name}
                </span>
            </div>
            {
                Clinic.Services?.length > 0 ?
                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered align-middle my-0">
                                <thead className="border-bottom border-gray-200 fs-6 fw-bold bg-lighten">
                                    <tr>
                                        <th colSpan={2} >
                                            {t("Clinic.Services")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="fs-6 fw-semibold text-gray-600">
                                    {
                                        Clinic.Services.sort((a, b) => a.Type.Id > b.Type.Id ? 1 : -1).map(service => {
                                            return <tr key={`service-${service.Id}`}>
                                                <td>
                                                    {service.Name}
                                                </td>
                                                <td>
                                                    {service.Price}
                                                    <span className="mx-1">
                                                        {service.Currency.Name}
                                                    </span>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <></>
            }
        </>);
    }
}

export default withTranslation('common')(Card);