import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

class SelectExam extends React.Component {

    state = {
        SelectedExam: [],
        Total: 0,
        isInitialize: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.Order && props.Order.items) {
            state.isInitialize = true;
            props.Order.items.forEach((exam) => {
                if (exam.OrderItemStatus?.Id > 1) {
                    if (exam.RadiologyExam.Price.IsActive) {
                        state.SelectedExam.push(exam);
                    }
                }
            });
            let Total = 0;
            const Rate = props.user.user?.HealthCenter.Currency.Rate;
            state.SelectedExam.forEach(Exam => {
                if (Exam.RadiologyExam.Price?.IsActive) {
                    Total += Exam.RadiologyExam.Price?.Price * (Exam.RadiologyExam.Price?.Currency.Rate / Rate);
                }
            });
            state.Total = Total;
        }
        return state;
    }

    setTotal = () => {
        const { user } = this.props;
        let Total = 0;
        const Rate = user.user?.HealthCenter.Currency.Rate;

        this.state.SelectedExam.forEach(Exam => {
            if (Exam.RadiologyExam.Price?.IsActive) {
                Total += Exam.RadiologyExam.Price.Price * (Exam.RadiologyExam.Price.Currency.Rate / Rate);
            }
        });
        this.setState({
            Total
        }, () => {
            this.props.setSelectedExam(this.state.SelectedExam);
        });
    }

    setSelectedExam = Exam => {
        const objIndex = this.state.SelectedExam.findIndex(p => p.Id === Exam.Id);
        let SelectedExam = this.state.SelectedExam;
        if (objIndex >= 0) {
            SelectedExam = SelectedExam.filter(p => p.Id !== Exam.Id);
        } else {
            SelectedExam.push(Exam);
        }
        this.setState({
            SelectedExam
        }, () => { this.setTotal() });
    }

    setSelectedAll = isChecked => {
        let SelectedExam = [];
        if (isChecked) {
            this.props.Order.items.forEach(Exam => {
                if (Exam.RadiologyExam.Price) {
                    SelectedExam.push(Exam);
                }
            });
        } else {
            this.props.Order.items.forEach((exam) => {
                if (exam.OrderItemStatus?.Id > 1) {
                    if (exam.RadiologyExam.Price.IsActive) {
                        SelectedExam.push(exam);
                    }
                }
            });
        }
        this.setState({
            SelectedExam
        }, () => { this.setTotal() });
    }

    renderTableRow = () => {
        const { t, Order } = this.props;

        return Order.items.map((exam) => {
            const hasExam = this.state.SelectedExam.some(p => p.Id === exam.Id);
            let disabled = false;
            if (exam.OrderItemStatus?.Id > 2) {
                disabled = true;
            }
            if (exam.RadiologyExam.Price?.IsActive === false) {
                disabled = true;
            }
            return <tr key={`selected-radiology-exam-${exam.Id}`}>
                <td>
                    <div className={`form-check form-check-custom form-check-${exam.OrderItemStatus?.Color}`} title={t(`OrdersStatus.${exam.OrderItemStatus?.Name}`)}>
                        <input
                            name="checkbox"
                            type="checkbox"
                            value={exam.Id}
                            onChange={() => this.setSelectedExam(exam)}
                            className="form-check-input"
                            disabled={exam.RadiologyExam.Price && !disabled ? false : true}
                            checked={hasExam}
                        />
                    </div>
                </td>
                <td>
                    {exam.RadiologyExam?.Name}
                </td>
                <td>
                    {exam.RadiologyExam.Price && exam.RadiologyExam.Price?.IsActive ? exam.RadiologyExam.Price.Price + " " + exam.RadiologyExam.Price.Currency.Name : t("Radiology.Exams.Prices.NoResult")}
                </td>
                <td>
                    {exam.RadiologyExam.Type?.Name}
                </td>
            </tr>
        });
    }

    render() {
        const { t, user } = this.props;
        return (<>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                        <i className="fa-regular fa-biohazard me-2 text-primary h3 mb-0"></i>
                        {t('ClinicScreen.Orders.Radiology.SelectedExam')}
                        :
                        ({this.state.SelectedExam.length})
                    </div>
                </div>
                <div>
                    <div className="form-check form-check-custom">
                        <input
                            name="checkbox"
                            type="checkbox"
                            id="SelectAll"
                            onChange={(e) => this.setSelectedAll(e.target.checked)}
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="SelectAll">
                            {t('SelectAll')}
                        </label>
                    </div>
                </div>
            </div>
            <div className={`  border-1 border-dark border-dashed table-responsive`} >
                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head ">
                    <thead>
                        <tr>
                            <th title={t("Select")}>
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-square-check"></i>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Radiology.Table.Name")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-biohazard me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Radiology.Table.Name")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Radiology.Exams.Price.Title")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-circle-dollar me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Radiology.Exams.Price.Title")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("ClinicScreen.Orders.Radiology.Table.Type")}>
                                <span className="d-flex align-items-center">
                                    <i className="fa-regular text-dark fa-radiation me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Radiology.Table.Type")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRow()}
                    </tbody>
                </table>
                <div className="card-footer">
                    <div className="w-100 d-flex justify-content-evenly" title={t("Sum_Note")}>
                        <span className="d-md-block d-none text-nowrap">
                            {t("Sum")}
                            :
                        </span>
                        <span className="fs-4 ">
                            {this.state.Total}
                            <span className="mx-1">
                                {user.user?.HealthCenter.Currency.Name}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(SelectExam));