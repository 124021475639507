import serverAPI from "../../../../api/serverAPI";

export const fetchLabExams = () => async dispatch => {
    dispatch({ type: 'LAB_EXAMS_LOADING' });
    const response = await serverAPI.get(`/LabExams/`);
    dispatch({ type: 'LAB_EXAMS_INITIAL', labExams: response.data.data });
}

export const fetchLabExamTypes = () => async dispatch => {
    dispatch({ type: 'LAB_EXAM_TYPES_LOADING' });
    const response = await serverAPI.get(`/LabExamTypes/`);
    dispatch({ type: 'LAB_EXAM_TYPES_INITIAL', labExamTypes: response.data.data });
}

export const fetchLabCenters = () => async dispatch => {
    dispatch({ type: 'LAB_CENTER_LOADING' });
    const response = await serverAPI.get(`/LabCenters/`);
    dispatch({ type: 'LAB_CENTER_INITIAL', labCenters: response.data.data });
}

export const fetchLabOrders = (Id, PatientId) => async dispatch => {
    dispatch({ type: 'LAB_ORDERS_LOADING' });
    const response = await serverAPI.get(`/VisitLabOrders/${Id}`);
    dispatch({ type: 'LAB_ORDERS_INITIAL', labOrders: response.data.data, PatientId });
}