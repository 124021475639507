import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import serverAPI from "../../../../api/serverAPI";
import SelectCities from "../../../shared/SelectList/SelectCities";
import SelectCurrencies from "../../../shared/SelectList/SelectCurrencies";

const SelectActivateUntilDate = React.createRef();

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, Id: 0, ActivateUntilDate: "", defaultCity: [] };

    componentDidMount() {
        if (!this.props.isEdit) {
            this.clearDate();
        } else if (this.props.isEdit && this.props.HealthCenter && this.props.HealthCenter.City) {
            this.setState({
                defaultCity: { ...this.props.HealthCenter.City, value: this.props.HealthCenter.City.Id, label: this.props.HealthCenter.City.Name }
            });
        }
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.props.dispatch(change('formCreateHealthCenter', 'ActivateUntilDate', picker.startDate.format('DD/MM/YYYY')));
        this.setState({
            ActivateUntilDate: picker.startDate.format('DD/MM/YYYY')
        });
    }

    clearDate = () => {
        window.setNull("#ActivateUntilDate");
    }

    static getDerivedStateFromProps(props, state) {

        if (!state.isInitialize && props.isEdit === true && props.HealthCenter) {
            state.isInitialize = true;
            props.initialize({
                Name: props.HealthCenter.Name,
                Email: props.HealthCenter.Email,
                Address: props.HealthCenter.Address,
                Phone: props.HealthCenter.Phone,
                ClinicNumber: props.HealthCenter.ClinicNumber,
                HasLab: props.HealthCenter.HasLab,
                HasPharmacy: props.HealthCenter.HasPharmacy,
                HasRad: props.HealthCenter.HasRad,
                LabName: props.HealthCenter.LabCenter?.Name,
                LabIsPublic: props.HealthCenter.LabCenter?.IsPublic,
                RadName: props.HealthCenter.RadCenter?.Name,
                RadIsPublic: props.HealthCenter.RadCenter?.IsPublic,
                CityId: props.HealthCenter.City?.Id,
                ActivateUntilDate: props.HealthCenter.ActivateUntilDate
            });
            state.Id = props.HealthCenter.Id;
            state.ActivateUntilDate = props.HealthCenter.ActivateUntilDate;
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.setState({
            isSubmit: true
        });

        let url = '/HealthCenters/';
        let method = serverAPI.post;
        if (this.props.isEdit) {
            method = serverAPI.put;
            url = '/HealthCenters/' + this.state.Id;
        }

        method(url, {
            ...this.props.formValues
        }).then(response => {
            if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.update();
                this.props.handleClose();
            } else {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Error.${response.data.code}.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Error.${response.data.code}.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    doNothing = () => { }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    setCity = City => {
        this.props.dispatch(change('formCreateHealthCenter', 'CityId', City?.Id));
    }
    setCurrency = Currency => {
        this.props.dispatch(change('formCreateHealthCenter', 'CurrencyId', Currency ? Currency.Id : 0));
    }
    setHasLab = HasLab => {
        this.props.dispatch(change('formCreateHealthCenter', 'HasLab', HasLab));
    }
    setHasRad = HasRad => {
        this.props.dispatch(change('formCreateHealthCenter', 'HasRad', HasRad));
    }

    render() {
        const { t, pristine, handleSubmit, submitting } = this.props;


        return <>

            <form method="POST" name="formCreateHealthCenter" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            } >
                <div className="row mb-5">
                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Name"><i className="fa-regular text-dark fa-text me-2"></i>{t('HealthCenter.Form.Name.Title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Name"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('HealthCenter.Form.Name.Placeholder')}
                            title={t('HealthCenter.Form.Name.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Email"><i className="fa-regular text-dark fa-at me-2"></i>{t('HealthCenter.Form.Email.Title')}</label>
                        <Field
                            ref={Email => this.Email = Email}
                            name="Email"
                            id="Email"
                            component={this.renderField}
                            type={"email"}
                            placeholder={t('HealthCenter.Form.Email.Placeholder')}
                            title={t('HealthCenter.Form.Email.Title')}
                            className="form-control"
                        />
                    </div>


                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Phone"><i className="fa-regular text-dark fa-phone me-2"></i>{t('HealthCenter.Form.Phone.Title')}</label>
                        <Field
                            ref={Phone => this.Phone = Phone}
                            name="Phone"
                            id="Phone"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('HealthCenter.Form.Phone.Placeholder')}
                            title={t('HealthCenter.Form.Phone.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className="col-md-4 mb-3">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="ActivateUntilDate"><i className="fa-regular text-dark fa-alarm-clock me-2"></i>{t('HealthCenter.Form.ActivateUntilDate.Title')}</label>
                        <div className="input-group position-relative">
                            <DateRangePicker
                                ref={SelectActivateUntilDate}
                                initialSettings={{
                                    singleDatePicker: true,
                                    opens: 'center',
                                    autoUpdateInput: true,
                                    buttonClasses: 'btn',
                                    cancelClass: "btn-danger",
                                    applyButtonClasses: "btn-primary",
                                    showDropdowns: true,
                                    format: 'DD/MM/YYYY',
                                    timePicker: false,
                                    minDate: `${moment().format('DD/MM/YYYY')}`,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        customRangeLabel: t('DateRangePicker.Range.customRange'),
                                        monthNames: [
                                            `${t("DateRangePicker.Months.January")}`,
                                            `${t("DateRangePicker.Months.February")}`,
                                            `${t("DateRangePicker.Months.March")}`,
                                            `${t("DateRangePicker.Months.April")}`,
                                            `${t("DateRangePicker.Months.May")}`,
                                            `${t("DateRangePicker.Months.June")}`,
                                            `${t("DateRangePicker.Months.July")}`,
                                            `${t("DateRangePicker.Months.August")}`,
                                            `${t("DateRangePicker.Months.September")}`,
                                            `${t("DateRangePicker.Months.October")}`,
                                            `${t("DateRangePicker.Months.November")}`,
                                            `${t("DateRangePicker.Months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("DateRangePicker.DaysOfWeek.Sunday"),
                                            t("DateRangePicker.DaysOfWeek.Monday"),
                                            t("DateRangePicker.DaysOfWeek.Tuesday"),
                                            t("DateRangePicker.DaysOfWeek.Wednesday"),
                                            t("DateRangePicker.DaysOfWeek.Thursday"),
                                            t("DateRangePicker.DaysOfWeek.Friday"),
                                            t("DateRangePicker.DaysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    onChange={() => this.doNothing()}
                                    value={this.state.ActivateUntilDate}
                                    pristine="false"
                                    ref={ActivateUntilDate => this.ActivateUntilDate = ActivateUntilDate}
                                    name="ActivateUntilDate"
                                    id="ActivateUntilDate"
                                    autoComplete="off"
                                    className="form-control form-control-solid"
                                    type="text"
                                    required
                                    placeholder={t('HealthCenter.Form.ActivateUntilDate.Placeholder')}
                                    title={t('HealthCenter.Form.ActivateUntilDate.Title')}
                                />
                            </DateRangePicker>
                            <button className="btn btn-danger btn-icon" type="button" onClick={() => this.clearDate()}>
                                <i className="fa-regular fa-broom"></i>
                            </button>
                        </div>
                    </div>

                    <div className={`col-md-4`}>
                        <SelectCities onChange={this.setCity} defaultValue={this.state.defaultCity} />
                    </div>

                    <div className={`col-md-4`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Address"><i className="fa-regular text-dark fa-location-dot me-2"></i>{t('HealthCenter.Form.Address.Title')}</label>
                        <Field
                            ref={Address => this.Address = Address}
                            name="Address"
                            id="Address"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('HealthCenter.Form.Address.Placeholder')}
                            title={t('HealthCenter.Form.Address.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4`}>
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="ClinicNumber"><i className="fa-regular text-dark fa-location-dot me-2"></i>{t('HealthCenter.Form.ClinicNumber.Title')}</label>
                        <Field
                            ref={ClinicNumber => this.ClinicNumber = ClinicNumber}
                            name="ClinicNumber"
                            id="ClinicNumber"
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('HealthCenter.Form.ClinicNumber.Placeholder')}
                            title={t('HealthCenter.Form.ClinicNumber.Title')}
                            className="form-control"
                        />
                    </div>
                    {
                        this.props.isEdit ? "" :
                            <div className={`col-md-4`}>
                                <SelectCurrencies required onChange={this.setCurrency} />
                            </div>
                    }
                </div>

                <div className="row mb-5">
                    <div className={`col-md-4`}>
                        <div className={`form-check form-check-custom form-check-dark mt-15`}>
                            <Field
                                id="HasLab"
                                name="HasLab"
                                component="input"
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => this.setHasLab(e.target.checked)}
                            />
                            <i className="fa-regular text-dark fa-microscope mx-2"></i>     {t("HealthCenter.Lab")}
                        </div>
                    </div>
                    {
                        this.props.formValues?.HasLab ?
                            <>
                                <div className={`col-md-4`}>
                                    <label className={`col-form-label required fw-bold fs-6`} htmlFor="LabName"><i className="fa-regular text-dark fa-text me-2"></i>{t('HealthCenter.Form.LabName.Title')}</label>
                                    <Field
                                        ref={LabName => this.LabName = LabName}
                                        name="LabName"
                                        id="LabName"
                                        component={this.renderField}
                                        type={"text"}
                                        required
                                        placeholder={t('HealthCenter.Form.LabName.Placeholder')}
                                        title={t('HealthCenter.Form.LabName.Title')}
                                        className="form-control"
                                    />
                                </div>
                                <div className={`col-md-4`}>
                                    <div className={`form-check form-check-custom form-check-dark mt-15`}>
                                        <Field
                                            id="LabIsPublic"
                                            name="LabIsPublic"
                                            component="input"
                                            type="checkbox"
                                            className="form-check-input"
                                        />
                                        <i className="fa-regular text-dark fa-globe mx-2"></i>     {t("HealthCenter.IsPublic")}
                                    </div>
                                </div>
                            </>
                            : ""
                    }
                </div>
                <div className="row mb-5">
                    <div className={`col-md-4`}>
                        <div className={`form-check form-check-custom form-check-dark mt-15`}>
                            <Field
                                id="HasRad"
                                name="HasRad"
                                component="input"
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => this.setHasRad(e.target.checked)}
                            />
                            <i className="fa-regular text-dark fa-circle-radiation mx-2"></i>     {t("HealthCenter.Radiology")}
                        </div>
                    </div>
                    {
                        this.props.formValues?.HasRad ?
                            <>
                                <div className={`col-md-4`}>
                                    <label className={`col-form-label required fw-bold fs-6`} htmlFor="RadName"><i className="fa-regular text-dark fa-text me-2"></i>{t('HealthCenter.Form.RadName.Title')}</label>
                                    <Field
                                        ref={RadName => this.RadName = RadName}
                                        name="RadName"
                                        id="RadName"
                                        component={this.renderField}
                                        type={"text"}
                                        required
                                        placeholder={t('HealthCenter.Form.RadName.Placeholder')}
                                        title={t('HealthCenter.Form.RadName.Title')}
                                        className="form-control"
                                    />
                                </div>
                                <div className={`col-md-4`}>
                                    <div className={`form-check form-check-custom form-check-dark mt-15`}>
                                        <Field
                                            id="RadIsPublic"
                                            name="RadIsPublic"
                                            component="input"
                                            type="checkbox"
                                            className="form-check-input"
                                        />
                                        <i className="fa-regular text-dark fa-globe mx-2"></i>     {t("HealthCenter.IsPublic")}
                                    </div>
                                </div>
                            </>
                            : ""
                    }
                </div>


                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Name) {
        errors.Name = 'HealthCenter.Form.Name.Error';
    }
    if (values.HasLab === true && !values.LabName) {
        errors.LabName = 'HealthCenter.Form.LabName.Error';
    }
    if (values.HasRad === true && !values.RadName) {
        errors.RadName = 'HealthCenter.Form.RadName.Error';
    }
    if (!values.ClinicNumber) {
        errors.ClinicNumber = 'HealthCenter.Form.ClinicNumber.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formCreateHealthCenter')(state),
        validate,
        user: state.user
    };
};

const formCreateHealthCenter = reduxForm({
    form: 'formCreateHealthCenter',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formCreateHealthCenter(Form)));