import serverAPI from "../../../../api/serverAPI";

export const loadClinics = () => async dispatch => {
    dispatch({ type: 'CLINIC_LOADING' });
}

export const fetchClinics = data => async dispatch => {
    dispatch({ type: 'CLINIC_LOADING' });
    const response = await serverAPI.get(`/Clinics/?Name=${data?.Name ? data.Name : ""}&DoctorName=${data?.DoctorName ? data.DoctorName : ""}&MedicalSpecialistId=${data?.MedicalSpecialistId ? data.MedicalSpecialistId : "0"}&CenterId=${data?.CenterId ? data.CenterId : "0"}`);
    dispatch({ type: 'CLINIC_INITIAL', clinics: response.data.data });
}

export const updateClinic = (id, clinic) => async dispatch => {
    dispatch({ type: 'CLINIC_UPDATE_INFO', clinic, id });
}

export const removeClinics = (id) => async dispatch => {
    dispatch({ type: 'CLINIC_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteClinics = (id) => async dispatch => {
    dispatch({ type: 'CLINIC_UNDO', id });
}

export const clearClinics = (id) => async dispatch => {
    dispatch({ type: 'CLINIC_CLEAN_NODES', id });
}

export const addClinic = clinic => async dispatch => {
    dispatch({ type: 'CLINIC_ADD_NEW', clinic });
}

export const changeLockedStatus = (id) => async dispatch => {
    dispatch({ type: 'CLINIC_CHANGE_LOCKED_STATUS', id });
}