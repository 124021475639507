const LabOrdersReducers = (state = [], action) => {
    switch (action.type) {
        case "LAB_ORDERS_LOADING":
            return {
                labOrders: [],
                PatientId: 0,
                isLoaded: false
            };
        case "LAB_ORDERS_INITIAL":
            return {
                labOrders: action.labOrders,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LabOrdersReducers;