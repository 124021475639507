import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";

import serverAPI from "../../../../api/serverAPI";
import SelectHealthCenters from "../../../shared/SelectList/SelectHealthCenters";
import SelectMedicalSpecialist from "../../../shared/SelectList/SelectMedicalSpecialist";
import SelectCurrencies from "../../../shared/SelectList/SelectCurrencies";

class Form extends React.Component {

    state = {
        isInitialize: false,
        Currency: null,
        Center: null,
        isSubmit: false,
        Id: 0,
        defaultCenter: [],
        MedicalSpecialist: null,
        defaultMedicalSpecialist: []
    };

    componentDidMount() {
        if (this.props.isEdit && this.props.Clinic) {
            this.setState({
                defaultCenter: { ...this.props.Clinic.HealthCenter, value: this.props.Clinic.HealthCenter.Id, label: this.props.Clinic.HealthCenter.Name },
                defaultMedicalSpecialist: { ...this.props.Clinic.MedicalSpecialist, value: this.props.Clinic.MedicalSpecialist.Id, label: this.props.Clinic.MedicalSpecialist.Name },
            });
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (!state.isInitialize && props.isEdit === true && props.Clinic) {
            state.isInitialize = true;
            props.initialize({
                Name: props.Clinic.Name,
                DoctorName: props.Clinic.DoctorName,
                CenterId: props.Clinic.HealthCenter.Id,
                MedicalSpecialistId: props.Clinic.MedicalSpecialist.Id
            });
            state.Id = props.Clinic.Id;
        }
        return state;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.setState({
            isSubmit: true
        });

        let url = '/Clinics/';
        let method = serverAPI.post;
        if (this.props.isEdit) {
            method = serverAPI.put;
            url = '/Clinics/' + this.state.Id;
        }

        method(url, {
            ...this.props.formValues
        }).then(response => {
            if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div
                    ><div className="h5">
                            {t('toast.Save.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.Save.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000
                });
                this.props.update();
                this.props.handleClose();
            } else {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t(`toast.Error.${response.data.code}.Title`)}
                        </div>
                        <div className="h6">
                            {t(`toast.Error.${response.data.code}.Body`)}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            }
            this.setState({
                isSubmit: false
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    doNothing = () => { }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }

    setMedicalSpecialist = MedicalSpecialist => {
        this.props.dispatch(change('formCreateClinic', 'MedicalSpecialistId', MedicalSpecialist?.Id));
        this.setState({
            MedicalSpecialist
        });
    }

    setCenter = Center => {
        this.props.dispatch(change('formCreateClinic', 'CenterId', Center?.Id));
        this.setState({
            Center
        });
    }


    setCurrency = Currency => {
        if (Currency?.length === 1) {
            if (Currency[0]) {
                Currency = Currency[0];
            }
        }
        this.props.dispatch(change('formCreateClinic', 'CurrencyId', Currency.Id));
        this.setState({
            Currency
        });
    }


    render() {
        const { t, pristine, handleSubmit, submitting, user } = this.props;

        return <>

            <form method="POST" name="formCreateClinic" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0;
                        })
                        .then(errors => {
                            if (errors) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            } >
                <div className="row mb-5">
                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Name"><i className="fa-regular text-dark fa-text me-2"></i>{t('Clinic.Forms.Name.Title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Name"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Clinic.Forms.Name.Placeholder')}
                            title={t('Clinic.Forms.Name.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4 mb-3`}>
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="DoctorName"><i className="fa-regular text-dark fa-user-doctor me-2"></i>{t('Clinic.Forms.DoctorName.Title')}</label>
                        <Field
                            ref={DoctorName => this.DoctorName = DoctorName}
                            name="DoctorName"
                            id="DoctorName"
                            component={this.renderField}
                            type={"text"}
                            required
                            placeholder={t('Clinic.Forms.DoctorName.Placeholder')}
                            title={t('Clinic.Forms.DoctorName.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`col-md-4 mb-3`}>
                        <SelectMedicalSpecialist required={true} onChange={this.setMedicalSpecialist} defaultValue={this.state.defaultMedicalSpecialist} />
                    </div>
                    {
                        this.props.isEdit === true ? "" :
                            <>
                                <div className={`col-md-4 mb-3`}>
                                    <label className={`col-form-label required fw-bold fs-6`} htmlFor="MainPrice"><i className="fa-regular text-dark fa-circle-dollar me-2"></i>{t('Clinic.Forms.MainPrice.Title')}</label>
                                    <Field
                                        ref={MainPrice => this.MainPrice = MainPrice}
                                        name="MainPrice"
                                        id="MainPrice"
                                        component={this.renderField}
                                        type={"number"}
                                        required
                                        placeholder={t('Clinic.Forms.MainPrice.Placeholder')}
                                        title={t('Clinic.Forms.MainPrice.Title')}
                                        className="form-control"
                                    />
                                </div>
                                <div className={`col-md-4 mb-3`}>
                                    <label className={`col-form-label required fw-bold fs-6`} htmlFor="ReviewPrice"><i className="fa-regular text-dark fa-circle-dollar me-2"></i>{t('Clinic.Forms.ReviewPrice.Title')}</label>
                                    <Field
                                        ref={ReviewPrice => this.ReviewPrice = ReviewPrice}
                                        name="ReviewPrice"
                                        id="ReviewPrice"
                                        component={this.renderField}
                                        type={"number"}
                                        required
                                        placeholder={t('Clinic.Forms.ReviewPrice.Placeholder')}
                                        title={t('Clinic.Forms.ReviewPrice.Title')}
                                        className="form-control"
                                    />
                                </div>
                                <div className={`col-md-4 mb-3`}>
                                    <SelectCurrencies required={true} onChange={this.setCurrency} />
                                </div>
                            </>
                    }
                </div>

                {
                    user && user.user && user.user.UserPermission.some(p => p.Id === 1) ?
                        <div className="row mb-5 d-flex justify-content-center">
                            <div className={`col-md-6`}>
                                <SelectHealthCenters onChange={this.setCenter} required={true} defaultValue={this.state.defaultCenter} />
                            </div>
                        </div>
                        : ""
                }
                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (!values.Name) {
        errors.Name = 'Clinic.Forms.Name.Error';
    }
    if (!values.DoctorName) {
        errors.DoctorName = 'Clinic.Forms.DoctorName.Error';
    }
    if (!values.MedicalSpecialistId) {
        errors.MedicalSpecialistId = 'Clinic.Forms.MedicalSpecialist.Error';
    }

    if (values.MainPrice < 0) {
        errors.MainPrice = 'Clinic.Forms.MainPrice.Error';
    }
    if (values.ReviewPrice < 0) {
        errors.ReviewPrice = 'Clinic.Forms.ReviewPrice.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formCreateClinic')(state),
        validate,
        user: state.user
    };
};

const formCreateClinic = reduxForm({
    form: 'formCreateClinic',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formCreateClinic(Form)));