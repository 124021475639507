const LabExamsReducers = (state = [], action) => {
    switch (action.type) {
        case "LAB_EXAMS_LOADING":
            return {
                labExams: [],
                isLoaded: false
            };
        case "LAB_EXAMS_INITIAL":
            return {
                labExams: action.labExams,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LabExamsReducers;