import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Info from "./Info";
import Delete from "./Delete";
import serverAPI from "../../../api/serverAPI";
import { fetchPatientForms as fetch } from "./actions";

class Table extends React.Component {

    state = { showDelete: false, showInfo: false, SearchByTitle: "", form: null };

    componentDidMount() {
        if (this.props.patient) {
            this.props.fetch(this.props.patient.Id);
        }
    }

    showDelete = form => {
        this.setState({
            showDelete: true,
            form
        })
    }

    handleClose = () => {
        this.setState({
            showInfo: false,
            showEdit: false,
            form: null
        });
    }

    showInfo = form => {
        this.setState({
            showInfo: true,
            form
        })
    }

    Delete = () => {
        const { t, patient } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/PatientForms/${this.state.form.Id}?PatientId=${patient.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.update();
            this.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    setSearch = SearchByTitle => {
        this.setState({
            SearchByTitle
        });
    }

    renderTableRow = () => {
        const { t, patientForms, user, showCreate } = this.props;
        if (patientForms && patientForms.patientForms && patientForms.patientForms.length > 0) {
            let searchResult = patientForms.patientForms.filter(p => p.Title.toLowerCase().includes(this.state.SearchByTitle.toLowerCase()));
            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`patient-forms-${index}-${item.Id}`}>
                        <td>
                            <span title={item.CreationDate}>
                                {moment(item.CreationDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")}
                            </span>
                        </td>
                        <td>
                            {item.Title}
                        </td>
                        <td>
                            {item.User.Name}
                        </td>
                        <td>
                            <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        <Link to="#"
                                            onClick={() => this.showInfo(item)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-info text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                        {
                                            item.User.Id === user.user.Id && showCreate ?
                                                <>
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => this.props.showEdit(item)}>
                                                        <i className="fa-regular fa-edit text-dark me-1"></i>
                                                        {t("Table.Options.Edit")}
                                                    </Link>
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => this.showDelete(item)}>
                                                        <i className="fa-regular fa-trash text-dark me-1"></i>
                                                        {t("Table.Options.Delete")}
                                                    </Link>
                                                </>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr >;
                });
            }
            return <tr><td className="text-center" colSpan={4}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={4}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, patientForms, showCreate } = this.props;

        let isLoaded = false;
        if (patientForms) {
            isLoaded = patientForms.isLoaded;
        }

        return <>
            {this.state.showInfo && this.state.form ? <Info form={this.state.form} handleClose={this.handleClose} /> : ""}
            {this.state.showDelete && this.state.form ? <Delete form={this.state.form} handleClose={this.handleClose} Delete={this.Delete} /> : ""}

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByTitle">{t('Search')} </label>
                    <input
                        value={this.state.SearchByTitle}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByTitle => this.SearchByTitle = SearchByTitle}
                        name="SearchByTitle"
                        id="SearchByTitle"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByTitle')}
                        title={t('AdvancedSearch.Search.ByTitle')}
                        className="form-control form-control-sm" />
                </div>
                {
                    showCreate ?
                        <div className="d-flex align-items-center">
                            <Link
                                to="#"
                                onClick={this.props.showCreateNew}
                                title={t("PatientForms.New")}
                                className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                                <i className="fa-regular fa-plus"></i>
                                <span className="d-md-block d-none text-nowrap ms-1">
                                    {t("PatientForms.New")}
                                </span>
                            </Link>
                        </div> : ""
                }

            </div>

            <div className="position-relative h-100">
                <div className={`table-responsive-md h-100 ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("CreationDate")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("CreationDate")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("PatientForms.Forms.Title.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-text me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("PatientForms.Forms.Title.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Clinic.Forms.DoctorName.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user-doctor me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Clinic.Forms.DoctorName.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        patientForms: state.patientForms
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Table));