import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { fetchMeasurementHistories as fetch } from "./actions";
import Info from "./Info";

class Table extends React.Component {

    state = { showInfo: false, measurement: null };

    showInfo = measurement => {
        this.setState({
            showInfo: true,
            measurement
        })
    }

    handleClose = () => {
        this.setState({
            showInfo: false,
            measurement: null
        });
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        if (this.props.patient) {
            this.props.fetch(this.props.patient.Id);
        }
    }

    getAgeFromDate = (FromDate, Birthday) => {
        let result = "";
        if (Birthday) {
            const { t } = this.props;

            const years = moment(FromDate, "DD/MM/YYYY HH:mm").diff(moment(Birthday, "DD/MM/YYYY"), 'years', false);
            const months = moment(FromDate, "DD/MM/YYYY HH:mm").diff(moment(Birthday, "DD/MM/YYYY"), 'months', false);
            const days = moment(FromDate, "DD/MM/YYYY HH:mm").diff(moment(Birthday, "DD/MM/YYYY"), 'days', false);

            if (years > 1) {
                result += years + " " + t("Age.Year") + " ";
                if (months % 12) {
                    result += months % 12 + " " + t("Age.Month");
                }
            } else {
                if (months > 0) {
                    result += months + " " + t("Age.Month") + " ";
                    if (days % 30) {
                        result += days % 30 + " " + t("Age.Day");
                    }
                } else {
                    result += days + " " + t("Age.Day");
                }
            }
        }
        return result;
    }

    renderTableRow = () => {
        const { t, measurementHistories, patient } = this.props;
        if (measurementHistories && measurementHistories.measurementHistories && measurementHistories.measurementHistories.length > 0) {
            return measurementHistories.measurementHistories.map((item, index) => {
                return <tr key={`patient-measurementHistories-${index}-${item.Id}`}>
                    <td>
                        {this.getAgeFromDate(item.CreationDate, patient.Birthday)}
                    </td>
                    <td>
                        {
                            item.Weight ?
                                <>
                                    {item.Weight}
                                    <span className="ms-1">
                                        {t("Measurements.Forms.Weight.Unit")}
                                    </span>
                                </> : ""
                        }
                    </td>
                    <td>
                        {
                            item.Height ?
                                <>
                                    {item.Height}
                                    <span className="ms-1">
                                        {t("Measurements.Forms.Height.Unit")}
                                    </span>
                                </> : ""
                        }
                    </td>
                    <td>
                        {item.Weight && item.Height && this.props.renderBMI ? this.props.renderBMI(item) : ""}
                    </td>
                    <td>
                        {
                            item.HeadCircumference ?
                                <>
                                    {item.HeadCircumference}
                                    <span className="ms-1">
                                        {t("Measurements.Forms.HeadCircumference.Unit")}
                                    </span>
                                </> : ""
                        }
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, measurementHistories } = this.props;

        let isLoaded = false;
        if (measurementHistories) {
            isLoaded = measurementHistories.isLoaded;
        }
        return <>
            {this.state.showInfo && this.state.measurement ? <Info {...this.props} moreInfo={true} measurement={this.state.measurement} handleClose={this.handleClose} /> : ""}

            <div className="position-relative h-100">
                <div className={`table-responsive-md h-100 ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("Measurements.Age.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Measurements.Age.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Measurements.Forms.Weight.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-weight-scale me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Measurements.Forms.Weight.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Measurements.Forms.Height.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-ruler-vertical me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Measurements.Forms.Height.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Measurements.BMI.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-weight-hanging me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Measurements.BMI.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Measurements.Forms.HeadCircumference.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-head-side me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Measurements.Forms.HeadCircumference.Title")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        measurementHistories: state.measurementHistories
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Table));