import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { toast } from "react-toastify";

import AccountField from '../../../../Accounts/AccountField';

class Form extends React.Component {

    state = { isSubmit: false, account: null };

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setDebited = value => {
        this.props.dispatch(change('formJournalVoucherItem', 'Debited', value));
        this.props.dispatch(change('formJournalVoucherItem', 'Credited', 0));
    }

    setCredited = value => {
        this.props.dispatch(change('formJournalVoucherItem', 'Debited', 0));
        this.props.dispatch(change('formJournalVoucherItem', 'Credited', value));
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    {t('toast.Error.AllInformationIsRequired.Title')}
                </div>
                <div className="h6">
                    {t('toast.Error.AllInformationIsRequired.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });

    }

    onSubmit = () => {
        this.props.addItem({ ...this.props.formValues, account: this.state.account });
        return false;
    }

    setAccount = account => {
        this.setState({
            account
        });
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;

        return <>
            <div className="row mb-5">
                <div className="col-md-12">
                    <AccountField account={this.state.account} update={this.setAccount} />
                </div>
            </div>
            <form method="POST" name="formJournalVoucherItem" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={
                handleSubmit(values => {
                    return new Promise(resolve => resolve())
                        .then(() => {
                            const errors = validate(values, this.props);
                            return Object.keys(errors).length !== 0;
                        })
                        .then(errors => {
                            if (errors || this.state.account === null) {
                                this.checkSubmit();
                            } else {
                                this.onSubmit();
                            }
                        })
                })
            } >

                <div className="row mb-5">
                    <div className="col-md-6">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Debited"><i className="fa-regular text-dark fa-scale-unbalanced-flip me-2"></i>{t('Accounting.JournalVoucher.Forms.Debited.Title')}</label>
                        <Field
                            ref={Debited => this.Debited = Debited}
                            name="Debited"
                            id="Debited"
                            onChange={e => this.setDebited(e.target.value)}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Accounting.JournalVoucher.Forms.Debited.Placeholder')}
                            title={t('Accounting.JournalVoucher.Forms.Debited.Title')}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Credited"><i className="fa-regular text-dark fa-scale-unbalanced me-2"></i>{t('Accounting.JournalVoucher.Forms.Credited.Title')}</label>
                        <Field
                            ref={Credited => this.Credited = Credited}
                            name="Credited"
                            id="Credited"
                            onChange={e => this.setCredited(e.target.value)}
                            component={this.renderField}
                            type={"number"}
                            required
                            placeholder={t('Accounting.JournalVoucher.Forms.Credited.Placeholder')}
                            title={t('Accounting.JournalVoucher.Forms.Credited.Title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Note"><i className="fa-regular text-dark fa-note me-2"></i>{t('Accounting.JournalVoucher.Items.Note.Title')}</label>
                        <Field
                            ref={Note => this.Note = Note}
                            name="Note"
                            id="Note"
                            rows={4}
                            component="textarea"
                            placeholder={t('Accounting.JournalVoucher.Items.Note.Placeholder')}
                            title={t('Accounting.JournalVoucher.Items.Note.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};
    if (values.Credited < 0) {
        errors.Credited = 'Accounting.JournalVoucher.Forms.Credited.Error';
    }
    if (values.Debited < 0) {
        errors.Debited = 'Accounting.JournalVoucher.Forms.Debited.Error';
    }
    if (parseFloat(values.Credited) === 0 && parseFloat(values.Debited) === 0) {
        errors.Debited = 'Accounting.JournalVoucher.Forms.Debited.ErrorZero';
        errors.Credited = 'Accounting.JournalVoucher.Forms.Credited.ErrorZero';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formJournalVoucherItem')(state),
        validate,
        user: state.user,
        currencies: state.currencies
    };
};

const formJournalVoucherItem = reduxForm({
    form: 'formJournalVoucherItem',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formJournalVoucherItem(Form)));
