import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import PatientInfo from "../../Patients/Search/Info";
import Info from "../Bills/Info";
import PatientName from "../../Patients/PatientName";
import Accept from "./Accept";

class Table extends React.Component {

    state = {
        patient: null,
        bill: null,
        SearchByName: "",
        showPatientInfo: false,
        showInfo: false,
        showAccept: false
    };

    handleClose = () => {
        this.setState({
            showPatientInfo: false,
            showAccept: false,
            showInfo: false,
            bill: null,
            patient: null
        });
    }

    showInfo = bill => {
        this.setState({
            showInfo: true,
            bill
        })
    }

    showPatientInfo = patient => {
        this.setState({
            showPatientInfo: true,
            patient
        })
    }

    showAccept = bill => {
        this.setState({
            showAccept: true,
            bill
        })
    }

    renderTableRow = () => {
        const { t, patientBills } = this.props;
        if (patientBills && patientBills.patientBills && patientBills.patientBills.length > 0) {

            let searchResult = patientBills.patientBills;
            const Names = this.state.SearchByName.split(' ');
            if (Names.length > 0) {
                Names.forEach(name => {
                    name = name.toLowerCase();
                    searchResult = searchResult.filter(p =>
                        p.Visit.patient.Name?.FirstName?.toLowerCase().includes(name) ||
                        p.Visit.patient.Name?.SecondName?.toLowerCase().includes(name) ||
                        p.Visit.patient.Name?.ThirdName?.toLowerCase().includes(name) ||
                        p.Visit.patient.Name?.LastName?.toLowerCase().includes(name) ||
                        p.Visit.patient.NameEnglish?.FirstName?.toLowerCase().includes(name) ||
                        p.Visit.patient.NameEnglish?.SecondName?.toLowerCase().includes(name) ||
                        p.Visit.patient.NameEnglish?.ThirdName?.toLowerCase().includes(name) ||
                        p.Visit.patient.NameEnglish?.LastName?.toLowerCase().includes(name)
                    )
                });
            }

            return searchResult.map((item, index) => {
                return <tr key={`patient-visit-bill-${index}-${item.Visit.Id}`}>
                    <td>
                        {item.Visit.Date}
                    </td>
                    <td>
                        <Link to="#" className="text-dark" onClick={() => this.showPatientInfo(item.Visit.patient)}>
                            <PatientName patient={item.Visit.patient} />
                        </Link>
                    </td>
                    <td>
                        {item.Visit.Clinic?.Name}
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.showAccept(item)}>
                                        <i className="fa-regular fa-file-invoice text-dark me-1"></i>
                                        {t("Table.Options.Accept.Bill")}
                                    </Link>
                                    <Link to="#"
                                        className="dropdown-item"
                                        onClick={() => this.props.showReceiptVouchers(item.Visit.patient.Account)}>
                                        <i className="fa-regular fa-receipt text-dark me-1"></i>
                                        {t("Table.Options.Voucher.Receipt")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={6}>{t("NoRows")}</td></tr>;
    }

    Accept = (PaymentType, Total, Currency) => {
        if (PaymentType === "Cash") {
            this.props.showReceiptVouchers(this.state.bill.Visit.patient.Account, Total, Currency);
        }
        this.handleClose();
        this.props.update();
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    render() {
        const { t, patientBills } = this.props;

        let isLoaded = false;
        if (patientBills) {
            isLoaded = patientBills.isLoaded;
        }
        return <>
            {this.state.showPatientInfo && this.state.patient ? <PatientInfo patient={this.state.patient} handleClose={this.handleClose} /> : ""}
            {this.state.showInfo && this.state.bill ? <Info Bill={this.state.bill} handleClose={this.handleClose} /> : ""}
            {this.state.showAccept && this.state.bill ? <Accept Bill={this.state.bill} handleClose={this.handleClose} update={this.Accept} /> : ""}

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
            </div>
            <div className="position-relative">
                <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>

                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th title={t("Accounting.ReceiveCash.Date")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Accounting.ReceiveCash.Date")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Patient.Info")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Patient.Info")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("HealthCenter.Clinic")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-house-medical-flag me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("HealthCenter.Clinic")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        patientBills: state.patientBills
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Table));