import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import AppointmentCard from "../AppointmentCard";

class Info extends React.Component {

    render() {
        const { t, currentClinic, Appointment } = this.props;
        return <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                    <span className="ms-3">
                        ({currentClinic.Name})
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppointmentCard {...this.props} Appointment={Appointment} />
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(Info);