import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Visits from "./Visits";
import FormsTable from "../ClinicScreen/PatientForms/Table";
import Drugs from "./Drugs";
import Radiology from "./Radiology";
import Labs from "./Labs";
import Measurements from "../ClinicScreen/Measurements";
import GynecologyInfo from "../ClinicScreen/GynecologyInfo";
import DrugAllergyAlert from "../ClinicScreen/DrugAllergy/DrugAllergyAlert";
import ChronicDiseaseAlert from "../ClinicScreen/ChronicDisease/ChronicDiseaseAlert";

class Info extends React.Component {

    state = { tab: "OverviewTab" };

    setTab = tab => {
        this.setState({
            tab
        });
    }

    render() {
        const { t, Patient, obstetricAndGyneHistory, pastMedicalHistory, familyHistory, pastSurgicalHistory } = this.props;

        let hasFamilyHistory = false;
        if (familyHistory && familyHistory.familyHistory && familyHistory.familyHistory.Note) {
            hasFamilyHistory = true;
        }

        let hasPastMedicalHistory = false;
        if (pastMedicalHistory && pastMedicalHistory.pastMedicalHistory && pastMedicalHistory.pastMedicalHistory.Note) {
            hasPastMedicalHistory = true;
        }

        let hasPastSurgicalHistory = false;
        if (pastSurgicalHistory && pastSurgicalHistory.pastSurgicalHistory && pastSurgicalHistory.pastSurgicalHistory.Note) {
            hasPastSurgicalHistory = true;
        }

        let hasObstetricAndGyneHistory = false;
        if (obstetricAndGyneHistory && obstetricAndGyneHistory.obstetricAndGyneHistory && obstetricAndGyneHistory.obstetricAndGyneHistory.Note) {
            hasObstetricAndGyneHistory = true;
        }

        return <div className="row">
            <div className="col-lg-12">

                <div className="card mb-5">
                    <div className="card-body p-3">
                        <div className="d-grid w-100 hover-scroll-x">
                            <ul className="nav flex-nowrap text-nowrap w-100 mb-2 text-nowrap hover-text-wrap ">
                                <li>
                                    <div title={t("MedicalRecords.Nav.OverviewTab")} className={`btn btn-sm btn-sm-icon m-1 btn-light-primary active`} data-bs-toggle="tab" href="#OverviewTab" onClick={() => this.setTab("OverviewTab")}>
                                        <span className="d-flex align-items-center">
                                            <i className={`fa-regular fa-info`}></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("MedicalRecords.Nav.Overview")}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div title={t("MedicalRecords.Nav.Visits")} className={`btn btn-sm btn-sm-icon m-1 btn-light-warning`} data-bs-toggle="tab" href="#VisitsTab" onClick={() => this.setTab("VisitsTab")}>
                                        <span className="d-flex align-items-center">
                                            <i className={`fa-regular fa-user-doctor-message`}></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("MedicalRecords.Nav.Visits")}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div title={t("MedicalRecords.Nav.Lab")} className={`btn btn-sm btn-sm-icon m-1 btn-light-success`} data-bs-toggle="tab" href="#LabTab" onClick={() => this.setTab("LabTab")}>
                                        <span className="d-flex align-items-center">
                                            <i className={`fa-regular fa-microscope`}></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("MedicalRecords.Nav.Lab")}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div title={t("MedicalRecords.Nav.Radiology")} className={`btn btn-sm btn-sm-icon m-1 btn-light-info`} data-bs-toggle="tab" href="#RadiologyTab" onClick={() => this.setTab("RadiologyTab")}>
                                        <span className="d-flex align-items-center">
                                            <i className={`fa-regular fa-circle-radiation`}></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("MedicalRecords.Nav.Radiology")}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div title={t("MedicalRecords.Nav.Drug")} className={`btn btn-sm btn-sm-icon m-1 btn-light-danger`} data-bs-toggle="tab" href="#DrugTab" onClick={() => this.setTab("DrugTab")}>
                                        <span className="d-flex align-items-center">
                                            <i className={`fa-regular fa-prescription`}></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("MedicalRecords.Nav.Drug")}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div title={t("MedicalRecords.Nav.Forms")} className={`btn btn-sm btn-sm-icon m-1 btn-light-dark`} data-bs-toggle="tab" href="#FormsTab" onClick={() => this.setTab("FormsTab")}>
                                        <span className="d-flex align-items-center">
                                            <i className={`fa-regular fa-file-lines`}></i>
                                            <span className="d-md-block d-none text-nowrap">
                                                {t("MedicalRecords.Nav.Forms")}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="tab-content h-100" >
                    <div className="tab-pane h-100 fade show active" id="OverviewTab" role="tabpanel">
                        <div className="row">
                            <div className="col-md-6">
                                {
                                    hasObstetricAndGyneHistory && obstetricAndGyneHistory?.obstetricAndGyneHistory?.Note && Patient.Sex === "female" ?
                                        <div className="card mb-5">
                                            <div className="card-header align-items-center border-0 mt-4">
                                                <h3 className="card-title">
                                                    <i className="h3 fa-duotone fa-person-breastfeeding me-2 text-info"></i>
                                                    {t("ObstetricAndGyneHistory.Title")}
                                                </h3>
                                            </div>
                                            <div className="card-body pt-5">
                                                <div style={{
                                                    whiteSpace: 'pre-wrap'
                                                }} dangerouslySetInnerHTML={{
                                                    __html: obstetricAndGyneHistory.obstetricAndGyneHistory.Note
                                                }}></div>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    hasPastMedicalHistory && pastMedicalHistory?.pastMedicalHistory?.Note ?
                                        <div className="card mb-5">
                                            <div className="card-header align-items-center border-0 mt-4">
                                                <h3 className="card-title">
                                                    <i className="h3 fa-duotone fa-notes-medical me-2 text-info"></i>
                                                    {t("PastMedicalHistory.Title")}
                                                </h3>
                                            </div>
                                            <div className="card-body pt-5">
                                                <div style={{
                                                    whiteSpace: 'pre-wrap'
                                                }} dangerouslySetInnerHTML={{
                                                    __html: pastMedicalHistory.pastMedicalHistory.Note
                                                }}></div>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    hasPastSurgicalHistory && pastSurgicalHistory?.pastSurgicalHistory?.Note ?
                                        <div className="card mb-5">
                                            <div className="card-header align-items-center border-0 mt-4">
                                                <h3 className="card-title">
                                                    <i className="h3 fa-duotone fa-kit-medical me-2 text-info"></i>
                                                    {t("PastSurgicalHistory.Title")}
                                                </h3>
                                            </div>
                                            <div className="card-body pt-5">
                                                <div style={{
                                                    whiteSpace: 'pre-wrap'
                                                }} dangerouslySetInnerHTML={{
                                                    __html: pastSurgicalHistory.pastSurgicalHistory.Note
                                                }}></div>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    hasFamilyHistory && familyHistory?.familyHistory?.Note ?
                                        <div className="card mb-5">
                                            <div className="card-header align-items-center border-0 mt-4">
                                                <h3 className="card-title">
                                                    <i className="h3 fa-duotone fa-family me-2 text-info"></i>
                                                    {t("FamilyHistory.Title")}
                                                </h3>
                                            </div>
                                            <div className="card-body pt-5">
                                                <div style={{
                                                    whiteSpace: 'pre-wrap'
                                                }} dangerouslySetInnerHTML={{
                                                    __html: familyHistory.familyHistory.Note
                                                }}></div>
                                            </div>
                                        </div> : ""
                                }
                                <div>
                                    <Measurements patient={Patient} isForShow={true} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <DrugAllergyAlert />
                                <ChronicDiseaseAlert />
                                {
                                    Patient.Sex === "female" ?
                                        <div className="mb-5">
                                            <GynecologyInfo patient={Patient} isForShow={true} />
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane h-100 fade" id="FormsTab" role="tabpanel">
                        {
                            this.state.tab === "FormsTab" ?
                                <div className="card">
                                    <div className="card-header align-items-center ">
                                        <h3 className="card-title">
                                            <span className="fw-bolder mb-2 text-dark">
                                                <i className="h3 fa-duotone fa-file-lines me-2 text-info mb-0"></i>
                                                {t('MedicalRecords.Nav.Forms')}
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="card-body pt-5">
                                        <div className="w-100">
                                            <FormsTable patient={Patient} />
                                        </div>
                                    </div>
                                </div> : ""
                        }
                    </div>
                    <div className="tab-pane h-100 fade" id="RadiologyTab" role="tabpanel">
                        {
                            this.state.tab === "RadiologyTab" ? <Radiology patient={Patient} /> : ""
                        }
                    </div>
                    <div className="tab-pane h-100 fade" id="LabTab" role="tabpanel">
                        {
                            this.state.tab === "LabTab" ? <Labs patient={Patient} /> : ""
                        }
                    </div>
                    <div className="tab-pane h-100 fade" id="DrugTab" role="tabpanel">
                        {
                            this.state.tab === "DrugTab" ? <Drugs patient={Patient} /> : ""
                        }
                    </div>
                    <div className="tab-pane h-100 fade" id="VisitsTab" role="tabpanel">
                        {
                            this.state.tab === "VisitsTab" ? <Visits patient={Patient} /> : ""
                        }
                    </div>
                </div>

            </div>
        </div >;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        drugAllergy: state.drugAllergy,
        familyHistory: state.familyHistory,
        pastMedicalHistory: state.pastMedicalHistory,
        pastSurgicalHistory: state.pastSurgicalHistory,
        patientChronicDisease: state.patientChronicDisease,
        obstetricAndGyneHistory: state.obstetricAndGyneHistory
    };
};


export default connect(mapStateToProps, {})(withTranslation('common')(Info));