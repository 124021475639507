import React from "react";
import { withTranslation } from 'react-i18next';
import { change, Field, reduxForm } from "redux-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import SelectBankBranches from "../../../shared/SelectList/SelectBankBranches";
import SelectBanks from "../../../shared/SelectList/SelectBanks";
import { toast } from "react-toastify";

class Form extends React.Component {

    state = { Bank: null, BankBranch: null, defaultBank: null, defaultBankBranch: null, Recipient: this.props.user.user.Name, isInitialize: false };

    static getDerivedStateFromProps(props, state) {
        if (props.item && !state.isInitialize) {

            state.isInitialize = true;

            setTimeout(() => {
                props.dispatch(change(props.form, 'Number', props.item.Number));
                props.dispatch(change(props.form, 'Value', props.item.Value));
                props.dispatch(change(props.form, 'Date', props.item.Date));
                props.dispatch(change(props.form, 'CheckFrom', props.item.CheckFrom));
                props.dispatch(change(props.form, 'Recipient', props.item.Recipient));
                props.dispatch(change(props.form, 'AccountNumber', props.item.AccountNumber));
            }, 100);

            state.Bank = props.item.Bank;
            state.Date = props.item.Date;
            state.Value = props.item.Value;
            state.Number = props.item.Number;
            state.CheckFrom = props.item.CheckFrom;
            state.Recipient = props.item.Recipient;
            state.BankBranch = props.item.BankBranch;
            state.AccountNumber = props.item.AccountNumber;


        }
        return state;
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                defaultBank: { ...this.props.item.Bank, value: this.props.item.Bank.Id, label: this.props.item.Bank.Name },
                defaultBankBranch: { ...this.props.item.BankBranch, Bank: { ...this.props.item.Bank }, value: this.props.item.BankBranch.Id, label: this.props.item.BankBranch.Name }
            });
        } else {
            window.setNull("#Date_" + this.props.CheckIndex);
            this.props.dispatch(change(this.props.form, 'Recipient', this.props.user.user.Name));
        }
    }

    submit = () => {
        if (this.state.Bank &&
            this.state.Date &&
            this.state.Value > 0 &&
            this.state.Number > 0 &&
            this.state.CheckFrom &&
            this.state.Recipient &&
            this.state.BankBranch &&
            this.state.AccountNumber > 0) {
            this.autoSubmit();
        } else {
            const { t } = this.props;
            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.AllInformationIsRequired.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.AllInformationIsRequired.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        }
    }

    autoSubmit = () => {
        if (this.state.Bank &&
            this.state.Date &&
            this.state.Value > 0 &&
            this.state.Number > 0 &&
            this.state.CheckFrom &&
            this.state.Recipient &&
            this.state.BankBranch &&
            this.state.AccountNumber > 0 &&
            this.props.submitItem) {
            this.props.submitItem(this.state, this.props.CheckIndex - 1);
        }
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        min,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} min={min} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setBank = Bank => {
        this.setState({
            Bank
        });
    }

    setBankBranch = BankBranch => {
        this.setState({
            BankBranch
        }, () => {
            this.autoSubmit()
        });
    }

    handleApplyDate = (event, picker) => {
        if (picker) {
            picker.element.val(picker.startDate.format('DD/MM/YYYY'));
            this.setState({
                Date: picker.startDate.format('DD/MM/YYYY')
            }, () => {
                this.autoSubmit()
            });
        } else {
            this.setState({
                Date: event.target.value
            }, () => {
                this.autoSubmit()
            });
        }
    }

    setValue = e => {
        const attr = e.target.name;
        this.setState({
            [attr]: e.target.value
        }, () => {
            this.autoSubmit()
        });
    }

    render() {
        const { t, CheckIndex, hasControl, key, isDuplicate } = this.props;
        return <>
            <tr key={key}>
                <td>
                    {
                        hasControl ?
                            <span className={`${isDuplicate ? "text-danger" : ""}`}>
                                {CheckIndex}
                            </span> : ""
                    }
                </td>
                <td>
                    <Field
                        ref={Number => this.Number = Number}
                        name={`Number`}
                        onBlur={this.setValue}
                        value={this.state.Number}
                        component={this.renderField}
                        type={"number"}
                        min={1}
                        placeholder={t('Accounting.Check.Forms.Number.Placeholder')}
                        title={t('Accounting.Check.Forms.Number.Title')}
                        className={`form-control form-control-sm ${isDuplicate ? "text-danger" : ""}`}
                    />
                </td>
                <td>
                    <Field
                        ref={AccountNumber => this.AccountNumber = AccountNumber}
                        name={`AccountNumber`}
                        component={this.renderField}
                        type={"number"}
                        min={1}
                        onBlur={this.setValue}
                        value={this.state.AccountNumber}
                        placeholder={t('Accounting.Check.Forms.AccountNumber.Placeholder')}
                        title={t('Accounting.Check.Forms.AccountNumber.Title')}
                        className={`form-control form-control-sm ${isDuplicate ? "text-danger" : ""}`}
                    />
                </td>
                <td>

                    <SelectBanks name={`BankId_${CheckIndex}`} onChange={this.setBank} defaultValue={this.state.defaultBank} hideTitle={true} className="form-control form-control-sm p-0" />
                    {
                        this.state.errors?.Bank ?
                            <div className="invalid-feedback d-block">{t(this.state.errors?.Bank)}</div>
                            : ""
                    }
                </td>
                <td>
                    <SelectBankBranches name={`BankBranchId_${CheckIndex}`} onChange={this.setBankBranch} BankId={this.state.Bank?.Id} defaultValue={this.state.defaultBankBranch} hideTitle={true} className="form-control form-control-sm p-0" />
                    {
                        this.state.errors?.BankBranch ?
                            <div className="invalid-feedback d-block">{t(this.state.errors?.BankBranch)}</div>
                            : ""
                    }
                </td>
                <td>
                    <Field
                        ref={Value => this.Value = Value}
                        name={`Value`}
                        component={this.renderField}
                        type={"number"}
                        min={1}
                        onBlur={this.setValue}
                        value={this.state.Value}
                        placeholder={t('Accounting.Check.Forms.Value.Placeholder')}
                        title={t('Accounting.Check.Forms.Value.Title')}
                        className={`form-control form-control-sm ${isDuplicate ? "text-danger" : ""}`}
                    />
                </td>
                <td>
                    <DateRangePicker
                        ref={SelectDate => this.SelectDate = SelectDate}
                        initialSettings={{
                            singleDatePicker: true,
                            opens: 'center',
                            autoUpdateInput: true,
                            buttonClasses: 'btn',
                            cancelClass: "btn-danger",
                            applyButtonClasses: "btn-primary",
                            showDropdowns: true,
                            format: 'DD/MM/YYYY',
                            timePicker: false,
                            locale: {
                                format: 'DD/MM/YYYY',
                                cancelLabel: t('Close'),
                                firstDay: 6,
                                applyLabel: t('Apply'),
                                customRangeLabel: t('DateRangePicker.Range.customRange'),
                                monthNames: [
                                    `${t("DateRangePicker.Months.January")}`,
                                    `${t("DateRangePicker.Months.February")}`,
                                    `${t("DateRangePicker.Months.March")}`,
                                    `${t("DateRangePicker.Months.April")}`,
                                    `${t("DateRangePicker.Months.May")}`,
                                    `${t("DateRangePicker.Months.June")}`,
                                    `${t("DateRangePicker.Months.July")}`,
                                    `${t("DateRangePicker.Months.August")}`,
                                    `${t("DateRangePicker.Months.September")}`,
                                    `${t("DateRangePicker.Months.October")}`,
                                    `${t("DateRangePicker.Months.November")}`,
                                    `${t("DateRangePicker.Months.December")}`
                                ],
                                daysOfWeek: [
                                    t("DateRangePicker.DaysOfWeek.Sunday"),
                                    t("DateRangePicker.DaysOfWeek.Monday"),
                                    t("DateRangePicker.DaysOfWeek.Tuesday"),
                                    t("DateRangePicker.DaysOfWeek.Wednesday"),
                                    t("DateRangePicker.DaysOfWeek.Thursday"),
                                    t("DateRangePicker.DaysOfWeek.Friday"),
                                    t("DateRangePicker.DaysOfWeek.Saturday")
                                ],
                            },
                        }}
                        onApply={this.handleApplyDate} >
                        <input
                            ref={Date => this.Date = Date}
                            name="Date"
                            id={`Date_${CheckIndex}`}
                            autoComplete="off"
                            onBlur={this.handleApplyDate}
                            onChange={this.handleApplyDate}
                            defaultValue={this.state.Date}
                            className={`form-control form-control-sm ${isDuplicate ? "text-danger" : ""}`}
                            type="text"
                            placeholder={t('Accounting.Check.Forms.Date.Placeholder')}
                            title={t('Accounting.Check.Forms.Date.Title')}
                        />
                    </DateRangePicker>
                </td>
                <td>
                    <Field
                        ref={CheckFrom => this.CheckFrom = CheckFrom}
                        name={`CheckFrom`}
                        component={this.renderField}
                        type={"text"}
                        onBlur={this.setValue}
                        value={this.state.CheckFrom}
                        placeholder={t('Accounting.Check.Forms.CheckFrom.Placeholder')}
                        title={t('Accounting.Check.Forms.CheckFrom.Title')}
                        className={`form-control form-control-sm ${isDuplicate ? "text-danger" : ""}`}
                    />
                </td>
                <td>
                    <Field
                        ref={Recipient => this.Recipient = Recipient}
                        name={`Recipient`}
                        component={this.renderField}
                        type={"text"}
                        onBlur={this.setValue}
                        value={this.state.Recipient}
                        placeholder={t('Accounting.Check.Forms.Recipient.Placeholder')}
                        title={t('Accounting.Check.Forms.Recipient.Title')}
                        className={`form-control form-control-sm ${isDuplicate ? "text-danger" : ""}`}
                    />
                </td>
                <td>
                    {
                        hasControl ?
                            <div className="d-flex ">
                                <button type="button" className="btn btn-sm btn-icon btn-dark me-1" title={t('Accounting.Check.Repeat')} onClick={() => this.props.repeat(CheckIndex - 1)}>
                                    <i className="fa-solid fa-copy"></i>
                                </button>
                                <button type="button" className="btn btn-sm btn-icon btn-danger" title={t('Table.Options.Delete')} onClick={() => this.props.delete(CheckIndex - 1)}>
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </div>
                            :
                            <button type="button" className="btn btn-sm btn-icon btn-success" title={t('Table.Options.Add')} onClick={() => this.submit()}>
                                <i className="fa-solid fa-check"></i>
                            </button>
                    }
                </td>
            </tr>
        </>;
    }
}

const validate = values => {
    const errors = {};

    if (values.Value <= 0) {
        errors.Value = 'Accounting.Check.Forms.Value.Error';
    }
    if (values.AccountNumber <= 0) {
        errors.AccountNumber = 'Accounting.Check.Forms.AccountNumber.Error';
    }
    if (values.Number <= 0) {
        errors.Number = 'Accounting.Check.Forms.Number.Error';
    }
    if (!values.Date) {
        errors.Date = 'Accounting.Check.Forms.Date.Error';
    }
    if (!values.Recipient) {
        errors.Recipient = 'Accounting.Check.Forms.Recipient.Error';
    }
    if (!values.CheckFrom) {
        errors.CheckFrom = 'Accounting.Check.Forms.CheckFrom.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        validate,
        user: state.user
    };
};

const formCheckCreate = reduxForm({
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formCheckCreate(Form)));

