import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Field, getFormValues, reduxForm } from "redux-form";
import { fetchPatients as fetch } from "../action";

class Form extends React.Component {

    state = { Birthday: null };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        this.clearDate();
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            Birthday: picker.startDate.format('DD/MM/YYYY')
        });
    }

    reset = () => {
        this.props.reset();
        this.setState({
            Birthday: null
        }, () => this.clearDate());
    }

    clearDate = () => {
        window.setNull("#Search_Birthday");
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.fetch({ ...this.props.formValues, Birthday: this.state.Birthday });
        return false;
    }

    render() {
        const { t, pristine, submitting, patients } = this.props;

        let isLoaded = false;
        if (patients && patients.patients) {
            isLoaded = patients.isLoaded;
        } else if (patients.length === 0) {
            isLoaded = true;
        }

        return <>
            <form method="POST" name="formPatientSearch" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password"  >
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_FirstName"><i className="fa-regular text-dark fa-user-injured me-2"></i>{t('Patient.Forms.FirstName.Title')}</label>
                        <Field
                            ref={FirstName => this.FirstName = FirstName}
                            name="FirstName"
                            id="Search_FirstName"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.FirstName.Placeholder')}
                            title={t('Patient.Forms.FirstName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_SecondName">{t('Patient.Forms.SecondName.Title')}</label>
                        <Field
                            ref={SecondName => this.SecondName = SecondName}
                            name="SecondName"
                            id="Search_SecondName"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.SecondName.Placeholder')}
                            title={t('Patient.Forms.SecondName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_ThirdName">{t('Patient.Forms.ThirdName.Title')}</label>
                        <Field
                            ref={ThirdName => this.ThirdName = ThirdName}
                            name="ThirdName"
                            id="Search_ThirdName"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.ThirdName.Placeholder')}
                            title={t('Patient.Forms.ThirdName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_LastName">{t('Patient.Forms.LastName.Title')}</label>
                        <Field
                            ref={LastName => this.LastName = LastName}
                            name="LastName"
                            id="Search_LastName"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.LastName.Placeholder')}
                            title={t('Patient.Forms.LastName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_FirstNameEnglish">{t('Patient.Forms.EnglishName.FirstName.Title')}</label>
                        <Field
                            ref={FirstNameEnglish => this.FirstNameEnglish = FirstNameEnglish}
                            name="FirstNameEnglish"
                            id="Search_FirstNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.FirstName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.FirstName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_SecondNameEnglish">{t('Patient.Forms.EnglishName.SecondName.Title')}</label>
                        <Field
                            ref={SecondNameEnglish => this.SecondNameEnglish = SecondNameEnglish}
                            name="SecondNameEnglish"
                            id="Search_SecondNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.SecondName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.SecondName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_ThirdNameEnglish">{t('Patient.Forms.EnglishName.ThirdName.Title')}</label>
                        <Field
                            ref={ThirdNameEnglish => this.ThirdNameEnglish = ThirdNameEnglish}
                            name="ThirdNameEnglish"
                            id="Search_ThirdNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.ThirdName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.ThirdName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_LastNameEnglish">{t('Patient.Forms.EnglishName.LastName.Title')}</label>
                        <Field
                            ref={LastNameEnglish => this.LastNameEnglish = LastNameEnglish}
                            name="LastNameEnglish"
                            id="Search_LastNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.LastName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.LastName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_DocumentNumber"><i className="fa-regular text-dark fa-hashtag me-2"></i>{t('Patient.Forms.ID.Title')}</label>
                        <Field
                            ref={DocumentNumber => this.DocumentNumber = DocumentNumber}
                            name="DocumentNumber"
                            id="Search_DocumentNumber"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.ID.Placeholder')}
                            title={t('Patient.Forms.ID.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_MotherName">{t('Patient.Forms.MotherName.Title')}</label>
                        <Field
                            ref={MotherName => this.MotherName = MotherName}
                            name="MotherName"
                            id="Search_MotherName"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.MotherName.Placeholder')}
                            title={t('Patient.Forms.MotherName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_MotherNameEnglish">{t('Patient.Forms.EnglishName.MotherName.Title')}</label>
                        <Field
                            ref={MotherNameEnglish => this.MotherNameEnglish = MotherNameEnglish}
                            name="MotherNameEnglish"
                            id="Search_MotherNameEnglish"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.EnglishName.MotherName.Placeholder')}
                            title={t('Patient.Forms.EnglishName.MotherName.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_Birthday"><i className="fa-regular text-dark fa-cake-candles me-2"></i>{t('Patient.Forms.Birthday.Title')}</label>
                        <div className="input-group position-relative">
                            <DateRangePicker
                                ref={SelectBirthday => this.SelectBirthday = SelectBirthday}
                                initialSettings={{
                                    singleDatePicker: true,
                                    opens: 'center',
                                    autoUpdateInput: true,
                                    buttonClasses: 'btn',
                                    cancelClass: "btn-danger",
                                    applyButtonClasses: "btn-primary",
                                    showDropdowns: true,
                                    format: 'DD/MM/YYYY',
                                    timePicker: false,
                                    maxDate: `${moment().format('DD/MM/YYYY')}`,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        customRangeLabel: t('DateRangePicker.Range.customRange'),
                                        monthNames: [
                                            `${t("DateRangePicker.Months.January")}`,
                                            `${t("DateRangePicker.Months.February")}`,
                                            `${t("DateRangePicker.Months.March")}`,
                                            `${t("DateRangePicker.Months.April")}`,
                                            `${t("DateRangePicker.Months.May")}`,
                                            `${t("DateRangePicker.Months.June")}`,
                                            `${t("DateRangePicker.Months.July")}`,
                                            `${t("DateRangePicker.Months.August")}`,
                                            `${t("DateRangePicker.Months.September")}`,
                                            `${t("DateRangePicker.Months.October")}`,
                                            `${t("DateRangePicker.Months.November")}`,
                                            `${t("DateRangePicker.Months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("DateRangePicker.DaysOfWeek.Sunday"),
                                            t("DateRangePicker.DaysOfWeek.Monday"),
                                            t("DateRangePicker.DaysOfWeek.Tuesday"),
                                            t("DateRangePicker.DaysOfWeek.Wednesday"),
                                            t("DateRangePicker.DaysOfWeek.Thursday"),
                                            t("DateRangePicker.DaysOfWeek.Friday"),
                                            t("DateRangePicker.DaysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    defaultValue={this.state.Birthday}
                                    ref={Birthday => this.Birthday = Birthday}
                                    name="Birthday"
                                    id="Search_Birthday"
                                    autoComplete="off"
                                    className="form-control form-control-solid"
                                    type="text"
                                    placeholder={t('Patient.Forms.Birthday.Placeholder')}
                                    title={t('Patient.Forms.Birthday.Title')}
                                />
                            </DateRangePicker>
                            <button className="btn btn-danger btn-icon" type="button" onClick={() => this.clearDate()}>
                                <i className="fa-regular fa-broom"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mb-10">

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_Email"><i className="fa-regular text-dark fa-at me-2"></i>{t('Patient.Forms.Email.Title')}</label>
                        <Field
                            ref={Email => this.Email = Email}
                            name="Email"
                            id="Search_Email"
                            component={this.renderField}
                            type={"email"}
                            placeholder={t('Patient.Forms.Email.Placeholder')}
                            title={t('Patient.Forms.Email.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_Phone"><i className="fa-regular text-dark fa-phone me-2"></i>{t('Patient.Forms.Phone.Title')}</label>
                        <Field
                            ref={Phone => this.Phone = Phone}
                            name="Phone"
                            id="Search_Phone"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.Phone.Placeholder')}
                            title={t('Patient.Forms.Phone.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_WhatsAppNumber"><i className="fa-brands fa-whatsapp me-2 text-success"></i>{t('Patient.Forms.WhatsAppNumber.Title')}</label>
                        <Field
                            ref={WhatsAppNumber => this.WhatsAppNumber = WhatsAppNumber}
                            name="WhatsAppNumber"
                            id="Search_WhatsAppNumber"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.WhatsAppNumber.Placeholder')}
                            title={t('Patient.Forms.WhatsAppNumber.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Search_Address"><i className="fa-regular text-dark fa-location-dot me-2"></i>{t('Patient.Forms.Address.Title')}</label>
                        <Field
                            ref={Address => this.Address = Address}
                            name="Address"
                            id="Search_Address"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('Patient.Forms.Address.Placeholder')}
                            title={t('Patient.Forms.Address.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0 justify-content-start pt-3">
                    <button type="button" className="btn btn-sm btn-info mx-1" onClick={e => this.onSubmit(e)} title={t('Search')} disabled={pristine || submitting || !isLoaded} data-kt-indicator={!isLoaded ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-magnifying-glass"></i>
                            {t('Search')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="reset" className="btn btn-sm btn-danger mx-1" title={t('Reset')} disabled={submitting} onClick={() => this.reset()}>
                        <i className="me-1 fa-solid fa-broom"></i>
                        {t('Reset')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const mapStateToProps = state => {
    return {
        formValues: getFormValues('formPatientSearch')(state),
        user: state.user,
        patients: state.patients
    };
};

const formPatientSearch = reduxForm({
    form: 'formPatientSearch',
    enableReinitialize: true,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, { fetch })(withTranslation('common')(formPatientSearch(Form)));