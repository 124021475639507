import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchCurrencies } from "./actions";

class SelectCurrencies extends React.Component {

    state = { currencies: [], isInitialize: false, CurrencyId: React.createRef() };

    constructor(props) {
        super(props);
        const { currencies } = this.props;
        if (!currencies || !currencies.currencies || currencies.currencies?.length === 0) {
            this.props.fetchCurrencies();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.defaultValue && !state.isInitialize && state.CurrencyId.current) {
            state.isInitialize = true;
            state.CurrencyId.current.setValue(props.defaultValue);
        }

        if (props.currencies?.currencies) {
            state.currencies = props.currencies.currencies.map(item => {
                return {
                    ...item,
                    value: item.Id,
                    name: item.Name,
                    rate: item.Rate,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        const { t, currencies, required, isClearable, hideTitle, className } = this.props;
        let isLoaded = false;
        if (currencies) {
            isLoaded = currencies.isLoaded;
        }

        return <>
            {
                hideTitle ? "" : <label className={`col-form-label ${required ? "required" : ""} fw-bold fs-6`} htmlFor="CurrencyId"><i className="fa-regular text-dark fa-wallet me-2"></i>{t('Currencies.Forms.Select.Title')}</label>
            }
            <Select
                name="CurrencyId"
                id="CurrencyId"
                placeholder={t('Currencies.Forms.Select.Placeholder')}
                title={t('Currencies.Forms.Select.Title')}
                isLoading={!isLoaded}
                isSearchable={true}
                isClearable={isClearable}
                ref={this.state.CurrencyId}
                required={required}
                onChange={this.onChange}
                options={this.state.currencies}
                filterOption={this.filterOptions}
                className={className}
            />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        currencies: state.currencies
    };
};

export default connect(mapStateToProps, { fetchCurrencies })(withTranslation('common')(SelectCurrencies));