const DrugOrdersReducers = (state = [], action) => {
    switch (action.type) {
        case "DRUG_ORDERS_LOADING":
            return {
                drugOrders: [],
                PatientId: 0,
                isLoaded: false
            };
        case "DRUG_ORDERS_INITIAL":
            return {
                drugOrders: action.drugOrders,
                PatientId: action.PatientId,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default DrugOrdersReducers;