const PharmaciesReducers = (state = [], action) => {
    switch (action.type) {
        case "PHARMACY_LOADING":
            return {
                pharmacies: [],
                isLoaded: false
            };
        case "PHARMACY_INITIAL":
            return {
                pharmacies: action.pharmacies,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default PharmaciesReducers;