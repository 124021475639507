import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { change, Field, getFormValues, reduxForm } from "redux-form";
import SelectCities from "../../shared/SelectList/SelectCities";

class Form extends React.Component {

    onSubmit = event => {
        event.preventDefault();
        this.props.update({
            ...this.props.formValues
        });
        return false;
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} checked={checked} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setCity = City => {
        this.props.dispatch(change('formHealthCenterSearch', 'CityId', City?.Id));
    }

    render() {
        const { t, users } = this.props;

        let isLoaded = false;
        if (users && users.users) {
            isLoaded = users.isLoaded;
        } else if (users.length === 0) {
            isLoaded = true;
        }

        return <>
            <form method="POST" name="formHealthCenterSearch" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password"
                onSubmit={e => this.onSubmit(e)}   >
                <div className="row mb-5">
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Name"><i className="fa-regular text-dark fa-text me-2"></i>{t('HealthCenter.Form.Name.Search')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            name="Name"
                            id="Name"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('HealthCenter.Form.Name.Placeholder')}
                            title={t('HealthCenter.Form.Name.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Email"><i className="fa-regular text-dark fa-at me-2"></i>{t('HealthCenter.Form.Email.Search')}</label>
                        <Field
                            ref={Email => this.Email = Email}
                            name="Email"
                            id="Email"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('HealthCenter.Form.Email.Placeholder')}
                            title={t('HealthCenter.Form.Email.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Phone"><i className="fa-regular text-dark fa-phone me-2"></i>{t('HealthCenter.Form.Phone.Search')}</label>
                        <Field
                            ref={Phone => this.Phone = Phone}
                            name="Phone"
                            id="Phone"
                            component={this.renderField}
                            type={"text"}
                            placeholder={t('HealthCenter.Form.Phone.Placeholder')}
                            title={t('HealthCenter.Form.Phone.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="col-md-3">
                        <SelectCities isClearable={true} onChange={this.setCity} />
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0 justify-content-start pt-3">
                    <button type="submit" className="btn btn-sm btn-info mx-1" title={t('Search')} disabled={!isLoaded} data-kt-indicator={!isLoaded ? "on" : "off"}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-magnifying-glass"></i>
                            {t('Search')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </form>
        </>;
    }
}


const mapStateToProps = state => {
    return {
        formValues: getFormValues('formHealthCenterSearch')(state),
        user: state.user,
        users: state.users
    };
};

const formHealthCenterSearch = reduxForm({
    form: 'formHealthCenterSearch',
    enableReinitialize: true,
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formHealthCenterSearch(Form)));