const HealthCentersReducers = (state = [], action) => {
    switch (action.type) {
        case "HEALTH_CENTERS_LOADING":
            return {
                healthCenters: [],
                isLoaded: false
            };
        case "HEALTH_CENTERS_INITIAL":
            return {
                healthCenters: action.healthCenters,
                isLoaded: true
            };
        case "HEALTH_CENTER_CHANGE_LOCKED_STATUS":
            return {
                healthCenters: changeLockedStatushealthCenters(state.healthCenters, action.id),
                isLoaded: true
            };

        case "HEALTH_CENTERS_REMOVE":
            return {
                healthCenters: state.healthCenters.filter(p => p.Id !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default HealthCentersReducers;

function changeLockedStatushealthCenters(healthCenters, id) {
    const list = healthCenters.map(healthCenter => {
        if (id && healthCenter.Id === id) {
            return { ...healthCenter, IsActive: !healthCenter.IsActive };
        }
        return healthCenter;
    });
    return list;
}