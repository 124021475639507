const ChronicDiseaseReducers = (state = [], action) => {
    switch (action.type) {
        case "CHRONIC_DISEASE_LOADING":
            return {
                chronicDisease: [],
                isLoaded: false
            };
        case "CHRONIC_DISEASE_INITIAL":
            return {
                chronicDisease: action.chronicDisease,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default ChronicDiseaseReducers;