import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from "./Form";
import Table from "./Table";

class Search extends React.Component {

    render() {
        const { t } = this.props;
        return <Modal
            size="xl"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                    {t('Search')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form {...this.props} />
                <div className="mt-3">
                    <Table {...this.props} isForSearch />
                </div>
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(Search);