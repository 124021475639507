import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import FormTemplate from "./FormTemplate";
import serverAPI from "../../../api/serverAPI";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, showBody: false, Id: 0 };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.isEdit === true && props.patientForm) {
            state.isInitialize = true;
            props.initialize({
                Title: props.patientForm.Title,
                Body: props.patientForm.Body
            });
            state.showBody = true;
            state.Id = props.patientForm.Id;
            setTimeout(() => {
                window.createRedactor("#Body");
            }, 200);
        }
        return state;
    }

    showBody = title => {
        if (!this.state.showBody && title) {
            this.setState({
                showBody: true
            }, () => {
                const Template = FormTemplate({ ...this.props, title });
                this.props.initialize({
                    Title: title,
                    Body: Template
                });
                setTimeout(() => {
                    window.createRedactor("#Body");
                }, 200);
            });
        }
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    renderTextArea = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        rows,
        meta: { touched, error }
    }) => (
        <>
            <textarea {...input} id={id} title={title} rows={rows} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t, patient } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let Id = patient.Id;
        let method = serverAPI.post;
        if (this.props.isEdit) {
            method = serverAPI.put;
            Id = this.state.Id;
        }

        method(`/PatientForms/${Id}`, {
            ...this.props.formValues,
            PatientId: patient.Id,
            Body: window.getRedactor("#Body")
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    render() {
        const { t } = this.props;

        return <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/redactor-colorpicker.min.css?v=' + process.env.REACT_APP_VERSION} />
                <script src={process.env.PUBLIC_URL + '/assets/redactor/colorpicker.js?v=' + process.env.REACT_APP_VERSION} ></script>
                <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/redactor/new-redactor.css?v=' + process.env.REACT_APP_VERSION} />
                <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
            </Helmet>
            <form method="POST" name="formPatientForms" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <div className="col-md-6">
                        <label className={`col-form-label required fw-bold fs-6`} htmlFor="Title"><i className="fa-regular text-dark fa-text me-2"></i>{t('PatientForms.Forms.Title.Title')}</label>
                        <Field
                            ref={Title => this.Title = Title}
                            name="Title"
                            id="Title"
                            component={this.renderField}
                            onBlur={e => this.showBody(e.target.value)}
                            type={"text"}
                            placeholder={t('PatientForms.Forms.Title.Placeholder')}
                            title={t('PatientForms.Forms.Title.Title')}
                            className="form-control form-control-solid"
                        />
                    </div>
                </div>
                {
                    this.state.showBody ?
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <label className={`col-form-label required fw-bold fs-6`} htmlFor="Body"><i className="fa-regular text-dark fa-note me-2"></i>{t('PatientForms.Forms.Body.Title')}</label>
                                <Field
                                    ref={Body => this.Body = Body}
                                    name="Body"
                                    id="Body"
                                    rows={5}
                                    required
                                    component={this.renderTextArea}
                                    placeholder={t('PatientForms.Forms.Body.Placeholder')}
                                    title={t('PatientForms.Forms.Body.Title')}
                                    className="form-control form-control-solid"
                                />
                            </div>
                        </div>
                        : ""
                }
                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}


const validate = values => {
    const errors = {};

    if (!values.Title) {
        errors.Title = 'PatientForms.Forms.Title.Error';
    }
    if (!values.Body) {
        errors.Body = 'PatientForms.Forms.Body.Error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formPatientForms')(state),
        validate,
        user: state.user
    };
};

const formPatientForms = reduxForm({
    form: 'formPatientForms',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formPatientForms(Form)));
