import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues } from "redux-form";
import { toast } from "react-toastify";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import serverAPI from "../../../../api/serverAPI";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, PressureFromLeftHand: true, Pain: 1 };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.isEdit && props.VitalSigns) {
            state.isInitialize = true;
            props.initialize({
                Temperature: props.VitalSigns.Temperature,
                Oxygen: props.VitalSigns.Oxygen,
                PressureFromLeftHand: props.VitalSigns.PressureFromLeftHand,
                Pulse: props.VitalSigns.Pulse,
                PressureLower: props.VitalSigns.PressureLower,
                PressureUpper: props.VitalSigns.PressureUpper
            });
            state.Pain = props.VitalSigns.Pain;
            state.PressureFromLeftHand = props.VitalSigns.PressureFromLeftHand;
        }
        return state;
    }

    setPain = Pain => {
        this.setState({
            Pain
        });
    }

    setPressureFromLeftHand = PressureFromLeftHand => {
        this.setState({
            PressureFromLeftHand
        });
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} id={id} title={title} checked={checked} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    onSubmit = () => {
        const { t, Appointment } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let Id = Appointment.Id;
        let method = serverAPI.post;
        if (this.props.isEdit === true) {
            method = serverAPI.put;
            Id = this.props.VitalSigns.Id;
        }

        method(`/VitalSigns/${Id}`, {
            ...this.props.formValues,
            Pain: this.state.Pain
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
            this.props.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    render() {
        const { pristine, submitting, t } = this.props;

        return <>

            <form method="POST" name="formVitalSigns" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">
                    <div className="col-md-6 col-sm-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="PressureUpper"><i className="fa-regular text-dark fa-stethoscope me-2"></i>{t("VitalSigns.Pressure.Title")}</label>
                        <div className="input-group input-group-sm">
                            <Field
                                ref={PressureUpper => this.PressureUpper = PressureUpper}
                                name="PressureUpper"
                                id="PressureUpper"
                                component={this.renderField}
                                autoComplete={"off"}
                                type={"number"}
                                placeholder={t("VitalSigns.Pressure.Upper")}
                                title={t("VitalSigns.Pressure.Upper")}
                                className="form-control" />
                            <span className="input-group-text">
                                <i className="fa-solid fa-slash-forward text-dark"></i>
                            </span>
                            <Field
                                ref={PressureLower => this.PressureLower = PressureLower}
                                name="PressureLower"
                                id="PressureLower"
                                component={this.renderField}
                                autoComplete={"off"}
                                type={"number"}
                                placeholder={t("VitalSigns.Pressure.Lower")}
                                title={t("VitalSigns.Pressure.Lower")}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Pressure-Left"><i className="fa-regular text-dark fa-hands me-2"></i>{t("VitalSigns.Pressure.Source.Title")}</label>

                        <div className="d-flex justify-content-around flex-wrap align-items-center pt-2">
                            <div className="form-check form-check-custom form-check-dark" title={t("VitalSigns.Pressure.Source.Left")}>
                                <Field name="PressureFromLeftHand" component={this.renderField} type="radio" value={true} id="Pressure-Left" onChange={() => this.setPressureFromLeftHand(true)} className="form-check-input" checked={this.state.PressureFromLeftHand === true} />
                                <label className="form-check-label" htmlFor="Pressure-Left">
                                    <i className="fa-solid fa-hand fa-flip-horizontal me-1 text-primary" ></i>
                                    {t("VitalSigns.Pressure.Source.Left")}
                                </label>
                            </div>

                            <div className="form-check form-check-custom form-check-dark" title={t("VitalSigns.Pressure.Source.Right")}>
                                <Field name="PressureFromLeftHand" component={this.renderField} type="radio" value={false} id="Pressure-Right" onChange={() => this.setPressureFromLeftHand(false)} className="form-check-input" checked={this.state.PressureFromLeftHand === false} />
                                <label className="form-check-label" htmlFor="Pressure-Right">
                                    <i className="fa-solid fa-hand me-1 text-primary"></i>
                                    {t("VitalSigns.Pressure.Source.Right")}
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-4 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Oxygen"><i className="fa-regular text-dark fa-droplet-percent me-2"></i>{t('VitalSigns.Oxygen.Title')}</label>
                        <Field
                            ref={Oxygen => this.Oxygen = Oxygen}
                            name="Oxygen"
                            id="Oxygen"
                            component={this.renderField}
                            type={"number"}
                            placeholder={t('VitalSigns.Oxygen.Placeholder')}
                            title={t('VitalSigns.Oxygen.Title')}
                            className="form-control form-control-sm"
                        />
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Pulse"><i className="fa-regular text-dark fa-heart-pulse me-2"></i>{t('VitalSigns.Pulse.Title')}</label>
                        <Field
                            ref={Pulse => this.Pulse = Pulse}
                            name="Pulse"
                            id="Pulse"
                            component={this.renderField}
                            type={"number"}
                            placeholder={t('VitalSigns.Pulse.Placeholder')}
                            title={t('VitalSigns.Pulse.Title')}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Temperature"><i className="fa-regular text-dark fa-thermometer me-2"></i>{t('VitalSigns.Temperature.Title')}</label>
                        <Field
                            ref={Temperature => this.Temperature = Temperature}
                            name="Temperature"
                            id="Temperature"
                            component={this.renderField}
                            type={"number"}
                            placeholder={t('VitalSigns.Temperature.Placeholder')}
                            title={t('VitalSigns.Temperature.Title')}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-sm-12 position-relative">
                        <div className={`col-form-label fw-bold fs-6`}>{t("VitalSigns.Pain.Title")}</div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-5">
                            <div title={t("VitalSigns.Pain.Status.sad")} className="d-flex flex-column align-items-center" onClick={() => this.setPain(5)}>
                                <i className="fa-solid fa-face-sad-tear display-6 mb-1 text-danger"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("VitalSigns.Pain.Status.sad")}
                                </span>
                            </div>
                            <div title={t("VitalSigns.Pain.Status.angry")} className="d-flex flex-column align-items-center" onClick={() => this.setPain(4)}>
                                <i className="fa-solid fa-face-angry display-6 mb-1 text-warning"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("VitalSigns.Pain.Status.angry")}
                                </span>
                            </div>
                            <div title={t("VitalSigns.Pain.Status.meh")} className="d-flex flex-column align-items-center" onClick={() => this.setPain(3)}>
                                <i className="fa-solid fa-face-meh display-6 mb-1 text-dark"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("VitalSigns.Pain.Status.meh")}
                                </span>
                            </div>
                            <div title={t("VitalSigns.Pain.Status.smile")} className="d-flex flex-column align-items-center" onClick={() => this.setPain(2)}>
                                <i className="fa-solid fa-face-smile display-6 mb-1 text-info"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("VitalSigns.Pain.Status.smile")}
                                </span>
                            </div>
                            <div title={t("VitalSigns.Pain.Status.happy")} className="d-flex flex-column align-items-center" onClick={() => this.setPain(1)}>
                                <i className="fa-solid fa-face-smile-beam display-6 mb-1 text-success"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("VitalSigns.Pain.Status.happy")}
                                </span>
                            </div>
                        </div>
                        <Nouislider onChange={e => this.setPain(parseInt(e[0]))} range={{ min: 1, max: 5 }} step={1} accessibility clickablePips start={this.state.Pain} />
                    </div>
                </div>
                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={this.state.isSubmit || pristine || submitting} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formVitalSigns')(state),
        familyHistory: state.familyHistory,
        user: state.user
    };
};

const formVitalSigns = reduxForm({
    form: 'formVitalSigns',
    persistentSubmitErrors: true,
    touchOnBlur: true
});


export default connect(mapStateToProps, {})(withTranslation('common')(formVitalSigns(Form)));
