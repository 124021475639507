import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Info from "../MedicalRecords/Info";

class MedicalRecords extends React.Component {

    render() {
        const { t } = this.props;

        return <Modal
            size="xl"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-rectangle-history-circle-user me-2 text-primary "></i>
                    {t("MedicalRecords.Title")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                background: '#F5F8FA'
            }}>
                <Info {...this.props} />
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(MedicalRecords);