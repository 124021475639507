import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import NewOrder from "./NewOrder";
import { fetchLabOrders as fetch } from './actions';
import Info from "./Modal/Info";
import Delete from "./Modal/Delete";
import serverAPI from "../../../api/serverAPI";

class LabOrders extends React.Component {

    state = {
        showCreateNew: false,
        showDelete: false,
        showInfo: false,
        showEdit: false,
        Order: null
    };

    componentDidMount() {
        const { labOrders } = this.props;
        if (!labOrders || !labOrders.labOrders || labOrders.labOrders?.length === 0 || labOrders.PatientId !== this.props.Appointment.patient?.Id) {
            this.update();
        }
    }

    showEdit = Order => {
        this.setState({
            showEdit: true,
            Order
        });
    }

    showInfo = Order => {
        this.setState({
            showInfo: true,
            Order
        });
    }

    showDelete = Order => {
        this.setState({
            showDelete: true,
            Order
        });
    }

    handleClose = () => {
        this.setState({
            showCreateNew: false,
            showDelete: false,
            showInfo: false,
            showEdit: false,
            Order: null
        });
    }

    showCreateNew = () => {
        this.setState({
            showCreateNew: true
        });
    }

    Delete = () => {
        const { t, Appointment } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.delete(`/VisitLabOrders/${this.state.Order.Id}?VisitId=${Appointment.Id}&PatientId=${Appointment.patient.Id}&ClinicId=${Appointment.Clinic.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.update();
            this.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Error.Delete.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Error.Delete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });

    }

    update = () => {
        this.props.fetch(this.props.Appointment.Id, this.props.Appointment.patient?.Id);
    }

    renderTableRow = () => {
        const { t, labOrders, showCreate } = this.props;
        if (labOrders && labOrders.labOrders && labOrders.labOrders.length > 0) {
            return labOrders.labOrders.map((item, index) => {
                return <tr key={`lab-order-${index}-${item.Id}`}>
                    <td>
                        <span title={t(`OrdersStatus.${item.Status.Name}`)} className={`me-1`}>
                            <i className={`fa-solid text-${item.Status.Color} fa-circle `}></i>
                        </span>
                        <span title={item.CreationDate}>
                            {moment(item.CreationDate, "DD/MM/YYYY HH:mm").format("HH:mm")}
                        </span>
                    </td>
                    <td title={item.LabCenter.Name}>
                        {item.LabCenter.HealthCenter.Name}
                    </td>
                    <td>
                        <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-regular fa-gears"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t("Table.Options.Title")}
                                    </span>
                                </button>
                                <div className="dropdown-menu ">
                                    <Link to="#"
                                        onClick={() => this.showInfo(item)}
                                        className="dropdown-item"  >
                                        <i className="fa-regular fa-info text-dark me-1"></i>
                                        {t("Table.Options.Info")}
                                    </Link>
                                    {
                                        item.Status.Id === 1 && showCreate ?
                                            <>
                                                <Link to="#"
                                                    className="dropdown-item"
                                                    onClick={() => this.showEdit(item)}>
                                                    <i className="fa-regular fa-edit text-dark me-1"></i>
                                                    {t("Table.Options.Edit")}
                                                </Link>
                                                <Link to="#"
                                                    className="dropdown-item"
                                                    onClick={() => this.showDelete(item)}>
                                                    <i className="fa-regular fa-trash text-dark me-1"></i>
                                                    {t("Table.Options.Delete")}
                                                </Link>
                                            </> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                </tr >;
            });
        }
        return <tr><td className="text-center" colSpan={4}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, labOrders, showCreate } = this.props;

        let isLoaded = false;
        if (labOrders && labOrders.labOrders) {
            isLoaded = labOrders.isLoaded;
        }
        return <>
            {
                this.state.showCreateNew ?
                    <NewOrder
                        {...this.props}
                        update={this.update}
                        handleClose={this.handleClose}
                    />
                    : ""
            }
            {
                this.state.showEdit && this.state.Order ?
                    <NewOrder
                        {...this.props}
                        isEdit
                        Order={this.state.Order}
                        update={this.update}
                        handleClose={this.handleClose}
                    />
                    : ""
            }
            {
                this.state.showInfo && this.state.Order ?
                    <Info
                        handleClose={this.handleClose}
                        Order={this.state.Order}
                        patient={this.props.Appointment.patient}
                    />
                    : ""
            }
            {
                this.state.showDelete && this.state.Order ?
                    <Delete
                        Delete={this.Delete}
                        handleClose={this.handleClose}
                        Order={this.state.Order}
                    />
                    : ""
            }
            {
                showCreate ? <div className="d-flex w-100">
                    <Link
                        to="#"
                        onClick={this.showCreateNew}
                        title={t("ClinicScreen.Orders.New")}
                        className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                        <i className="fa-regular fa-plus "></i>
                        <span className="d-md-block d-none text-nowrap ms-1">
                            {t("ClinicScreen.Orders.New")}
                        </span>
                    </Link>
                </div> : ""
            }
            <div className="position-relative h-100">
                <div className={`table-responsive-md h-100 ${isLoaded ? "" : "table-loading"}`} style={{
                    minHeight: '300px'
                }}>
                    <div className="table-loading-message">
                        <i className="fa-solid fa-spinner-third fa-spin  me-3"></i>
                        {t("Processing.Title")}
                    </div>
                    <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                        <thead>
                            <tr>
                                <th title={t("ClinicScreen.Orders.Time")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-clock me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("ClinicScreen.Orders.Time")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("HealthCenter.Lab")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-microscope me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("HealthCenter.Lab")}
                                        </span>
                                    </span>
                                </th>
                                <th title={t("Table.Options.Title")}>
                                    <span className="d-flex align-items-center">
                                        <i className="fa-regular text-dark fa-gears me-1"></i>
                                        <span className="d-md-block d-none text-nowrap">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        labOrders: state.labOrders
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(LabOrders));