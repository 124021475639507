import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import { fetchPatientLabs as fetch } from "./actions";
import { fetchLabExamTypes } from "../../ClinicScreen/LabOrders/actions";

class Labs extends React.Component {

    state = { ExamTypes: [], SearchByName: "", lab: null };

    componentDidMount() {
        const { patientLabs, labExamTypes } = this.props;
        if (!patientLabs || !patientLabs.patientLabs || patientLabs.patientLabs?.length === 0 || patientLabs.PatientId !== this.props.patient.Id) {
            this.props.fetch(this.props.patient.Id);
        }
        if (!labExamTypes || !labExamTypes.labExamTypes || labExamTypes.labExamTypes?.length === 0) {
            this.props.fetchLabExamTypes();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.labExamTypes && props.labExamTypes.labExamTypes) {
            state.ExamTypes = props.labExamTypes.labExamTypes.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        return state;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    showInfo = lab => {
        this.setState({
            showInfo: true,
            lab
        });
    }

    handleClose = () => {
        this.setState({
            showInfo: false,
            lab: null
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setSearchExamTypes = SearchExamTypes => {
        this.setState({
            SearchExamTypes
        });
    }

    renderTableRow = () => {
        const { t, patientLabs } = this.props;
        if (patientLabs && patientLabs.patientLabs && patientLabs.patientLabs.length > 0) {
            let searchResult = patientLabs.patientLabs.filter(p => p.Exam?.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()));

            if (this.state.SearchExamTypes) {
                searchResult = searchResult.filter(p => this.state.SearchExamTypes.value === p.Exam.Type.Id);
            }

            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`patient-lab-${index}-${item.Id}`}>
                        <td>
                            <span title={item.CreationDate}>
                                {moment(item.CreationDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")}
                            </span>
                        </td>
                        <td>
                            {item.Exam.Type.Name}
                        </td>
                        <td>
                            {item.Exam.Profile?.Name}
                        </td>
                        <td>
                            {item.Exam.Name}
                        </td>
                        <td>
                            {item.Exam.result?.Result}
                        </td>
                        <td>
                            {item.Exam.result?.Range}
                        </td>
                        <td>
                            <div className="dropdown" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm btn-sm-icon d-flex align-items-center" title={t("Table.Options.Title")} data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-regular fa-gears"></i>
                                        <span className="d-md-block d-none text-nowrap ms-1">
                                            {t("Table.Options.Title")}
                                        </span>
                                    </button>
                                    <div className="dropdown-menu ">
                                        <Link to="#"
                                            onClick={() => this.showInfo(item)}
                                            className="dropdown-item"  >
                                            <i className="fa-regular fa-info text-dark me-1"></i>
                                            {t("Table.Options.Info")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr >;
                });
            }
            return <tr><td className="text-center" colSpan={3}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={3}>{t("NoRows")}</td></tr>;
    }

    render() {
        const { t, patientLabs, labExamTypes } = this.props;

        let isLoaded = false;
        if (patientLabs) {
            isLoaded = patientLabs.isLoaded;
        }

        let isLoadedExamTypes = false;
        if (labExamTypes) {
            isLoadedExamTypes = labExamTypes.isLoaded;
        }


        return <>
            {
                this.state.showInfo ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                                {t("Table.Options.Info")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-vial me-2"></i>
                                            {t("ClinicScreen.Orders.Lab.Table.Name")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.Exam.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-flask-vial me-2"></i>
                                            {t("ClinicScreen.Orders.Lab.Table.Type")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.Exam.Type?.Name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-hospital me-2"></i>
                                            {t("HealthCenter.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.LabCenter.HealthCenter.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-microscope me-2"></i>
                                            {t("HealthCenter.Lab")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.LabCenter.Name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-user me-1"></i>
                                            {t("LastUpdatedUser")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.User?.Name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("CreationDate")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.CreationDate}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("LastUpdatedDate")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.lab.UpdateDate}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.lab.Exam.result ? <>
                                    <div className="separator separator-dashed border-dark my-5"></div>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <span className="fw-bold text-muted mb-2">
                                                    <i className="fa-regular text-dark fa-vial-virus me-2"></i>
                                                    {t("Lab.Results.Table.Result")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.state.lab.Exam.result.Result}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <span className="fw-bold text-muted mb-2">
                                                    <i className="fa-regular text-dark fa-tags me-2"></i>
                                                    {t("Lab.Results.Table.Range.Title")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.state.lab.Exam.result.Range}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <span className="fw-bold text-muted mb-2">
                                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                                    {t("LastUpdatedUser")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.state.lab.Exam.result.User?.Name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <span className="fw-bold text-muted mb-2">
                                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                    {t("Lab.SpecimenTaken.Date")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.state.lab.SpecimenTakenDate}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <span className="fw-bold text-muted mb-2">
                                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                    {t("CreationDate")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.state.lab.Exam.result.CreationDate}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <span className="fw-bold text-muted mb-2">
                                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                    {t("LastUpdatedDate")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.state.lab.Exam.result.UpdateDate}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </> : ""
                            }

                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <div className="card">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-microscope me-2 text-info mb-0"></i>
                            {t('MedicalRecords.Nav.Lab')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">

                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                                <input
                                    value={this.state.SearchByName}
                                    onChange={e => this.setSearch(e.target.value)}
                                    ref={SearchByName => this.SearchByName = SearchByName}
                                    name="SearchByName"
                                    id="SearchByName"
                                    autoComplete={"off"}
                                    type={"text"}
                                    placeholder={t('AdvancedSearch.Search.ByName')}
                                    title={t('AdvancedSearch.Search.ByName')}
                                    className="form-control form-control-sm" />
                            </div>
                            <div className="d-flex align-items-center">
                                <Select
                                    onChange={this.setSearchExamTypes}
                                    isClearable={true}
                                    name="ExamTypes "
                                    id="ExamTypes "
                                    placeholder={t('AdvancedSearch.Search.ByExamTypes')}
                                    title={t('AdvancedSearch.Search.ByExamTypes')}
                                    isRtl={true}
                                    isLoading={!isLoadedExamTypes}
                                    isSearchable={true}
                                    ref={ExamTypes => this.ExamTypes = ExamTypes}
                                    options={this.state.ExamTypes}
                                    filterOption={this.filterOptions}
                                />

                            </div>
                        </div>

                        <div className="position-relative">
                            <div className={`table-responsive-md ${isLoaded ? "" : "table-loading"}`} style={{
                                minHeight: '300px'
                            }}>
                                <div className="table-loading-message">
                                    {t("Processing.Title")}
                                </div>
                                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                    <thead>
                                        <tr className="fw-bold fs-6 text-gray-800">
                                            <th title={t("Date")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-calendar me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Date")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("ClinicScreen.Orders.Lab.Table.Type")} >
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-flask-vial me-md-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("ClinicScreen.Orders.Lab.Table.Type")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("ClinicScreen.Orders.Lab.Table.Profile")} >
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-cubes me-md-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("ClinicScreen.Orders.Lab.Table.Profile")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("ClinicScreen.Orders.Lab.Table.Name")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-vial me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("ClinicScreen.Orders.Lab.Table.Name")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Lab.Results.Table.Result")} >
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-vial-virus me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Lab.Results.Table.Result")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Lab.Results.Table.Range.Title")} >
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-tags me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Lab.Results.Table.Range.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Table.Options.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-gears me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Table.Options.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableRow()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }

}

const mapStateToProps = state => {
    return {
        patientLabs: state.patientLabs,
        labExamTypes: state.labExamTypes
    };
};

export default connect(mapStateToProps, { fetch, fetchLabExamTypes })(withTranslation('common')(Labs));