import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';

class Card extends React.Component {

    getGestationalAge = LMP => {
        let result = "";
        if (LMP) {
            const { t } = this.props;
            const weeks = moment().diff(moment(LMP, "DD/MM/YYYY"), 'weeks', false);
            const days = moment().diff(moment(LMP, "DD/MM/YYYY"), 'days', false);
            if (weeks > 0) {
                result += weeks + " " + t("Age.Week") + " ";
                if (days % 7) {
                    result += days % 7 + " " + t("Age.Day");
                }
                result += " (" + days + " " + t("Age.Day") + ")";
            } else {
                result += days + " " + t("Age.Day");
            }
        }
        return result;
    }

    getEstimatedDate = LMP => {
        let result = "";
        if (LMP) {
            result = moment(LMP, "DD/MM/YYYY").add(7, 'days').add(-3, 'months').add(1, 'years').format('DD/MM/YYYY');
        }
        return result;
    }

    render() {
        const { t, gynecologyInfo, moreInfo } = this.props;
        if (gynecologyInfo) {
            return (<>
                <div className="w-100">
                    <div className={`row mb-${moreInfo ? 5 : 0}`}>
                        {
                            gynecologyInfo.LMP ? <div className="col-md-6">
                                <div className="row mb-5">
                                    <span className="fw-bold text-muted mb-2">
                                        <i className="fa-solid fa-calendar text-dark me-2"></i>
                                        {t("GynecologyInfoes.Forms.LMP.Title")}
                                    </span>
                                    <span className="ms-10">
                                        {gynecologyInfo.LMP}
                                    </span>
                                </div>
                            </div> : ""
                        }
                        {
                            gynecologyInfo.IsPregnant === true || gynecologyInfo.IsPregnant === false ? <>
                                <div className="col-md-6">
                                    <div className="row mb-5">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-solid fa-person-pregnant text-dark me-2"></i>
                                            {t("GynecologyInfoes.Forms.IsPregnant.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo.IsPregnant ? t("GynecologyInfoes.Forms.IsPregnant.Options.Yes") : t("GynecologyInfoes.Forms.IsPregnant.Options.No")}
                                        </span>
                                    </div>
                                </div>
                                {
                                    gynecologyInfo.LMP ? <>
                                        <div className="col-md-6">
                                            <div className="row mb-5">
                                                <span className="fw-bold text-muted mb-2">
                                                    {t("GynecologyInfoes.GestationalAge")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.getGestationalAge(gynecologyInfo.LMP)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-5">
                                                <span className="fw-bold text-muted mb-2">
                                                    {t("GynecologyInfoes.EstimatedDate")}
                                                </span>
                                                <span className="ms-10">
                                                    {this.getEstimatedDate(gynecologyInfo.LMP)}
                                                </span>
                                            </div>
                                        </div>
                                    </> : ""
                                }

                            </> : ""

                        }

                        <div className="col-md-6">
                            <div className="row mb-5">
                                <span className="fw-bold text-muted mb-2">
                                    {t("GynecologyInfoes.Forms.Gravida.Title")}
                                </span>
                                <span className="ms-10">
                                    {gynecologyInfo.Gravida}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mb-5">
                                <span className="fw-bold text-muted mb-2">
                                    {t("GynecologyInfoes.Forms.Para.Title")}
                                </span>
                                <span className="ms-10">
                                    {gynecologyInfo.Para}
                                </span>
                            </div>
                        </div>
                        {
                            gynecologyInfo.Abortion > 0 ? <>
                                <div className="col-md-6">
                                    <div className="row mb-5">
                                        <span className="fw-bold text-muted mb-2">
                                            {t("GynecologyInfoes.Forms.Abortion.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo.Abortion}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row mb-5">
                                        <span className="fw-bold text-muted mb-2">
                                            {t("GynecologyInfoes.Forms.Ectopic.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo.Ectopic}
                                        </span>
                                    </div>
                                </div>
                            </> : ""
                        }
                    </div>
                    {
                        moreInfo ? <>
                            <div className="separator separator-dashed border-dark mb-5"></div>
                            <div className="row">
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-user me-1"></i>
                                            {t("LastUpdatedUser")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo?.User?.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-hospital me-1"></i>
                                            {t("HealthCenter.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo?.User?.HealthCenter.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("CreationDate")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo?.CreationDate}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("LastUpdatedDate")}
                                        </span>
                                        <span className="ms-10">
                                            {gynecologyInfo?.UpdateDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </> : ""
                    }
                </div>
            </>);
        }
        return <div className="w-100">
            <div className="alert alert-dismissible bg-light-info border-3 border-info d-flex flex-column flex-sm-row p-5 mb-10">
                <i className="fs-2hx text-info me-4 mb-0 fa-regular fa-person-pregnant"></i>
                <div className="d-flex flex-column pe-0 pe-sm-10 w-100">
                    <h5 className="mb-1">{t("GynecologyInfoes.Title")}</h5>
                    <span>{t("GynecologyInfoes.Alert")}.</span>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <i className="ki-duotone ki-cross fs-1 text-info fa-regular fa-xmark"></i>
                </button>
            </div>
        </div>;
    }
}


export default withTranslation('common')(Card);