import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { toast } from "react-toastify";

import PatientName from "../../Patients/PatientName";
import { fetchLabExamResults, fetchLabSpeciments } from "./actions";
import Table from "./Table";
import PatientsCard from "../../Patients/PatientsCard";
import serverAPI from "../../../api/serverAPI";
import Print from "./PrintResult";

class LabExamsResult extends React.Component {

    state = {
        Order: null,
        SearchByName: '',
        isSubmit: false,
        showPrint: false,
        startDate: moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    };

    showPrint = () => {
        this.setState({
            showPrint: true
        });
    }

    hidePrint = () => {
        this.setState({
            showPrint: false
        });
    }

    showApprovedAll = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('Processing.ApprovedAllLab')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-dark " onClick={() => { toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('Undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-light-success" onClick={() => { this.Approved(); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-check"></i> {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.WARNING,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }

    Approved = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        serverAPI.post(`/ApprovedLabExamResult/${this.state.Order.Id}`).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Save.Approved.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Approved.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.fetchLabExamResults(this.state.Order.Id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    fetchLabSpeciments = () => {
        this.props.fetchLabSpeciments(this.state.startDate, this.state.endDate);
    }

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "on");
        document.getElementById("kt_content_inner").classList.add("w-100");
        document.getElementById("kt_content_inner").classList.add("mx-5");
        document.getElementById("kt_content_inner").classList.remove("container-xxl");

        this.fetchLabSpeciments();
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('data-kt-aside-minimize', "off");
        document.getElementById("kt_content_inner").classList.add("container-xxl");
        document.getElementById("kt_content_inner").classList.remove("mx-5");
        document.getElementById("kt_content_inner").classList.remove("w-100");
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    selectOrder = Order => {
        this.setState({
            Order
        }, () => {
        });
    }

    filterPatientByName = (arrayToSearch, key) => {
        let searchResult = [];
        const subResult = arrayToSearch.filter(p =>
            p.patient.Name.FirstName.includes(key) ||
            p.patient.Name.SecondName.includes(key) ||
            p.patient.Name.ThirdName.includes(key) ||
            p.patient.Name.LastName.includes(key)
        );
        subResult.forEach(result => {
            if (!searchResult.some(p => p.Id === result.Id)) {
                searchResult.push(result);
            }
        });
        return searchResult;
    }

    renderTableRow = () => {
        const { t, labSpeciments } = this.props;
        if (labSpeciments && labSpeciments.labSpeciments && labSpeciments.labSpeciments.length > 0) {
            const searchArray = this.state.SearchByName.split(' ');

            let searchResult = labSpeciments.labSpeciments;
            searchArray.forEach(item => {
                searchResult = this.filterPatientByName(searchResult, item);
            });

            if (searchResult.length > 0) {
                return searchResult.map((item, index) => {
                    return <tr key={`lab-result-screen-${index}-${item.Id}`}>
                        <td title={t(`OrdersStatus.${item.Status?.Name}`)}>
                            <span className={`badge badge-square badge-${item.Status?.Color} badge-sm me-1 p-2`}> {index + 1}</span>
                        </td>
                        <td>
                            <Link to="#" onClick={() => this.selectOrder(item)} className="text-nowrap">
                                <PatientName patient={item.patient} />
                            </Link>
                        </td>
                        <td title={t("Lab.SpecimenTaken.Orders.Date")}>
                            {item.CreationDate}
                        </td>
                    </tr >;
                });
            }
            return <tr><td className="text-center" colSpan={3}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={3}>{t("NoRows")}</td></tr>;
    }

    doNothing = () => { }

    render() {
        const { t, labSpeciments } = this.props;

        let isLoaded = false;
        if (labSpeciments) {
            isLoaded = labSpeciments.isLoaded;
        }

        return <>
            {this.state.showPrint && this.state.Order ? <Print Order={this.state.Order} labExamResults={this.props.labExamResults} handleClose={this.hidePrint} /> : ""}
            <Helmet>
                <title>{t("Lab.Results.Title")} | {t('SiteName')}</title>
            </Helmet>
            <div className={`row g-6 g-xl-9 mb-6 mb-xl-9 mt-0 `}>
                <div className="col-lg-4 col-xxl-3">
                    <div className="card h-100">
                        <div className="card-header align-items-center">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="fw-bolder text-dark">
                                    <i className="h3 fa-solid  fa-magnifying-glass text-info me-1"></i>
                                    {t("Lab.Results.Specimen.Title")}
                                </span>
                            </h3>

                        </div>
                        <div className="card-body p-3 pt-5">
                            {
                                this.state.Order ?
                                    <div className="card-body pt-5">
                                        <Link
                                            to="#"
                                            title={t('Lab.Results.Specimen.ChangeOrder')}
                                            className="btn btn-sm btn-block btn-light-danger mb-4 w-100"
                                            onClick={() => this.selectOrder()}>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <i className="fa-duotone fa-ban me-2"></i>
                                                {t('Lab.Results.Specimen.ChangeOrder')}
                                            </div>
                                        </Link>
                                        <PatientsCard patient={this.state.Order.patient} />
                                        <div className="separator separator-dashed border-dark mb-5"></div>
                                        <div className="row mb-5">
                                            <div className={`col-md-12`}>
                                                <div className="row">
                                                    <span className="fw-bold text-muted mb-2">
                                                        <i className="fa-regular text-dark fa-hashtag me-2"></i>
                                                        {t("Lab.Results.Specimen.Number")}
                                                    </span>
                                                    <span className={`ms-10`}>
                                                        <span className="badge badge-light-info mx-2 text-nowrap"> <i className="fa-regular fa-hashtag me-1 text-info"></i>{this.state.Order.Id}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.Order.Note ?
                                                <div className="row mb-5">
                                                    <div className={`col-md-12`}>
                                                        <div className="row">
                                                            <span className="fw-bold text-muted mb-2">
                                                                <i className="fa-regular text-dark fa-note me-2"></i>
                                                                {t("ClinicScreen.Orders.Forms.Note.Title")}
                                                            </span>
                                                            <span className={`ms-10`}>
                                                                {this.state.Order.Note}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                        }
                                        <div className="row mb-5">
                                            <div className={`col-md-6`}>
                                                <div className="row">
                                                    <span className="fw-bold text-muted mb-2">
                                                        <i className="fa-regular text-dark fa-hospital me-2"></i>
                                                        {t("HealthCenter.Title")}
                                                    </span>
                                                    <span className={` ms-10`} >
                                                        {this.state.Order.LabCenter.HealthCenter.Name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={`col-md-6`}>
                                                <div className="row">
                                                    <span className="fw-bold text-muted mb-2">
                                                        <i className="fa-regular text-dark fa-microscope me-2"></i>
                                                        {t("HealthCenter.Lab")}
                                                    </span>
                                                    <span className={` ms-10`}>
                                                        {this.state.Order.LabCenter.Name}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <span className="fw-bold text-muted mb-2">
                                                        <i className="fa-regular text-dark fa-user me-1"></i>
                                                        {t("LastUpdatedUser")}
                                                    </span>
                                                    <span className="ms-10">
                                                        {this.state.Order.User.Name}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <span className="fw-bold text-muted mb-2">
                                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                        {t("CreationDate")}
                                                    </span>
                                                    <span className="ms-10">
                                                        {this.state.Order.CreationDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <span className="fw-bold text-muted mb-2">
                                                        <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                                        {t("LastUpdatedDate")}
                                                    </span>
                                                    <span className="ms-10">
                                                        {this.state.Order.UpdateDate}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={`table-responsive-md w-100 h-100 ${isLoaded ? "" : "table-loading"}`}  >
                                        <div className="d-flex flex-wrap justify-content-between align-items-center  w-100">

                                            <div className="d-flex align-items-center mb-2 w-100">
                                                <DateRangePicker
                                                    ref={SelectDate => this.SelectDate = SelectDate}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        autoUpdateInput: true,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        format: 'DD/MM/YYYY',
                                                        timePicker: false,
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            firstDay: 6,
                                                            applyLabel: t('Apply'),
                                                            customRangeLabel: t('DateRangePicker.Range.customRange'),
                                                            monthNames: [
                                                                `${t("DateRangePicker.Months.January")}`,
                                                                `${t("DateRangePicker.Months.February")}`,
                                                                `${t("DateRangePicker.Months.March")}`,
                                                                `${t("DateRangePicker.Months.April")}`,
                                                                `${t("DateRangePicker.Months.May")}`,
                                                                `${t("DateRangePicker.Months.June")}`,
                                                                `${t("DateRangePicker.Months.July")}`,
                                                                `${t("DateRangePicker.Months.August")}`,
                                                                `${t("DateRangePicker.Months.September")}`,
                                                                `${t("DateRangePicker.Months.October")}`,
                                                                `${t("DateRangePicker.Months.November")}`,
                                                                `${t("DateRangePicker.Months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("DateRangePicker.DaysOfWeek.Sunday"),
                                                                t("DateRangePicker.DaysOfWeek.Monday"),
                                                                t("DateRangePicker.DaysOfWeek.Tuesday"),
                                                                t("DateRangePicker.DaysOfWeek.Wednesday"),
                                                                t("DateRangePicker.DaysOfWeek.Thursday"),
                                                                t("DateRangePicker.DaysOfWeek.Friday"),
                                                                t("DateRangePicker.DaysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        ref={Date => this.Date = Date}
                                                        name="Date"
                                                        id="Date"
                                                        value={`${this.state.startDate} - ${this.state.endDate}`}
                                                        autoComplete="off"
                                                        required
                                                        onChange={() => this.doNothing()}
                                                        className="form-control form-control-solid ps-10"
                                                        type="text"
                                                        placeholder={t('Lab.SpecimenTaken.Orders.Date')}
                                                        title={t("Lab.SpecimenTaken.Orders.Date")}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                            <Link
                                                to="#"
                                                title={t('Refresh')}
                                                className="btn btn-sm btn-block btn-light-primary mb-4 w-100"
                                                onClick={() => this.fetchLabSpeciments()}>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <i className="fa-duotone fa-rotate me-2"></i>
                                                    {t('Refresh')}
                                                </div>
                                            </Link>
                                            <div className="d-flex align-items-center mb-2 w-100">
                                                <input
                                                    value={this.state.SearchByName}
                                                    onChange={e => this.setSearch(e.target.value)}
                                                    ref={SearchByName => this.SearchByName = SearchByName}
                                                    name="SearchByName"
                                                    id="SearchByName"
                                                    autoComplete={"off"}
                                                    type={"text"}
                                                    placeholder={t('AdvancedSearch.Search.ByName')}
                                                    title={t('AdvancedSearch.Search.ByName')}
                                                    className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="table-loading-message">
                                            {t("Processing.Title")}
                                        </div>
                                        <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head">
                                            <thead className="border-bottom border-gray-200 fs-6 fw-bold ">
                                                <tr>
                                                    <th title={t("Table.Number")}>
                                                        <i className="fa-regular text-dark fa-hashtag"></i>
                                                    </th>
                                                    <th title={t("Patient.Name")}>
                                                        <span className="d-flex align-items-center">
                                                            <i className="fa-regular text-dark fa-user-injured me-1"></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("Patient.Name")}
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th title={t("Lab.SpecimenTaken.Orders.Date")} >
                                                        <span className="d-flex align-items-center">
                                                            <i className="fa-regular text-dark fa-clock me-1"></i>
                                                            <span className="d-md-block d-none text-nowrap">
                                                                {t("Lab.SpecimenTaken.Orders.Date")}
                                                            </span>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="fs-6 fw-semibold text-gray-600">
                                                {this.renderTableRow()}
                                            </tbody>
                                        </table>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-xxl-9">
                    {
                        this.state.Order ?
                            <div className="card h-100">
                                <div className="card-header align-items-center">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="fw-bolder text-dark">
                                            <i className="h3 fa-solid fa-flask-round-potion text-info me-1"></i>
                                            {t("Lab.Results.Title")}
                                        </span>
                                    </h3>
                                    <div className="card-toolbar">
                                        {
                                            this.state.Order.Status.Name !== "Done" ?
                                                <Link
                                                    to="#"
                                                    onClick={this.showApprovedAll}
                                                    title={t(`Table.Options.Approved.All`)}
                                                    className="btn btn-sm btn-sm-icon btn-light-success ms-auto d-flex align-items-center me-1">
                                                    <i className="fa-regular fa-check"></i>
                                                    <span className="d-md-block d-none text-nowrap ms-1">
                                                        {t(`Table.Options.Approved.All`)}
                                                    </span>
                                                </Link>
                                                : ""
                                        }

                                        <Link
                                            to="#"
                                            onClick={this.showPrint}
                                            title={t(`Print`)}
                                            className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center">
                                            <i className="fa-regular fa-print"></i>
                                            <span className="d-md-block d-none text-nowrap ms-1">
                                                {t(`Print`)}
                                            </span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-body p-3 pt-5">
                                    <Table Order={this.state.Order} />
                                </div>
                            </div> : ""
                    }
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        labExamResults: state.labExamResults,
        labSpeciments: state.labSpeciments,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetchLabSpeciments, fetchLabExamResults })(withTranslation('common')(LabExamsResult));