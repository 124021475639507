import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";

import PatientName from "../../../Patients/PatientName";
import CheckInfo from "../../Checks/Info";
import LifeCycle from "../../Checks/CheckLifeCycle";

class Info extends React.Component {

    state = { showInfo: false, check: null, showLifeCycle: false };

    showLifeCycle = check => {
        this.setState({
            showLifeCycle: true,
            check
        });
    }

    showInfo = check => {
        this.setState({
            showInfo: true,
            check
        });
    }

    handleClose = () => {
        this.setState({
            showInfo: false,
            showLifeCycle: false,
            check: null
        });
    }

    render() {
        const { t, Voucher } = this.props;

        if (this.state.showInfo && this.state.check) {
            return <CheckInfo Check={this.state.check} handleClose={this.handleClose} />;
        }

        if (this.state.showLifeCycle && this.state.check) {
            return <LifeCycle Check={this.state.check} handleClose={this.handleClose} />;
        }

        return <>
            <Modal
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                        {t("Table.Options.Info")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=" mb-5" title={t("NumberAuto")}>
                        <span className="badge badge-square badge-secondary badge-sm me-1"> <i className="fa-regular text-dark fa-hashtag me-1"></i>{Voucher.Id}</span>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <span className="d-flex align-items-center text-muted">
                                <i className="fa-regular text-dark fa-file-user me-1"></i>
                                <span className="d-md-block d-none text-nowrap">
                                    {t("Accounting.ReceiptVoucher.Forms.FromAccount.Title")}
                                </span>
                            </span>
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="d-flex align-items-center ms-10">
                                    <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                        {
                                            Voucher.Account.patient ?
                                                <PatientName patient={Voucher.Account.patient} />
                                                :
                                                <span>
                                                    {Voucher.Account.Number + " " + Voucher.Account.Name}
                                                </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            Voucher.Number ?
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-tally me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.Number.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.Number}
                                        </span>
                                    </div>
                                </div>
                                : ""
                        }
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar me-2"></i>
                                    {t("Accounting.ReceiptVoucher.Forms.Date.Title")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.Date}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-sack-dollar me-2"></i>
                                    {t("Accounting.ReceiptVoucher.Forms.Value.Title")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.Value}
                                    <span className="ms-1">
                                        {Voucher.Currency?.Name}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-percent me-2"></i>
                                    {t("Currencies.Forms.Rate.Title")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.Rate}
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                        Voucher.CashValue > 0 ?
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-money-bills me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.CashValue.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.CashValue}
                                            <span className="ms-1">
                                                {Voucher.Currency?.Name}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-money-check-dollar me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.BoxAccount.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.BoxAccount?.Number + " " + Voucher.BoxAccount?.Name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                    {
                        Voucher.CheckValue > 0 ? <>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-money-check me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.CheckValue.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.CheckValue}
                                            <span className="ms-1">
                                                {Voucher.Currency?.Name}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-tally me-2"></i>
                                            {t("Accounting.Check.Count")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.items.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`table-responsive-lg`}>
                                <table className="table table-sm table-row-dashed table-striped table-row-gray-600 table-rounded table-hover table-fix-head mb-5">
                                    <thead>
                                        <tr>
                                            <th title={t("NumberAuto")}>
                                                <i className="fa-regular text-dark fa-tally "></i>
                                            </th>
                                            <th title={t("Accounting.Check.Forms.Number.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-hashtag me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Accounting.Check.Forms.Number.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Banks.Forms.Select.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-landmark me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Banks.Forms.Select.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Accounting.Check.Forms.Value.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-coin me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Accounting.Check.Forms.Value.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Accounting.Check.Forms.Date.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-calendar-days me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Accounting.Check.Forms.Date.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                            <th title={t("Table.Options.Title")}>
                                                <span className="d-flex align-items-center">
                                                    <i className="fa-regular text-dark fa-gears me-1"></i>
                                                    <span className="d-md-block d-none text-nowrap">
                                                        {t("Table.Options.Title")}
                                                    </span>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Voucher.items.map((check, index) => {
                                                return <tr key={`check_info_${check.Id}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{check.Number}</td>
                                                    <td>{check.Bank.Name}</td>
                                                    <td>
                                                        {check.Value}
                                                        <span className="ms-1">
                                                            {check.Currency?.Name}
                                                        </span>
                                                    </td>
                                                    <td>{check.Date}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-sm btn-icon btn-info me-1" title={t('Table.Options.Info')} onClick={() => this.showInfo(check)}>
                                                            <i className="fa-solid fa-info"></i>
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-icon btn-dark" title={t('Accounting.Check.LifeCycle.Title')} onClick={() => this.showLifeCycle(check)}>
                                                            <i className="fa-solid fa-rectangle-history"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </> : ""
                    }
                    {
                        Voucher.CardValue > 0 ?
                            <div className="row mb-5">
                                <div className="col-md-4">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-credit-card me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.CardValue.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.CardValue}
                                            <span className="ms-1">
                                                {Voucher.Currency?.Name}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-badge-dollar me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.CreditCardCommission.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {
                                                Voucher.CreditCardStatusIsCommit ? "" : ""
                                            }
                                            {Voucher.CreditCardCommission}
                                            <span className="ms-1">
                                                {Voucher.Currency?.Name}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-hashtag me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.ReferenceNumber.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.ReferenceNumber}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }

                    {
                        Voucher.Note ?
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-notes me-2"></i>
                                            {t("Accounting.ReceiptVoucher.Forms.Note.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {Voucher.Note}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }

                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-user me-1"></i>
                                    {t("LastUpdatedUser")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.User.Name}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("CreationDate")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.CreationDate}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <span className="fw-bold text-muted mb-2">
                                    <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                    {t("LastUpdatedDate")}
                                </span>
                                <span className="ms-10">
                                    {Voucher.UpdateDate}
                                </span>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(Info));