import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import serverAPI from "../../../api/serverAPI";

class Form extends React.Component {

    state = { isInitialize: false, isSubmit: false, IsPregnant: null, LMP: "" };

    static getDerivedStateFromProps(props, state) {
        if (!state.isInitialize && props.gynecologyInfo) {
            state.isInitialize = true;
            props.initialize({
                Gravida: props.gynecologyInfo.Gravida,
                Para: props.gynecologyInfo.Para,
                Abortion: props.gynecologyInfo.Abortion,
                Ectopic: props.gynecologyInfo.Ectopic
            });
        }
        return state;
    }


    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        setp,
        min,
        max,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} setp={setp} min={min} max={max} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    componentDidMount() {
        this.clearDate();
    }

    clearDate = () => {
        window.setNull("#LMP");
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            LMP: picker.startDate.format('DD/MM/YYYY')
        });
    }

    setIsPregnant = IsPregnant => {
        this.setState({
            IsPregnant
        });
    }

    onSubmit = () => {
        const { t, Appointment } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/GynecologyInfoes/${Appointment.patient.Id}`, {
            ...this.props.formValues,
            LMP: this.state.LMP,
            VisitId: Appointment.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });

        return false;
    }

    doNothing = () => { }

    render() {
        const { t, pristine, submitting, AbortionCount } = this.props;

        return <>

            <form method="POST" name="formGynecologyInfoes" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" >
                <div className="row mb-5">

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="LMP"><i className="fa-regular text-dark fa-calendar me-2"></i>{t('GynecologyInfoes.Forms.LMP.Title')}</label>
                        <DateRangePicker
                            ref={LMP => this.LMP = LMP}
                            initialSettings={{
                                singleDatePicker: true,
                                opens: 'center',
                                autoUpdateInput: true,
                                buttonClasses: 'btn',
                                cancelClass: "btn-danger",
                                applyButtonClasses: "btn-primary",
                                showDropdowns: true,
                                format: 'DD/MM/YYYY',
                                timePicker: false,
                                maxDate: `${moment().format('DD/MM/YYYY')}`,
                                locale: {
                                    format: 'DD/MM/YYYY',
                                    cancelLabel: t('Close'),
                                    firstDay: 6,
                                    applyLabel: t('Apply'),
                                    customRangeLabel: t('DateRangePicker.Range.customRange'),
                                    monthNames: [
                                        `${t("DateRangePicker.Months.January")}`,
                                        `${t("DateRangePicker.Months.February")}`,
                                        `${t("DateRangePicker.Months.March")}`,
                                        `${t("DateRangePicker.Months.April")}`,
                                        `${t("DateRangePicker.Months.May")}`,
                                        `${t("DateRangePicker.Months.June")}`,
                                        `${t("DateRangePicker.Months.July")}`,
                                        `${t("DateRangePicker.Months.August")}`,
                                        `${t("DateRangePicker.Months.September")}`,
                                        `${t("DateRangePicker.Months.October")}`,
                                        `${t("DateRangePicker.Months.November")}`,
                                        `${t("DateRangePicker.Months.December")}`
                                    ],
                                    daysOfWeek: [
                                        t("DateRangePicker.DaysOfWeek.Sunday"),
                                        t("DateRangePicker.DaysOfWeek.Monday"),
                                        t("DateRangePicker.DaysOfWeek.Tuesday"),
                                        t("DateRangePicker.DaysOfWeek.Wednesday"),
                                        t("DateRangePicker.DaysOfWeek.Thursday"),
                                        t("DateRangePicker.DaysOfWeek.Friday"),
                                        t("DateRangePicker.DaysOfWeek.Saturday")
                                    ],
                                },
                            }}
                            onApply={this.handleApplyDate} >
                            <input
                                onChange={() => this.doNothing()}
                                value={this.state.LMP}
                                pristine="false"
                                ref={LMP => this.LMP = LMP}
                                name="LMP"
                                id="LMP"
                                autoComplete="off"
                                className="form-control form-control-solid"
                                type="text"
                                placeholder={t('GynecologyInfoes.Forms.LMP.Placeholder')}
                                title={t('GynecologyInfoes.Forms.LMP.Title')}
                            />
                        </DateRangePicker>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="IsPregnant"><i className="fa-regular text-dark me-2 fa-person-pregnant"></i>{t('GynecologyInfoes.Forms.IsPregnant.Title')}</label>
                        <div className="d-flex justify-content-around flex-wrap align-items-center pt-3">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field name="IsPregnant" component={this.renderField} type="radio" value={"Yes"} id="isPregnant-Yes" onChange={() => this.setIsPregnant(true)} className="form-check-input" required checked={this.state.IsPregnant === true} />
                                <label className="form-check-label" htmlFor="isPregnant-Yes">
                                    {t("GynecologyInfoes.Forms.IsPregnant.Options.Yes")}
                                </label>
                            </div>

                            <div className="form-check form-check-custom form-check-solid">
                                <Field name="IsPregnant" component={this.renderField} type="radio" value={"No"} id="isPregnant-No" onChange={() => this.setIsPregnant(false)} className="form-check-input" required checked={this.state.IsPregnant === false} />
                                <label className="form-check-label" htmlFor="isPregnant-No">
                                    {t("GynecologyInfoes.Forms.IsPregnant.Options.No")}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className={`${AbortionCount > 0 ? "col-md-3" : "col-md-4"}`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Gravida">{t('GynecologyInfoes.Forms.Gravida.Title')}</label>
                        <Field
                            ref={Gravida => this.Gravida = Gravida}
                            name="Gravida"
                            id="Gravida"
                            component={this.renderField}
                            type={"number"}
                            setp={1}
                            min={0}
                            placeholder={t('GynecologyInfoes.Forms.Gravida.Placeholder')}
                            title={t('GynecologyInfoes.Forms.Gravida.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`${AbortionCount > 0 ? "col-md-3" : "col-md-4"}`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Para">{t('GynecologyInfoes.Forms.Para.Title')}</label>
                        <Field
                            ref={Para => this.Para = Para}
                            name="Para"
                            id="Para"
                            component={this.renderField}
                            type={"number"}
                            setp={1}
                            min={0}
                            placeholder={t('GynecologyInfoes.Forms.Para.Placeholder')}
                            title={t('GynecologyInfoes.Forms.Para.Title')}
                            className="form-control"
                        />
                    </div>

                    <div className={`${AbortionCount > 0 ? "col-md-3" : "col-md-4"}`}>
                        <label className={`col-form-label fw-bold fs-6`} htmlFor="Abortion">{t('GynecologyInfoes.Forms.Abortion.Title')}</label>
                        <Field
                            ref={Abortion => this.Abortion = Abortion}
                            name="Abortion"
                            id="Abortion"
                            component={this.renderField}
                            type={"number"}
                            setp={1}
                            min={0}
                            placeholder={t('GynecologyInfoes.Forms.Abortion.Placeholder')}
                            title={t('GynecologyInfoes.Forms.Abortion.Title')}
                            className="form-control"
                        />
                    </div>
                    {
                        AbortionCount > 0 ?
                            <div className="col-md-3">
                                <label className={`col-form-label fw-bold fs-6`} htmlFor="Ectopic">{t('GynecologyInfoes.Forms.Ectopic.Title')}</label>
                                <Field
                                    ref={Ectopic => this.Ectopic = Ectopic}
                                    name="Ectopic"
                                    id="Ectopic"
                                    component={this.renderField}
                                    type={"number"}
                                    setp={1}
                                    min={0}
                                    max={AbortionCount}
                                    placeholder={t('GynecologyInfoes.Forms.Ectopic.Placeholder')}
                                    title={t('GynecologyInfoes.Forms.Ectopic.Title')}
                                    className="form-control"
                                />
                            </div>
                            : ""
                    }
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="button" className="btn btn-sm btn-success mx-1" title={t('Save')} disabled={pristine || submitting || this.state.isSubmit} data-kt-indicator={this.state.isSubmit ? "on" : "off"} onClick={() => this.onSubmit()}>
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Save')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>
            </form>
        </>;
    }
}

const validate = values => {
    const errors = {};

    if (values.Ectopic > values.Abortion) {
        errors.Ectopic = 'GynecologyInfoes.Forms.Ectopic.Error';
    }
    return errors;
}

const selector = formValueSelector('formGynecologyInfoes');
const mapStateToProps = state => {
    return {
        formValues: getFormValues('formGynecologyInfoes')(state),
        measurement: state.measurement,
        user: state.user,
        AbortionCount: parseInt(selector(state, 'Abortion'))
    };
};

const formGynecologyInfoes = reduxForm({
    form: 'formGynecologyInfoes',
    persistentSubmitErrors: true,
    touchOnBlur: true,
    validate
});


export default connect(mapStateToProps, {})(withTranslation('common')(formGynecologyInfoes(Form)));
