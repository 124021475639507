import serverAPI from "../../../../api/serverAPI";

export const fetchChronicDisease = () => async dispatch => {
    dispatch({ type: 'CHRONIC_DISEASE_LOADING' });
    const response = await serverAPI.get(`/ChronicDiseases/`);
    dispatch({ type: 'CHRONIC_DISEASE_INITIAL', chronicDisease: response.data.data });
}

export const fetchPatientChronicDiseases = id => async dispatch => {
    dispatch({ type: 'PATIENT_CHRONIC_DISEASE_LOADING' });
    const response = await serverAPI.get(`/PatientChronicDiseases/${id}`);
    dispatch({ type: 'PATIENT_CHRONIC_DISEASE_INITIAL', patientChronicDisease: response.data.data });
}