import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';

import { fetchLabExamTypes } from "../../ClinicScreen/LabOrders/actions";
import serverAPI from "../../../api/serverAPI";
import { fetchLabExamResults } from "./actions";

class Table extends React.Component {

    state = {
        OrderId: 0,
        SearchByName: "",
        ExamTypes: [],
        SearchExamTypes: null,
        showInfo: false,
        Exam: null
    };

    constructor(props) {
        super(props);
        const { labExamTypes } = this.props;
        if (!labExamTypes || !labExamTypes.labExamTypes || labExamTypes.labExamTypes?.length === 0) {
            this.props.fetchLabExamTypes();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.labExamTypes && props.labExamTypes.labExamTypes) {
            state.ExamTypes = props.labExamTypes.labExamTypes.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: item.Name,
                };
            });
        }
        if (props.Order && props.Order.Id !== state.OrderId) {
            state.OrderId = props.Order.Id;
            props.fetchLabExamResults(props.Order.Id);
        }
        return state;
    }

    setSearch = SearchByName => {
        this.setState({
            SearchByName
        });
    }

    setSearchExamTypes = SearchExamTypes => {
        this.setState({
            SearchExamTypes
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    setExamResult = (Result, Id, Exam) => {
        if (Result) {
            const { t } = this.props;

            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            serverAPI.post(`/LabExamResult/${Id}`, {
                Result,
                ExamId: Exam.Id
            }).then(response => {
                toast.dismiss(_toastId);
                this.props.fetchLabExamResults(Id);
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            {t('toast.NoConnection.Title')}
                        </div>
                        <div className="h6">
                            {t('toast.NoConnection.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
            });
        }
    }

    Approved = (Id, Exam) => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        serverAPI.put(`/ApprovedLabExamResult/${Id}`, {
            ExamId: Exam.Exam.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.Save.Approved.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Approved.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.props.fetchLabExamResults(Id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        });
    }

    showInfo = (Exam, examResult) => {
        this.setState({
            Exam: {
                ...Exam,
                ...examResult
            },
            showInfo: true
        });
    }

    handleClose = () => {
        this.setState({
            Exam: null,
            showInfo: false
        });
    }

    renderTableRow = () => {
        const { t, Order, labExamResults } = this.props;
        if (Order && Order.items && Order.items.length > 0) {
            let searchResult = Order.items.filter(p => p.Exam.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()) || p.Exam.Profile?.Name.toLowerCase().includes(this.state.SearchByName.toLowerCase()));
            if (this.state.SearchExamTypes) {
                searchResult = searchResult.filter(p => this.state.SearchExamTypes.value === p.Exam.Type.Id);
            }

            if (searchResult.length > 0) {
                return searchResult.map((exam) => {
                    var examResult = labExamResults.labExamResults?.filter(p => p.Exam.Id === exam.Exam.Id && Order.Id === p.OrderId)[0];
                    if (exam.Exam.Profile && exam.Exam.IsSelectable === true)
                        return "";
                    return <tr className="text-dark" key={`lab-exam-result-${exam.Exam.Id}-${Order.Id}`}>
                        <td>
                            {exam.Exam.Type.Name}
                        </td>
                        <td>
                            {exam.Exam.Profile?.Name}
                        </td>
                        <td>
                            {exam.Exam?.Name}
                        </td>
                        <td>
                            <input
                                type="text"
                                disabled={examResult?.IsApproved}
                                defaultValue={examResult?.Result}
                                onBlur={e => this.setExamResult(e.target.value, Order.Id, exam.Exam)}
                                className={`form-control form-control-sm border-dark`}
                            />
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                            {
                                examResult?.IsApproved ? "" :
                                    <Link
                                        to="#"
                                        title={t(`Table.Options.Approved.Title`)}
                                        onClick={() => this.Approved(Order.Id, exam)}
                                        className={`btn btn-sm btn-icon btn-light-dark ms-1`}  >
                                        <i className="fa-solid fa-check"></i>
                                    </Link>
                            }


                            <Link
                                to="#"
                                title={t("Table.Options.Info")}
                                onClick={() => this.showInfo(exam, examResult)}
                                className="btn btn-sm btn-icon btn-info ms-1"  >
                                <i className="fa-solid fa-info"></i>
                            </Link>
                        </td>
                    </tr>
                });
            }
            return <tr><td className="text-center" colSpan={7}>{t("NoResult")}</td></tr>;
        }
        return <tr><td className="text-center" colSpan={7}>{t("NoRows")}</td></tr >;
    }

    render() {
        const { t, labExamTypes, labExamResults } = this.props;

        let isLoaded = false;
        if (labExamResults) {
            isLoaded = labExamResults.isLoaded;
        }

        let isLoadedExamTypes = false;
        if (labExamTypes) {
            isLoadedExamTypes = labExamTypes.isLoaded;
        }

        return <>

            {
                this.state.showInfo && this.state.Exam ?
                    <Modal
                        size="lg"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={true}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                                {t("Table.Options.Info")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-vial me-2"></i>
                                            {t("ClinicScreen.Orders.Lab.Table.Name")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.Exam.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-flask-vial me-2"></i>
                                            {t("ClinicScreen.Orders.Lab.Table.Type")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.Exam.Type?.Name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-vial-virus me-2"></i>
                                            {t("Lab.Results.Table.Result")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.Result}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-tags me-2"></i>
                                            {t("Lab.Results.Table.Range.Title")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.Range}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-user me-1"></i>
                                            {t("LastUpdatedUser")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.User?.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("Lab.SpecimenTaken.Date")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.SpecimenTakenDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("CreationDate")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.CreationDate}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <span className="fw-bold text-muted mb-2">
                                            <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                            {t("LastUpdatedDate")}
                                        </span>
                                        <span className="ms-10">
                                            {this.state.Exam.UpdateDate}
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                    : ""
            }

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                    <label className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`} htmlFor="SearchByName">{t('Search')} </label>
                    <input
                        value={this.state.SearchByName}
                        onChange={e => this.setSearch(e.target.value)}
                        ref={SearchByName => this.SearchByName = SearchByName}
                        name="SearchByName"
                        id="SearchByName"
                        autoComplete={"off"}
                        type={"text"}
                        placeholder={t('AdvancedSearch.Search.ByName')}
                        title={t('AdvancedSearch.Search.ByName')}
                        className="form-control form-control-sm" />
                </div>
                <div className="d-flex align-items-center">
                    <Select
                        onChange={this.setSearchExamTypes}
                        isClearable={true}
                        name="ExamTypes "
                        id="ExamTypes "
                        placeholder={t('AdvancedSearch.Search.ByExamTypes')}
                        title={t('AdvancedSearch.Search.ByExamTypes')}
                        isRtl={true}
                        isLoading={!isLoadedExamTypes}
                        isSearchable={true}
                        ref={ExamTypes => this.ExamTypes = ExamTypes}
                        options={this.state.ExamTypes}
                        filterOption={this.filterOptions}
                    />

                </div>
            </div>
            <div className={`table-responsive-md w-100 h-100 ${isLoaded ? "" : "table-loading"}`}  >
                <div className="table-loading-message">
                    {t("Processing.Title")}
                </div>
                <table className="table table-sm table-striped table-row-gray-800 table-rounded table-hover table-fix-head border" style={{
                    borderCollapse: 'collapse'
                }} >
                    <thead className="fs-6 fw-bold align-middle border  border-gray-800" style={{
                        borderCollapse: 'collapse'
                    }}>
                        <tr>
                            <th rowSpan={2} title={t("ClinicScreen.Orders.Lab.Table.Type")}  >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-flask-vial me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Type")}
                                    </span>
                                </span>
                            </th>
                            <th rowSpan={2} title={t("ClinicScreen.Orders.Lab.Table.Profile")} >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-cubes me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Profile")}
                                    </span>
                                </span>
                            </th>
                            <th rowSpan={2} title={t("ClinicScreen.Orders.Lab.Table.Name")}   >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-vial me-md-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("ClinicScreen.Orders.Lab.Table.Name")}
                                    </span>
                                </span>
                            </th>

                            <th rowSpan={2} title={t("Lab.Results.Table.Result")}   >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-vial-virus me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Lab.Results.Table.Result")}
                                    </span>
                                </span>
                            </th>
                            <th colSpan={3} title={t("Lab.Results.Table.Range.Title")}   >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-tags me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Lab.Results.Table.Range.Title")}
                                    </span>
                                </span>
                            </th>
                            <th rowSpan={2} title={t("Table.Options.Title")} >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-gears me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Table.Options.Title")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                        <tr>
                            <th title={t("Lab.Results.Table.Range.Min")}   >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-arrow-down-to-line me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Lab.Results.Table.Range.Min")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Lab.Results.Table.Range.Max")}  >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-arrow-up-to-line me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Lab.Results.Table.Range.Max")}
                                    </span>
                                </span>
                            </th>
                            <th title={t("Lab.Results.Table.Range.Unit")}  >
                                <span className="d-flex align-items-center justify-content-center">
                                    <i className="fa-regular text-dark fa-tag me-1"></i>
                                    <span className="d-md-block d-none text-nowrap">
                                        {t("Lab.Results.Table.Range.Unit")}
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="fs-7 text-gray-800 border border-gray-800" style={{
                        borderCollapse: 'collapse'
                    }}>
                        {this.renderTableRow()}
                    </tbody>
                </table>
            </div>
        </>;

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        labExamResults: state.labExamResults,
        labExamTypes: state.labExamTypes
    };
};

export default connect(mapStateToProps, { fetchLabExamTypes, fetchLabExamResults })(withTranslation('common')(Table));