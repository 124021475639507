import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";

import Card from "../Card";
import PatientName from "../../../Patients/PatientName";
import PrintOrder from "../../../PrintTemplate/PrintOrder";

class Info extends React.Component {

    render() {
        const { t, Order } = this.props;
        return <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            enforceFocus={false}
            show={true}
            onHide={this.props.handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>
                    <i className="h3 fa-duotone fa-info me-2 text-primary "></i>
                    {t("Table.Options.Info")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center"></div>
                    <div className="d-flex align-items-center">
                        <ReactToPrint
                            copyStyles={true}
                            documentTitle={t("ClinicScreen.Orders.Lab.Description")}
                            trigger={() => {
                                return <Link to="#" className="btn btn-sm btn-sm-icon btn-light-primary ms-auto d-flex align-items-center" title={t('Print')}>
                                    <i className="fa-regular fa-print"></i>
                                    <span className="d-md-block d-none text-nowrap ms-1">
                                        {t('Print')}
                                    </span>
                                </Link>;
                            }}
                            content={() => this.componentRef}
                        />
                    </div>
                </div>

                {
                    Order.patient ? <>
                        <span className="d-flex align-items-center text-muted">
                            <i className="fa-regular text-dark fa-user-injured me-1"></i>
                            <span className="d-md-block d-none text-nowrap">
                                {t("Patient.Name")}
                            </span>
                        </span>
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <div className="d-flex align-items-center ms-10">
                                <div className={`col-form-label fw-bold fs-6 me-2 d-flex align-items-center`}>
                                    <PatientName patient={Order.patient} />
                                </div>
                            </div>
                        </div>
                    </> : ""
                }

                <div ref={el => (this.componentRef = el)}>
                    <PrintOrder patient={this.props.patient} content={<Card forPrint={true} {...this.props} />} />
                </div>

                <Card {...this.props} />
                <div className="separator separator-dashed border-dark mb-5"></div>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-user me-1"></i>
                                {t("LastUpdatedUser")}
                            </span>
                            <span className="ms-10">
                                {Order.User.Name}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("CreationDate")}
                            </span>
                            <span className="ms-10">
                                {Order.CreationDate}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <span className="fw-bold text-muted mb-2">
                                <i className="fa-regular text-dark fa-calendar-clock me-1"></i>
                                {t("LastUpdatedDate")}
                            </span>
                            <span className="ms-10">
                                {Order.UpdateDate}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>;
    }
}

export default withTranslation('common')(Info);