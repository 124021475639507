import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, getFormValues, reduxForm } from "redux-form";
import { toast } from "react-toastify";

import SelectService from "./SelectService";
import serverAPI from "../../../../api/serverAPI";

class Form extends React.Component {

    state = {
        Currency: null,
        isSubmit: false,
        PaymentType: "",
        SelectedService: [],
        count: 0,
        Total: 0
    };

    setSelectedService = SelectedService => {
        let count = 0;
        SelectedService.forEach(item => {
            if (item.IsDeported === false) {
                count++;
            }
        });
        this.setState({
            SelectedService, count
        }, () => { this.setTotal() });
    }

    renderField = ({
        id,
        input,
        title,
        type,
        placeholder,
        required,
        className,
        disabled,
        style,
        checked,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} checked={checked} disabled={disabled} id={id} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setPaymentType = PaymentType => {
        this.setState({
            PaymentType
        });
    }

    setTotal = () => {
        let Total = 0;
        const Currency = this.props.user.user?.HealthCenter.Currency;
        const Rate = Currency.Rate;
        this.state.SelectedService.forEach(Service => {
            if (Service.IsDeported === false) {
                Total += Service.Price * (Service.Currency.Rate / Rate);
            }
        });
        this.setState({
            Total
        });
    }

    onSubmit = event => {
        event.preventDefault();

        const { t, Bill } = this.props;
        this.setState({
            isSubmit: true
        });
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing.Title')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        serverAPI.post(`/PatientBills/${Bill.Visit.Id}`, {
            ...this.props.formValues,
            Services: this.state.SelectedService.map(({ Id }) => Id),
            PatientId: Bill.Visit.patient.Id
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div
                ><div className="h5">
                        {t('toast.Save.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.Save.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
            this.props.update(this.state.PaymentType, this.state.Total, this.props.user.user?.HealthCenter.Currency);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        {t('toast.NoConnection.Title')}
                    </div>
                    <div className="h6">
                        {t('toast.NoConnection.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            this.setState({
                isSubmit: false
            });
        });
        return false;
    }

    render() {
        const { t } = this.props;

        return <>
            <form method="POST" name="formReceiveCashBill" className="form fv-plugins-bootstrap5 fv-plugins-framework" autoComplete="new-password" onSubmit={(e) => this.onSubmit(e)}>
                <div className="row mb-5">
                    <SelectService {...this.props} setSelectedService={this.setSelectedService} />
                </div>

                <div className="row mb-5">
                    <div className="col-md-12">
                        <label className={`col-form-label fw-bold required fs-6`} htmlFor="PaymentType"><i className="fa-regular text-dark me-2 fa-cash-register"></i>{t('Accounting.ReceiveCash.Payment.Type.Title')}</label>
                        <div className="d-flex justify-content-around flex-wrap align-items-center pt-1">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field name="PaymentType" component={this.renderField} type="radio" value={"Cash"} id="Cash" onChange={() => this.setPaymentType('Cash')} className="form-check-input" required checked={this.state.PaymentType === "Cash"} />
                                <label className="form-check-label" htmlFor="Cash">
                                    <i className="fa-solid me-1 fa-money-bills text-primary" title={t("Accounting.ReceiveCash.Payment.Type.Options.Cash")}></i>
                                    {t("Accounting.ReceiveCash.Payment.Type.Options.Cash")}
                                </label>
                            </div>

                            <div className="form-check form-check-custom form-check-danger form-check-solid">
                                <Field name="PaymentType" component={this.renderField} type="radio" value={"Postponed"} id="Postponed" onChange={() => this.setPaymentType('Postponed')} className="form-check-input" required checked={this.state.PaymentType === "Postponed"} />
                                <label className="form-check-label" htmlFor="Postponed">
                                    <i className="fa-solid me-1 fa-money-check text-danger" title={t("Accounting.ReceiveCash.Payment.Type.Options.Postponed")}></i>
                                    {t("Accounting.ReceiveCash.Payment.Type.Options.Postponed")}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer pb-0 px-0">
                    <button type="submit" className="btn btn-sm btn-success mx-1" title={t('Accounting.ReceiveCash.Accept.Title')} disabled={this.state.isSubmit || this.state.count === 0 || this.state.PaymentType === ""} data-kt-indicator={this.state.isSubmit ? "on" : "off"}  >
                        <span className="indicator-label">
                            <i className="me-1 fa-solid fa-save"></i>
                            {t('Accounting.ReceiveCash.Accept.Title')}
                        </span>
                        <span className="indicator-progress">{t('PleaseWait')}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button type="button" className="btn btn-sm btn-danger mx-1" title={t('Close')} onClick={this.props.handleClose}>
                        <i className="me-1 fa-solid fa-xmark"></i>
                        {t('Close')}
                    </button>
                </div>

            </form>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('formReceiveCashBill')(state),
        user: state.user,
        patientBills: state.patientBills
    };
};

const formReceiveCashBill = reduxForm({
    form: 'formReceiveCashBill',
    persistentSubmitErrors: true,
    touchOnBlur: true
});

export default connect(mapStateToProps, {})(withTranslation('common')(formReceiveCashBill(Form)));