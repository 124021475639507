import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Form from "./Search/Form";
import Table from "./Search/Table";
import Create from "./Create";

class Patients extends React.Component {

    state = { showNewPatient: false };

    showNewPatient = () => {
        this.setState({
            showNewPatient: true
        });
    }

    handleClose = () => {
        this.setState({
            showNewPatient: false
        });
    }

    render() {
        const { t } = this.props;
        return <>
            <Helmet>
                <title>{t("Patient.Title")} | {t('SiteName')}</title>
            </Helmet>
            {
                this.state.showNewPatient ? <Create handleClose={this.handleClose} /> : ""
            }
            <div className="card h-100">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-solid fa-magnifying-glass mx-1 text-info "></i>
                            {t('Search')}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <Link
                            to="#"
                            title={t('Patient.New')}
                            className="btn btn-sm btn-sm-icon btn-light-primary "
                            onClick={() => this.showNewPatient()}>
                            <i className="me-1 fa-solid fa-plus"></i>
                            {t('Patient.New')}
                        </Link>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Form {...this.props} />
                    </div>
                </div>
            </div>
            <div className="card h-100">
                <div className="card-header align-items-center ">
                    <h3 className="card-title">
                        <span className="fw-bolder mb-2 text-dark">
                            <i className="h3 fa-duotone fa-user-injured me-2 text-info mb-0"></i>
                            {t('Patient.Title')}
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-5">
                    <div className="w-100">
                        <Table />
                    </div>
                </div>
            </div>
        </>;
    }
}

export default withTranslation('common')(Patients);