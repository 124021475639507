const FamilyHistoryReducers = (state = [], action) => {
    switch (action.type) {
        case "FAMILY_HISTORY_LOADING":
            return {
                familyHistory: null,
                isLoaded: false
            };
        case "FAMILY_HISTORY_INITIAL":
            return {
                familyHistory: action.familyHistory,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default FamilyHistoryReducers;